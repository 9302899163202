import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";
import { useHistory, useLocation } from "react-router";
import success from "../animation/succesful.json";
import * as thirdpartyService from "../services/thirdpartyService";
import { message} from 'antd';

function ThirdPartySuccess(props) {
  const container = useRef(null);
  let history = useHistory();
  let location = useLocation();

  useEffect(() => {
    // Animation setup
    lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: success,
    });
  
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    const locationParam = queryParams.get('location');
    const accountsServer = queryParams.get('accounts-server');

    const projectId = localStorage.getItem('projectId');
    const type = localStorage.getItem("authCategory") || "";
  
    const checkTokenAsync = async () => {
      try {
        await thirdpartyService.checkToken(projectId,code, locationParam, accountsServer, type);
      } catch (error) {
        message.error("Token check failed. Our team will get back to you");
      }
    };
  
    checkTokenAsync();
  }, [location.search]);
  


  const handleClick = () => {
    history.replace("/");
  };

  return (
    <div style={{ height: "100vh" }} className="flexcolumn flexcolumn--allcenter">
      <div
        style={{ height: "600px" }}
        className="container"
        ref={container}
      ></div>
      <h1>Authentication Successful</h1>
      <button onClick={handleClick} className="btnn btnn-primary">
        Take me to dashboard
      </button>
    </div>
  );
}

export default ThirdPartySuccess;
