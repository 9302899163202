import React,{useState, useEffect} from 'react';
import { useHistory} from "react-router";
import Form from '../common/Form';
import Joi from "joi-browser";
import SubmitInput from '../common/SubmitInput';
import { message } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import * as knowledgeService from "../services/knowledgebaseService";
import Navbar from '../common/Navbar';



function ExtractForm({passedData}) {

  const history = useHistory();

  const urlPath = history.location.pathname;
  const urlSearch = history.location.search;




useEffect(() => {


  if (!passedData) {
    history.push("/")
  }
}, [passedData, history]);




    const [inputField, setInputField] = useState({
        description1: "",
        description2: "",
        description3: "",
        description4: "",
        description5: "",
        description6: "",
        description7: "",
        description8: "",
        description9: "",
        description10: "",
        description11: "",
        description12: "",
        description13: "",
        description14: "",
        description15: "",
        description16: "",
        description17: "",
        description18: "",
        description19: "",
        description20: "",
        description21: "",
        description22: "",
        description23: "",
        description24: "",
        description25: "",
        description26: "",
        description27: "",
        description28: "",
        description29: "",
        description30: "",
    
      });
    
      const [errorMessage] = useState({});
      const[loading, setLoading] = useState(false);

      const [emptyState, setEmptyState]= useState(false);

      const [isDashboard, setIsDashboard]= useState(false);
    
      let [count, setCount] = useState(1)
    
      // let history = useHistory();
      // let location = useLocation();
      // let auth = useAuth();
    
      const template = [
        {
          name: "description1",
          placeholder: "Copy description here",
          type: "textArea",
          customClass: "light--extend",
        },
        (count >= 2) && {
          name: "description2",
          placeholder: "Copy description here",
          type: "textArea",
          customClass: "light--extend",
        },
        (count >= 3) && {
          name: "description3",
          placeholder: "Copy description here",
          type: "textArea",
          customClass: "light--extend",
        },
        (count >= 4) && {
          name: "description4",
          placeholder: "Copy description here",
          type: "textArea",
          customClass: "light--extend",
        },
        (count >= 5) &&{
          name: "description5",
          placeholder: "Copy description here",
          type: "textArea",
          customClass: "light--extend",
        },
        (count >= 6) &&{
          name: "description6",
          placeholder: "Copy description here",
          type: "textArea",
          customClass: "light--extend",
        },
        (count >= 7) &&{
          name: "description7",
          placeholder: "Copy description here",
          type: "textArea",
          customClass: "light--extend",
        },
        (count >= 8) && {
          name: "description8",
          placeholder: "Copy description here",
          type: "textArea",
          customClass: "light--extend",
        },
        (count >= 9) &&{
          name: "description9",
          placeholder: "Copy description here",
          type: "textArea",
          customClass: "light--extend",
        },
        (count >= 10) &&{
          name: "description10",
          placeholder: "Copy description here",
          type: "textArea",
          customClass: "light--extend",
        },
        (count >= 11) &&{
          name: "description11",
          placeholder: "Copy description here",
          type: "textArea",
          customClass: "light--extend",
        },
        (count >= 12) &&{
          name: "description12",
          placeholder: "Copy description here",
          type: "textArea",
          customClass: "light--extend",
        },
        (count >= 13) &&{
          name: "description13",
          placeholder: "Copy description here",
          type: "textArea",
          customClass: "light--extend",
        },
        (count >= 14) &&{
          name: "description14",
          placeholder: "Copy description here",
          type: "textArea",
          customClass: "light--extend",
        },
        (count >= 15) &&{
          name: "description15",
          placeholder: "Copy description here",
          type: "textArea",
          customClass: "light--extend",
        },
        (count >= 16) &&{
          name: "description16",
          placeholder: "Copy description here",
          type: "textArea",
          customClass: "light--extend",
        },
        (count >= 17) &&{
          name: "description17",
          placeholder: "Copy description here",
          type: "textArea",
          customClass: "light--extend",
        },
        (count >= 18) && {
          name: "description18",
          placeholder: "Copy description here",
          type: "textArea",
          customClass: "light--extend",
        },
        (count >= 19) &&{
          name: "description19",
          placeholder: "Copy description here",
          type: "textArea",
          customClass: "light--extend",
        },
        (count >= 20) &&{
          name: "description20",
          placeholder: "Copy description here",
          type: "textArea",
          customClass: "light--extend",
        },
        (count >= 21) &&{
          name: "description21",
          placeholder: "Copy description here",
          type: "textArea",
          customClass: "light--extend",
        },
        (count >= 22) &&{
          name: "description22",
          placeholder: "Copy description here",
          type: "textArea",
          customClass: "light--extend",
        },
        (count >= 23) &&{
          name: "description23",
          placeholder: "Copy description here",
          type: "textArea",
          customClass: "light--extend",
        },
        (count >= 24) &&{
          name: "description24",
          placeholder: "Copy description here",
          type: "textArea",
          customClass: "light--extend",
        },
        (count >= 25) &&{
          name: "description25",
          placeholder: "Copy description here",
          type: "textArea",
          customClass: "light--extend",
        },
        (count >= 26) &&{
          name: "description26",
          placeholder: "Copy description here",
          type: "textArea",
          customClass: "light--extend",
        },
        (count >= 27) &&{
          name: "description27",
          placeholder: "Copy description here",
          type: "textArea",
          customClass: "light--extend",
        },
        (count >= 28) &&{
          name: "description28",
          placeholder: "Copy description here",
          type: "textArea",
          customClass: "light--extend",
        },
        (count >= 29) &&{
            name: "description29",
            placeholder: "Copy description here",
            type: "textArea",
            customClass: "light--extend",
          },
          (count >= 30) &&{
            name: "description30",
            placeholder: "Copy description here",
            type: "textArea",
            customClass: "light--extend",
          },
    
      ];

      const handleAdd =()=>{
        if(count===30)
        {
        setIsModalOpen(true);
        return;
        }

        count = count+1;
        setCount(count);
      }  
    
      const [isModalOpen, setIsModalOpen] = useState(false);
      const [isSaveOpen, setisSaveOpen] = useState(false);



      const showModal = () => {
        setIsModalOpen(true);
      };
    
      const handleOk = () => {
        setIsModalOpen(false);
      };
    
      const handleCancel = () => {
        setIsModalOpen(false);

      }

      const handleSaveCancel = () => {
        setisSaveOpen(false);

      }

      const handleDashboardCancel =()=>{

      }
    
    
      const buttonInfo = { label: "Update", style: "" };
    
      const schema = {
        description1: Joi.string().regex(/^\s*(\S+\s+){0,400}\S*$/).options({ language: { string: { regex: { base: 'This section must contain no more than 400 words' } } } }).label("this section"),
        // description1: Joi.string().regex(/^(([\w\s,."'()-]+)\b[\s,.]*){0,400}$/).options({ language: { string: { regex: { base: 'This section must contain no more than 400 words' } } } }).label("this section"),
        description2: Joi.string().allow(null, "").regex(/^\s*(\S+\s+){0,400}\S*$/).options({ language: { string: { regex: { base: 'This section must contain no more than 400 words' } } } }).label("this section"),
        description3: Joi.string().allow(null, "").regex(/^\s*(\S+\s+){0,400}\S*$/).options({ language: { string: { regex: { base: 'This section must contain no more than 400 words' } } } }).label("this section"),
        description4: Joi.string().allow(null, "").regex(/^\s*(\S+\s+){0,400}\S*$/).options({ language: { string: { regex: { base: 'This section must contain no more than 400 words' } } } }).label("this section"),
        description5: Joi.string().allow(null, "").regex(/^\s*(\S+\s+){0,400}\S*$/).options({ language: { string: { regex: { base: 'This section must contain no more than 400 words' } } } }).label("this section"),
        description6: Joi.string().allow(null, "").regex(/^\s*(\S+\s+){0,400}\S*$/).options({ language: { string: { regex: { base: 'This section must contain no more than 400 words' } } } }).label("this section"),
        description7:Joi.string().allow(null, "").regex(/^\s*(\S+\s+){0,400}\S*$/).options({ language: { string: { regex: { base: 'This section must contain no more than 400 words' } } } }).label("this section"),
        description8: Joi.string().allow(null, "").regex(/^\s*(\S+\s+){0,400}\S*$/).options({ language: { string: { regex: { base: 'This section must contain no more than 400 words' } } } }).label("this section"),
        description9: Joi.string().allow(null, "").regex(/^\s*(\S+\s+){0,400}\S*$/).options({ language: { string: { regex: { base: 'This section must contain no more than 400 words' } } } }).label("this section"),
        description10: Joi.string().allow(null, "").regex(/^\s*(\S+\s+){0,400}\S*$/).options({ language: { string: { regex: { base: 'This section must contain no more than 400 words' } } } }).label("this section"),
        description11: Joi.string().allow(null, "").regex(/^\s*(\S+\s+){0,400}\S*$/).options({ language: { string: { regex: { base: 'This section must contain no more than 400 words' } } } }).label("this section"),
        description12: Joi.string().allow(null, "").regex(/^\s*(\S+\s+){0,400}\S*$/).options({ language: { string: { regex: { base: 'This section must contain no more than 400 words' } } } }).label("this section"),
        description13: Joi.string().allow(null, "").regex(/^\s*(\S+\s+){0,400}\S*$/).options({ language: { string: { regex: { base: 'This section must contain no more than 400 words' } } } }).label("this section"),
        description14: Joi.string().allow(null, "").regex(/^\s*(\S+\s+){0,400}\S*$/).options({ language: { string: { regex: { base: 'This section must contain no more than 400 words' } } } }).label("this section"),
        description15: Joi.string().allow(null, "").regex(/^\s*(\S+\s+){0,400}\S*$/).options({ language: { string: { regex: { base: 'This section must contain no more than 400 words' } } } }).label("this section"),
        description16: Joi.string().allow(null, "").regex(/^\s*(\S+\s+){0,400}\S*$/).options({ language: { string: { regex: { base: 'This section must contain no more than 400 words' } } } }).label("this section"),
        description17: Joi.string().allow(null, "").regex(/^\s*(\S+\s+){0,400}\S*$/).options({ language: { string: { regex: { base: 'This section must contain no more than 400 words' } } } }).label("this section"),
        description18: Joi.string().allow(null, "").regex(/^\s*(\S+\s+){0,400}\S*$/).options({ language: { string: { regex: { base: 'This section must contain no more than 400 words' } } } }).label("this section"),
        description19: Joi.string().allow(null, "").regex(/^\s*(\S+\s+){0,400}\S*$/).options({ language: { string: { regex: { base: 'This section must contain no more than 400 words' } } } }).label("this section"),
        description20: Joi.string().allow(null, "").regex(/^\s*(\S+\s+){0,400}\S*$/).options({ language: { string: { regex: { base: 'This section must contain no more than 400 words' } } } }).label("this section"),
        description21: Joi.string().allow(null, "").regex(/^\s*(\S+\s+){0,400}\S*$/).options({ language: { string: { regex: { base: 'This section must contain no more than 400 words' } } } }).label("this section"),
        description22:Joi.string().allow(null, "").regex(/^\s*(\S+\s+){0,400}\S*$/).options({ language: { string: { regex: { base: 'This section must contain no more than 400 words' } } } }).label("this section"),
        description23: Joi.string().allow(null, "").regex(/^\s*(\S+\s+){0,400}\S*$/).options({ language: { string: { regex: { base: 'This section must contain no more than 400 words' } } } }).label("this section"),
        description24: Joi.string().allow(null, "").regex(/^\s*(\S+\s+){0,400}\S*$/).options({ language: { string: { regex: { base: 'This section must contain no more than 400 words' } } } }).label("this section"),
        description25: Joi.string().allow(null, "").regex(/^\s*(\S+\s+){0,400}\S*$/).options({ language: { string: { regex: { base: 'This section must contain no more than 400 words' } } } }).label("this section"),
        description26: Joi.string().allow(null, "").regex(/^\s*(\S+\s+){0,400}\S*$/).options({ language: { string: { regex: { base: 'This section must contain no more than 400 words' } } } }).label("this section"),
        description27: Joi.string().allow(null, "").regex(/^\s*(\S+\s+){0,400}\S*$/).options({ language: { string: { regex: { base: 'This section must contain no more than 400 words' } } } }).label("this section"),
        description28: Joi.string().allow(null, "").regex(/^\s*(\S+\s+){0,400}\S*$/).options({ language: { string: { regex: { base: 'This section must contain no more than 400 words' } } } }).label("this section"),
        description29: Joi.string().allow(null, "").regex(/^\s*(\S+\s+){0,400}\S*$/).options({ language: { string: { regex: { base: 'This section must contain no more than 400 words' } } } }).label("this section"),
        description30: Joi.string().allow(null, "").regex(/^\s*(\S+\s+){0,400}\S*$/).options({ language: { string: { regex: { base: 'This section must contain no more than 400 words' } } } }).label("this section"),
    
      };

      // const { projectId } = props.match.params;
    



const handleSubmit =async(data)=>{


  const output = Object.values(data)
  .filter(value => value !== "") 
  .map(value => ({ value: { data: value } }));

  if(urlPath.includes("edit")|| urlSearch.includes("edit"))
  {


    const finalData = {
      label: passedData.label,
      projectId: passedData.projectId,
      knowledgeBasePatchObject: {
        add: output,
      },
    };

    try {
      const response = await knowledgeService.updateKB(finalData);
      if(response.status===200)
      {
        setisSaveOpen(true)
        setLoading(false)
        setEmptyState(true);
        return;
      }
    } catch (ex) {
      if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
        setLoading(false);
    
        message.error(ex.response.data.msg);
        return;
      }
    }
    
    return;
  }

  const finalData ={};
  finalData.label =passedData.label;
  finalData.projectId = passedData.projectId;


 
  finalData.paragraphs = output;   



try {
  const response = await knowledgeService.createProject(finalData);
  if(response.status===200)
  {
    history.replace("/");
  }
} catch (ex) {
  if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
    setLoading(false);

    message.error(ex.response.data.msg);
    return;
  }
}




}


const handleRemain =()=>{


  setLoading(false);
  setEmptyState(true);
  setisSaveOpen(false)
  
}

const handleDashboard =()=>{
 
  setIsDashboard(true)

}

const handleRedirection =()=>{
  history.replace("/");
}

const handleRedirectionCancel =()=>{
  setIsDashboard(false)

}






    return (
   
        <div>
       
          <div className='overflow'>
          <div>
            <h2 style={{paddingBottom:"20px", paddingTop:"20px"}}>Add content for basic training</h2>
            <div>
              <p>To open more input fields, simply click on the "add extra field" button. <br/> This will create additional space for you to input more information or data.</p>
              <div className='flexrow'>
                <div style={{marginRight:"10px"}} onClick={handleDashboard} className='roundButton'> Take me to dashboard</div>
                <div onClick={handleAdd} className='roundButton'> + add extra field</div>
              </div>
            </div>
            </div>
              <div style={{overFlow:"none"}}>
                <Form
                        errorMessage={errorMessage}
                        inputField={inputField}
                        template={template}
                        schema={schema}
                        buttonInfo={buttonInfo}
                        onSubmit={handleSubmit}
                        loading={loading}
                        setLoading={setLoading}
                        emptyState={emptyState}
                        setEmptyState={setEmptyState}
                      />
              </div>
              <Modal   cancelText="Close" title="Save your content" open={isModalOpen} onOk={handleOk} okText="Save">
          
          
          <p>As a good practise, we recommend our users to save existing content at this point and continue forward </p>
                </Modal>
                <Modal   cancelText="Close" title="Your changes succesfully saved. choose an option" open={isSaveOpen} footer={false} onCancel={handleSaveCancel} >
               <div className='flexcolumn'>
           <button  style={{width:"100%"}} onClick={handleRemain} className='btnn '>Remain on this page</button>
           <button style={{width:"100%"}}  onClick={handleDashboard}  className='btnn '>Navigate to dashboard</button>
               </div>
                </Modal>
          
                <Modal   cancelText="Close" title="Progress not saved" open={isDashboard} onCancel={handleRedirectionCancel} onOk={handleRedirection} okText="Proceed without saving">
          
          
          <p>If you don't update the changes, you'll lose everything on this page. Click "update" below the form to avoid losing your work. </p>
                </Modal>
              </div>
        </div>
    );
}

export default ExtractForm;