import http from "./httpService";
const apiEndpoint = "/supwebhooks";

export function subscribetoWebhook(projectId, eventName,endpointUrl) {
    return http.post(apiEndpoint + "/" + "subscribe", {
        projectId: projectId,
        eventName : eventName,
        endpointUrl : endpointUrl
    });
  }
  