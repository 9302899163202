import React from "react";
import Toggle from "../common/Toggle"


const Input = ({
  name,
  label,
  value,
  error,
  onChange,
  type,
  style,
  placeholder,
  options,
  width,
  customClass
}) => {

  return ( 

    <div>
     { (type === "text" || type === "number") && (
      <>
           <label  htmlFor={name}>{label}</label>
        <input
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          className={style === "extend" ? "input--custom input--extend input--dark" : "input--custom input--dark"}
          id={name}
          name={name}
          type={type}
        />
        {error && <div className="alertmessage ">{error}</div>}
      </>
    )}


      {type === "password" && (
        <div>
          <div>
            <label className="label-style" htmlFor={name}>
              {label}
            </label> 
            <input
              key={name}
              value={value}
              onChange={onChange}
              className="input--custom input--dark"
              placeholder={placeholder}
              id={name}
              name={name}
              type={type}
            />
            {error && <div className="alertmessage ">{error}</div>}
          </div>
        </div>
      )}

      {type === "email" && (
        <div>
          <div>
             <label className="label-style" htmlFor={name}>
              {label}
            </label>
            <input
              key={name}
              value={value}
              onChange={onChange}
              className="input--custom input--dark"
              placeholder={placeholder}
              id={name}
              name={name}
              type={type}
              
            />
            {error && <div className="alertmessage ">{error}</div>}
          </div>
        </div>
      )}

      {type === "textArea" && (
        <div>
          <div className="input-bundle">
            <label className="label-style" htmlFor={name}>
              {label}
            </label> 
            <textarea
              key={name}
              value={value}
              onChange={onChange}
              className={
                customClass=== "" ?
                  "input--custom input--width input--height--small" :
                  customClass === "small--width" ?
                    "input--custom input--width--small input--height--small" :
                    "input--custom input--height input--width"
              }
              
               placeholder={placeholder}
              id={name}
              name={name}
              type={type}
              style={style}
            />
            {error && <div className="alertmessage ">{error}</div>}
          </div>
        </div>
      )}

      {type === "select" && (
        <div style={{marginBottom:"10px"}} className="form-group">
          <label htmlFor={name}>{label}</label>
          <select
            name={name}
            id={name}
            onChange={onChange}
            className="selectcustom"
            value={value}
          >
            <option value="" />
            {options.map((option) => (
              <option  key={option.id} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {error && <div className="alertmessage">{error}</div>}
        </div>
      )}

      {type === "toggle" && (
       <div  style={{marginBottom:"30px"}}>
       <label>{label}</label>
       <Toggle
         toggle={value}
        
         onChange={(isActive) => {
           onChange({ 
             currentTarget: { 
               name: name, 
               value: isActive 
             }, 
             toggleValue: isActive 
           })
         }}
       />
       {error && <div className="alertmessage ">{error}</div>}
     </div>
      )}

    </div>
  );
};

export default Input;
