import React, {useState, useEffect} from 'react';
import { useUserContext } from "../../userContext";
import { useCompanyContext } from "../../companyContext";
import * as projectService from "../../services/projectService";
import * as embedService from "../../services/embedService";
import * as widgetService from "../../services/widgetService";
import * as userService from "../../services/userService";
import { useHistory} from "react-router";
import ToggleSwitch from '../../common/ToggleSwitch';


import Joi from "joi-browser";
import Form from "../../common/Form";
import { message } from "antd";


function ExtractionTemplateSelection(props) {

    const history = useHistory();

  

      const apiPoint = process.env.REACT_APP_API_POINT; 
      const[loading, setLoading] = useState(false);
      const [formState, setFormState] = useState ("templateSelection")
      const [key, setKey] = useState("")
     
    
    
    const { companyId, projectId } = props.match.params;
  
    
    
 
    
      const buttonInfo = { label: "Configure Project", style: "" };
    
    
  
       const handleSubmit = async (data) => {


        let extractionPipelineData ={};
        extractionPipelineData.extractionPipelineType = key;
        extractionPipelineData.dataToBeExtracted = data.dataToBeExtracted;
        extractionPipelineData.sheetURL = data.sheetLink;
        extractionPipelineData.assistantEnabled = key==="RFP"? true:false;
        extractionPipelineData.documentType = key==="RFP"? data.taskDetails:""
        extractionPipelineData.companyDescription  = key==="RFP"? data.companyDescription:""

        try {
            const response = await projectService.updateProject(projectId, { extractionPipelineData });
        
            if (response.status === 200) {
                message.success("Changes saved");
                history.replace("/");
            }
        } catch (ex) {
            if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
                setLoading(false);
                message.error(ex.response.data.msg);
                return;
            }
        
            // Handle any unexpected errors here
            message.error(ex.response.data.error);
            setLoading(false);
        }
      };


                  const getClasses =(item, number)=>{
                    if(number===key)
                    return `${item} selectionbox_selected`
        
                    else return `${item}`
        
                  }

 /*--------- Google sheet form link -----------------------*/

 const [inputFieldforSheetLink] = useState({
  sheetLink:"",
  dataToBeExtracted:""
});


const [errorMessageforSheetLink] = useState({});



const templateforSheetLink = [
  {
    name: "sheetLink",
    label: "Google sheet link",
    type: "text",

  },
  {
    name: "dataToBeExtracted",
    label: "Type data to be extracted, separated by a comma. For example name, email, phone.",
    type: "textArea",
    customClass: "light--extend",
  }
];

const buttonInfoforsheetLink = { label: "Create Te", style: "" };


const schemaforSheetLink = {
    sheetLink: Joi.string().uri().min(3).max(4000).label('Project title'),
  dataToBeExtracted:Joi.string().min(1).max(40000).label('Data to be extracted'),
};


const handleSubmitforSheetLink =() =>{

}


 /*--------- RFP input selection -----------------------*/

 const [inputFieldforRFP] = useState({
  companyDescription:"",
  taskDetails:""
});


const [errorMessageforRFP] = useState({});



const templateforRFP = [
  {
    name: "companyDescription",
    label: "Describe in detail what your company does.",
    type: "textArea",
    customClass:""

  },
  {
    name: "taskDetails",
    label: "Type of document you will be feeding the AI",
    type: "select",
    options: [
      { id: 1, value: "RFP", label: "RFP" },
      { id: 2, value: "RFI", label: "RFI" },
      { id: 3, value: "Security Questionnaire", label: "Security Questionnaire" },
      // { id: 4, value: "email", label: "Email" },
   ]
  },
];

const buttonInfoforRFP = { label: "Customize AI", style: "" };


const schemaforRFO = {
companyDescription: Joi.string().trim().min(20).max(4000).required().options({
    language: {
      any: { required: 'companyDescription is required' },
      string: {
        min: 'Must have a minimum length of 20 characters',
        max: 'Should have a maximum length of 4000 characters',
        regex: { base: 'Cannot be empty' }
      }
    }
  }),
taskDetails: Joi.string().trim().min(1).max(40000).required().options({
    language: {
      any: { required: 'Task Details are required' },
      string: {
        min: 'Task Details should have a minimum length of 1 character',
        max: 'Task Details should have a maximum length of 40000 characters',
        regex: { base: 'Task Details cannot be empty' }
      }
    }
  })
};





   

const handleKeySelection = async(value)=>{
    setKey(value)

    if(value==="RFP")
    setFormState("RFPInput")
    // {
    //   let extractionPipelineData ={};
    //   extractionPipelineData.extractionPipelineType = value;
    //   extractionPipelineData.dataToBeExtracted="";
    //   extractionPipelineData.sheetURL="";

    //   try {
    //       const response = await projectService.updateProject(projectId, { extractionPipelineData });
      
    //       if (response.status === 200) {
    //           message.success("Changes saved");
    //           return history.push(`/extraction-rfp-template/${companyId}/${projectId}`)
    //       }
    //   } catch (ex) {
    //       if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
    //           message.error(ex.response.data.msg);
    //           return;
    //       }
      
    //       // Handle any unexpected errors here
    //       message.error(ex.response.data.error);
    //       setLoading(false);
    //   }

    // }
    else {
   setFormState("sheetLink")
    }
}





    return (
            <div style={{height:"100vh"}} className="splithalf">
            <div className="lefthalf centrething flexcolumn flexcolumn--allcenter blackcontainer">
              <div className="leftcontainer">
           <div>    

            {(formState === "templateSelection" || formState === "sheetLink") && (
                <h2 style={{ color: "white" }}>
                    <br />Please make a <em> selection </em>
                </h2>
            )}

            {formState === "custom" && (
                <h2 style={{ color: "white" }}>
                    <br />We're super excited to work with you <em>  on this</em>
                </h2>
            )}
  
  
      {/* <button onClick={handleNext} style={{ marginTop:"50px"}} className='btnn btnn-primary'>Next Step</button> */}
      
    
       </div>
              </div>
            </div>
            <div  className="righthalf centrething flexcolumn flexcolumn--allcenter">
      

                {formState==="templateSelection" && <div className='flexcolumn flexcolumn--allcenter'>
           <div>

    <div>
            <h3 className='bb'>Extract and Create Documents</h3>

      <div className='grid grid--1x2'>
     
        <div onClick={()=>handleKeySelection("RFP")} style={{margin:"10px"}} className={getClasses("selectionbox",21)}>
          <h3>RFP Template</h3>
          Extract key information from the specified documents <br/> and create a first draft proposal.
          </div>

          <div 
        
        onClick={() => {
      window.location.href = `mailto:support@trysuperdash.com?subject=I have a custom requirement`;
        }} 
        style={{ margin: "10px" }} 
        className={getClasses("selectionbox", 4)}
    >
        <h3>Custom</h3>
        I have a custom requirement.
     </div>
      </div>


        

      {/* <div onClick={()=>setKey(100)} className='selectionbox'></div> */}
      {/* <h3>Default</h3> */}
    </div>
        
           {/* <div>
            <h3 className='bb'>Just Extract</h3>

      <div className='grid grid--1x2'>
     
        <div onClick={()=>handleKeySelection("cv")} style={{margin:"10px"}} className={getClasses("selectionbox",1)}>
          <h3>CV Parser</h3>
          Extract important information from a resume.
          </div>
          <div onClick={()=>handleKeySelection("receipt")} style={{margin:"10px"}} className={getClasses("selectionbox",2)}>
          <h3>Receipt Parser</h3>
          Automatically extract key information from receipts
          </div>
          <div 
        
            onClick={() => {
          window.location.href = `mailto:support@trysuperdash.com?subject=I have a custom requirement`;
            }} 
            style={{ margin: "10px" }} 
            className={getClasses("selectionbox", 4)}
        >
            <h3>Custom</h3>
            I have a custom requirement.
         </div>
      </div>


        

      {/* <div onClick={()=>setKey(100)} className='selectionbox'></div> */}
      {/* <h3>Default</h3> */}
    </div> 

           {/* </div> */}
  
                </div>}

  

      {formState==="sheetLink" && <div style={{background:"white"}} className='bluecontainer'>
      Please provide a publicly accessible Google Sheets link below, which should only include the necessary column headers and no additional data. This will allow the AI to append data to it.
      <div style={{textAlign:"center", marginTop:"20px"}}>
        <Form
                    errorMessage={errorMessageforSheetLink}
                    inputField={inputFieldforSheetLink}
                    template={templateforSheetLink}
                    schema={schemaforSheetLink}
                    buttonInfo={buttonInfo}
                    onSubmit={handleSubmit}
                    loading={loading}
                    setLoading={setLoading}
                  />
      </div>
       </div>

       }


{formState==="RFPInput" && <div style={{background:"white"}} className='bluecontainer'>
We are now going to tailor the AI to ensure it manages your tasks according to your requirements.
      <div style={{textAlign:"center", marginTop:"20px"}}>
        <Form
                    errorMessage={errorMessageforRFP}
                    inputField={inputFieldforRFP}
                    template={templateforRFP}
                    schema={schemaforRFO}
                    buttonInfo={buttonInfoforRFP}
                    onSubmit={handleSubmit}
                    loading={loading}
                    setLoading={setLoading}
                  />
      </div>
       </div>

       }
     
            </div>



          </div>
        );
}

export default ExtractionTemplateSelection;