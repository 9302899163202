import { Tabs } from 'antd';
import { size } from 'lodash';
import React,{useState} from 'react';
import { Sticky, StickyContainer } from 'react-sticky';
const { TabPane } = Tabs


const renderTabBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={80}>
      {({ style }) => (
        <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style,  padding:"0px 10px 0px 10px", fontSize:"18px", width:"1000px"}} />
      )}
    </Sticky>
  );


  function TabContainer({ filters, setKey, activeKey }) {
    const changeTab = (key) => {
        setKey(parseInt(key));
    };

  

    return (
        <div>
            <StickyContainer>
                {filters ? (
                    <Tabs activeKey={`${activeKey}`} renderTabBar={renderTabBar} onChange={changeTab}>
                        {filters.map((item, index) => (
                            <TabPane tab={item} key={index}>
                                {/* content of the tab */}
                            </TabPane>
                        ))}
                    </Tabs>
                ) : null}
            </StickyContainer>
        </div>
    );
}

export default TabContainer;
