import React,{useState, useEffect} from 'react';
import { Button, Modal } from 'antd';
import CopyInput from '../../common/CopyInput';
import * as keyService from "../../services/keyService";
import CopyToClipboard from 'react-copy-to-clipboard';
import { MdContentCopy } from "react-icons/md";
import { message } from "antd";
import { BsTrash} from "react-icons/bs";
import { BiBorderRadius } from 'react-icons/bi';
import { useLocation } from "react-router";
import NavigationBar from '../../common/NavigationBar';
import AddCustomertPassword from '../../components/FormComponent/AddCustomerPassword';
import * as customerService from "../../services/customerService";
import * as clientPortalSerivce from "../../services/clientPortalService"
import DeleteModalforMembership from '../../components/ModalComponent/DeleteModalforMembership';
import AddModalforClientDomain from '../../components/ModalComponent/AddModalforClientDomain';


function ExtractionSettings({ project: propProject, companyId,  setActiveProject, activeProject, projects, companyData }) {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [key, setKey] = useState("")
    const [keyData, setKeys] = useState([])
    const [specificKey, setSpecificKey] = useState("")
    const [formState, setFormState] = useState("first")
    const [isMembershipModalOpen, setIsMembershipModalOpen]= useState(false)
    const [reload, setReload] = useState(false)
    const [members, setMembers] = useState([])

    const [deleteModalOpenForMembers, setDeleteModalOpenForMembers] = useState(false)
    const [portalModalOpen, setPortalModalOpen] = useState(false)
    const [memberid, setMembershipId] = useState("")
    const [portalDetails, setPortalDetails] = useState({})
   
    

    

    let location = useLocation();

    useEffect(() => {
      const fetchData = async () => {
        const { data } = await keyService.allKeys(projectId);
        setKeys(data)
      };
  
      fetchData();
    }, [isModalOpen, deleteModalOpen]);


    useEffect(() => {
      const fetchData = async () => {
        const { data } = await clientPortalSerivce.getPortalDetails(projectId);
        setPortalDetails(data)
      };
  
      fetchData();
    }, []);


    useEffect(() => {
      const fetchData = async () => {
        const { data } = await customerService.allCustomers(projectId);
        setMembers(data)
      };
  
      fetchData();
    }, [reload, companyData]);

    let projectId = propProject || (location.state && location.state.project);
    
    const showModal = async () => {
      try {
        const response = await keyService.generateKey(projectId);
        if(response.status===200) {
          setKey(response.data.apiKey.key)
          setIsModalOpen(true);
        }
      } catch (ex) {
        if (ex.response && ex.response.status >= 400 && ex.response.status <500) {
          message.error("Please upgrade your account");
          return;
        }
        message.error("Something is wrong");
        return;
      }
    };


    const showAddDomainModel = async () => {
      setPortalModalOpen(true)
    };
    




    const showClientPortalModal = async () => {

      if(!companyData.membershipManagement)
       return message.error("MembershipManagement is not enabled")

       setIsMembershipModalOpen(true)

      // try {
      //   const response = await keyService.generateKey(projectId);
      //   if(response.status===200) {
      //     setKey(response.data.apiKey.key)
      //     setIsModalOpen(true);
      //   }
      // } catch (ex) {
      //   if (ex.response && ex.response.status >= 400 && ex.response.status <500) {
      //     message.error("Please upgrade your account");
      //     return;
      //   }
      //   message.error("Something is wrong");
      //   return;
      // }
    };
    

    const showRevokeModal =(keyId)=>{
      setDeleteModalOpen(true);
      setSpecificKey(keyId);
    }

    const showRevokeMemberModal =(memberId)=>{
      setDeleteModalOpenForMembers(true);
      setMembershipId(memberId);
    }


  
    const handleOk = () => {
      setIsModalOpen(false);
    };

    const RevokeOk = async (keyId) => {
      setKey("")
      const {data} = await keyService.deleteKey(specificKey);
      setDeleteModalOpen(false);
    };

    const RevokeCancel = () => {
      setDeleteModalOpen(false);
    };

    const AddMember = async (keyId) => {

      // const {data} = await keyService.deleteKey(specificKey);
      setIsMembershipModalOpen(false);
    };

    const CancelMember = () => {
      setIsMembershipModalOpen(false);
    };
  
  
    const handleCancel = () => {
      setIsModalOpen(false);
 
    };

    const handleCopy =()=>{
      message.success("ProjectID copied");
    }


    const getTime =(data)=>{

  if(data===0)
  return "Never"

 const epochTime = data;
 const date = new Date(epochTime);
 const dateString = date.toLocaleString(); // or use toUTCString() or toDateString() as needed
 return(dateString);
    }
    



    let copyProject =  `${projectId}` 


    return (
        <div style={{overflow:"auto", height:"auto", marginLeft: "22vw" }}>
              <NavigationBar companyId={companyId} setActiveProject={setActiveProject} activeProject={activeProject} projects={projects}  />
                   <div style={{ margin: "20px 50px"}}>
<div style={{background: "white", width:"70vw", padding:"50px", borderRadius:"25px"}}>
  <div className='flexrow wideapart borderbottom'>
  <h3>ProjectID</h3>
  <div>
  <CopyToClipboard text={copyProject}>
    <div style={{cursor:"pointer"}} onClick={handleCopy} className='flexrow flexrow--rigid'>
      <p style={{marginRight:"20px"}} >{projectId}</p>
      <MdContentCopy/>
    </div>
    </CopyToClipboard>
  </div>
  </div>







            <div  className='flexrow wideapart'>
              <h3>API Keys</h3>
              <button  onClick={showModal} className='btnn  btnn--small'>+ create key</button>
            </div>
            <p style={{marginTop:"20px", marginBottom:"40px"}}>Below are your secret API keys. Kindly note that once you generate them, we won't display them again.
<br/>
Please refrain from disclosing your API key to others or revealing it in your browser or other client-side code.</p>


{key && <Modal title="API Key Generated" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>For security purposes, it's advised that you save this secret key in a secure and accessible location. 
            <br/>
            <br/>
            Once saved, please note that it won't be visible again through your Superdash account. If the secret key is lost, generating a new one will be required.</p>

            <CopyInput value={key}/>

</Modal>}


<div>
{(keyData && (keyData.apiKeys && keyData.apiKeys.length>=1)) &&  <table className='tableStyle'>

        <thead>
          <tr>
          <th >SECRET KEY</th>
          <th>CREATED</th>  
          <th >LAST USED</th>
          <th></th>      
          </tr>
        </thead>
        <tbody>
      {keyData.apiKeys && keyData.apiKeys.map((item, index) => (
            <tr key={index}>
            <td >{item.redactedKey}</td> 
            <td>{getTime(item.createdAt)}</td> 
            <td>{getTime(item.lastUsed)}</td>
            <td style={{cursor:"pointer"}}><BsTrash onClick={()=>showRevokeModal(item._id)} style={{ color: 'red' }}/></td> 
        </tr>


        ))} 
       </tbody>
       {<Modal 
   title="Revoke API key"
   visible={deleteModalOpen}
   onOk={RevokeOk}
   onCancel={RevokeCancel}
   okButtonProps={{
     style: { backgroundColor: "red", color: "white", outline:"none" },
   }}
   okText="Revoke Key"  
       >
        <p>Revoking this API key will immediately disable it. 
            <br/>
            <br/>
            <br/>
            Any API requests made using this key will be rejected, which may result in systems that rely on it to malfunction
            <br/></p>


</Modal>}


      </table>}

      </div> 
      </div>

      {companyData.membershipManagement &&  <div style={{background: "white", width:"70vw", padding:"50px", borderRadius:"25px", marginTop:"20px"}}>
 
  
    <div className='bb flexrow wideapart'>
      <h3>Client Portal Management</h3>
  
      <div>
        <button  onClick={showAddDomainModel} className='btnn  btnn--small btnn-transparent'>{portalDetails.domain?"Update domain": "Add domain"}</button>
        <button  onClick={showClientPortalModal} className='btnn  btnn--small'>+ Add member</button>
        {<AddModalforClientDomain portalModalOpen={portalModalOpen} setPortalModalOpen={setPortalModalOpen} projectId={projectId} portalDetails={portalDetails} companyId={companyId}/>}
      </div>
    </div>
    <div>
    <p style={{marginTop:"20px", marginBottom:"40px"}}>You can give access to this project via the Client Portal here. Create an account for your customer.
    <br/>
    Please ensure you're giving access to the portal to the appropriate owner.</p>
    </div>
  

  {(members.length>=1) &&  <table className='tableStyle'>
  
          <thead>
            <tr>
            <th>ID</th>
            <th >EMAIL</th>
            <th >ACTIVE</th>
            <th>DELETE</th>
            </tr>
          </thead>
          <tbody>
        {members.map((item, index) => (
              <tr key={index}>
              <td >{item._id}</td>
              <td>{item.email}</td>
              <td>{item.active===true?"ACTIVE" :"DEACTIVATED"}</td>
              <td style={{cursor:"pointer"}}><BsTrash onClick={()=>showRevokeMemberModal(item._id)} style={{ color: 'red' }}/></td>
          </tr>
  
  
          ))}
         </tbody>
         {<DeleteModalforMembership deleteModalOpenForMembers={deleteModalOpenForMembers} setDeleteModalOpenForMembers={setDeleteModalOpenForMembers} projectId={projectId}  companyId={companyId} memberid={memberid} setReload={setReload} reload={reload}/>}
  
        </table>}
</div>}

<Modal 
  title="Add a customer"
  visible={isMembershipModalOpen}
  footer={null}
  onCancel={() => setIsMembershipModalOpen(false)}
>
  <AddCustomertPassword projectId={projectId} setIsMembershipModalOpen={setIsMembershipModalOpen}/>
</Modal>

  




      </div>
        </div>
    );
}

export default ExtractionSettings;