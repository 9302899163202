import http from "./httpService";
const apiEndpoint = "/assistantConversation";


export function runAssistant(projectId, req) {
    return http.post(apiEndpoint + "/runAssistant" + "/" + projectId, req);
}  

export function getAllAssistantMessages(projectId, extractionId) {
  return http.get(apiEndpoint + "/getMessages" + "/" + projectId + "/" + extractionId);
} 


