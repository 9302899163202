import React,{useState, useEffect} from 'react';
import Search from '../common/Search';
import { MdEdit } from "react-icons/md";
import EditKB from '../components/EditKB';
import { BiHome } from "react-icons/bi";
import { Link } from "react-router-dom";
import { RiDeleteBin6Line } from "react-icons/ri";
import {  Modal, message } from 'antd';
import * as knowledgeService from "../services/knowledgebaseService";
import { useHistory, useLocation } from "react-router";





function KBSearch({KB}) {

  let location = useLocation();


 const [formState, setFormState]= useState("view");  
const [editItem, setEditItem] = useState({})
let [searchQuery, setSearchQuery] = useState("");
const [originalKBData, setOriginalKBData] = useState([]);
const [filteredKBData, setFilteredKBData] = useState([]);
const [key, setKey] = useState("");
const [deleteModalOpen,setDeleteModalOpen] = useState(false)


const history = useHistory();



useEffect(() => {
  const paragraphs = location.state.KB.paragraphs;
  setOriginalKBData(paragraphs);
  setFilteredKBData(paragraphs);
}, [location.state.KB.paragraphs]);

const handleSearch  = (query) => {

    setSearchQuery(query);

  const filteredData = originalKBData.filter((paragraph) => {
    const lowerCasedValue = query.toLowerCase().trim();
    return paragraph.value.data.toLowerCase().includes(lowerCasedValue);
  });

  setFilteredKBData(filteredData);
};

const handleBackspaceKeyPress = (event) => {
  if (event.keyCode === 8) {
    const inputValue = searchQuery.slice(0, -1);

    setSearchQuery(inputValue);

    const filteredData = originalKBData.filter((paragraph) => {
      const lowerCasedValue = inputValue.toLowerCase().trim();
      return paragraph.value.data.toLowerCase().includes(lowerCasedValue);
    });

    setFilteredKBData(filteredData);
  }
};

 
 const handleEdit =(item)=>{
    setFormState("edit") 
    setEditItem(item)
 }


const handleBack =()=>{
    setFormState("view")
}

const showDeleteModal =(item)=>{
  setDeleteModalOpen(true);
  setKey(item.key);
}


const RevokeOk = async () => {
  try {
    const response = await knowledgeService.deleteParagraph(location.state.KB.project, key);
    if (response.status === 200) {
      setDeleteModalOpen(false);
      message.info("Deletion succesful")
      history.replace("/")
    }
  } catch (ex) {
    if (ex.response && ex.response.status >= 400 && ex.response.status<500 ) {

      message.error(ex.response.data.msg);
      setDeleteModalOpen(false);
      return;
    }
  }
};


const RevokeCancel = () => {
  setDeleteModalOpen(false);
};



// const handleSearch = (query) => {
//     setSearchQuery(query);
//   };


 if(formState==="view")   
 return (
  <div className='flexcolumn flexcolumn--allcenter' style={{width:"100vw"}}>
      <Link to={{ pathname: "/" }}>
          <div style={{cursor:"pointer", marginTop:"30px"}} className='flexrow flexrow--rigid'>
              <BiHome/>
              <p style={{marginLeft:"10px"}}>Go home</p>
          </div>
      </Link>
      <div style={{marginTop:"50px"}}>
          <Search onSearch={handleSearch} handleBackspaceKeyPress={handleBackspaceKeyPress}/>
      </div>
      <div>
          {filteredKBData.map((item, index) => (
              <div className='resultbox resultbox--movement' key={index}>
                  <div>
                      <RiDeleteBin6Line onClick={() => showDeleteModal(item)} style={{cursor:"pointer", color:"red", opacity:"0.8"}}/>
                  </div>
                  <button onClick={() => handleEdit(item)} className='btnn--small'><MdEdit/>Edit</button>
                  <div>
                      {item.value.data}
                  </div>
              </div>
          ))} 
      </div>
      {<Modal 
  title="Delete File"
  visible={deleteModalOpen}
  onOk={RevokeOk}
  onCancel={RevokeCancel}
  okButtonProps={{
    style: { backgroundColor: "red", color: "white", outline:"none" },
  }}
  okText="Delete this"  
      >
       <p>Once you confirm the deletion, this specific piece of information will be permanently removed from the entire system, and your chatbot will no longer have access to or be able to refer to this document.



         </p>


</Modal>}
  </div>
);




    if(formState==="edit")
    return (
        <div className='flexcolumn flexcolumn--allcenter'>
         
            <div onClick={handleBack} style={{cursor:"pointer", marginTop:"30px"}} className='flexrow flexrow--rigid'>
         
                <BiHome  />
                 <p style={{marginLeft:"10px"}}>Go back</p>
    
            </div>
        
        
           <EditKB KB={KB} editItem={editItem} />
        </div>

    )  


}

export default KBSearch;