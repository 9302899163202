import { Switch } from "antd";

function Toggle({ onChange, isActive, toggle, size }) {



  return (
    <div>
      <Switch size={size?size:"medium"} defaultChecked={false} checked={toggle} onChange={onChange} />
    </div>
  );
}

export default Toggle;
