import React,{useState, useEffect} from 'react';
import Navbar from '../common/Navbar';
import { useHistory } from "react-router";
import MenuBar from '../components/Menubar';
import OutputComponent from '../components/OutputComponent';
import HomeIntegrations from './Home/HomeIntegrations';
import HomeKB from './Home/HomeKB';
import HomeSettings from './Home/HomeSettings';
import * as profileService from "../services/profileService";
import * as companyService from "../services/companyService";
import * as projectService from "../services/projectService";
import * as KBService from "../services/knowledgebaseService";
import * as conversationService from "../services/conversationService";
import HomeDashboard from './Home/HomeDashboard';
import HomeConversations from './Home/HomeConversations';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { message } from 'antd';
import HomeModules from './Home/HomeModules';







function Home(props) {


  const [menuState, setMenuState] = useState("Dashboard");
  let menuOptions =["Dashboard", "Knowledge base", "Modules",  "Chats", "Integrations","Settings"]

  const [companyId, setCompanyId] = useState("");
  const [companyData, setCompanyData] = useState("");
  const [projects, setProjects] = useState([]);
  const [activeProject, setActiveProject] = useState(0);
  const [portelactiveProject, setPortalActiveProject] = useState(0);
  const [projectId, setProjectId] = useState([]);
  const [KB, setKB]= useState([]);
  const [conversation, setConversation] = useState([])
  const [corpus,setCorpus] =useState([])
  const [sign, setSign] = useState("")
  const [refresh, setRefresh] = useState(false)
  const [reload, setReload] = useState(false)
  const [settings, setSettings] = useState({})



  const history = useHistory();


    // Load the last known value of activeProject from localStorage on component mount
    useEffect(() => {
      const savedActiveProject = localStorage.getItem('activeProject');
      if (savedActiveProject) {
        setActiveProject(parseInt(savedActiveProject));
      }
    }, []);
  
    // Save the current value of activeProject to localStorage whenever it changes
    useEffect(() => {
      localStorage.setItem('activeProject', activeProject);
    }, [activeProject]);
    

  useEffect(() => {
  
    const fetchData = async () => {

      try {
        const responseData = await profileService.allUserDetails();

        if(responseData.status===200)
        {
          setCompanyId(responseData.data.company._id)
          setCompanyData(responseData.data.company);
          if(responseData.data.company.accountType==="unsubscribed")
           {
          history.replace({ pathname: "/billing/",
          state: {
            company:responseData.data.company,
         
          },
        });
          return 
        }
           
          const response= await projectService.getAllProjects(responseData.data.company._id)
          setProjects(response.data)
          if(response.data.length<1){
           history.replace({ pathname: "/onboarding/" +  responseData.data.company._id});
           return;
         }

        }
      } catch (ex) {


        if (ex.response && ex.response.status === 401) {
          history.replace({ pathname: "/logout" });
          return;
        }


        if (ex.response && ex.response.status === 400) {
          history.replace({ pathname: "/welcome" });
          return;
        }
      }







  
    //  const response = await companyService.allCompanyDetails(data.company._id)
    //  console.log(response);
                   
      // setCountries(data.countries)
    };
    fetchData();
   
  }, [refresh]);

  useEffect(() => {

    if(KB && KB.project && projects[activeProject])

    {
    const fetchData = async () => {
      const {data}= await KBService.getCorpus(projects[activeProject]._id);
      setCorpus(data.data)
      getStateCountStatus(data.data)
    };
    fetchData();
  }

  }, [KB,reload]); 
  



   useEffect(() => {
    const fetchData = async () => {
      if(projects[activeProject])
      {
      setProjectId(projects[activeProject]._id);
      const value = await KBService.getSpecificKB(projects[activeProject]._id);
      setKB(value.data)
      const convo =  await conversationService.allConversation(projects[activeProject]._id)

    convo.data.conversations.reverse()
      setConversation(convo.data)

      const response = await projectService.getSettings(projects[activeProject]._id)
      setSettings(response.data)

    }
    };
    fetchData();
  }, [activeProject, projects, refresh]);





  const handleEmpty = () =>{
    history.replace({ pathname: "/training/"+ projectId});
  }

  



  const handleRefresh = () => {
  message.info("We've retrieved the latest info")
  setReload(!reload)
  };
  




  function getStateCountStatus(array) {
    // Initialize count variables
    let finishedCount = 0;
    let submittedCount = 0;
    let failedCount = 0;
  
    // Iterate through each element in the array
    array.forEach((element) => {
      // Check the state property and increment the corresponding count
      switch (element.state) {
        case 'finished':
          finishedCount++;
          break;
        case 'submitted':
          submittedCount++;
          break;
        case 'failed':
          failedCount++;
          break;
        default:
          break;
      }
    });
  
 
    if (failedCount > 0) {
      setSign("red"); 
    } else if (submittedCount > finishedCount) {
      setSign("orange"); 
    } else {
      setSign("green"); 
    }
  }
  



  return (
    <div style={{width:"100vw"}}>
      <Navbar activeProject={activeProject} projects={projects} company={companyData} setActiveProject={setActiveProject}  corpus={corpus} sign={sign} setRefresh={setRefresh} refresh={refresh}/>
      <div style={{justifyContent: "center"}} className='flexrow'>
<div><MenuBar menuOptions={menuOptions} menuState={menuState} setMenuState={setMenuState} /></div>

<div className='homebox'>


{menuState ==="Dashboard" && <HomeDashboard conversation={conversation} corpus={corpus}/> }
{menuState ==="Integrations" && <HomeIntegrations projectId={projectId} companyData={companyData}/> }
{menuState ==="Settings" && <HomeSettings projectId={projectId} /> }
{menuState ==="Knowledge base" && <HomeKB  handleEmpty={handleEmpty} activeProject={activeProject} projects={projects} knowledgeBase={KB} corpus={corpus} handleRefresh={handleRefresh}/> }
{menuState ==="Chats" && <HomeConversations conversation={conversation} projectId={projectId}/> }
{menuState ==="Modules" && <HomeModules conversation={conversation} projectId={projectId} settings={settings} companyId={companyId}/> }



</div>

      </div>





    </div>
  );





}

export default Home;


