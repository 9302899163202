import React,{useState} from 'react';
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router";
import Joi from "joi-browser";
import Form from "../common/Form";
import * as knowledgeService from "../services/knowledgebaseService";
import * as companyknowledgeService from "../services/companyKnowledgebase";
import { message } from 'antd';


function EditKB({KB, editItem,setFormState}) {



    const [inputField] = useState({
        content:editItem.value.data,
      });

      let location = useLocation();


      const[loading, setLoading] = useState(false);
        
      const [errorMessage] = useState({});

            // let location = useLocation();


      const template = [
        {
          name: "content",
          label: "Edit your content here",
          type: "textArea",
          customClass: "light--extend",
        }
      ];

      const schema = {
      content: Joi.string().regex(/^\s*(\S+\s+){0,400}\S*$/).options({ language: { string: { regex: { base: 'This section must contain no more than 400 words' } } } }).label("this section"),
      }
    



      const buttonInfo = { label: "Update content", style: "" };
      // const buttonInfo1 = { label: " Create A", style: "" };
    
      const history = useHistory();
 
      const handleSubmit = async (data) => {
        let result;

        try {

          let serviceToCall;
          const hostname = window.location.hostname; 

 
      
          if (hostname.includes(`extraction.${process.env.REACT_APP_DOMAIN}.com`)) {
            serviceToCall = companyknowledgeService.updateKB;
     
            result = {
            
              companyId: KB.companyId,
              knowledgeBasePatchObject: {
                update: [
                  {
                    key: editItem.key,
                    _id: editItem._id,
                    value: {
                      data: data.content,
                    },
                  },
                ],
              },
            };
          
            
          } 
          
          else if (hostname.includes(`app.${process.env.REACT_APP_DOMAIN}.com`)) {
            serviceToCall = knowledgeService.updateKB;
            result = {
              label: location.state.KB.label,
              projectId: location.state.KB.project,
              knowledgeBasePatchObject: {
                update: [
                  {
                    key: editItem.key,
                    _id: editItem._id,
                    value: {
                      data: data.content,
                    },
                  },
                ],
              },
            };
          
          } 
          
          else {
            throw new Error("Invalid hostname");
          }
      
          const res = await serviceToCall(result);
          if (res.status === 200) {
            message.success("Your update is successful");
            history.replace("/");
          }
        } catch (ex) {
          setLoading(false);
          if (ex.response && ex.response.status === 400) {
            message.error(ex.response.data);
          } else {
            // Handle other errors or invalid hostname error
            message.error(ex.message || "An unexpected error occurred.");
          }
        }
      };
      




    return (
        <div className="flexcolumn flexcolumn--allcenter" style={{ width:"100vw"}}>
                <div className='resultbox'  style={{textAlign:"center", marginTop:"80px"}}>
                    <h3>Please edit your content here to retrain your application automatically.</h3>
              <Form
                      errorMessage={errorMessage}
                      inputField={inputField}
                      template={template}
                      schema={schema}
                      buttonInfo={buttonInfo}
                      onSubmit={handleSubmit}
                      loading={loading}
                      setLoading={setLoading}
                    />
            </div>
            
        </div>
    );
}

export default EditKB;