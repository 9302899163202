import React, { useEffect, useState } from 'react';
import UploadMultiple from '../common/UploadMultiple';
import Joi from "joi-browser";
import { RiDeleteBin6Fill } from "react-icons/ri";
import * as knowledgebaseService from "../services/knowledgebaseService";
import { message } from "antd";
import { useHistory } from "react-router";

function FileUpload({passedData}) {

  const history = useHistory();

  const urlPath = history.location.pathname;
  const urlSearch = history.location.search;




useEffect(() => {


  if (!passedData) {
    history.push("/")
  }
}, [passedData, history]);

    

    const [inputField] = useState({
        url: "",
    
      });

      const [errorMessage] = useState({});
    
      const[loading, setLoading] = useState(false);
      const[uploadedFiles, setUploadedFiles] = useState([]);


      useEffect(() => {
 
      }, [uploadedFiles]);
      

      const template = [
        {
          name: "url",
          label: "Enter a URL",
          type: "text",
        },
        
    
    
      ];


        
    
      const buttonInfo = { label: " Submit ", style: "" };
    
      const schema = {
        url: Joi.string().uri().min(2).max(500).required().label("URL"),
    
      };


      const handleSubmit = async () => {
        setLoading(true);
      
        const formData = new FormData();
        uploadedFiles.forEach((file) => {
          formData.append("files", file);
        });

        if(uploadedFiles.length<1)
        {
        setLoading(false);
        return message.error("Please upload atleast one file.")

      }

      
        try {
          const response = await knowledgebaseService.docsUpload(passedData.projectId, formData);
      
          if (response.status === 200) {
             setLoading(false);
             history.replace("/");
          }
        } catch (ex) {
          if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
            setLoading(false);
            message.error(ex.response.data.msg);
            return;
          }
        }
      };

    

      const handleFileChange = (info) => {
        if (info.file.type === 'text/csv') {
          message.error('CSV files are not allowed');
          return false;
        }
      
        if (info.file.size > 15 * 1024 * 1024) {
          message.error('File size must be smaller than 15MB!');
          setLoading(false);
          return false;
        }
      
        setUploadedFiles((prevUploadedFiles) => [
          ...prevUploadedFiles,
          info.file,
        ]);

      
        // } 
        
        // else if (info.file.status === "removed") {
        //   const filteredFiles = uploadedFiles.filter(
        //     (file) => file.uid !== info.file.uid
        //   );
        //   setUploadedFiles(filteredFiles);
        // }
      };
      
   


      const handleDelete =(index)=>{
        const newArray = uploadedFiles.filter((_, i) => i !== index);
        setUploadedFiles(newArray);
      }
  
      return (
        <div style={{ height: "100vh", width:"100vw" }} className="flexcolumn flexcolumn--allcenter">
          <div className="flexrow">
            <div style={{ marginRight: "50px", width: "600px", height:"420px" }} className="flexcolumn flexcolumn--allcenter bluecontainer">
              <p>Please upload files to train your chatbot</p>
              <UploadMultiple onChange={handleFileChange} />
              <button className={uploadedFiles.length<1?"btnn btnn-disable":"btnn btnn-primary"} disabled={loading} onClick={handleSubmit}>
                {loading ? "Uploading files..." : "Submit"}
              </button>
            </div>
    
  
              {/* {uploadedFiles.length < 1 && (
                <div className="flexcolumn flexcolumn--allcenter">
                  <img style={{ width: "150px", height: "50%", marginTop:"30%" }} src={empty} alt="empty" />
                  <h3 style={{ marginTop: "30px" }}>It's empty. It seems you have not uploaded any file.</h3>
                </div>
              )} */}
              {uploadedFiles.length > 0 && 
                        <div style={{ overflow: "auto",height:"700px", width:"600px" }} className="bluecontainer">
              
                <div >
                  {uploadedFiles.map((item, index) => (
                    <div className='wideapart'>
          <p className='bb' key={index}>{item.name.length > 30 ? item.name.slice(0, 30) + '...' : item.name}</p>

                      <RiDeleteBin6Fill onClick={()=>handleDelete(index)} style={{color:"#f34f4f", cursor:"pointer"}}/>
                    </div>
                  ))}
                </div>
              
              </div>
              }
            </div>
    
        </div>
      );
    }
    

export default FileUpload;