import React,{useState} from 'react';
import InputWithDropdown from '../../common/InputWithDropdown';
import RoundCheck from '../../common/RoundCheck';
import * as knowledgebaseService from "../../services/knowledgebaseService";
import { useHistory, useLocation } from "react-router";
import { message } from 'antd';
import loadingIcon from "../../images/loading.svg";
import Modal from 'antd/lib/modal/Modal';



function Site({passedData}) {

  const [checkedUrls, setCheckedUrls] = useState({});

  const options = ['Parent website'];

  const [selectedOption, setSelectedOption] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [urlSuggestion, setURLSuggestions] = useState([]);

  const [isSaveOpen, setisSaveOpen] = useState(false);

  const history = useHistory();


  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  function handleUrlChecked(url) {
    setCheckedUrls(prevState => ({ ...prevState, [url]: !prevState[url] }));
  }




      const handleSubmit = async () => {
        {
          message.success("Please wait. We are fetching URLs.")

const obj ={};
obj.link = inputValue;
obj.linkLimit = 200;

            try {
              const response = await knowledgebaseService.fetchURLs(obj);
              setURLSuggestions(response.data)
              if(response.data.length<1)
              message.error("Scrawling may not be enabled on this page. We were unable to fetch any URLs from here.")
           
            } catch (ex) {
              if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
                setLoading(false)
                message.error(ex.response.data.msg);
                return;
              }
            }
          
          };
       
      };

    

      const handleTraining =async()=>{
        const urlList = Object.entries(checkedUrls)
        .filter(([url, isChecked]) => isChecked)
        .map(([url, isChecked]) => url);

        if (urlList.length<1)
        return message.info("Please select atleast one URL");
    
    const data = {
        urlList: urlList
    };

    setLoading(true)

    try {
        const response = await knowledgebaseService.trainURLs(passedData.projectId, data );
       if(response.status===200)
        setisSaveOpen(true)
      } catch (ex) {
        if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
          setLoading(false)
          message.error(ex.response.data.msg);
          return;
        }
      }
    
    


      }

      const handleDashboard =()=>{
        history.replace("/");
      }

      const handleRemain =()=>{
        setLoading(false);
        setisSaveOpen(false)
        setCheckedUrls({})
        setURLSuggestions([])
        
      }

      const handleSaveCancel = () => {
        setisSaveOpen(false);
       
      }

   

      return (
        <div className="flexcolumn flexcolumn--allcenter">
                  <Modal   cancelText="Close" title="We have added these URLs for training. Please choose an option." open={isSaveOpen} footer={false} onCancel={handleSaveCancel} >
     <div className='flexcolumn'>
         <button  style={{width:"100%"}} onClick={handleRemain} className='btnn '>Add another parent URL</button>
         <button style={{width:"100%"}}  onClick={handleDashboard}  className='btnn '>Navigate to dashboard</button>
     </div>
      </Modal>
          {urlSuggestion.length<1 ? <div style={{ marginTop: "50px" }}>
            <InputWithDropdown
              options={options}
              onOptionChange={handleOptionChange}
              onInputChange={handleInputChange}
              onSubmit={handleSubmit}
            />
          </div>: <div style={{ width:"800px"}}className='wideapart'>
              <h3>Start training with these links.</h3>
              {loading ? <span className='btnn btnn-primary'>
  Please wait
  <img style={{marginLeft:"10px", width:"48px"}} src={loadingIcon}></img>
  
</span> : <button onClick={handleTraining} className='btnn btnn-primary'>Submit</button>}
          </div>}
    
          <div style={{ paddingTop: "30px", minWidth: "800px", width:"auto"}} className='whiteContainer'>
            <table className='tableStyle'>
              <thead>
                <tr>
                  <th>URLs</th>
                </tr>
              </thead>
              <tbody>
                {urlSuggestion.map((url, index) => (
                  <tr key={index}>
                    <td className="flexrow wideapart">
                      <span>{url}</span>
                      <RoundCheck
                        id={`checkbox-${index}`}
                        isChecked={checkedUrls[url]}
                        onChange={() => handleUrlChecked(url)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    }


export default Site;