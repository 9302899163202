import React,{useEffect, useState} from 'react';
import { FiHome, FiBox, FiUpload, FiFileText, FiUsers, FiBarChart2, FiDollarSign, FiSearch, FiSettings } from 'react-icons/fi';
import MenuBar from '../../common/MenuBar';
import * as companySettingService from "../../services/companySettingService";
import * as customerService from "../../services/customerService";
import ExtractionUpload from './ExtractionUpload';
import ExtractionFiles from './ExtractionFiles';
import Extractionjobs from './Extractionjobs';
import * as profileService from "../../services/profileService";
import * as projectService from "../../services/projectService";
import { useHistory } from "react-router";
import ExtractionSettings from './ExtractionSettings';
import ExtractionAnalytics from './ExtractionAnalytics';
import * as knowledgebaseService from "../../services/knowledgebaseService";
import { message } from "antd";


function ExtractionHome(props) {

  const [isSuperdash] = useState(window.location.hostname.includes(process.env.REACT_APP_DOMAIN));



  const MENU_ITEMS = [
    { icon: FiUpload, label: 'Upload' },
    { icon: FiFileText, label: 'Files' },
    { icon: FiBox, label: 'Jobs' },
    { icon: FiBarChart2, label: 'Analytics' },
    // Include 'Settings' only if isSuperdash is true
    ...(isSuperdash ? [{ icon: FiSettings, label: 'Settings' }] : []),
    // Uncomment the following line if 'Analytics' should be visible regardless of isSuperdash
]; 


    const [companyId, setCompanyId] = useState("");
    const [companyData, setCompanyData] = useState("");   
    const [activeProject, setActiveProject] = useState(0);
    const [portalActiveProject, setPortalActiveProject] = useState("")

 const [style, setStyle] = useState({})
 const [activeMenu, setActiveMenu] = useState("Upload")
 const [projectId, setProjectId] = useState("")

 const [projects, setProjects] = useState([]);
 const [refresh, setRefresh] = useState(false);
 const [loading, setLoading] = useState(true);
 const [corpus, setCorpus] = useState([]);
 const [reload, setReload] = useState(false)

 const history = useHistory();

 function findProjectIndex(projects, projectId) {
  return projects.findIndex(project => project._id === projectId);
 }


 useEffect(() => {
    if (isSuperdash) {
        const fetchData = async () => {

            try {
              const responseData = await profileService.allUserDetails();
      
              if(responseData.status===200)
              {
                setCompanyId(responseData.data.company._id)
                setCompanyData(responseData.data.company);
                if(responseData.data.company.accountType==="unsubscribed")
                 {
                history.replace({ pathname: "/extraction-billing",
                state: {
                  company:responseData.data.company,
               
                },
              });
                return 
              }
                 
                const response= await projectService.getAllProjects(responseData.data.company._id)
                setProjects(response.data)
                if(response.data.length<1){
                 history.replace({ pathname: "/extraction-onboarding/" +  responseData.data.company._id});
                 return;
               }
      
              }
            } catch (ex) {
      
      
              if (ex.response && ex.response.status === 401) {
                history.replace({ pathname: "/logout" });
                return;
              }
      
      
              if (ex.response && ex.response.status === 400) {
                history.replace({ pathname: "/welcomehome" });
                return;
              }
            }
    
          };
          fetchData();
    }
  }, [refresh]);





 useEffect(() => {
    if (!isSuperdash) {
    
      const fetchData = async () => {
        const styleData = await companySettingService.allCompanyStyles();
        setStyle(styleData.data.design);


        const { data } = await customerService.findPortalCustomer();
        setProjectId(data.project);

        const response = await projectService.getAllProjects(styleData.data.design.company)
        setProjects(response.data)

      };

      fetchData();
    }
  }, []);


      // Load the last known value of activeProject from localStorage on component mount
      useEffect(() => {
        if (isSuperdash) {
        const savedActiveProject = localStorage.getItem('activeProject');
        if (savedActiveProject) {
          setActiveProject(parseInt(savedActiveProject));
        }
      }
      }, []);
    
      // Save the current value of activeProject to localStorage whenever it changes
      useEffect(() => {
        if (isSuperdash) {
        localStorage.setItem('activeProject', activeProject);
        }
      }, [activeProject]);

      useEffect(() => {
        if (!isSuperdash) {
        const savedActiveProject = localStorage.getItem('portalActiveProject');
        if (savedActiveProject) {
          setPortalActiveProject(parseInt(savedActiveProject));
        }
        if (!savedActiveProject) {
          const projectIndex = findProjectIndex(projects, projectId);
          if (projectIndex !== -1) {
            setPortalActiveProject(parseInt(projectIndex));
        } else {
            console.log('Project not found');
        }
        }
      }
      }, [projects, projectId]);
      
      
      useEffect(() => {
        if (isSuperdash) {
        const fetchData = async () => {
          if(projects[activeProject])
          {
          setProjectId(projects[activeProject]._id);    
        }
        };
        fetchData();
      }
      }, [activeProject, projects, refresh]);

      useEffect(() => {
        const fetchData = async () => {

          setLoading(true);
      
          let response;
          // Check if the current hostname includes the domain specified in the environment variable
          if (window.location.hostname.includes(process.env.REACT_APP_DOMAIN)) {
            // Call getCorpus if the domain matches
            response = projectId ? await knowledgebaseService.getCorpus(projectId) : null;
          } else {
            // Call getPortalCorpus if the domain does not match
            response = projectId ? await knowledgebaseService.getPortalCorpus(projectId) : null;
          }
      

          if (response && response.data && response.data.data) {
            const reversedData = response.data.data.slice().reverse();

            setCorpus(reversedData);
          }
      
          setLoading(false);
        };
      
        fetchData();
      }, [projectId, reload, refresh]);
      







    
    return (
        <div style={{minHeight:"100vh", width:"100vw"}}>
           <div className='flex flexrow'>
               <MenuBar activeMenu={activeMenu} MENU_ITEMS={MENU_ITEMS} style={style} setActiveMenu={setActiveMenu} isSuperdash={isSuperdash} refresh={refresh} setRefresh={setRefresh}/>
             {activeMenu==="Upload" && <ExtractionUpload project={projectId} isSuperdash={isSuperdash} companyId={companyId} reload={reload} setReload={setReload} projects={projects} activeProject={activeProject} setActiveProject={setActiveProject}  refresh={refresh} setRefresh={setRefresh}/>}
             {activeMenu==="Files" && <ExtractionFiles project={projectId} isSuperdash={isSuperdash} companyId={companyId} corpus={corpus} loading={loading} reload={reload} setReload={setReload} projects={projects} activeProject={activeProject} setActiveProject={setActiveProject} refresh={refresh} setRefresh={setRefresh}/>}
             {activeMenu==="Jobs" && <Extractionjobs project={projectId} isSuperdash={isSuperdash} companyId={companyId} projects={projects} activeProject={activeProject} setActiveProject={setActiveProject}  refresh={refresh} setRefresh={setRefresh}/>} 
             {activeMenu==="Settings" && <ExtractionSettings project={projectId} isSuperdash={isSuperdash} companyId={companyId} projects={projects} activeProject={activeProject} setActiveProject={setActiveProject} companyData={companyData}  refresh={refresh} setRefresh={setRefresh}/>}
             {activeMenu==="Analytics" && <ExtractionAnalytics project={projectId} isSuperdash={isSuperdash} companyId={companyId} corpus={corpus} companyData={companyData} projects={projects} activeProject={activeProject} setActiveProject={setActiveProject} portalActiveProject={portalActiveProject}  refresh={refresh} setRefresh={setRefresh}/>}
           </div>

        </div>
    );
}

export default ExtractionHome;

