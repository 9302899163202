import React from "react";
import "antd/dist/antd.css";
import { Popconfirm } from "antd";
import { MdDeleteOutline } from "react-icons/md";

function PopupConfirm({ onConfirm }) {
  return (
    <div>
      <Popconfirm
        title="Are you sure to delete this task?"
        onConfirm={onConfirm}
        okText="Delete"
        cancelText="No"
      >
        <a href="#">
          {" "}
          <MdDeleteOutline style={{ color: "#ed4e50" }} />
        </a>
      </Popconfirm>
    </div>
  );
}

export default PopupConfirm;
