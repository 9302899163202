import http from "./httpService";
const apiEndpoint = "/humanAgent";

export function getAllAgents(projectId) {
    return http.get(apiEndpoint + "/" + projectId);
  }

  export function addHumanAgent(projectId, data) {
    return http.post(apiEndpoint + "/" + projectId, data);
  }
    
  