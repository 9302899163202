import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PromptBuilder from "./pages/Block/PromptBuilder";
import ExtractionHome from "./pages/ExtractionApp/ExtractionHome";
import CPLogin from "./pages/ClientPortal/CPLogin";
import ClientPortalProtectedRoute from "./common/ClientPortalProtectedRoute";
import CPLogout from "./pages/ClientPortal/CPlogout";
// Import other necessary components/pages

function ClientPortal() {
    return (
        <div style={{ backgroundColor: '#f0f1f5' }}>
            <Switch>
                <Route path="/login" component={CPLogin} /> 
                <Route path="/logout" component={CPLogout} /> 
                <ClientPortalProtectedRoute path="/" component={ExtractionHome} />
                <Redirect to="/login" />
            </Switch>
        </div>
    );
}

export default ClientPortal;
