import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


function HomeDashboard({conversation,corpus}) {




function countMessages(array) {
    let count = 0;
    for (let i = 0; i < array.length; i++) {
      const messages = array[i].messages;
      const messageCount = messages.length % 2 === 0 ? messages.length : messages.length + 1;
      count += messageCount;
    }
    return count;
  }


if(conversation.conversations &&countMessages(conversation.conversations)===0 )
return(
<div>
  <p className='bb'>Onboarding Instruction</p>
  
  <div>
    {corpus.length<1 && <div style={{padding:"10px 10px 20px 10px"}} className='flexrow bb'>
      <h3 style={{paddingRight:"100px"}}>Step 1</h3>
     
     <div >
    
    <ul className='instructionlist'>
    <li>Please visit the knowledge base</li>
    <li>Click on the "Add" button</li>
    <li>Upload your training material.</li>
    </ul>
      </div>
  </div>}

  <div>
  <div style={{padding:"30px 10px 30px 10px"}} className='flexrow bb'>
    <h3 style={{paddingRight:"100px"}}>{corpus.length<1?"Step 2": "Step 1"}</h3>
    <div>
<ul  className='instructionlist'>
  <li> Go to the Knowledgebase and check if there is a green tick next to the uploaded file. <br/>Only training on those files is complete as of now.</li>
  <li> Click "Refresh training status" for the latest updates on your document training.</li>
  <li> Check the color in the navigation bar. Green or orange indicates testing readiness. </li>
  <li> AI will only answer questions from documents with a green tick. </li>
</ul>
</div>
  </div>
  </div>
    

  </div>
</div>
)



  

if(conversation.conversations && conversation.conversations.length>=1)    
return (
        <div>
            <h3>Analytics</h3>
            <div className='grid grid--1x2'>
<div style={{padding:"30px"}} className='outlinebox'>
<div  >
<p>Total messages sent</p>
</div>
<h1 style={{fontStyle:"bold"}}>{countMessages(conversation.conversations)}</h1>
</div>
       


<div style={{padding:"30px"}} className='outlinebox'>
<div  >
<p>Total conversations</p>
</div>
<h1 style={{fontStyle:"bold"}}>{conversation.conversations.length}</h1>
</div>
  


          </div>  

        </div>
    );








    return (


        <div>
        <h3>Analytics</h3>
        <div className='grid grid--1x2'>
<div style={{padding:"30px"}} className='outlinebox'>
<div  >
<p>Total messages sent</p>
</div>
{(conversation.conversations&& conversation.conversations.length===0)?<h1 style={{fontStyle:"bold"}}>0</h1>:<p><Skeleton width={300} height={30} /></p>}
</div>
   


<div style={{padding:"30px"}} className='outlinebox'>
<div  >
<p>Total conversations</p>
</div>
{(conversation.conversations&& conversation.conversations.length===0)?<h1 style={{fontStyle:"bold"}}>0</h1>:<p><Skeleton width={300} height={30} /></p>}
</div>



      </div>  

    </div>

        

    
    )


    



}

export default HomeDashboard;