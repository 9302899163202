// NavigationBar.js
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ProjectsBox from '../components/ProjectsBox';

const styles = {
  navbar: {
    width: "80vw",
    // backgroundColor: '#f0f1f5',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0 20px',
  },
  navList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
  },
  navItem: {
    cursor: 'pointer',
    padding: '16px',
  },
  dropbtn: {
    // backgroundColor: '#4CAF50',
    // color: 'white',
    padding: '10px 16px',
    fontSize: '16px',
    border: 'none',
    cursor: 'pointer',
  },
  dropdownContent: {
    display: 'none',
    position: 'absolute',
    right: '20px', // adjusted this line to make the dropdown align to the right of the button
    backgroundColor: '#f9f9f9',
    minWidth: '160px',
    boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
    zIndex: 1,
  },
  dropdownContentShow: {
    display: 'block',
  },
  dropdownLink: {
    color: 'black',
    padding: '12px 16px',
    textDecoration: 'none',
    display: 'block',
  },

};

const NavigationBar = ({ companyId, projects, activeProject, setActiveProject}) => {
  const history = useHistory();

  const [isSuperdash] = useState(window.location.hostname.includes(process.env.REACT_APP_DOMAIN));

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleCompanySettings = () => {
    history.push(`/companysettings/${companyId}`);
  };

  return (
    <nav style={styles.navbar}>
      <ul style={styles.navList}>
        {/* Other navigation items can be added here */}
        {(projects && isSuperdash ) && (
          <li style={styles.navItem}>
            <ProjectsBox projects={projects} activeProject={activeProject} setActiveProject={setActiveProject}  />
          </li>
        )}
      </ul>
      <ul style={styles.navList}>
        <li style={styles.navItem}>
          <button onClick={toggleDropdown} style={styles.dropbtn}>
            ▼ {/* Unicode down arrow */}
          </button>
          <div style={{...styles.dropdownContent, ...(isDropdownOpen ? styles.dropdownContentShow : {})}}>
            {window.location.hostname.includes(process.env.REACT_APP_DOMAIN) && <a onClick={handleCompanySettings} style={styles.dropdownLink}>Company Settings</a>}
            {window.location.hostname.includes(process.env.REACT_APP_DOMAIN) && <a href="/extraction-billing" style={styles.dropdownLink}>Billing</a>}
            <a href="/logout" style={styles.dropdownLink}>Logout</a>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default NavigationBar;
