import React,{useState, useEffect} from 'react';
import { Button, Modal } from 'antd';
import CopyInput from '../../common/CopyInput';
import * as keyService from "../../services/keyService";
import CopyToClipboard from 'react-copy-to-clipboard';
import { MdContentCopy } from "react-icons/md";
import { message } from "antd";
import { BsTrash} from "react-icons/bs";


function HomeSettings({projectId}) {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [key, setKey] = useState("")
    const [keyData, setKeys] = useState([])
    const [specificKey, setSpecificKey] = useState("")
    const [formState, setFormState] = useState("first")

    useEffect(() => {
      const fetchData = async () => {
        const { data } = await keyService.allKeys(projectId);
        setKeys(data)
      };
  
      fetchData();
    }, [isModalOpen, deleteModalOpen]);
    
    const showModal = async () => {
      try {
        const response = await keyService.generateKey(projectId);
        if(response.status===200) {
          setKey(response.data.apiKey.key)
          setIsModalOpen(true);
        }
      } catch (ex) {
        if (ex.response && ex.response.status >= 400 && ex.response.status <500) {
          message.error("Please upgrade your account");
          return;
        }
        message.error("Something is wrong");
        return;
      }
    };
    

    const showRevokeModal =(keyId)=>{
      setDeleteModalOpen(true);
      setSpecificKey(keyId);
    }


  
    const handleOk = () => {
      setIsModalOpen(false);
    };

    const RevokeOk = async (keyId) => {
      setKey("")
      const {data} = await keyService.deleteKey(specificKey);
      setDeleteModalOpen(false);
    };

    const RevokeCancel = () => {
      setDeleteModalOpen(false);
    };
  
    const handleCancel = () => {
      setIsModalOpen(false);
 
    };

    const handleCopy =()=>{
      message.success("ProjectID copied");
    }


    const getTime =(data)=>{

  if(data===0)
  return "Never"

 const epochTime = data;
 const date = new Date(epochTime);
 const dateString = date.toLocaleString(); // or use toUTCString() or toDateString() as needed
 return(dateString);
    }
    



    let copyProject =  `${projectId}` 


    return (
        <div>
<div className='flexrow wideapart borderbottom'>
<h3>ProjectID</h3>
<div>
<CopyToClipboard text={copyProject}>
  <div style={{cursor:"pointer"}} onClick={handleCopy} className='flexrow flexrow--rigid'>
    <p style={{marginRight:"20px"}} >{projectId}</p>
    <MdContentCopy/>
  </div>
  </CopyToClipboard>
</div>
</div>






            <div  className='flexrow wideapart'>
              <h3>API Keys</h3>
              <button  onClick={showModal} className='btnn  btnn--small'>+ create key</button>
            </div>
            <p style={{marginTop:"20px", marginBottom:"40px"}}>Below are your secret API keys. Kindly note that once you generate them, we won't display them again.
<br/>
Please refrain from disclosing your API key to others or revealing it in your browser or other client-side code.</p>


{key && <Modal title="API Key Generated" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>For security purposes, it's advised that you save this secret key in a secure and accessible location. 
            <br/>
            <br/>
            Once saved, please note that it won't be visible again through your Superdash account. If the secret key is lost, generating a new one will be required.</p>

            <CopyInput value={key}/>

</Modal>}

<div>
{(keyData && (keyData.apiKeys && keyData.apiKeys.length>=1)) &&  <table className='tableStyle'>

        <thead>
          <tr>
          <th >SECRET KEY</th>
          <th>CREATED</th>  
          <th >LAST USED</th>
          <th></th>      
          </tr>
        </thead>
        <tbody>
      {keyData.apiKeys && keyData.apiKeys.map((item, index) => (
            <tr key={index}>
            <td >{item.redactedKey}</td> 
            <td>{getTime(item.createdAt)}</td> 
            <td>{getTime(item.lastUsed)}</td>
            <td style={{cursor:"pointer"}}><BsTrash onClick={()=>showRevokeModal(item._id)} style={{ color: 'red' }}/></td> 
        </tr>


        ))} 
       </tbody>
       {<Modal 
   title="Revoke API key"
   visible={deleteModalOpen}
   onOk={RevokeOk}
   onCancel={RevokeCancel}
   okButtonProps={{
     style: { backgroundColor: "red", color: "white", outline:"none" },
   }}
   okText="Revoke Key"  
       >
        <p>Revoking this API key will immediately disable it. 
            <br/>
            <br/>
            <br/>
            Any API requests made using this key will be rejected, which may result in systems that rely on it to malfunction
            <br/></p>


</Modal>}

      </table>}

      </div>  
        </div>
    );
}

export default HomeSettings;