import React, { useState, useEffect } from "react";
import { MdEdit } from "react-icons/md";
import { FcOk, FcHighPriority, FcAnswers } from "react-icons/fc";
import { useHistory } from "react-router";
import { Button, message, Modal } from "antd";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import * as knowledgeService from "../../services/knowledgebaseService";
import upload from "../../images/upload.svg";
import spreadsheet from "../../images/spreadsheet.svg";
import url from "../../images/url.svg";
import { Tooltip } from "antd";
import { Empty } from "antd";
import { BiRefresh } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoCloudDownloadOutline } from "react-icons/io5"

function HomeKB({
  handleEmpty,
  activeProject,
  projects,
  knowledgeBase,
  corpus,
  handleRefresh,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [eTag, setEtag] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  // const [formState, setFormState]= useState("second");

  // useEffect(() => {
  //   if (KB) {
  //     setFormState("first");
  //   }
  // }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  let history = useHistory();

  const handleEdit = () => {
    history.push({
      pathname: "/kbsearch/" + knowledgeBase.project,
      state: {
        KB: knowledgeBase,
      },
    });
  };

  const getType = (item) => {
    if (item === "url_list")
      return (
        <Tooltip title="url">
          <img style={{ width: "25px" }} src={url}></img>
        </Tooltip>
      );

    if (item === "google_sheet")
      return (
        <Tooltip title="google sheets">
          <img style={{ width: "25px" }} src={spreadsheet}></img>
        </Tooltip>
      );

    return (
      <Tooltip title="file upload">
        <img style={{ width: "25px" }} src={upload}></img>
      </Tooltip>
    );
  };

  const showDeleteModal = (etag) => {
    setDeleteModalOpen(true);
    setEtag(etag);
  };

  const RevokeOk = async () => {
    try {
      const response = await knowledgeService.deleteKB(
        projects[activeProject]._id,
        eTag
      );
      if (response.status === 200) {
        handleRefresh();
        setDeleteModalOpen(false);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        message.error(ex.response.data.msg);
        setDeleteModalOpen(false);
        return;
      }
    }
  };

  const RevokeCancel = () => {
    setDeleteModalOpen(false);
  };



  const handletDocumentDownloadLink = async (etag, item) => {

    if(item.type != "s3")
    message.error("Only documents can be downloaded.");


    message.info("Please wait while we download the file...");

    try {
      const response = await knowledgeService.getDocumentDownloadLink(projects[activeProject]._id, etag);
      if (response.status === 200) {
        const link = document.createElement('a');
        link.href = response.data;
        link.target = '_blank'; 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (ex) {
      if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
        message.error(ex.response.data);
        return;
      }
    }
  };
  


  const handleAdd = (keyword) => {
    let obj = {};
    obj.projectId = projects[activeProject]._id;
    obj.label = knowledgeBase.label;

    history.replace({
      pathname: "/trainingdata/" + "?ref=" + keyword + "?edit",
      state: {
        passedData: obj,
      },
    });
  };

  if (knowledgeBase && knowledgeBase.project)
    return (
      <div>
        <div className="wideapart">
          <h3>Knowledgebase</h3>
          <div>
            {knowledgeBase && knowledgeBase.paragraphs.length >= 1 && (
              <button
                style={{ width: "auto", fontSize: "12px" }}
                onClick={handleEdit}
                className="btnn--small"
              >
                {" "}
                <MdEdit style={{ marginRight: "5px" }} />
                Edit text data
              </button>
            )}
            {knowledgeBase && (
              <button
                style={{
                  width: "auto",
                  fontSize: "12px",
                  background: "transparent",
                  border: "1px solid black",
                  color: "black",
                }}
                onClick={handleRefresh}
                className="btnn--small"
              >
                {" "}
                <BiRefresh style={{ marginRight: "5px" }} /> Refresh training
                status
              </button>
            )}
            {knowledgeBase && (
              <button
                style={{ fontSize: "12px" }}
                onClick={showModal}
                className="btnn--small"
              >
                + Add
              </button>
            )}
            {!knowledgeBase && (
              <button
                style={{ fontSize: "12px" }}
                onClick={handleEmpty}
                className="btnn--small"
              >
                + Add
              </button>
            )}
          </div>
        </div>

        {
          <Modal
            title="Delete File"
            visible={deleteModalOpen}
            onOk={RevokeOk}
            onCancel={RevokeCancel}
            okButtonProps={{
              style: {
                backgroundColor: "red",
                color: "white",
                outline: "none",
              },
            }}
            okText="Delete this knowledge"
          >
            <p>
              Deleting this file will result in the removal of all training
              conducted using this particular file from your project, and your
              project will no longer be aware of this knowledge.
            </p>
          </Modal>
        }

        <div>
          {corpus && corpus.length >= 1 && (
            <table style={{ marginTop: "50px" }} className="tableStyle">
              <thead>
                <tr>
                  <th>TYPE</th>
                  <th>FILE NAME</th>
                  <th>Download</th>
                  <th>STATUS</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {corpus &&
                  corpus.map((item, index) => (
                    <tr key={index}>
                      <td>{getType(item.type)}</td>
                      <td>
                        {item.type != "s3" ? item.location : item.filename}
                      </td>
                      <td style={{cursor:"pointer"}}>
                      <IoCloudDownloadOutline onClick={() => handletDocumentDownloadLink (item.eTag, item.type)}/>
                      </td>

                      <td style={{ width: "10px" }}>
                        {item.state === "finished" ? (
                          <Tooltip title="finished">
                            <FcOk size={20} style={{ width: "25%" }} />
                          </Tooltip>
                        ) : item.state === "failed" ? (
                          <Tooltip title="failed">
                            <FcHighPriority
                              size={20}
                              style={{ width: "25%" }}
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip title="submitted">
                            <FcAnswers size={20} style={{ width: "25%" }} />
                          </Tooltip>
                        )}
                      </td>
                      <td>
                        <RiDeleteBin6Line
                          onClick={() => showDeleteModal(item.eTag)}
                          style={{
                            cursor: "pointer",
                            color: "red",
                            opacity: "0.8",
                          }}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>

        {corpus && corpus.length < 1 && (
          <div style={{ marginTop: "150px" }}>
            <Empty description={false} />
            <p style={{ textAlign: "center" }}>
              You haven't uploaded any training data.
            </p>
          </div>
        )}

        <div>
          <div style={{ margin: "5px" }} className="flexrow flexend--other">
            <Modal
              title="How would you like to bring the data?"
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={
                <div>
                  <Button key="cancel" onClick={handleCancel}>
                    Cancel
                  </Button>
                </div>
              }
            >
              <div className="flexcolumn">
                <button
                  onClick={() => handleAdd("qna")}
                  style={{ width: "100%" }}
                  className="btnn "
                >
                  Write or Paste Text
                </button>
                <button
                  onClick={() => handleAdd("fileupload")}
                  style={{ width: "100%" }}
                  className="btnn "
                >
                  Upload Files
                </button>
                <button
                  onClick={() => handleAdd("gsheetlink")}
                  style={{ width: "100%" }}
                  className="btnn "
                >
                  Google Sheet
                </button>
                <button
                  onClick={() => handleAdd("url")}
                  style={{ width: "100%" }}
                  className="btnn "
                >
                  Add URLs
                </button>
                {/* <button onClick={()=>handleAdd("website")} style={{width:"100%"}} className='btnn '>Website Link</button> */}
              </div>
            </Modal>
          </div>
        </div>
      </div>
    );

  return (
    <div>
      <div className="wideapart">
        <h3>Knowledgebase</h3>
        <div>
          <button
            onClick={handleEmpty}
            style={{ fontSize: "11.5px" }}
            className="btnn--small"
          >
            + Add data
          </button>
        </div>
      </div>

      <div>
        <p>It seems you haven't provided any training data yet.</p>

        {/* <Skeleton width={800} height={10} />
                    <Skeleton width={800} height={10} />
                    <Skeleton width={800} height={10} />
                    <Skeleton width={800} height={10} />
                    <Skeleton width={800} height={10} />
                    <Skeleton width={800} height={10} />
                    <Skeleton width={800} height={10} />
                    <Skeleton width={800} height={10} />
                    <Skeleton width={800} height={10} />
                    <Skeleton width={800} height={10} />
                    <Skeleton width={800} height={10} /> */}
      </div>
    </div>
  );
}

export default HomeKB;
