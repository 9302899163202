import { useEffect } from "react";
import auth from "../../services/portalAuthService";

function CPLogout(props) {
  useEffect(() => {
    auth.logout();
    window.location = "/login";
  });

  return null;
}

export default CPLogout;
