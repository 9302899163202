// pricingConfig.js
const pricingConfig = {
    "app": [
        {
          planName: "Ada",
          monthly: "$29/month",
          yearly: "$290/yearly",
          features: [
            "1 standard project",
            "1000 questions/month",
            "50 training content",
            "Training via sheets, urls, text, doc, docx, faqs",
            "Chat widget",
            "Whatsapp connection",
            "API access",
            "Zapier",
            "Chat + community support"
          ],

          productIdMonthly: "prod_Nr8PjXg3FRXonw",
          productIdYearly: "prod_O9sL8h2z1XGSlG"

          //Below pricing is for staging testing
          // productIdYearly: "prod_NhA4e3yFcrFSZ6",
          // productIdMonthly: "prod_NhA4e3yFcrFSZ6"
     
        },
        {
          planName: "Scrolls",
          monthly: "$60/month",
          yearly: "$600/yearly",
          features: [
            "3 projects",
            "5000 questions/month",
            "200 training content",
            "Training via sheets, urls, text, doc, docx, faqs",
            "Whatsapp connection",
            "API access",
            "Zapier",
            "Chat + community support"
          ],
          productIdMonthly: "prod_NhYqNns9gA0Qjq",
          productIdYearly: "prod_NhYBFmqmSmIjXj"
        },
        {
          planName: "Rosetta",
          monthly: "$100/month",
          yearly: "$1000/yearly",
          features: [
            "5 projects",
            "10000 questions/month",
            "1000 training content",
            "Training via sheets, urls, text, doc, docx, faqs",
            "Whatsapp connection",
            "API access",
            "Zapier",
            "Priority support"
          ],
          productIdMonthly: "prod_NhYqlUWnUZx9tI",
          productIdYearly: "prod_NhYErjJ64KGNgT"
        },
        {
          planName: "Enterprize",
          monthly: "Custom",
          yearly: "Custom",
          features: [
            "High-end customization",
            "Human supervision learning",
            "Complex workflows",
            "Training via sheets, urls, text, doc, docx, faqs",
            "Custom sheet template",
            "API access",
            "Zapier",
            "Priority support + dedicated account manager"
          ],
          // Assuming these are placeholders for now as it's a custom solution
          productIdMonthly: "customSolutionMonthly",
          productIdYearly: "customSolutionYearly"
        }
      ],
      "extraction": [
        {
          planName: "Essentials",
          monthly: "$249/month",
          yearly: "$199/month",
          features: [
            "Generate 100k words monthly",
            "50 monthly questions to AI chat Assistant",
            "Email support"
          ],

          
          
          productIdYearly: "prod_PdhNW0tyYwuSTi",
          productIdMonthly: "prod_PdhEODmcMw1NVL",

          //Below pricing is for staging testing
          // productIdYearly: "prod_NhA4e3yFcrFSZ6",
          // productIdMonthly: "prod_NhA4e3yFcrFSZ6"
        },
        {
          planName: "Premium",
          monthly: "$599/month",
          yearly: "$499/month",
          features: [
            "Generate 500k words monthly",
            "200 monthly questions to AI chat Assistant",
            "Premium support"
          ],
          
          productIdYearly: "prod_PTa3S0yoJ2NJdZ",
          productIdMonthly: "prod_PTa2inOyoeQuK4"
        },
        {
          planName: "Enterprise",
          monthly: "Custom",
          yearly: " Custom",
          features: [
            "Custom quota",
            "Higher quality responses",
            "Advanced customization",
            "Dedicated account Manager"
          ],
          productIdMonthly: "prod_MonthlyPremiumExtraction",
          productIdYearly: "prod_YearlyPremiumExtraction"
        },
      ],
  };
  
  export default pricingConfig;
  