import React from 'react';
import { useHistory } from 'react-router-dom';

function ExtractionPaymentSuccess(props) {


    const history = useHistory();

const handleDashboard =()=>{
    history.replace("/")
}

    return (
        <div className='flexcolumn flexcolumn--allcenter' style={{height: "100vh", width: "100vw"}}>
            <div>
                <h2>Thank you. Your payment has been successfully processed.</h2>
                <p>Please click here to access the Dashboard.</p>
                <button onClick ={handleDashboard} className='btnn btnn-primary'>Take me to dashboard</button>
            </div>
        </div>
    );
}

export default ExtractionPaymentSuccess;
