import http from "./httpService";
const apiEndpoint = "/customerplan";


export function allPlans(projectId) {
    return http.get(apiEndpoint + "/" + projectId);
}



