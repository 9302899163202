import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function TextEditor({ value, setValue }) {
  const modules = {
    toolbar: false
  };
  const formats = [];
  return (
    <div className="scrolling-container" style={{ height: '70vh', overflowY: 'auto' }}>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={setValue}
        modules={modules}
        formats={formats}
        style={{ height: '100%' }}
      />
    </div>
  );
}

export default TextEditor;
