import http from "./httpService";
// import { apiUrl } from "../config.json";

// const apiEndpoint = apiUrl + "/payments";
const apiEndpoint = "/payment";

// export function makePayment(method, price) {
//   return http.post(apiEndpoint, {
//     price_id: price,
//   });
// }

export function billingPortal(customerId) {
  return http.post(apiEndpoint + "/create-customer-portal-session", {
    customer_id: customerId,
    // method: "POST",
    // headers: {
    //   "Content-Type": "application/json",
    // },
  });
}

export function checkoutSession(prodId, type) {
  return http.post(apiEndpoint + "/create-checkout-session" + "/" + type, {
    prod_id: prodId,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
}


export function connectedSession(companyId, projectId, prodId, email) {
  return http.post(apiEndpoint + "/create-checkout-session" + "/connectedaccount/" + companyId + "/" +  projectId, {
    email:email,
    prod_id: prodId,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
}



export function startOnboarding(listingId,req) {
  return http.post(apiEndpoint + "/start-account-onboarding/" + listingId, req?{
    accountId: req
  }:null);
 }
 


 export function retrieveAccount(accountId) {
  return http.post(apiEndpoint + "/retrive-account/" + accountId);
 }



// export function allBillingDetails() {
//   return http.get(apiEndpoint);
// }
