import React, { useState } from "react";
import { useHistory } from "react-router";
import { Redirect } from "react-router-dom";
import { message } from "antd";
import Joi from "joi-browser";
import Form from "../common/Form";
import authService from "../services/authService";
import { Link } from "react-router-dom";

function SendOTP(props) {

    const [inputField] = useState({
        email: "",
    
      });
    
      const[loading, setLoading] = useState(false);

    
      const [errorMessage] = useState({});


    


      // let auth = useAuth();
      const history = useHistory();
    
      const template = [
        {
          name: "email",
          label: "Enter your email here",
          type: "email",
    
        },
      
      ];
    
      const buttonInfo = { label: "Send email", style: "" };
    
      const schema = {
        email: Joi.string().email().required().label("email"),
    
      };
    
      const handleSubmit = async (email) => 
      {



  
      try {
      const response = await authService.sendOptOnEmail(email);
      if(response.status===200)
       {
        setLoading(false);
        history.replace({ pathname: "/resetpassword/"+ email.email});
       }
     } catch (ex) {

    if (ex.response && ex.response.status === 400 && ex.response.status < 500 ) {

     setLoading(false)
     message.error(ex.response.data.msg);
     return }
     }
      
      };

      





/*---------------- FormState ------------------------------------*/



    return (
        <div>
              <div style={{ height: "100vh" }} className="flexcolumn flexcolumn--allcenter">
        <div className="flexcolumn bluecontainer">
       <div style={{textAlign:"center"}}>
         
              <h2 style={{ paddingBottom: "2rem" }}>Reset password</h2>
              </div>
              <Form
                errorMessage={errorMessage}
                inputField={inputField}
                template={template}
                schema={schema}
                buttonInfo={buttonInfo}
                onSubmit={handleSubmit}
                loading={loading}
                setLoading={setLoading}
       
              />    
        </div>
        <p className="instruction instruction--med">
               Login page<Link to="/login"> here </Link>   
              </p>
        </div>
            
        </div>
    );


}

export default SendOTP;