import React,{useState} from 'react';
import { useHistory } from "react-router";
import { Redirect } from "react-router-dom";
import { message } from "antd";
import Joi from "joi-browser";
import * as clientPortalSerivce from "../../services/clientPortalService";

import { Link } from "react-router-dom";
import Form from '../../common/Form';

function AddClientDomain({projectId, companyId, setPortalModalOpen, portalDetails}) {

    const [inputField, setInputField] = useState({domain:portalDetails.domain?portalDetails.domain:""});


      const[loading, setLoading] = useState(false);

      const [errorMessage] = useState({});
    
      // let auth = useAuth();
      const history = useHistory();
    
      const template = [
        {
          name: "domain",
          label: "Whitelist domain",
          type: "text",  
        },    
      ];
    
      const buttonInfo = { label: "Enable portal", style: "" };
    
      const schema = {
        domain: Joi.string().label("Domain"),
      };

  

      const handleSubmit = async (req) => {
        try {
          let response;

          if (!portalDetails.domain) {

            response = await clientPortalSerivce.enableClientPortal(companyId,projectId, req);
          } else {

            response = await clientPortalSerivce.editClientPortal(companyId,projectId, req);
          }
      
          if (response.status === 200) {
            message.success("Client Portal Updated");
            setPortalModalOpen(false);
            setLoading(false);
            setInputField({ domain: "" });
          }
        } catch (ex) {
          if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
            setInputField({ domain: "" });
            message.error(ex.response.data.msg);
            setPortalModalOpen(false);
            setLoading(false);
          } else {
            message.error("Something went wrong. Please try again later.");
            setPortalModalOpen(false);
            setLoading(false);
            setInputField({ domain: "" });
          }
          setLoading(false);
        }
      };
      
      

    return (
        <div>

       <div style={{textAlign:"center"}}>
              </div>
              <Form
                errorMessage={errorMessage}
                inputField={inputField}
                template={template}
                schema={schema}
                buttonInfo={buttonInfo}
                onSubmit={handleSubmit}
                loading={loading}
                setLoading={setLoading}
              />
        </div>
    );
}

export default AddClientDomain;