import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import Joi from "joi-browser";
import Form from "../../common/Form";
import * as companyService from "../../services/companyService";
import * as profileService from "../../services/profileService";
import { message } from "antd";
import sheets from "../../images/spreadsheet.svg";
import {IoCopy} from "react-icons/io5";
import * as knowledgebaseService from "../../services/knowledgebaseService";
import { Link } from 'react-router-dom';


// import Navbar from "../common/Navbar";


function GSheetLink({passedData}) {

  let history = useHistory();

const [inputField] = useState({
    
    url:"",
    template:"",

  });

  useEffect(() => {


    if (!passedData) {
      history.push("/")
    }
  }, [passedData, history]);


  const[loading, setLoading] = useState(false);


  const [errorMessage] = useState({});

  // let auth = useAuth();




  const template = [
    {
      name: "url",
      label: "Copy paste your Public google sheet link",
      type: "text",
    },
    {
      name: "template",
      label: "Select a template",
      type: "select",
      options: [
        { id: 1, value: "qna", label: "Qna Template" },
        { id: 2, value: "url", label: "URL Template" },
        // { id: 3, value: "custom", label: "Custom Template" },
        // { id: 2, value: "2", label: "cust" },
      ]
    },
  ];


  const buttonInfo = { label: "Upload data", style: "" };
  // const buttonInfo1 = { label: " Create A", style: "" };


  const schema = {

    url: Joi.string().uri().min(2).max(500).required().label("URL"),
    template: Joi.string().max(50).required().label("Template"),

  };

  const handleSubmit = async (data) => {
    {
      try {
        const response = await knowledgebaseService.addGSheet(passedData.projectId,data);
        if(response.status===200)
        {
        history.replace({ pathname: "/" });
        }
      } catch (ex) {
        if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
          setLoading(false)
          message.error(ex.response.data.msg);
          return;
        }
      }
    
    };
 
  }



  function handleDiscordClick(event) {
    event.preventDefault();
    window.open("https://discord.com/invite/xJJfYjxcqy", "_blank");
  }
  

const handleTemplate =(string)=>{
  if(string==="1")
    window.open("https://docs.google.com/spreadsheets/d/1Il3HA1afnwwCDYkGqCz8NTIlqDPfPVtOfzR6sAHuCeE/edit?usp=sharing", '_blank'); 

    if(string==="2")
    window.open("https://docs.google.com/spreadsheets/d/1iP-GE4V4ubNZUd2x2WGMD9LuAJXu5bdjtyjq8bpMTVc/edit#gid=0", '_blank'); 

}


    return (

                <div style={{ height: "100vh", width:"100vw" }} className="flexrow">

 <div style={{marginBottom:"0px"}} className="flexcolmn bluecontainer">

   <Form
                errorMessage={errorMessage}
                inputField={inputField}
                template={template}
                schema={schema}
                buttonInfo={buttonInfo}
                onSubmit={handleSubmit}
                loading={loading}
                setLoading={setLoading}
              />
        
        </div>
        <div style={{margin:"50px"}}>
        <h3>
  Please make a copy of the template and add content in the specified format to train the chatbot. 
  <br/>
  <br/>
  If you're facing any issues or have <u>a custom sheet requirement</u>, please send an email to support@superdashhq.com or <a href="#" onClick={handleDiscordClick} target="_blank">join our Discord here</a>.
</h3>





            <div style={{marginTop:"50px", alignItems: "center"}} className="flex">
            
            
            <div onClick={()=>handleTemplate("1")} style={{margin:"20px", alignItems: "center"}}  className="flexcolumn hover-outline">
                        <img style={{width:"100px", height:"100px"}} src={sheets}></img>
                        <div style={{alignItems: "center"}} className="flexrow flexrow--rigid">
                            <p style={{marginTop:"20px"}}>QnA </p>
                            <IoCopy style={{marginLeft:"10px"}} />
                        </div>
            </div>

                <div onClick={()=>handleTemplate("2")} style={{margin:"20px", alignItems: "center"}}  className="flexcolumn hover-outline">
                        <img style={{width:"100px", height:"100px"}} src={sheets}></img>
                        <div style={{alignItems: "center"}} className="flexrow flexrow--rigid">
                        <p style={{marginTop:"20px"}}>URL</p>
                        <IoCopy style={{marginLeft:"10px"}} />
                        </div>
                </div>


                <div  style={{margin:"20px", alignItems: "center",opacity:"0.6"}}  className="flexcolumn">
                        <img style={{width:"100px", height:"100px"}} src={sheets}></img>
                        <div style={{alignItems: "center"}} className="flexrow flexrow--rigid">
                        <p style={{marginTop:"20px"}}>Custom</p>
                        <IoCopy style={{marginLeft:"10px"}} />
                        </div>
                </div>




                <div style={{margin:"20px", alignItems: "center", opacity:"0.6"}}  className="flexcolumn">
                        <img style={{width:"100px", height:"100px"}} src={sheets}></img>
                        <div style={{alignItems: "center"}} className="flexrow flexrow--rigid">
                        <p style={{marginTop:"20px"}}>Ecommerce</p>
                        <IoCopy style={{marginLeft:"10px"}} />
                        </div>
                </div>
           
           
            </div>


            </div>
      </div>
 
    );
}

export default GSheetLink;