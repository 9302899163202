import React, { useState, useEffect } from 'react';
import BlockComponent from '../../components/BlockComponent';
import * as humanAgentService from "../../services/humanAgentService";
import * as projectService from "../../services/projectService";
import { useParams } from 'react-router-dom';
import Joi from "joi-browser";
import Form from "../../common/Form";
import { message } from "antd";
import { Button, Modal } from 'antd';
import Navbar  from '../../common/Navbar';
import { useLocation } from 'react-router-dom';




function HumanAgent(props) {

  const location = useLocation();
  const passedSettings = location.state?.settings;

  const { projectId } = useParams();

  const [humanagents, setHumanAgents] = useState([])
  const [modalStatus, setModalStatus] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [reload, setReload] = useState(false)
  const [toggle, setToggle] = useState(passedSettings.checkforHumanAgentRequest?passedSettings.checkforHumanAgentRequest:false);
  // Ensure searchTerm state is always an array
 

  
  const [searchItems, setSearchItems] = useState(
    passedSettings.searchItems && Array.isArray(passedSettings.searchItems) 
      ? passedSettings.searchItems 
      : (passedSettings.searchItems ? [passedSettings.searchItems] : [])
  );
  


  const handleModal =(item, key) =>{

    setModalStatus(true)
   

   
   }

   const handleOk = async () => {
    setIsLoading(true)
  
  };


const handleAdd =()=>{
  showModal()
}




const handleToggle = async () => {
  try {
    const response = await projectService.updateProject(projectId, { checkforHumanAgentRequest: !toggle });

    if (response.status === 200) {
      message.success("Changes saved");
      setToggle(!toggle);
    } else {
      message.error("Failed to save changes");
    }
  } catch (error) {
    // Handle any unexpected errors here
    message.error("An error occurred while saving changes");
  }
};

/*-------------------- Modal related --------------------------------*/

const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };


  const handleYes = async (data) => {
    let updatedSearchItems = searchItems;

    // Set the default value when there are no search items
    if (searchItems.length < 1) {
        data.searchTerm = "--default--";
    }

    if (data.searchTerm) {
        data.searchTerm = data.searchTerm.split(' ').map(word => word.toLowerCase()).join(' ');
        updatedSearchItems = [...searchItems, data.searchTerm];
        setSearchItems(updatedSearchItems);
    }

    try {
        const responseAdd = await humanAgentService.addHumanAgent(projectId, data);
    
        if (responseAdd.status === 200) {
            const responseUpdate = await projectService.updateProject(projectId, { searchItems: updatedSearchItems });
    
            if (responseUpdate.status === 200) {
                setIsModalOpen(false);
                setLoading(false);
                setReload(!reload);
            } else {
                console.error("Error updating project:", responseUpdate.status);
                setIsModalOpen(false);
                message.error("Update project request failed");
                setLoading(false);
            }
        } else {
            console.error("Error adding human agent:", responseAdd.status);
            setIsModalOpen(false);
            message.error("Add human agent request failed");
            setLoading(false);
        }
    } catch (error) {
        console.error("An error occurred:", error);
        setIsModalOpen(false);
        message.error("request failed");
        setLoading(false);
    }
};




  useEffect(() => {

    {
     const fetchData = async () => {
      const response = await humanAgentService.getAllAgents(projectId)
      setHumanAgents(response.data)
    };
     fetchData();
    }
    }, [reload]);



    const [inputField, setInputField] = useState({
      name: "",
      phoneNumber:"",
      searchTerm:"",
      email:"",
      contactTemplate:"",
      contactMedium:"",
      language:""
    });
  
    const[loading, setLoading] = useState(false);
  
  
    const [errorMessage] = useState({});
  
  
    // let auth = useAuth();
    // const history = useHistory();
  
    const template = [
  
      {
        name: "name",
        label: "Name of the agent",
        type: "text",
        
      },
  
      {
        name: "email",
        label: "Email",
        type: "text",
  
      },

      searchItems.length>=1 && {
        name: "searchTerm",
        label: "Match keyword",
        type: "text",
      },
  

      {
        name: "phoneNumber",
        label: "Phone Number",
        type: "text",
  
      },
  

      {
        name: "contactTemplate",
        label: "Template Name (Copy from FB)",
        type: "text",
  
      },
  

      {
        name: "contactMedium",
        label: "Channel Name",
        type: "select",
        options: [
          { id: 1, value: "whatsapp", label: "Whatsapp" },
          // { id: 2, value: "email", label: "Email" },
        ]
  
      },

       {
       name: "language",
       label: "Language (Copy from FB)",
       type: "select",
       options: [
        { id: 1, value: "af", label: "Afrikaans" },
        { id: 2, value: "sq", label: "Albanian" },
        { id: 3, value: "ar", label: "Arabic" },
        { id: 4, value: "az", label: "Azerbaijani" },
        { id: 5, value: "bn", label: "Bengali" },
        { id: 6, value: "bg", label: "Bulgarian" },
        { id: 7, value: "ca", label: "Catalan" },
        { id: 8, value: "zh_CN", label: "Chinese (CHN)" },
        { id: 9, value: "zh_HK", label: "Chinese (HKG)" },
        { id: 10, value: "zh_TW", label: "Chinese (TAI)" },
        { id: 11, value: "hr", label: "Croatian" },
        { id: 12, value: "cs", label: "Czech" },
        { id: 13, value: "da", label: "Danish" },
        { id: 14, value: "nl", label: "Dutch" },
        { id: 15, value: "en", label: "English" },
        { id: 16, value: "en_GB", label: "English (UK)" },
        { id: 17, value: "en_US", label: "English (US)" },
        { id: 18, value: "et", label: "Estonian" },
        { id: 19, value: "fil", label: "Filipino" },
        { id: 20, value: "fi", label: "Finnish" },
        { id: 21, value: "fr", label: "French" },
        { id: 22, value: "ka", label: "Georgian" },
        { id: 23, value: "de", label: "German" },
        { id: 24, value: "el", label: "Greek" },
        { id: 25, value: "gu", label: "Gujarati" },
        { id: 26, value: "ha", label: "Hausa" },
        { id: 27, value: "he", label: "Hebrew" },
        { id: 28, value: "hi", label: "Hindi" },
        { id: 29, value: "hu", label: "Hungarian" },
        { id: 30, value: "id", label: "Indonesian" },
        { id: 31, value: "ga", label: "Irish" },
        { id: 32, value: "it", label: "Italian" },
        { id: 33, value: "ja", label: "Japanese" },
        { id: 34, value: "kn", label: "Kannada" },
        { id: 35, value: "kk", label: "Kazakh" },
        { id: 36, value: "rw_RW", label: "Kinyarwanda" },
        { id: 37, value: "ko", label: "Korean" },
        { id: 38, value: "ky_KG", label: "Kyrgyz (Kyrgyzstan)" },
        { id: 39, value: "lo", label: "Lao" },
        { id: 40, value: "lv", label: "Latvian" },
        { id: 41, value: "lt", label: "Lithuanian" },
        { id: 42, value: "mk", label: "Macedonian" },
        { id: 43, value: "ms", label: "Malay" },
        { id: 44, value: "ml", label: "Malayalam" },
        { id: 45, value: "mr", label: "Marathi" },
        { id: 46, value: "nb", label: "Norwegian" },
        { id: 47, value: "fa", label: "Persian" },
        { id: 48, value: "pl", label: "Polish" },
        { id: 49, value: "pt_BR", label: "Portuguese (BR)" },
        { id: 50, value: "pt_PT", label: "Portuguese (POR)" },
        { id: 51, value: "pa", label: "Punjabi" },
        { id: 52, value: "ro", label: "Romanian" },
        { id: 53, value: "ru", label: "Russian" },
        { id: 54, value: "sr", label: "Serbian" },
        { id: 55, value: "sk", label: "Slovak" },
        { id: 56, value: "sl", label: "Slovenian" },
        { id: 57, value: "es", label: "Spanish" },
        { id: 58, value: "es_AR", label: "Spanish (ARG)" },
        { id: 59, value: "es_ES", label: "Spanish (SPA)" },
        { id: 60, value: "es_MX", label: "Spanish (MEX)" },
        { id: 61, value: "sw", label: "Swahili" },
        { id: 62, value: "sv", label: "Swedish" },
        { id: 63, value: "ta", label: "Tamil" },
        { id: 64, value: "te", label: "Telugu" },
        { id: 65, value: "th", label: "Thai" },
        { id: 66, value: "tr", label: "Turkish" },
        { id: 67, value: "uk", label: "Ukrainian" },
        { id: 68, value: "ur", label: "Urdu" },
        { id: 69, value: "uz", label: "Uzbek" },
        { id: 70, value: "vi", label: "Vietnamese" },
        { id: 71, value: "zu", label: "Zulu" }
    ]
  }
    ,
  
  
    
    ];
  
    const buttonInfo = { label: "Add agent", style: "" };
  
    const schema = {
      name: Joi.string().required().max(50).label("Name"),
      email: Joi.string().required().max(50).label("Email"),
      searchTerm: Joi.string().allow(null, "").max(30).label("Match keyword"),
      phoneNumber: Joi.number().required().label("Phone number"),
      contactTemplate: Joi.string().required().max(50).label("Template Name"),
      contactMedium: Joi.string().required().label("Medium"),
      language:Joi.string().required().label("Language"),
    };


  
    //*----------------- Human Agent tranfer form ----------------------------------*//

    const [inputField1] = useState({
      humanAgentMessage: passedSettings.humanAgentMessage?passedSettings.humanAgentMessage:""
    });
  

  
    const [errorMessage1] = useState({});
  
  
    // let auth = useAuth();
    // const history = useHistory();
  
    const template1 = [
  
      {
        name: "humanAgentMessage",
        label: "Transfer message",
        type: "textArea",
        
      },  
    ];
  
    const buttonInfo1 = { label: "Add message", style: "" };
  
    const schema1 = {
      humanAgentMessage: Joi.string().required().max(500).label("Name"),
    };

    const handleMessage = async (data)=>{
      const response = await projectService.updateProject(projectId, data )
      if(response.status===200)
      {
      message.success("Changes saved")
      setLoading(false)
      }
    }


  
  
    //*------------ Each Tab component below ----------------------------*//



    const formState1 = <div className='grid grid--1x3'>
    {humanagents.map((item, index)=>(

  <div style={{padding:"20px"}} key={item._id} className='actionbox'>
    <h3 className='bb'>{item.name}</h3>   
    <p>Contact number: {item.phoneNumber}</p>
    <p>Medium: {item.contactMedium}</p>
    <p>Search term : {item.searchTerm}</p>
  </div>

     
     ))} </div>

     const formState2 = <div>
       <p>Message to be sent to customer while transferring the chat.</p>
       <Form
          errorMessage={errorMessage1}
          inputField={inputField1}
          template={template1}
          schema={schema1}
          buttonInfo={buttonInfo1}
          onSubmit={handleMessage}
          loading={loading}
          setLoading={setLoading}
        />
      
      </div>

const formState3 = <div style={{background:"white", padding:"15px", borderRadius:"10px"}}>
<p>To enable human agent transfer, you must first configure a WhatsApp message template on Facebook and then use the same template name when adding an agent. 
  <br/>
  <br/>
  Please contact us at support@trysuperdash.com if you require any assistance with this process.</p>
</div>




    const filters = ["Existing agents","Transfer message", "Instructions"];
    const [activeKey, setActiveKey] = useState(0);
    const title = "Human agent transfer";
    const description = "Transfer your live WhatsApp conversation to a specific agent"
    const buttonName ="Add an agent"



const ChangeTab =()=>{
  
}



    return (
      <div style={{height:"100vh"}}>
                <Navbar/>
        <BlockComponent title={title} description={description} buttonName={buttonName} filters={filters} setKey={setActiveKey}  formState1={ formState1} formState2={formState2} formState3={formState3} handleModal={handleAdd} onChange={handleToggle} toggle={toggle} activeKey={activeKey}/>
        <>
          <Modal title="Add a new agent" open={isModalOpen} footer={null} onCancel={() => setIsModalOpen(false)}>
          <div style={{ display: "table-caption" }}>
        <Form
          errorMessage={errorMessage}
          inputField={inputField}
          template={template}
          schema={schema}
          buttonInfo={buttonInfo}
          onSubmit={handleYes}
          loading={loading}
          setLoading={setLoading}
        />
            </div>
          </Modal>
        </>
      </div>
    );
}

export default HumanAgent;
