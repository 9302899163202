import React, { useState, useEffect } from 'react';
import BlockComponent from '../../components/BlockComponent';
import * as humanAgentService from "../../services/humanAgentService";
import * as projectService from "../../services/projectService";
import { useParams } from 'react-router-dom';
import Joi from "joi-browser";
import Form from "../../common/Form";
import { message } from "antd";
import { Button, Modal } from 'antd';
import Navbar  from '../../common/Navbar';
import { useLocation } from 'react-router-dom';



function WebSearch(props) {

  const location = useLocation();
  const passedSettings = location.state?.settings;

  const { projectId } = useParams();
  const [humanagents, setHumanAgents] = useState([])
  const [modalStatus, setModalStatus] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [reload, setReload] = useState(false)
  const [toggle, setToggle] = useState(passedSettings.googleSearch?passedSettings.googleSearch:false)





  const handleModal =(item, key) =>{

    setModalStatus(true)
   

   
   }

   const handleOk = async () => {
    setIsLoading(true)
  
  };


const handleAdd =()=>{


  showModal()


}




const handleToggle = async () => {
  try {
    const response = await projectService.updateProject(projectId, {googleSearch: !toggle });

    if (response.status === 200) {
      message.success("Changes saved");
      setToggle(!toggle);
    } else {
      message.error("Failed to save changes");
    }
  } catch (error) {
    // Handle any unexpected errors here
    message.error("An error occurred while saving changes");
  }
};

/*-------------------- Modal related --------------------------------*/

const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };



  const handleYes = async (data) => {

    try {
      const response = await projectService.updateProject(projectId, data);
    
      if (response.status === 200) {
        message.success("Success");
        setIsModalOpen(false);
        setLoading(false);
        setReload(!reload)
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setIsModalOpen(false);
      message.error("request failed");
      setLoading(false);
    }
  };


    const [inputField] = useState({
        // googleAPIKey: "",
        googleCSEID:"",
    });
  
    const[loading, setLoading] = useState(false);
  
  
    const [errorMessage] = useState({});
  
  
    // let auth = useAuth();
    // const history = useHistory();
  
    const template = [
  
      // {
      //   name: "googleAPIKey",
      //   label: "Google API Key",
      //   type: "password",
        
      // },
  
      {
        name: "googleCSEID",
        label: "Google CSEID",
        type: "password",
  
      },

    ];
  
    const buttonInfo = { label: "Sync", style: "" };
  
    const schema = {
        // googleAPIKey: Joi.string().required().max(500).label("Google API key"),
        googleCSEID: Joi.string().required().max(500).label("Google CSEID"),
    };

 
  
    //*----------------- Human Agent tranfer form ----------------------------------*//

    const [inputField1] = useState({
      humanAgentMessage: passedSettings.humanAgentMessage?passedSettings.humanAgentMessage:""
    });
  

  
    const [errorMessage1] = useState({});
  
  
    // let auth = useAuth();
    // const history = useHistory();
  
    const template1 = [
  
      {
        name: "humanAgentMessage",
        label: "Transfer message",
        customClass: "textArea",
        
      },  
    ];
  
    const buttonInfo1 = { label: "Add message", style: "" };
  
    const schema1 = {
      humanAgentMessage: Joi.string().required().max(500).label("Name"),
    };

    const handleMessage = async (data)=>{
      const response = await projectService.updateProject(projectId, data )
      if(response.status===200)
      {
      message.success("Changes saved")
      setLoading(false)
      }
    }


  
  
    //*------------ Each Tab component below ----------------------------*//



    const formState1 = null;
 




    const filters = ["Instructions"];
    const [activeKey, setActiveKey] = useState(0);
    const title = "Google search module";
    const description = "Add Google search to answer your queries"
    const buttonName ="Edit Connection"



const ChangeTab =()=>{
  
}



    return (
      <div>
                <Navbar/>
        <BlockComponent title={title} description={description} buttonName={buttonName} filters={filters} setKey={setActiveKey}  formState1={ formState1} handleModal={handleAdd} onChange={handleToggle} toggle={toggle} activeKey={activeKey}/>
        <>
          <Modal title="Connect to Google Console" open={isModalOpen} footer={null} onCancel={() => setIsModalOpen(false)}>
          <div style={{ display: "table-caption" }}>
        <Form
          errorMessage={errorMessage}
          inputField={inputField}
          template={template}
          schema={schema}
          buttonInfo={buttonInfo}
          onSubmit={handleYes}
          loading={loading}
          setLoading={setLoading}
        />
            </div>
          </Modal>
        </>
      </div>
    );
}

export default WebSearch;
