import React,{useState} from 'react';
import DrawerElement from '../../common/DrawerElement';
import whatsapp from  "../../images/wapp.svg";
import messenger from  "../../images/messenger.svg";
import zapier from  "../../images/zapier.svg";
import twilio from  "../../images/twilio.svg";
import zendesk from  "../../images/zendesk.png";
import zoho from  "../../images/zoho.svg";
import { FcSupport} from "react-icons/fc";
import { BsChatQuote} from "react-icons/bs";
import {FcSms} from "react-icons/fc";
import { ImEmbed} from "react-icons/im";
import { message } from 'antd';
import { BiLinkExternal} from "react-icons/bi";
import { Modal } from 'antd';
import { MdEdit } from "react-icons/md";
import { TbWebhook } from "react-icons/tb";
import { FaWpforms} from "react-icons/fa";
import { useHistory } from 'react-router-dom';






function HomeIntegrations({projectId, companyData}) {

  const history = useHistory();

    const [embedModalOpen, setEmbedModalOpen] = useState(false);  
    const [menuState, setMenuState] = useState("appearance");
    


    const [formState, setFormState] = useState("")


    const [open, setOpen] = useState(false);
    const [size, setSize] = useState();

    const showDefaultDrawer = () => {
      setSize('default');
      setOpen(true);
    };
 
    const onClose = () => {
      setOpen(false);
    };


    const handleFormState =()=>{

        let state = menuState==="appearance"? "settings" :"appearance"
        setMenuState(state)
       }
    
const validItems = ["whatsapp", "messenger", "form", "twilio", "zohocrm"];

const handleClick = (item) => {
  if (validItems.includes(item)) {
    setOpen(true);
    setFormState(item);
  }
};

const handleChat =(item)=>{
    setOpen(true)
    setFormState(item)
    setMenuState("appearance")
}

const handleSales =()=>{
message.info("Please contact support")
}



const handledocs =()=>{
    window.open("https://superdashhq-1.gitbook.io/superdash-api-docs/", '_blank'); 

}

const RevokeOk = async (keyId) => {
    // const {data} = await keyService.deleteKey(specificKey);
    setEmbedModalOpen(false);
  };

  const RevokeCancel = () => {
    setEmbedModalOpen(false);
    setFormState("")
  };







    return (
        <div>
             <h3>Integrations</h3>
             {/* <p>Turn on the toggle switch to connect to your favorite services</p> */}

             <div className='grid grid--1x2'>

             <div onClick={handledocs} style={{padding:"15px"}} className='outlinebox flexrow alignhorizontal-wideapart'>
                   <FcSupport style={{height:"50px", width:"50px"}}/>
                     <div>API Docs</div>
                   <BiLinkExternal />   
                 </div>


                 <div onClick={()=>handleClick("form")} style={{padding:"15px"}} className='outlinebox flexrow alignhorizontal-wideapart'>
                  <FaWpforms style={{height:"50px", width:"50px"}}/>
                     <div>Share link</div>
                     {/* <Toggle/> */}
                  
                 </div>

                 {/* <div onClick={handleWebhook} style={{padding:"15px"}} className='outlinebox flexrow alignhorizontal-wideapart'>
                 <TbWebhook style={{height:"50px", width:"50px"}}/>
                     <div>Webhooks</div>
             
                  
                 </div> */}


                 <div onClick={()=>handleChat("chatwidget")} style={{padding:"15px"}} className='outlinebox flexrow alignhorizontal-wideapart'>
                 <BsChatQuote style={{height:"50px", width:"50px"}}/>
                     <div>Chat widget</div>
             
                  
                 </div>


                 <div onClick={()=>handleChat("embed")} style={{padding:"15px"}} className='outlinebox flexrow alignhorizontal-wideapart'>
                 <ImEmbed style={{height:"50px", width:"50px"}}/>
                     <div>Embed code</div>
             
                  
                 </div>

                 {/* <div onClick={handleClick} style={{padding:"15px"}} className='outlinebox flexrow alignhorizontal-wideapart'>
                 <FcSms style={{height:"50px", width:"50px"}}/>
                     <div>SMS</div>
                 
                 </div> */}


                 <div onClick={()=>handleClick("whatsapp")} style={{padding:"15px"}} className='outlinebox flexrow alignhorizontal-wideapart'>
                     <img style={{width:"50px"}} src={whatsapp}></img>
                     <div>Whatsapp</div>
                     {/* <Toggle/> */}
                 </div>


                 <div onClick={()=>handleClick("messenger")} style={{padding:"15px"}} className='outlinebox flexrow alignhorizontal-wideapart'>
                     <img style={{width:"50px"}} src={messenger}></img>
                     <div>Facebook</div>
                     {/* <Toggle/> */}
                  
                 </div>




                 <div onClick={handleSales} style={{padding:"15px"}} className='outlinebox flexrow alignhorizontal-wideapart'>
                 <img style={{width:"50px"}} src={zapier}></img>
                     <div>Zapier</div>                  
                 </div>


                 <div onClick={()=>handleClick("twilio")}  style={{padding:"15px"}} className='outlinebox flexrow alignhorizontal-wideapart'>
                 <img style={{width:"50px"}} src={twilio}></img>
                     <div>Twilio</div>                  
                 </div>

                 <div onClick={()=>handleClick("zohocrm")}  style={{padding:"15px"}} className='outlinebox flexrow alignhorizontal-wideapart'>
                 <img style={{width:"50px"}} src={zoho}></img>
                     <div>Zoho CRM</div>                  
                 </div>

                 {/* <div onClick={handleClick} style={{padding:"15px"}} className='outlinebox flexrow horizontal-center alignhorizontal-wideapart'>
                     <img style={{width:"50px"}} src={zendesk}></img>
                     <div>Zendesk (contact sales)</div>
                     
                  
                 </div> */}
             </div>

    <DrawerElement title={
        <div className='wideapart'>
    {formState === "whatsapp"
    ? "Whatsapp Configuration"
    : formState === "messenger"
    ? "Messenger Configuration"
    : formState === "chatwidget"
    ? "Chat settings" : 
    formState === "twilio" ? "Twilio settings" :
    formState === "zohocrm" ? "Zoho Authentication" :
    "Embed settings"
   
    }

          {(formState==="chatwidget" ||formState==="embed") && <button onClick={handleFormState} style={{width:"auto"}} className='btnn btnn--small'><MdEdit style={{marginRight:"10px"}}/>{menuState}</button>}
        </div>
      }



             projectId={projectId} setOpen={setOpen} open={open} formState={formState} setFormState={setFormState} menuState={menuState}/>
  
  {<Modal 
   title="Whitelist domain"
   visible={embedModalOpen}
   onOk={RevokeOk}
   onCancel={RevokeCancel}
   okText="Save settings"  
       >





        {/* <p>Revoking this API key will immediately disable it. 
            <br/>
            <br/>
            <br/>
            Any API requests made using this key will be rejected, which may result in systems that rely on it to malfunction
            <br/></p> */}


</Modal>}
        </div>
    );
}

export default HomeIntegrations;