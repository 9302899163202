import React, { useState } from 'react';
import { DatePicker, Space } from 'antd';

function FromToDateSelection({setDates}) {



    // Define your primary color or retrieve it from your theme settings
    const primaryColor = '#4a90e2'; // Example color, replace with your desired color

    const style = {
      border: `1px solid ${primaryColor}`,
      borderRadius: '50%',
    };

    const cellRender = React.useCallback((current, info) => {
      if (info.type !== 'date') {
        return info.originNode;
      }
      if (typeof current === 'number') {
        return <div className="ant-picker-cell-inner">{current}</div>;
      }
      return (
        <div className="ant-picker-cell-inner" style={current.date() === 1 ? style : {}}>
          {current.date()}
        </div>
      );
    }, [style]);

    // Function to handle date range selection
    const handleDateChange = (dates, dateStrings) => {
      setDates({
        startDate: dateStrings[0],
        endDate: dateStrings[1]
      });
    };

    return (
        <Space size={12} direction="vertical" style={{marginBottom:"30px"}}>
          <DatePicker.RangePicker
            cellRender={cellRender}
            onChange={handleDateChange} // Set the onChange handler
          />
        </Space>
      );
}

export default FromToDateSelection;
