import React from "react";
import PopupConfirm from "../common/PopupConfirm";
import Toggle from "../common/Toggle";
import { MdModeEditOutline } from "react-icons/md";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Search from "../common/Search";
import { head } from "lodash";

function MovableCards({
  posts,
  onDragEnd,
  onConfirm,
  onEdit,
  onChange,
  onSearch,
  value,
}) {
  const apiPoint = process.env.REACT_APP_API_POINT;

  function getCardsClasses(item) {
    return item.visibility === "no"
      ? "move cards movecards--invisible"
      : "movecards";
  }



  return (
    <div className="flexcolumn horizontal--center" style={{minHeight:"100vh", height:"auto"}}>
      <div >
        <DragDropContext onDragEnd={onDragEnd}>
          {/* <Search style={{width:"900px"}} value={value} onSearch={onSearch} /> */}
          <Droppable droppableId="droppable-1">
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {posts && posts.map((item, index) => (
                  <Draggable
                    key={item._id}
                    draggableId={"draggable_" + item._id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        className={getCardsClasses(item)}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className="wideapart"><span></span>
                        <span onClick={() => onEdit(item, index)} className="editbox"> <MdModeEditOutline style={{width:"30px"}}  />Edit</span></div>
                        <h4>{item.question}</h4>
                        <div className="horizontal--center">
                          <div className="horizontal--center">
                            <PopupConfirm onConfirm={() => onConfirm(item, index)} />
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
}

export default MovableCards;
