import React,{useState} from 'react';
import { Button, Modal } from 'antd';
import * as customerService from "../../services/customerService";
import { message } from "antd";
import AddClientDomain from '../FormComponent/AddClientDomain';

function AddModalforClientDomain( {portalModalOpen, setPortalModalOpen, companyId, projectId, portalDetails}) {



  
  
  
      const RevokeCancel = () => {
    setPortalModalOpen(false);
      };



    return (
        <div>
{<Modal 
 title="Whitelist Domain"
 visible={portalModalOpen}
//  onOk={RevokeOk}
 onCancel={RevokeCancel}
footer={null}
 okText="Confirm"  
     >
        <AddClientDomain projectId={projectId} setPortalModalOpen={setPortalModalOpen} portalDetails={portalDetails} companyId={companyId}/>
    
</Modal>}  
            
        </div>
    );
}

export default AddModalforClientDomain;