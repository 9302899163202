import React, { useState, useEffect } from "react";
import DemoComponent from '../../components/DemoComponent';
import { useParams } from 'react-router-dom';
import * as widgetService from "../../services/widgetService";
import { useLocation } from 'react-router-dom';


const _ = require("lodash");

function SharePage(props) {

    const [open, setOpen] = useState(true);
    const [formStyle, setFormStyle] = useState({});
    const [title, setTitle] = useState("");
    // const title = "Demo to test your custom chatGPT"

    const { projectId } = useParams();

    const location = useLocation(); 

    const isCloseButtonVisible = location.pathname === '/'; // Change this condition to match your specific URL

    useEffect(() => {

      {
       const fetchData = async () => {
        const response = await widgetService.allWidgetDetails(projectId);  
        const style = _.pick(response.data.widget, [ "formImage", "formTitle", "formDescription", "formHeading", "formBackground", "formFontColor","firstMessage", "imageWidth"]);

        // console.log("response", response)
        setFormStyle(() => ({ ...style}));
        setTitle(style.formHeading && style.formHeading!== ""? style.formHeading:"Talk to your data")
        // let data ={};
        // data.title = style.title;
        // data.question = style.question;
        // setFormField(data)
  
        // setSideBar(style.navbarDisplay==="block"?true:false)
  
        //  let data1 ={}
        //  data1.bio= style.bio;
        //  data1.embedTitle= style.embedTitle;
        //  setFormField3(data1)

      };
       fetchData();
      }
      }, []);
  
const getBackground =()=>{
  return formStyle.formBackground ? formStyle.formBackground : "#ffffff"

}

document.addEventListener("DOMContentLoaded", function() {
  // Check if the current URL contains "sharepage"
  if (window.location.href.includes("sharepage")) {
    // Select the .ant-drawer-mask element
    var drawerMask = document.querySelector(".ant-drawer-mask");

    // Remove the background property
    if (drawerMask) {
      drawerMask.style.background = "none";
    }
  }
});



  return (
    <div style={{ height:"100vh", width:"100vw", background: getBackground()}}>
<div style={{ padding: "150px"}} className='flex flexcolumn'>
     <img src={formStyle.formImage} style={{ width: formStyle.imageWidth, marginBottom: "25px" }} />
        <h1 style={{color: formStyle.formFontColor }}>{formStyle.formTitle}</h1>
        <p style={{color: formStyle.formFontColor, maxWidth:"400px" }}>{formStyle.formDescription}</p>
      </div>
     <DemoComponent open={open} title={title} projectID={projectId} formStyle={formStyle}/>
    </div>
  );
}

export default SharePage;
