import React, { useState } from "react";
import { useHistory } from "react-router";
import { Redirect } from "react-router-dom";
import { message } from "antd";
import Joi from "joi-browser";
import Form from "../common/Form";
import * as userService from "../services/userService";
import auth from "../services/authService";
import { Link } from "react-router-dom";
// import "../scss/onboarding.scss";

function SignUp() {
  const [inputField] = useState({
    email: "",
    password: "",

  });

  const[loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  // let auth = useAuth();
  const history = useHistory();

  const template = [
    {
      name: "email",
      label: "Enter your email here",
      type: "email",

    },
    {
      name: "password",
      label: "Set up a password",
      type: "password",

    },
  
  ];

  const buttonInfo = { label: "Sign Up ", style: "" };

  const schema = {
    email: Joi.string().email().required().label("email"),
    password: Joi.string().min(8).required().label("password"),

  };

  const handleSubmit = async (user) => 
  {
    try {
      const response = await userService.register(user);
      if(response.status===200)
      {
      auth.loginWithJwt(response.data.token);
      // auth.loginWithJwt(response.data["x-auth-token"]);
      history.replace({ pathname: "/welcome" });
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        setLoading(false)
        message.error(ex.response.data.msg);
        return;
      }
    }
  
  };

  // history.replace({ pathname: "/" });
  // } catch (ex) {
  //   toast.error(ex.message);
  // }

  // if (auth.isLoggedIn()) {
  //   return (
  //     <Redirect
  //       to={{
  //         pathname: "/",
  //       }}
  //     ></Redirect>
  //   );
  // }

  return auth.getCurrentUser() ? (
    <Redirect to="/" />
  ) : (
    <div>
      <div style={{ height: "100vh" }} className="flexcolumn flexcolumn--allcenter">
        <div className="flexcolumn bluecontainer">
       <div style={{textAlign:"center"}}>
         
              <h2 style={{ paddingBottom: "2rem" }}>Create an account</h2>
              </div>
              <Form
                errorMessage={errorMessage}
                inputField={inputField}
                template={template}
                schema={schema}
                buttonInfo={buttonInfo}
                onSubmit={handleSubmit}
                loading={loading}
                setLoading={setLoading}
              />
  

         
        </div>
        <div style={{textAlign:"center"}} >
             <p className="instruction instruction--med">
              Already have an account? Click here to <Link to="/login">login</Link>
              {/* <Link to="/forgot">reset password</Link> */}
            </p>
            {/* <p className="instruction instruction--mini">
              By signing up you're agreeing to our{" "}
              <Link
                to={{
                  pathname: "https://whatzzznew.com/privacy.html",
                }}
                target="_blank"
              >
                privacy policy {" "}
              </Link>
              and {" "}
              <Link
                to={{
                  pathname: "https://whatzzznew.com/terms.html",
                }}
                target="_blank"
              >
            
                terms.
              </Link>
            </p> */}
          </div>
      </div>
      {/* <ToastContainer position="top-right" /> */}
    </div>
  );
}

export default SignUp;
