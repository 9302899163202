import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Redirect } from "react-router-dom";
import { message } from "antd";
import Joi from "joi-browser";
import Form from "../common/Form";
import * as customerService from "../services/customerService";
// import auth from "../services/authService";
import { Link } from "react-router-dom";


function FormStripeConnectCustomer({projectId, companyId, setPortalModalOpen, portalDetails}) {

    const [inputField, setInputField] = useState({ 
    phoneNumber: "",
    email: "",
});

      let [formState, setFormState] = useState ("form")

      const [sessionId, setSessionId] = useState("")


      const[loading, setLoading] = useState(false);

      const [errorMessage] = useState({});
    
      // let auth = useAuth();
      const history = useHistory();
    
      const template = [
        {
          name: "phoneNumber",
          label: "Phone number",
          type: "text",  
        },  
        {
            name: "email",
            label: "Email",
            type: "text",  
        },  
      ];
    
      const buttonInfo = { label: "Get access", style: "" };
    
      const schema = {
        phoneNumber: Joi.string().required().label("Phone Number"),
        email: Joi.string().email().label("Email").allow('').optional(),
      };

      useEffect(() => {

        const storedSessionId = localStorage.getItem("sessionId");
        const sessionId = storedSessionId || null;
        setSessionId(sessionId)
    
      }, []);


      const handleSubmit = async (req)=>{

        if(sessionId===""||!sessionId)
        return  message.error("You don't have sufficient permission to proceed");


        {
          try {
            const response = await customerService.updateCustomerUsingSessionID(sessionId, req);
            if(response.status===200)
            {
              setFormState("success")
              // console.log(response.data.projectId)
              localStorage.removeItem("sessionId");
              setLoading(false)
            }
          } catch (ex) {
            if (ex.response && ex.response.status >= 400 && ex.response && ex.response.status < 500) {
              setLoading(false)
              message.error(ex.response.data.msg);
              return;
            }
          }
        
        };
      }

  

   
    //   };
      
      
if(formState==="form")
    return (
        <div style={{width:"100vw", height:"100vh", textAlign:"center"}} className="flex flexcolumn flexcolumn--allcenter "> 
      <h3 style={{marginBottom:"70px"}}>Thank you for subscribing. <br/>Please fill in the details below to activate your account.</h3>
      <div>

<div>
       </div>
       <Form
         errorMessage={errorMessage}
         inputField={inputField}
         template={template}
         schema={schema}
         buttonInfo={buttonInfo}
         onSubmit={handleSubmit}
         loading={loading}
         setLoading={setLoading}
       />


 
 </div>
           
        </div>
    );


else return (
      <div style={{width:"100vw", height:"100vh", textAlign:"center"}} className="flex flexcolumn flexcolumn--allcenter "> 
<h3>You have now access to the AI.</h3>
 </div>
    )    
} 

export default FormStripeConnectCustomer