import React,{useState, useEffect} from 'react';
import * as companyService from "../../services/companyService";
import { useParams } from 'react-router-dom';


function CSCompany(props) {

const [companyData, setCompanyData] = useState({})

const {companyId} = useParams();

    useEffect(() => {

        {
         const fetchData = async () => {
          const {data} = await companyService.allCompanyDetails(companyId);  
          setCompanyData(data)
        };
         fetchData();
        }
        }, []);



if(companyData)
    return (
        <div>
          <div className='bb flexrow wideapart'>
              <h3 style={{fontSize:"16px"}}>Package</h3>
              <h3 style={{fontSize:"16px"}}>{companyData.accountType}</h3>
          </div>
          <div className='bb flexrow wideapart'>
              <h3 style={{fontSize:"16px"}}>Projects Count</h3>
              <h3 style={{fontSize:"16px"}}>{companyData.projectLimit}</h3>
          </div>
          <div className='bb flexrow wideapart'>
              <h3 style={{fontSize:"16px"}}>Page Quota</h3>
              {companyData && companyData.usage && <h3 style={{fontSize:"16px"}}>{companyData.usage.current.pagesExtracted} / {companyData.pageExtractionLimit}</h3>}
          </div>
          <div className='bb flexrow wideapart'>
              <h3 style={{fontSize:"16px"}}>Billing end</h3>
              <h3 style={{fontSize:"16px"}}>{companyData.billingEnd}</h3>
          </div>
          <div className='bb flexrow wideapart'>
              <h3 style={{fontSize:"16px"}}>Client portal</h3>
              <h3 style={{fontSize:"16px"}}>{companyData.clientPortal?"Activated" : "Not activated"}</h3>
          </div>
          <div className='bb flexrow wideapart'>
              <h3 style={{fontSize:"16px"}}>Membership Management</h3>
              <h3 style={{fontSize:"16px"}}>{companyData.membershipManagement?"Activated" : "Not activated"}</h3>
          </div>
          <div className='bb flexrow wideapart'>
              <h3 style={{fontSize:"16px"}}>Support</h3>
              <h3 style={{fontSize:"16px"}}>Priority Support</h3>
          </div>
        </div>
    );

    else return null
}

export default CSCompany;