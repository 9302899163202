import React, {useState, useEffect} from 'react';
import { useUserContext } from "../../userContext";
import { useCompanyContext } from "../../companyContext";
import * as projectService from "../../services/projectService";
import * as embedService from "../../services/embedService";
import * as widgetService from "../../services/widgetService";
import * as userService from "../../services/userService";
import { useHistory} from "react-router";
import ToggleSwitch from '../../common/ToggleSwitch';


import Joi from "joi-browser";
import Form from "../../common/Form";
import { message } from "antd";


function ExtractionOnboarding(props) {

    const history = useHistory();

    const [inputField] = useState({
        title:"",
        // projectExtractionLimit:""
      });

      const [projectId, setProjectID] = useState("")

      const apiPoint = process.env.REACT_APP_API_POINT; 
      const[loading, setLoading] = useState(false);
      const [key, setKey] = useState(1)
     
    
    const [errorMessage] = useState({});
    
    const { companyId } = props.match.params;
  
  
    
      const template = [
        {
          name: "title",
          label: "Project Title eg: RFP response project",
          type: "text",
      
        },
        // {
        //   name: "projectExtractionLimit",
        //   label: "Limit extraction quota for this project (e.g., 100)",
        //   type: "number",
      
        // }
      ];
    
      const buttonInfo = { label: "Create Project", style: "" };
    
    
      const schema = {
        title: Joi.string().min(3).max(45).label('Project title'),
        // projectExtractionLimit: Joi.number().required().label('projectExtractionLimit'),
      };
      


      const handleSubmit = async (req) => {

        let type ="extraction"

                    try {
                      const response1 = await projectService.createProject(req, companyId, type);
                      if (response1.status === 200) {
                        let data = {};
                        data.projectId = response1.data._id;
                        setProjectID(response1.data._id)
                        data.domainIdentifiers = ["https://app.superdashhq.com"];
                        data.usageCount = 0;
                        localStorage.setItem('onboarding', true);
                        history.push(`/extraction-template/${companyId}/${response1.data._id}`);
                      }
        
                    } catch (ex) {
                      if (ex.response && ex.response.status === 402) {
                        setLoading(false);
                        message.info(ex.response && ex.response.data.msg);
                            
                      }
                      if (ex.response && ex.response.status === 400) {
                        setLoading(false);
                        message.error(ex.response && ex.response.data.msg);
                        return;
                      }
                    }
                  };



                  const getClasses =(item, number)=>{
                    if(number===key)
                    return `${item} selectionbox_selected`
        
                    else return `${item}`
        
                  }








    return (
            <div style={{height:"100vh"}} className="splithalf">
            <div className="lefthalf centrething flexcolumn flexcolumn--allcenter blackcontainer">
              <div className="leftcontainer">
           <div>    

                <h2 style={{ color: "white" }}>
                    <br />Create a project on <em>Superdash</em>
                </h2>


    
          
      {/* <button onClick={handleNext} style={{ marginTop:"50px"}} className='btnn btnn-primary'>Next Step</button> */}
      
    
       </div>
              </div>
            </div>
            <div  className="righthalf centrething flexcolumn flexcolumn--allcenter">
              <div className='flexcolumn flexcolumn--allcenter'>
           <div style={{background:"white"}} className='bluecontainer'>
           <Form
                  errorMessage={errorMessage}
                  inputField={inputField}
                  template={template}
                  schema={schema}
                  buttonInfo={buttonInfo}
                  onSubmit={handleSubmit}
                  loading={loading}
                  setLoading={setLoading}
                />
  
           </div>
  
                </div>

       



     
            </div>
          </div>
        );
}

export default ExtractionOnboarding;