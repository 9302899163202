import React,{useState, useEffect} from 'react';
import MovableCards from '../../components/MovableCards';
import { message } from "antd";
import { useHistory, useLocation} from "react-router";
import { useParams } from 'react-router-dom';
import Navbar  from '../../common/Navbar';
import BlockComponent from '../../components/BlockComponent';
import Joi from "joi-browser";
import Form from "../../common/Form";
import { Modal } from 'antd';
import * as projectService from "../../services/projectService";
import SpecialToggle from '../../common/SpecialToggle';
import Toggle from '../../common/Toggle';

function QNA(props) {

    const location = useLocation();
    const passedSettings = location.state?.settings;
    const [formKey, setFormKey] = useState(0);
    const { projectId } = useParams();

    const [searchQuery, setSearchQuery] = useState("");
    const [toggle, setToggle] = useState();
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteItem, setDeleteItem] = useState("")
    const[formState, setFormState] = useState("add")
    const[indexForEdit, setIndexForEdit] = useState("add")
    const [advancedVisibility, setAdvancedVisibility]  = useState(true)
    const [toggleValueforAdvancedOptions, setToggleValueforAdvancedOptions] = useState(false)
   

    const handleSearch = (query) => {
        setSearchQuery(query);
      };

      let [posts, setPosts] = useState([]);

      const [reload, setReload] = useState(false)


      useEffect(() => {
        const fetchData = async () => {
          const { data } = await projectService.getPosts(projectId);
  
          setPosts(data.workflowQuestions)
        };
        fetchData();
      }, [reload]);

    useEffect(() => {
  
          if (searchQuery) {
            let postsReceived = posts.filter((m) =>
              m.question.toLowerCase().includes(searchQuery.toLowerCase())
            );
            let postsPending = posts.filter(
              (m) => !m.question.toLowerCase().includes(searchQuery.toLowerCase())
            );
    
            postsPending.map((item) => (item.visibility = "no"));
            posts = [...postsReceived, ...postsPending];
            setPosts(posts);
            return;
          }
    

      }, [searchQuery]);

      const onDragEnd = async (props) => {
        const srcI = props.source.index;
    
        if (!props.destination) {
          message.error("Please drop the item only into the permitted area");
          return;
        }
    
        const desI = props.destination.index;
    
        posts.splice(desI, 0, posts.splice(srcI, 1)[0]);
        setPosts(posts);
    
        try {
        const response = await projectService.updateProject(projectId, { workflowQuestions: posts});
        if (response.status === 200) {
            message.success("Succesfully rearranged");
            setReload(!reload)
        }
        } catch (ex) {
          if (ex.response && ex.response.status >= 400 &&  ex.response.status<500) {
            message.error(ex.response.data);
            return;
          }
        }
      };
    
      const handleDelete = async () => {
        try {
            const updatedPosts = [...posts];
            updatedPosts.splice(deleteItem, 1);
            const response = await projectService.updateProject(projectId, { workflowQuestions: updatedPosts });
            if (response.status === 200) {
                message.success("Post deleted");
                setReload(!reload);
            }
        } catch (ex) {
            if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
                message.error(ex.response.data);
                return;
            }
        }
       
    };
    


    

      const handleEdit = async (item, index) => {
  
        setIsModalOpen(true);

        setFormState("edit")
        setIndexForEdit(index)
        // console.log("edititem", posts[index])
        setInputField({
            question:  posts[index].question,
            field: posts[index].field,
            variableName: posts[index].variableName,
            condition: posts[index].condition,
            variableMatchValue:posts[index].variableMatchValue
          });
        
      

        setToggleValueforAdvancedOptions(posts[index].enableAdvancedOptions)

          setFormKey(prevKey => prevKey + 1);
      };

   

   

      const handleAdd =()=>{
        setFormState("add")
        setInputField({ question: "", field: "", variableName: "", condition: "" , variableMatchValue:""});
        showModal()
        setFormKey(prevKey => prevKey + 1);
      }

      const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);

  };


          
      const handleState = async (item) => {
        // const data = _.pick(item, ["post", "isPublished", "url", "matchType","position", "embedAction","embedUrl","extraData", "ctaButton1", "ctaURL1","ctaButton2","ctaURL2","postTiming","triggerName",'important','hideUser','buttonEnabled','trigger']);
        // data.isPublished = item.isPublished === true ? false : true;
    
        try {
        //   const response = await companyService.updatePost(
        //     user.companyId,
        //     item._id,
        //     data
        //   );
        //   if (response.status === 200) {
        //     message.success("Succesfully updated");
        //     window.location = "/";
        //   }
        } catch (ex) {
          if (ex.response && ex.response.status >= 400) {
            message.error(ex.response.data);
            return;
          }
        }
      };


      const showDeleteModal =(keyId, index)=>{
        setDeleteModalOpen(true);
        setDeleteItem(index);
      }

      const RevokeOk = async (keyId) => {
        setDeleteItem("")
        handleDelete()
        setDeleteModalOpen(false);
      };
  
      const RevokeCancel = () => {
        setDeleteModalOpen(false);
      };


      const filters = ["Your Questions"];
      const [activeKey, setActiveKey] = useState(0);
      const title = "Follow up questions";
      const description = " Here, you can ask follow-up questions, reorder, and even edit questions."
      const buttonName ="Add a question"

      const handleToggle = async () => {
        // try {
        //   const response = await projectService.updateProject(projectId, { checkforHumanAgentRequest: !toggle });
      
        //   if (response.status === 200) {
        //     message.success("Changes saved");
        //     setToggle(!toggle);
        //   } else {
        //     message.error("Failed to save changes");
        //   }
        // } catch (error) {
        //   // Handle any unexpected errors here
        //   message.error("An error occurred while saving changes");
        // }
      };


      const formState1 = <div className='grid grid--1x3'>
          <MovableCards
  value={searchQuery}
  posts={posts}
  onSearch={handleSearch}
  onDragEnd={onDragEnd}
  onConfirm={showDeleteModal}
  onEdit={handleEdit}
  onChange={handleState}
  /> 
    </div>




let [inputField, setInputField] = useState({
    question:"",
    enableAdvancedOptions:"",
    field:"",
    variableName:"",
    condition:false,
    variableMatchValue:""
  });


  useEffect(() => {
  }, [inputField]); 


  const[loading, setLoading] = useState(false);


  const [errorMessage] = useState({});




  // let auth = useAuth();
  // const history = useHistory();

  const advancedOptions = [
    {
      name: "field",
      label: "Type of field",
      type: "select",
      options: [
        { id: 1, value: "attribute", label: "Attribute" },
        { id: 2, value: "metadata", label: "Metadata" },
        { id: 3, value: "api", label: "API" },
      ],
    },
    {
      name: "variableName",
      label: "Specify variable name",
      type: "text",
    },
    {
      name: "condition",
      label: "Specify condition",
      type: "select",
      options: [
        { id: 1, value: "is_missing", label: "is Missing" },
        { id: 2, value: "is_present", label: "is Present" },
      ],
    },
    {
      name: "variableMatchValue",
      label: "Variable match value",
      placeholder: "Leave it blank if it can take any value; otherwise, specify it.",
      type: "text",
    },
  ];
  
  const template = [
    {
      name: "question",
      label: "Add your question",
      type: "textArea",
      style: { width: "400px" },
    },
    // {
    //   name: "enableAdvancedOptions",
    //   label: "Conditional Visibility",
    //   type: "select",
    //   options: [
    //     { id: 1, value: false, label: "false" },
    //     { id: 2, value: true, label: "true" },
    //   ],
    // }
    ,
    ... (toggleValueforAdvancedOptions ? advancedOptions : []),
  ];


  const buttonInfo = { label: formState==="edit"? "Save changes":" Add a question", style: "" };

  const schema = {
    question: Joi.string().allow(null, "").max(500).label("Question"),
    field: Joi.string().allow(null, "").max(250).label("Field"),
    variableName: Joi.string().allow(null, "").max(30).label("variableName"),
    condition: Joi.string().allow(null, "").max(50).label("Condition"),
    // enableAdvancedOptions: Joi.boolean().label("Active state"),
    variableMatchValue:Joi.string().allow(null, "").max(5000).label("Match value"),

  };


  const handleEditQuestion = async (post) => {
    try {
        post.enableAdvancedOptions = toggleValueforAdvancedOptions
        let newPosts = [...posts]; 
        newPosts[indexForEdit] = post; 

        const response = await projectService.updateProject(projectId, { workflowQuestions: newPosts });

        if (response.status === 200) {
            setIsModalOpen(false);
            setLoading(false);
            message.success("Edit succesful");
            setReload(!reload);
        }

    } catch (ex) {
        setLoading(false);
        if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
            message.error(ex.response.data.msg);
        } else {
            message.error("An unexpected error occurred");
        }
    }
};

const handleToggleValue =()=>{
  setToggleValueforAdvancedOptions(!toggleValueforAdvancedOptions)  
}


  const handleQuestion = async (post) => {
     
    let newPosts



     if (posts.length === 0) {
        newPosts = [post]; // Wrap post in an array when posts is empty
    } else {
        newPosts = [...posts, post]; // Append post to posts
    }


    try {

        const response = await projectService.updateProject(projectId, { workflowQuestions: newPosts});
        // const response = await customerService.addCustomer(projectId, data);
        
        if (response.status === 200) {
            setIsModalOpen(false);
            setLoading(false);
            message.success("New question added succesfully");
            setReload(!reload)
        }

    } catch (ex) {
        if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
            setLoading(false);
            message.error(ex.response.data.msg);
        }
    }

  }
  
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  


    return (
        <div>
                            <Navbar/>
        <BlockComponent title={title} description={description} buttonName={buttonName} filters={filters} setKey={setActiveKey}  formState1={ formState1}  handleModal={handleAdd} onChange={handleToggle} toggle={toggle} activeKey={activeKey} toggleVisibility={false}/>
        <Modal title={formState==="add"? "Add a question and a condition to display this question." :"Edit this question and a condition to display this question."} open={isModalOpen} footer={null} onCancel={() => setIsModalOpen(false)}>
          <div style={{ display: "table-caption" }}>
            <div className='flexrow wideapart toggleblock'>
              Enable advanced options 
              <Toggle  onChange={handleToggleValue} size="small" toggle={toggleValueforAdvancedOptions}/>
            </div>
        <Form
          key={formKey}
          errorMessage={errorMessage}
          inputField={inputField}
          template={template}
          schema={schema}
          buttonInfo={buttonInfo}
          onSubmit={formState==="edit"? handleEditQuestion: handleQuestion}
          loading={loading}
          setLoading={setLoading}
          onCancel={handleModalClose}
        />
            </div>
  </Modal>

  {<Modal 
   title="Delete Question"
   visible={deleteModalOpen}
   onOk={RevokeOk}
   onCancel={RevokeCancel}
   okButtonProps={{
     style: { backgroundColor: "red", color: "white", outline:"none" },
   }}
   okText="Delete this"  
       >
        <p>

        You're about to delete this question permanently.
            <br/></p>


</Modal>}


        </div>
    );
}

export default QNA;