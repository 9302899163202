import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";
import { useHistory } from "react-router";
import failed from "../animation/failed.json";
import { BiArrowBack } from "react-icons/bi";
import { Link } from "react-router-dom";

function Cancelled(props) {
  const container = useRef(null);

  let history = useHistory();

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: failed,
    });
  }, []);

  const handleClick = () => {
    history.replace("/");
  };

  return (
    <div style={{ height: "100vh" }} className="flexcolumn flexcolumn--allcenter">
      <div
        style={{ height: "300px" }}
        className="container"
        ref={container}
      ></div>
      <h2 style={{ textAlign: "center", margin: "30px" }}>
        Your payment attempt didn't go through. Please try again
      </h2>
      {/* <button onClick={handleClick} className="btnn btnn-primary">
   Go Home
      </button> */}
      <Link to="/">
        {" "}
        <BiArrowBack /> Go home
      </Link>
    </div>
  );
}

export default Cancelled;
