import React, { useState, useEffect } from "react";
import Input from "./InputComponent.jsx";
import Joi from "joi-browser";
import loadingIcon from "../images/loading.svg"


function Form({
  inputField,
  errorMessage,
  template,
  schema,
  buttonInfo,
  onSubmit,
  options,
  getValue,
  setErrorState,
  dataExample,
  setLoading,
  loading,
  emptyState,
  setEmptyState
}) {
  const [data, setData] = useState(inputField);
  const [error, setError] = useState({ errorMessage });
  const [schemaCheck] = useState(schema);


  useEffect(() => {
if(dataExample)
setData(dataExample)
  }, [dataExample]);



  useEffect(() => {
if(emptyState===true)
{
  setData(inputField);
  setError({ errorMessage });
  setEmptyState(false)
}
}, [emptyState]);

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(data, schemaCheck, options);

    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemaNew = { [name]: schemaCheck[name] };
    const { error } = Joi.validate(obj, schemaNew);
    return error ? error.details[0].message : null;
  };

  const handleChange = ({ currentTarget: input, toggleValue }) => {
    let newData = { ...data };
    let newError = { ...error };
    
    const targetValue = toggleValue !== undefined ? toggleValue : input.value;
    const errorMessage = validateProperty(input);
    if (errorMessage) newError[input.name] = errorMessage;
    else delete newError[input.name];
    
    newData[input.name] = targetValue;

    if(getValue) getValue(newData)
    setData(newData);
    setError(newError);
    if(setErrorState) validate();
};


  const onchangeInput = (val, index) => {
    let newData = { ...data };
    newData.options[index] = val.target.value;
    if(getValue)getValue(newData)
    setData(newData);
  }


  // const handleSelect = (selectedOption) => {
  //   let newData = { ...data };
  //   newData.matchType = selectedOption.value;
  //   setData(newData);
  // };

  const getButtonClasses = (style) => {
    let classes = style === "normal" ? "btnn " : "btnn btnn-strech ";

    return (classes += validate() ? "btnn-disable " : "btnn-primary");
  };

  const handleSubmit = (e) => {
    setLoading(true)


    e.preventDefault();
    const errors = validate();

    setError(errors || {});


    if (errors) 
    return;
     


  onSubmit(data);
  };

  return (
    <form>
      {template.map((item, index) => (
        <Input
          key={index}
          name={item.name}
          value={data[item.name]}
          label={item.label}
          type={item.type}
          placeholder={item.placeholder}
          onChange={handleChange}
          error={error[item.name]}
          style={item.style}
          options={item.options}
          arrayLength={item.arrayLength}
          customClass={item.customClass}
          onchangeInput={onchangeInput}
        />
      ))}

      {buttonInfo && <button
        onClick={handleSubmit}
        disabled={validate()||loading}
        className={getButtonClasses(buttonInfo.style)}
      >
        {loading?<span>
  Please wait
  <img style={{marginLeft:"10px", width:"48px"}}src={loadingIcon}></img>
  
</span>:buttonInfo.label}
      </button>}
    </form>
  );
}

export default Form;


