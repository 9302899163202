import React,{useState, useEffect, useRef} from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Drawer, message } from 'antd';
import loadingIcon from "../images/animation.svg";
import sendIcon from "../images/send.svg";
import { MdContentCopy } from "react-icons/md";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ArrowTextArea from '../common/ArrowTextArea';
import DOMPurify from 'dompurify'; 
import * as assistConversations from "../services/assistConversations"

function ChatComponent({open,setOpen, title, activeExtractionChat, setRefresh, refresh, extractionId, projectId}) {


    function renderHTML(htmlString) {
        const sanitizedHTML = DOMPurify.sanitize(htmlString); // Use DOMPurify or another HTML sanitizer library
        return { __html: sanitizedHTML };
      }
      
      const apiPoint = process.env.REACT_APP_API_POINT; 
    
      const [drawerVisible, setDrawerVisible] = useState(false);
      const location = useLocation();
      const isCloseButtonVisible = location.pathname === '/';
    
      const [drawerWidth, setDrawerWidth] = useState(700);
    
      let apiURL = `${apiPoint}api/assistantConversation/runAssistant`
    
    const [question, setQuestion] = useState("")
    
      // const showDrawer = () => {
      //   setOpen(true);
      // };
      const onClose = () => {
        if(!setOpen)
        return;
    
        setOpen(false);
        // setRefresh(!refresh)
    
      };
    
      const [loading, setLoading] = useState(true)
    
    
      const [conversationId, setConversationId] = useState("")
      const [conversation, setConversation] = useState([]);
      const [asked, setAsked] = useState("");
      const [streamingResponse, setStreamingResponse] = useState("");
      const [streamingActivity, setStreamingActivity] = useState(false);

     
    
    const handleChange =(e)=>{
    setQuestion(e.target.value);
    
    }
    
    
    const conversationContainerRef = useRef(null);
    
    
    
    useEffect(() => {
      setConversationId('');
      setConversation([]);
    }, [activeExtractionChat]); 

    
    
    useEffect(() => {
      setConversation([]);
      const fetchData = async () => {
        if(extractionId) {
       const {data} =  await assistConversations.getAllAssistantMessages(projectId, extractionId);
       setConversation(data.ac.messages)
      }
      };
    
      fetchData();
    }, [extractionId]);





    
    
    
    const finalURL = `${apiURL}/${projectId}`
    
    // useEffect(() => {
    
    // }, [asked, conversation]);
    
    
    useEffect(() => {
      const conversationContainer = conversationContainerRef.current;
      if (conversationContainer) {
        conversationContainer.scrollTop = conversationContainer.scrollHeight;
      }
    }, [conversation, asked, streamingActivity, streamingResponse]);
    
    
    useEffect(() => {
      const handleResize = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth < 1000) {
          setDrawerWidth('100vw');
        } else {
          setDrawerWidth(700);
        }
      };
    
      handleResize(); // Set initial width
    
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    useEffect(() => {
      // Check if the current URL contains "sharepage"
      const shouldShowCloseButton = !location.pathname.includes('sharepage');
      setDrawerVisible(shouldShowCloseButton);
    }, [location]);
    
    const handleDrawerClose = () => {
      setDrawerVisible(false);
    };
    
    

    
    const handleSubmit = async (latestQuestion) => {
      let currentQuestion = typeof latestQuestion === "string" ? latestQuestion : question;
  
      if (asked === "true") return;
  

  
      let newElement = { q: currentQuestion };
      setAsked("true");
  
      let array = [...conversation, newElement];

      setConversation(array);
      setQuestion("");
  
      const req = { extractionId: extractionId, message: currentQuestion };
  
      try {
          const { data } = await assistConversations.runAssistant(projectId, req);
   
  
          let lastItem = array[array.length - 1];
          lastItem.a = data.response;
     
  
          setConversation(array);
      } catch (error) {
          console.error(error);
  
      
          if (error.response && error.response.status === 403) {
 
              const errorMessage = error.response.data && error.response.data.msg ? error.response.data.msg : 'Access Denied: You do not have permission to perform this action.';
              message.error(errorMessage);
          } else {
           
              message.error('An error occurred. Please try again later.');
          }
      }
  
      setAsked("false");
  };
  
  

    
    const handleKeyDown = (event) => {
      if (event.keyCode === 13 && !event.shiftKey) {
    
        event.preventDefault();
        handleSubmit();
      }
    };
    


    return (
        <div>
                <Drawer  
      
      title={
        <div className='flexrow wideapart' style={{ fontWeight: 'bold', fontSize: '18px'}}>
          <div style={{paddingLeft:"10px"}}>{title}</div>

        </div>
      }
      onClose={onClose} open={open} 
      width={drawerWidth}
      closable={isCloseButtonVisible}
      drawerStyle={{ background: '#f9f9f9' }}>
      <div className='chatbox'>
                <div className="chatbox_header">
                </div>


              

                <div ref={conversationContainerRef} style={{overflow: 'auto', height: '80%', position:"relative"}} >
<div className="conversation">
            
                </div>                

                <div  >
                    {conversation.length>=1 && conversation.map((item,index) => (
                               <div key={index} className="conversation">


{item.q && item.q.trim() !== "" && (
    <div className="operator-response" lang="">{item.q}</div>
)}
                      {item.a && (<div className="user-input" lang="" dangerouslySetInnerHTML={renderHTML(item.a)}></div>)}
                     {((conversation.length === index+1) && asked==="true") && <div>{<div style={{padding:"5px", margin:"5px", position:"absolute", left:"20px"}} className="user-input">
                     <img  style={{width:"150px", height:"40px"}}  src={loadingIcon}></img>
                     </div>}</div>}  

              </div>

        ))} 
   </div>

                      </div>
  



       



            
                {/* <h1>Hello world</h1> */}
                <div style={{flexWrap: "nowrap", alignItems: "center"}} className="chatblock flexrow flexrow--rigid alignhorizontal">
        

                <ArrowTextArea value={question} handleSubmit={handleSubmit} handleChange={handleChange} asked={asked} setQuestion={setQuestion}  handleKeyDown={handleKeyDown} streamingActivity={streamingActivity}/>



                {/* <textarea onKeyDown={handleKeyDown}  style={{width:"650px"}} value={question} onChange={handleChange} placeholder="Type your message here and press enter" className="input--custom input--width "></textarea> */}
			    {/* <img onClick={handleSubmit}  className="sendButton"  style={{width:"40px", height:"20px",  paddingLeft:"20px", cursor:"pointer"}} src={sendIcon}></img> */}
            


                </div>
            </div>
      </Drawer>
            
        </div>
    );
}

export default ChatComponent;