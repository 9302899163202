import React, {useState} from 'react';
import { useUserContext } from "../userContext";
import { useCompanyContext } from "../companyContext";
import * as companyService from "../services/companyService";
import { useHistory} from "react-router";
import ToggleSwitch from '../common/ToggleSwitch';
import Joi from "joi-browser";
import Form from "../common/Form";
import { message } from "antd";
import play from '../images/play.svg';
import gohighlevel from '../images/gohighlevel.jpg';
import whatsapp from '../images/whatsapp.svg';
import { set } from 'lodash';
import Whatsapp from '../components/FormComponent/Whatsapp';



function Integration(props) {

    const apiPoint = process.env.REACT_APP_API_POINT; 
    const[loading, setLoading] = useState(false);
    const history = useHistory();
    const [accountsId, setAccountsId] = useState("")

    const [formState, setFormState]= useState("first")   


    // const user = useUserContext();
    // const company = useCompanyContext();

    const { companyId } = props.match.params;
 

   
  

  
        // const handleNext =()=>{
        // button? setFormState("button") : setFormState("simple");
        // }
  


        const handlePlay=()=>{

          history.replace({ pathname: "/googleplay" + "/" + companyId });

        }





  
 



      return (
              <div style={{height:"100vh"}} className="splithalf">
              <div className="lefthalf centrething flexcolumn flexcolumn--allcenter">
                <div className="leftcontainer">
             <div>    
      <h2 style={{color:"white"}}> <br/>Which app do you want to connect </h2>
      <p style={{color:"white"}}>If you would like to link multiple accounts together, please reach out to us for assistance.</p>
       
        {/* <button onClick={handleNext} style={{ marginTop:"50px"}} className='btnn btnn-primary'>Next Step</button> */}
        
      
         </div>
                </div>
              </div>
              {formState==="first" && <div  className="righthalf centrething flexcolumn flexcolumn--allcenter">
                  <div className='flexcolumn flexcolumn--allcenter'>
      
    <div onClick={()=>setFormState("2nd")}style={{justifyContent: "space-around"}} className='flexrow bluebox alignhorizontal-center'>
    <img style={{width:"40px"}} src={whatsapp}></img>
    <p style={{fontWeight:"600"}}>Connect Whatsapp</p>

    </div>

    <div style={{justifyContent: "space-around"}} className='flexrow bluebox alignhorizontal-center'>
    <img style={{width:"60px"}} src={gohighlevel}></img>
    <p style={{fontWeight:"600"}}>Connect Highlevel</p>

    </div>
                  </div>
              </div>}


              {formState==="2nd" && 
              <div className='righthalf centrething flexcolumn flexcolumn--allcenter'
              ><Whatsapp/></div>
              }
            </div>
          );
  
     
  

  }
  
  export default Integration;