import React, {useState, useEffect} from 'react';
import { useUserContext } from "../userContext";
import { useCompanyContext } from "../companyContext";
import * as projectService from "../services/projectService";
import * as embedService from "../services/embedService";
import * as widgetService from "../services/widgetService";
import * as userService from "../services/userService";
import { useHistory} from "react-router";
import ToggleSwitch from '../common/ToggleSwitch';


import Joi from "joi-browser";
import Form from "../common/Form";
import { message } from "antd";



function Onboarding(props) {


  // const user = useUserContext();
  // const company = useCompanyContext();
  const [formState, setFormState] = useState("first");
  const [key, setKey] = useState(1)
  const [projectId, setProjectID] = useState("")

    const apiPoint = process.env.REACT_APP_API_POINT; 
    const[loading, setLoading] = useState(false);
    const [type, setType] = useState("chat");
    const history = useHistory();
    const [inputField] = useState({
      title:"",
    });

    // const [domainIdentifiers] = useState(["https://superdashhq.com/"]);
 
  
  const [errorMessage] = useState({});
  
  const { companyId } = props.match.params;


  
    const template = [
      {
        name: "title",
        label: "Project Title eg: Customer support chatbot",
        type: "text",
    
      }
    ];
  
    const buttonInfo = { label: "Create Workspace", style: "" };
  
  
    const schema = {
      title: Joi.string().min(3).max(45).label('Project title'),
    };
    
     
    
  
    
          // const handleNext =()=>{
          // button? setFormState("button") : setFormState("simple");
          // }


    
          const handleSubmit = async (req) => {

if(key==="") return message.info("Please make atleast one selection")


            try {
              const response1 = await projectService.createProject(req, companyId, type);
              if (response1.status === 200) {
                let data = {};
                data.projectId = response1.data._id;
                setProjectID(response1.data._id)
                data.domainIdentifiers = ["https://app.superdashhq.com"];
                data.usageCount = 0;
                // data.userId = "6429070cdfc282ef61060a1b";
                
    
                try {
                  const response2 = await embedService.createDomainIntegration(data);
                  if (response2.status === 200) {
                    await widgetService.addWidget(response1.data._id)
                    setFormState("second")
                    // history.replace({ pathname: "/training/" + response1.data._id });
                  }
                } catch (ex) {
                  if (ex.response && ex.response.status === 400) {
                    setLoading(false);
                    message.error(ex.response && ex.response.data.msg);
                    return;
                  }
                }
              }

            } catch (ex) {
              if (ex.response && ex.response.status === 402) {
                message.info("Please upgrade your account to add more projects. Redirecting to home page");
                history.replace("/")

              }
              if (ex.response && ex.response.status === 400) {
                setLoading(false);
                message.error(ex.response && ex.response.data.msg);
                return;
              }
            }
          };


          const handleBot = async () => {
            try {
              const response = await projectService.updatePrompt(projectId, key);
              if (response.status === 200) {
              if(key!==100)  
              history.replace({ pathname: "/training/" + projectId });

              else setFormState("third")
              }

            } catch (ex) {
             
              if (ex.response && ex.response.status >= 400 && ex.response && ex.response.status<500) {
                setLoading(false);
                message.error(ex.response && ex.response.data.msg);
                return;
              }
            }
          };

          const getClasses =(item, number)=>{
            if(number===key)
            return `${item} selectionbox_selected`

            else return `${item}`

          }
          
          



  
if(formState==="first")
 return (
          <div style={{height:"100vh"}} className="splithalf">
          <div className="lefthalf centrething flexcolumn flexcolumn--allcenter">
            <div className="leftcontainer">
         <div>    
  <h2 style={{color:"white"}}> <br/>Create A Workspace on <em>SuperdashHQ</em></h2>


    {/* <button onClick={handleNext} style={{ marginTop:"50px"}} className='btnn btnn-primary'>Next Step</button> */}
    
  
     </div>
            </div>
          </div>
          <div  className="righthalf centrething flexcolumn flexcolumn--allcenter">
              <div className='flexcolumn flexcolumn--allcenter'>
         <div className='bluecontainer'>
         <Form
                errorMessage={errorMessage}
                inputField={inputField}
                template={template}
                schema={schema}
                buttonInfo={buttonInfo}
                onSubmit={handleSubmit}
                loading={loading}
                setLoading={setLoading}
              />

         </div>

              </div>
   
          </div>
        </div>
      );

      if(formState==="second")
      return (
        <div style={{height:"100vh"}} className="splithalf">
        <div className="lefthalf centrething flexcolumn flexcolumn--allcenter">
          <div className="leftcontainer">
       <div>    
<h2 style={{color:"white"}}> <br/>Make <em>your AI <br/> specialize</em> in a task </h2>


  {/* <button onClick={handleNext} style={{ marginTop:"50px"}} className='btnn btnn-primary'>Next Step</button> */}
  

   </div>
          </div>
        </div>
        <div  style={{margin:"100px"}} className="righthalf centrething flexcolumn flexcolumn--allcenter">
            <div className='flexcolumn flexcolumn--allcenter'>
  
    <div className='grid grid--1x2--special'>
      <div onClick={()=>setKey(1)} className={getClasses("selectionbox",1)}>
        <h3>Question answer over documents</h3>
        An AI assistant to help you comprehend documents.
        </div>
      <div onClick={()=>setKey(3)} className={getClasses("selectionbox",3)}>
        <h3>Support agent</h3>
        A support agent who answers customers using my documents, URLs and FAQs.
        </div>
      <div onClick={()=>setKey(2)} className={getClasses("selectionbox",2)}>
      <h3>Sales agent</h3>
      A sales AI agent that learns from your documents, answers customer queries, and pitches your product.
      </div>

      <div  onClick={()=>setKey(100)} className={getClasses("selectionbox black", 100)}>
      <h3 style={{color: "#ececef"}}>Workflow builder (Limited access)</h3>
      <p style={{color:"#ececef", opacity:0.6}}>Ideal if you want to guide users through specific flows and collect information from them.</p>
      </div>
      {/* <div onClick={()=>setKey(100)} className='selectionbox'></div> */}
      {/* <h3>Default</h3> */}
    </div>
    <button className='btnn btnn-primary' style={{marginTop:"50px"}} onClick={handleBot}>Submit</button>

            </div>
 
        </div>
      </div>
      )

      else return (

        <div style={{height:"100vh"}} className="splithalf">
        <div className="lefthalf centrething flexcolumn flexcolumn--allcenter">
          <div className="leftcontainer">
       <div>    
<h2 style={{color:"white"}}> <br/>Make <em>your AI <br/> specialize</em> in a task </h2>


  {/* <button onClick={handleNext} style={{ marginTop:"50px"}} className='btnn btnn-primary'>Next Step</button> */}
  

   </div>
          </div>
        </div>
        <div  style={{margin:"100px"}} className="righthalf centrething flexcolumn flexcolumn--allcenter">
            <div className='flexcolumn flexcolumn--allcenter'>
            <h3>Thanks for showing interest in Workflow!</h3>
            <p style={{lineHeight:"2"}}>
  Could you please contact us through the <span class="underline-text">chat at the bottom left corner</span> and share your use case with us?<br/> It's still a work in progress, but even then, we can <span class="underline-text">quickly enable it for your use case</span>.
</p>
  



            </div>
 
        </div>
      </div>


    
        )
  
 
  

  }
  
  export default Onboarding;