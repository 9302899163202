import http from "./httpService";
// import { apiUrl } from "../config.json";
import jwtDecode from "jwt-decode";

// const apiEndpoint = apiUrl + "/auth";
const apiEndpoint = "/customers";

export async function login(email, password) {
  const response = await http.post(apiEndpoint + "/" +  "clientlogin", {
    email,
    password,
  });
  
  localStorage.setItem("porteltoken", response.data.token);
}

export function sendOptOnEmail(email) {
 return http.post(apiEndpoint + "/sendOptOnEmail", email);
}

export function resetPassword(data) {
  return http.post(apiEndpoint + "/resetPassword", data);
}

export function loginWithJwt(jwt) {
  localStorage.setItem("porteltoken", jwt);
}


export function logout() {
  localStorage.removeItem("porteltoken");
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem("porteltoken");
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export default {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  sendOptOnEmail,
  resetPassword
};
