
import React from 'react';

function OutputComponent(props) {
    return (
        <div>
        <div>

<div  style={{justifyContent: "space-around"}} className='flexrow bluebox alignhorizontal-center'>
<img style={{width:"120px"}}></img>
<p>Connect my Google play</p>

</div>

<div style={{justifyContent: "space-around", opacity: "0.55"}} className='flexrow bluebox alignhorizontal-center'>
<img style={{width:"60px", opacity:""}} ></img>
<p>Connect my Google business (soon)</p>

</div>
        </div>

    </div>
    );
}

export default OutputComponent;