import { message } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from "react-router";
import SubmitInput from '../../common/SubmitInput';
import ExtractForm from '../../components/ExtractForm';
import TextEditor from '../../common/TextEditor';


function LongText({passedData}) {

    const [value, setValue] = useState('');


  const history = useHistory();
  const location = useLocation();
  
  
  const urlPath = history.location.pathname;
  const urlSearch = history.location.search;





    const iframeRef = useRef(null);


 

    

    return (

          <div style={{width:"100vw",height:"100vh"}} className="flexcolumn flexcolumn--allcenter" >
                       <div style={{width:"60vw"}}>
                           <div className='flexrow wideapart'>
                               <h3>Copy paste your long form content here</h3>
                               <button className='btnn btnn-primary'>Submit</button>
                           </div>
                           <TextEditor value={value} setValue={setValue}/>
                       </div>
       
            {/* {(urlPath.includes("qna")||(urlSearch.includes("qna"))) &&  */}
            
 
           
         {/* } */}
          
          </div>
    
   
    );
}

export default LongText;