import http from "./httpService";
const apiEndpoint = "/getextraction";

export function GetAllExtractionStatus(projectId) {
    return http.get(apiEndpoint + "/" + projectId )
  }
  

  export function GetAllExtractionStatusForPortal(projectId) {
    return http.get(apiEndpoint + "/portalextraction" + "/" + projectId )
  }
    

  export function CsvDownload(projectId, startDate, endDate) {
    return http.get(apiEndpoint + "/" + "csvdownload/" + projectId + "/" + startDate + "/" + endDate)
  }