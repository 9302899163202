import React, { useState, useEffect } from "react";
import { Button, Drawer, Space } from 'antd';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { MdContentCopy } from "react-icons/md";
import { message } from "antd";
import ToggleSwitch from './ToggleSwitch';
import Toggle from './Toggle';
import * as embedService from "../services/embedService";
import * as widgetService from "../services/widgetService";
import * as whatsappService from "../services/whatsappService";
import * as messengerService from "../services/messengerService";
import * as twilioService from "../services/twilioService";
import {BsPlusCircle } from "react-icons/bs";
import { useHistory } from "react-router";
import { Redirect } from "react-router-dom";

import Joi from "joi-browser";
import Form from "../common/Form";
import { set } from "lodash";
import CopyInput from "./CopyInput";
import SpecialToggle from "./SpecialToggle";
const _ = require("lodash");






function DrawerElement({title,projectId, setOpen, open, formState, menuState }) {

  const [inputField] = useState({
url:""

  });

  let [formField, setFormField] = useState({
    title: "",
   question: "",


 });


 let [formField3, setFormField3] = useState({
//   embedTitle: "",
//  bio: "",
multiStateForm:"",
//  initialStateMessage:"",
 buttonText:""
});

  const [inputValue, setInputValue] = useState({});
  const [reload, setReload] = useState(true);
  const [sideBar, setSideBar] = useState(true);



  useEffect(() => {

    {
     const fetchData = async () => {
      const response = await widgetService.allWidgetDetails(projectId);  
      const style = _.pick(response.data.widget, ["brandColor", "iconColor", "inputfieldFontColor","fontColor", "question", "title","bio","embedTitle","navbarDisplay", "formImage", "formTitle", "formDescription", "formHeading", "formBackground", "formFontColor","firstMessage", "imageWidth","multiStateForm","initialStateMessage","buttonText"]);
      // console.log("response", response)
      setInputValue(() => ({ ...style}));
      let data ={};
      data.title = style.title;
      data.question = style.question;
      setFormField(data)

 
      setSideBar(style.navbarDisplay==="block"?true:false)

       let data1 ={}
      //  data1.bio= style.bio;
      //  data1.embedTitle= style.embedTitle;
      data1.multiStateForm= style.multiStateForm ?style.multiStateForm:false
      //  data1.initialStateMessage = style.initialStateMessage ? style.initialStateMessage:""
       data1.buttonText = style.buttonText?style.buttonText:""

       setFormField3(data1)

       let data2={}
       data2.formTitle = style.formTitle;
       data2.formDescription = style.formDescription;
       data2.formHeading = style.formHeading;
       data2.formImage = style.formImage;
       data2.firstMessage = style.firstMessage;
       data2.imageWidth = style.imageWidth;
       setInputField5(data2)

    };
     fetchData();
    }
    }, [reload]);










  const[loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  // let auth = useAuth();
  const history = useHistory();

  const template = [
    {
      name: "url",
      label: "Whitelist domain",
      type: "text",

    },
 
  
  ];

  const buttonInfo = { label: "Add domain ", style: "" };

  const schema = {
    url: Joi.string()
      .uri({ scheme: ['http', 'https'] })
      .required()
      .label("URL"),
  };
  






    const [size, setSize] = useState();
    const [embedStatus, setEmbedStatus] = useState(false);
    const [whitelistURL, setWhiteListURL] = useState("");
    const [allow, setAllow] = useState(false)

const handleEmbedChange =()=>{
  setEmbedStatus(!embedStatus)

}



   
    const showDefaultDrawer = () => {
      setSize('default');
      setOpen(true);
    };
  
    const onClose = () => {
      setOpen(false);
    };

    const handleCopy =()=>{
      message.success("Embed code copied");
    }

    const handleInputChange = (event) => {

      setWhiteListURL(event.target.value); 
    }


    let snippetTracking =  `
    <script>
    (function (w, d, s, o, f, js, fjs) {
     w[o] =
     w[o] ||
     function () {
         (w[o].q = w[o].q || []).push(arguments);
     };
    (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);
    js.id = o;
    js.src = f;
    js.async = 1;
    fjs.parentNode.insertBefore(js, fjs);
    })(window, document, "script", "_hw", "https://superdashhq.com/widget.js");
    _hw("init", { debug: true, projectID: "${projectId}"  });
    </script>         
    ` 

    let iframe =  `
    <iframe id="super_embed" style="height:100vh; width:100vw" src="https://www.superdashhq.com/embed?projectID=${projectId}"></iframe>         
    ` 
  
   const handleSave = async (passData) =>{



    let newURL = passData.url.replace(/\/$/, "");
   
 

    let data ={};
    data.projectId =projectId;
    data.domains =[];
    data.domains.push(newURL);


    try {
    
      const response = await embedService.whitelistDomain(data);

      if(response.status===200)
      {
      message.success("New domain whitelisted.")
      setLoading(false)

   
    }
    } catch (ex) {
      if (ex.response && ex.response.status >= 400 && ex.response.status<500 ) {

        message.error(ex.response.data.msg);
        setLoading(false)
        return;
      }
    }

   }
   
   const handlePlus =()=>{
    setAllow(true);
   }




/*----------------------- Bot configuration settings -----------------------------------*/
  

const [errorMessage1] = useState({});

  // let auth = useAuth();


  const template1 = [
    {
      name: "title",
      label: "Bot Title",
      type: "text",

    },
    {
      name: "question",
      label: "Welcome Message",
      type: "textArea",
      customClass: "",

    },
  
  ];

  const buttonInfo1 = { label: "Update settings", style: "" };

  const schema1 = {
    title: Joi.string().max(25).label("title"),
    question: Joi.string().max(50).label("Welcome message"),

  };


  const handleSideBar = async() =>{
    setSideBar(!sideBar)
  }

  
  const handleSubmit = async (data) => 
  {


    inputValue.title = data.title?data.title:inputValue.title;
    inputValue.question = data.question?data.question:inputValue.question;
    inputValue.embedTitle = data.embedTitle?data.embedTitle:inputValue.embedTitle
    inputValue.bio = data.bio? data.bio:inputValue.bio
    inputValue.multiStateForm = data.multiStateForm? data.multiStateForm:inputValue.multiStateForm
    inputValue.initialStateMessage = data.initialStateMessage || data.initialStateMessage===""? data.initialStateMessage:inputValue.initialStateMessage
    inputValue.buttonText = data.buttonText || data.buttonText===""? data.buttonText:inputValue.buttonText;
    inputValue.navbarDisplay = sideBar===true?"block":"none"
    inputValue.formTitle = data.formTitle?data.formTitle:inputValue.formTitle;     
    inputValue.formDescription = data.formDescription?data.formDescription:inputValue.formDescription;     
    inputValue.formHeading = data.formHeading?data.formHeading:inputValue.formHeading;     
    inputValue.formImage = data.formImage?data.formImage:inputValue.formImage;  
    inputValue.firstMessage = data.firstMessage || data.firstMessage===""? data.firstMessage:inputValue.firstMessage; 
    inputValue.imageWidth = data.imageWidth? data.imageWidth:inputValue.imageWidth; 

    try {
      const response = await widgetService.updateWidget(projectId, inputValue);
      if(response.status===200)
      {
     message.success("Configuration saved")
     setLoading(false)
     setOpen(false);
     setReload(!reload)
      }
    } catch (ex) {
      if (ex.response && ex.response.status >= 400 && ex.response.status < 500 ) {
        setLoading(false)
        message.error(ex.response.data.msg)
        return;
      }
    }
  
  };

/*----------------------- Embed configuration settings -----------------------------------*/
  

const [errorMessage3] = useState({});

  // let auth = useAuth();


  const template3 = [
    {
      name: "multiStateForm",
      label: "Activate Multistate form",
      type: "select",
      options: [
        { id: 1, value: false, label: "False" },
        { id: 2, value: true, label: "True" },
      ]

    },
    // {
    //   name: "initialStateMessage",
    //   label: "MultiState Welcome Message",
    //   type: "textArea",
    //   customClass: "small--width",

    // },
    {
      name: "buttonText",
      label: "Button Text",
      type: "text",

    },
    // {
    //   name: "embedTitle",
    //   label: "Heading",
    //   type: "text",

    // },
    // {
    //   name: "bio",
    //   label: "Description",
    //   type: "textArea",
    //   customClass: "small--width",

    // },



  ];

  const buttonInfo3 = { label: "Update content ", style: "" };

  const schema3 = {
  
    multiStateForm: Joi.boolean().label("MultiState Form"),
    buttonText:Joi.string().max(25).label("title")
    // initialStateMessage: Joi.string().allow(null, "").max(200).label("Multistate welcome Message"),
    // embedTitle: Joi.string().max(25).label("title"),
    // bio: Joi.string().max(200).label("Description"),
  };

  
  /*----------------------- Form configuration -----------------------------------*/
  

const [errorMessage5] = useState({});



const [inputField5, setInputField5] = useState({
  formTitle:"",
   formDescription:"",
   formHeading:"",
   formImage:"",
   firstMessage :"",
   imageWidth:""
   });
 



const template5 = [
  {
    name: "formTitle",
    label: "Page Title",
    type: "text",

  },
  {
    name: "formDescription",
    label: "Page Description",
    type: "textArea",
    customClass: "",

  },
  {
    name: "formHeading",
    label: "Form heading",
    type: "text",
    style: "",

  },
  {
    name: "formImage",
    label: "Logo URL",
    type: "text",
    style: "",

  },

  {
    name: "firstMessage",
    label: "Welcome message",
    type: "textArea",
    customClass: "",

  },

  {
    name: "imageWidth",
    label: "Image width",
    type: "text",
    style: "",

  },

  

];

const buttonInfo5 = { label: "Update", style: "" };

const schema5 = {
  formTitle: Joi.string().max(100).allow(null, "").label("Page Title"),
  formDescription: Joi.string().max(1000).allow(null, "").label("Page description"),
  formHeading: Joi.string().max(500).allow(null, "").label("form Heading"),
  formImage: Joi.string().max(100000).allow(null, "").label("Image URL"),
  firstMessage: Joi.string().max(1000).allow(null, "").label("Welcome message"),
  imageWidth: Joi.number().max(500).allow(null, "").label("Image Width"),
};


const handleForm =()=>{

}




 //*----------------------- For Whatsapp ----------------------------------------*//

 
 const [inputField2] = useState({
  wabaID:"",
  wabaAccessToken:"",
  phoneNumberId:""
   });
 
 
 
 const [errorMessage2] = useState({});


  const template2 = [
    {
      name: "wabaID",
      label: "Whatsapp Business Account ID",
      type: "text",
      style: "",
    },
    {
      name: "wabaAccessToken",
      label: "Access token",
      type: "text",
      style: "",
    },
    {
      name: "phoneNumberId",
      label: "Phone Number ID",
      type: "text",

    },
    
  ];

  const buttonInfo2 = { label: "Connect Whatsapp", style: "" };

  const schema2 = {
    wabaID: Joi.string().max(25).required().label("WhatsApp Business Account ID"),
    wabaAccessToken: Joi.string().max(300).required().label("Access Token"),
    phoneNumberId: Joi.string().max(50).required().label("Phone Number ID"),
  };
  

  
  const handleWhatsapp = async (data) => {

  
    const updatedObj = {};
  
    for (let key in data) {
      if (typeof data[key] === "string") {
        updatedObj[key] = data[key].trim();
      } else {
        updatedObj[key] = data[key];
      }
    }

  
    try {
      const response = await whatsappService.manageWhatsapp(projectId, updatedObj);
  
      if (response.status === 200) {
        message.success("Whatsapp configuration saved");
        setLoading(false);
        setOpen(false);
      }
    } catch (ex) {
      if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
        setLoading(false);
        message.error(ex.response.data.msg);
        return;
      }
    }
  };



 //*----------------------- For Messenger ----------------------------------------*//


 const [inputField4] = useState({
  messengerToken:"",
  pageID:""
   });
 


 
 const [errorMessage4] = useState({});


  const template4 = [
    {
      name: "messengerToken",
      label: "Messenger Token",
      type: "text",
      style: "",
    },
    {
      name: "pageID",
      label: "pageID",
      type: "text",
      style: "",

    },
    
  ];

  const buttonInfo4 = { label: "Connect Messenger", style: "" };

  const schema4 = {
    messengerToken: Joi.string().max(300).required().label("Messenger Token"),
    pageID: Joi.string().max(50).required().label("pageID"),
  };
  


  const handleMessenger = async (data) => {

  
    const updatedObj = {};
  
    for (let key in data) {
      if (typeof data[key] === "string") {
        updatedObj[key] = data[key].trim();
      } else {
        updatedObj[key] = data[key];
      }
    }

  
    try {
      const response = await messengerService.manageMessenger(projectId, updatedObj);
  
      if (response.status === 200) {
        message.success("Messenger configuration saved");
        setLoading(false);
        setOpen(false);
      }
    } catch (ex) {
      if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
        setLoading(false);
        message.error(ex.response.data.msg);
        return;
      }
    }
  };
  

  //*----------------------- For Twilio ----------------------------------------*//


 const [inputFieldTwilio] = useState({
  twilioAccountSid:"",
  twilioAuthToken:"",
  channel:"",
  identifier:"",
  

   
});
 


 
 const [errorMessageTwilio] = useState({});


  const templateTwilio = [
    {
      name: "twilioAccountSid",
      label: "Twilio account SID",
      type: "text",
      style: "",
    },
    {
      name: "twilioAuthToken",
      label: "Twilio Auth token",
      type: "text",
      style: "",

    },
    {
      name: "channel",
      label: "",
      type: "select",
      options: [
        { id: 1, value: "whatsapp:", label: "Whatsapp" },
        { id: 2, value: "sms:", label: "SMS" },
      ],
      style:""

    },
    {
      name: "identifier",
      label: "Unique identifier like Phone number",
      type:"text",
      style: "",

    }
    
  ];

  const buttonInfoTwilio = { label: "Connect Twilio", style: "" };

  const schemaTwilio = {
    twilioAccountSid: Joi.string().max(300).required().label("Twilio account SID"),
    twilioAuthToken: Joi.string().max(300).required().label("Twilio Auth Token"),
    channel: Joi.string().max(100).required().label("Twilio channle"),
    identifier: Joi.string().max(100).required().label("Twilio Identifier"),
  };
  


  const handleTwilio = async (data) => {  
    const { twilioAccountSid, twilioAuthToken, channel,identifier } = data;

    const newData = {
      twilioDetails: {
        twilioAccountSid,
        twilioAuthToken
      },
      twilioIdentifier: `${channel}${identifier}`
    };
  
    try {
      const response = await twilioService.addTwilioConnection(projectId, newData);
  
      if (response.status === 200) {
        message.success("Twilio configuration saved");
        setLoading(false);
        setOpen(false);
      }
    } catch (ex) {
      if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
        setLoading(false);
        message.error(ex.response.data.msg);
        return;
      }
    }
  };
  
  
/*------------------- ZohoCRM ----------------------------------------*/

const [formFieldforZohoCRM] = useState({
  region:"", 
});


const [errorMessageZohoCRM] = useState({});


  const templateZohoCRM = [
    {
      name: "region",
      label: "Select your Zoho region",
      type: "select",
      options: [
        { id: 1, value: "https://accounts.zoho.com", label: "US" },
        { id: 2, value: "https://accounts.zoho.com.au", label: "AU" },
        { id: 3, value: "https://accounts.zoho.eu", label: "EU" },
        { id: 4, value: "https://accounts.zoho.in", label: "IN" },
        { id: 5, value: "https://accounts.zoho.com.cn", label: "CN" },
        { id: 6, value: "https://accounts.zoho.jp", label: "JP" },
      ],
      style:""

    }
  ];

  const buttonInfoZohoCRM = { label: "Connect Zoho CRM", style: "" };

  const schemaZohoCRM = {
    region: Joi.string().max(300).required().label("You region"),
  };


  const handleZohoConnect = async (region) => {

    localStorage.setItem('projectId', projectId);
    const zohoAuthUrl = 'https://accounts.zoho.com/oauth/v2/auth?scope=ZohoCRM.users.ALL,ZohoCRM.settings.ALL,ZohoCRM.modules.contacts.READ,ZohoCRM.modules.all,ZohoCRM.org.ALL,ZohoCRM.modules.leads.READ,ZohoCRM.modules.contacts.READ,ZohoSearch.securesearch.READ&client_id=1000.XNJ2PWXBVSHFJE9Q30F9FUR4ZGUYRC&response_type=code&access_type=offline&prompt=consent&redirect_uri=https://app.superdashhq.com/thirdparty/success'
    


    
    // const zohoAuthUrl = `https://accounts.zoho.com/oauth/v2/auth?response_type=code&client_id=1000.57EPV4N5NM2TCAH7S7NZGH13AYTJ0I&scope=email&access_type=offline&redirect_uri=${encodeURIComponent("https://app.trysuperdash.com/thirdparty/success")}`;


    window.open(zohoAuthUrl, '_blank');

  };
  
  

  /*----------------------finished ---------------------------------*/




  const handleColorChange =({ currentTarget: input })=>{
 
    const data = inputValue
    data[input.name] = input.value;

    setInputValue(() => ({ ...data }));
   
 }


 function handleWhatsappInstruction(event) {
  event.preventDefault();
  window.open("https://superdashhq.notion.site/Super_-WhatsApp-Integration-Instructions-17933f1b92d44e5588ad510dc2885995", "_blank");
}


const getFormLink =()=>{
return `https://app.superdashhq.com/sharepage/${projectId}`
}


    

    return (
        <>
          <Space>
       
          </Space>
          <Drawer
            title={title}
            placement="right"
            size={size}
            onClose={onClose}
            open={open}
            width={450}
            // extra={
            //   <Space>
            //     <Button onClick={onClose}>Cancel</Button>
            //     <Button type="primary" onClick={handleSave}>
            //       Save
            //     </Button>
            //   </Space>
            // }
          >




{/* 
        {title==="Embed settings" && 
        <div>
          <div className='flexrow wideapart'>
            <p>Enable Embed Feature</p>
                  <Toggle
                  id="embed"
                  onChange={handleEmbedChange}
                  isActive={embedStatus}
                />
          </div>
          <div className="codebox">
      {snippetTracking}<div style={{position:"relative", bottom:"0px", left:"70px"}}>
      <CopyToClipboard text={snippetTracking}>
      <button style={{marginTop:"30px", padding:"10px 15px", cursor:"pointer"}} onClick ={handleCopy} className="btn"><MdContentCopy /> Click here to copy</button>
      </CopyToClipboard>
    </div></div>



    <div>

  <input id="domain" placeholder="It should start with https: or https://" value={whitelistURL} onChange={handleInputChange} className="input--custom input--small" />
</div>

      

        </div>

        } */}


        <div>

        {formState==="whatsapp" && <div>      

      
     <div style={{marginBottom:"15px"}}>
     <CopyInput value="https://api.superdashhq.com/api/whatsapp/webhook" title="Whatsapp Webhook URL"/>
     </div>

     <div style={{marginBottom:"15px"}}>
     <CopyInput value="4915359cede4b64cb190f02bc1b396af" title="Verify token"/>
     </div>
     

        <div >
            <Form
                errorMessage={errorMessage2}
                inputField={inputField2}
                template={template2}
                schema={schema2}
                buttonInfo={buttonInfo2}
                onSubmit={handleWhatsapp}
                loading={loading}
                setLoading={setLoading}
                
              />
  
        </div>
          
         
        <span>Please find instruction to integrate Whatsapp <a href="#" onClick={handleWhatsappInstruction} target="_blank">here</a> </span> 
       
</div>



}


{formState==="twilio" && <div>      
     
<div style={{marginBottom:"15px"}}>
<CopyInput value="https://api.superdashhq.com/api/webhook" title="Twilio Webhook URL"/>
</div>

   <div >
       <Form
           errorMessage={errorMessageTwilio}
           inputField={inputFieldTwilio}
           template={templateTwilio}
           schema={schemaTwilio}
           buttonInfo={buttonInfoTwilio}
           onSubmit={handleTwilio}
           loading={loading}
           setLoading={setLoading}
           
         />

   </div>  
</div>

}




{formState==="messenger" && <div>      

      
<div style={{marginBottom:"15px"}}>
<CopyInput value="https://api.superdashhq.com/api/messenger/webhook" title="Messenger Webhook URL"/>
</div>

<div style={{marginBottom:"15px"}}>
<CopyInput value="4915359cede4b64cb190f02bc1b396af" title="Verify token"/>
</div>


   <div >
       <Form
           errorMessage={errorMessage4}
           inputField={inputField4}
           template={template4}
           schema={schema4}
           buttonInfo={buttonInfo4}
           onSubmit={handleMessenger}
           loading={loading}
           setLoading={setLoading}
           
         />

   </div>
     
    
   <span>Please find instruction to integrate Facebook <a href="#" onClick={handleWhatsappInstruction} target="_blank">here</a> </span> 
  
</div>



}


{formState==="form" && <div>      

      
<div style={{marginBottom:"15px"}}>
<CopyInput value={getFormLink} title="Share link"/>
</div>

<div className="wideapart">
        <p>Form Background Color</p>
        <input value={inputValue.formBackground} className='colorbox' onChange={handleColorChange}  type="color" id="formBackground" name="formBackground"></input>
  </div>

  <div className="wideapart">
        <p>Font color</p>
        <input value={inputValue.formFontColor} className='colorbox' onChange={handleColorChange}  type="color" id="formFontColor" name="formFontColor"></input>
  </div>



   <div >
       <Form
           errorMessage={errorMessage5}
           inputField={inputField5}
           template={template5}
           schema={schema5}
           buttonInfo={buttonInfo5}
           onSubmit={handleSubmit}
           loading={loading}
           setLoading={setLoading}
           
         />

   </div>
     
    

  
</div>



}


       


{(menuState==="appearance" && formState!=="whatsapp"  && formState!=="messenger" && formState!=="form" && formState!=="twilio" && formState!=="zohocrm") && <div>
<div className="codebox">
      {formState==="chatwidget"? snippetTracking:iframe}<div style={{position:"relative", bottom:"0px", left:"70px"}}>
      <CopyToClipboard text={formState==="chatwidget"? snippetTracking:iframe}>
      <button style={{marginTop:"30px", padding:"10px 15px", cursor:"pointer"}} onClick ={handleCopy} className="btn"><MdContentCopy /> Click here to copy code</button>
      </CopyToClipboard>
    </div></div>
        
        
        {formState==="chatwidget" && <div>
          <div className='flexrow wideapart'>
            <p>First Step: Whitelist URL</p>
          
          </div>
          <div style={{marginBottom:"20px"}}>
          To make the chatbot work on your website, you need to whitelist your domain.
          <br/>
          <br/>
          Just copy and paste the main URL of your website. For instance, if your page is https://www.mysite.com/blogs/hello, your main URL is https://www.mysite.com.
          </div>
          <div >
              <Form
                  errorMessage={errorMessage}
                  inputField={inputField}
                  template={template}
                  schema={schema}
                  buttonInfo={buttonInfo}
                  onSubmit={handleSave}
                  loading={loading}
                  setLoading={setLoading}
          
                />
          </div>
        </div>}
          
         
       
       
</div>}



{menuState==="settings" && (formState === "embed" || formState === "chatwidget") && 
<div>
  <div>
  <div className="wideapart">
        <p>Brand color</p>
        <input value={inputValue.brandColor} className='colorbox' onChange={handleColorChange}  type="color" id="brandColor" name="brandColor"></input>
  </div>

  <div className="wideapart">
        <p>Font color</p>
        <input value={inputValue.fontColor} className='colorbox' onChange={handleColorChange}  type="color" id="fontColor" name="fontColor"></input>
  </div>

  <div className="wideapart">
        <p>Icon color</p>
        <input value={inputValue.iconColor} className='colorbox' onChange={handleColorChange}  type="color" id="iconColor" name="iconColor"></input>
  </div>

  <div className="wideapart">
        <p>InputField Text Color</p>
        <input value={inputValue.inputfieldFontColor} className='colorbox' onChange={handleColorChange}  type="color" id="inputfieldFontColor" name="inputfieldFontColor"></input>
  </div>

  {formState==="chatwidget" && <div >
              <Form
                  errorMessage={errorMessage1}
                  inputField={formField}
                  template={template1}
                  schema={schema1}
                  buttonInfo={buttonInfo1}
                  onSubmit={handleSubmit}
                  loading={loading}
                  setLoading={setLoading}
  
                />
  
  </div>} 








  
  {formState==="embed" &&<div>

  {/* <div className="wideapart" style={{marginBottom:"25px"}}>Display Sidebar
  <div><Toggle onChange={handleSideBar} isActive={sideBar}/></div> */}
  {/* </div>   */}

 <Form
                  errorMessage={errorMessage3}
                  inputField={formField3}
                  template={template3}
                  schema={schema3}
                  buttonInfo={buttonInfo1}
                  onSubmit={handleSubmit}
                  loading={loading}
                  setLoading={setLoading}
  
                /> 
            
            
    </div>}
    




    
    </div>
  
  
       
</div>
}





{formState==="zohocrm" && <div>
  <Form
                errorMessage={errorMessageZohoCRM}
                inputField={formFieldforZohoCRM}
                template={templateZohoCRM}
                schema={schemaZohoCRM}
                buttonInfo={buttonInfoZohoCRM}
                onSubmit={handleZohoConnect}
                loading={loading}
                setLoading={setLoading}

              /> 
  
  </div>} 





      
 

        </div>

        
























          </Drawer>
        </>
      );
}

export default DrawerElement;











