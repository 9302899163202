import React from 'react';
import NavigationBar from '../../common/NavigationBar';
import { useLocation } from "react-router";

function ExtractionAnalytics({project: propProject, companyId, corpus: propCorpus, companyData:propcompanyData,  setActiveProject, activeProject, projects, isSuperdash, portalActiveProject}) {

    let location = useLocation();

    let corpusDocuments = propCorpus || location.state && location.state.corpus;

    let companyData =  propcompanyData || location.state && location.state.companyData;

    let project =  propProject || location.state && location.state.project;

    const countDocumentsUploadedThisMonth = (corpusDocuments) => {

        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();
      
        // Filter documents uploaded in the current month
        const documentsThisMonth = corpusDocuments.filter(document => {
          // Convert the uploadDate from milliseconds to a Date object
          const uploadDate = new Date(document.uploadDate);
      
          return uploadDate.getMonth() === currentMonth && uploadDate.getFullYear() === currentYear;
        });
        
        return documentsThisMonth.length;
      };

      let currentUsage;
      let Limit;
      if(isSuperdash)
      {
      currentUsage = projects && projects[activeProject] && projects[activeProject].usage && projects[activeProject].usage.current && projects[activeProject].usage.current.pagesExtracted;
      Limit = projects[activeProject] && projects[activeProject].projectExtractionLimit;
      } else {
        currentUsage = projects && projects[portalActiveProject] && projects[portalActiveProject].usage && projects[portalActiveProject].usage.current && projects[portalActiveProject].usage.current.pagesExtracted;
        Limit = projects[portalActiveProject] && projects[portalActiveProject].projectExtractionLimit;

      }


      let tokenUsage;
      let tokenLimit;
      if(isSuperdash)
      {
        tokenUsage = projects && projects[activeProject] && projects[activeProject].usage && projects[activeProject].usage.current && (projects[activeProject].usage.current.inputTokens + projects[activeProject].usage.current.outputTokens );
        tokenLimit = projects[activeProject] && projects[activeProject].tokenConsumptionLimit;
      } else {
        tokenUsage = projects && projects[portalActiveProject] && projects[portalActiveProject].usage && projects[portalActiveProject].usage.current && (projects[portalActiveProject].usage.current.inputTokens + projects[portalActiveProject].usage.current.outputTokens)
        tokenLimit = projects[portalActiveProject] && projects[portalActiveProject].tokenConsumptionLimit;

      }

      let chatUsage;
      let chatLimit;
      if(isSuperdash)
      {
        chatUsage = projects && projects[activeProject] && projects[activeProject].usage && projects[activeProject].usage.current.questions;
        chatLimit = projects[activeProject] && projects[activeProject].company.allowedQuestions;
      } else {
        chatUsage = projects && projects[portalActiveProject] && projects[portalActiveProject].usage && projects[portalActiveProject].usage.current.questions;
        chatLimit = projects[portalActiveProject] && projects[portalActiveProject].company.allowedQuestions;

      }



    return (
        <div style={{overflow:"auto", height:"auto", marginLeft: "22vw" }}>
                          <NavigationBar companyId={companyId} setActiveProject={setActiveProject} activeProject={activeProject} projects={projects}  />
                   <div  style={{ margin: "20px 50px", width:"70vw", padding:"50px", borderRadius:"25px"}}>
             <div className='grid grid--1x2'>
             <div style={{
  padding: "30px",
  background: "white",
  boxShadow: "0px 4px 8px -2px rgba(9, 30, 66, 0.25), 0px 0px 0px 1px rgba(9, 30, 66, 0.08)"
}} className='outlinebox'>
                 
                 <div style={{ textAlign: "left" }}>
  <h3 style={{ display: "inline" }}>Number of documents</h3>
  <p style={{ display: "inline" }}> / this month</p>
</div>

                    <h2 style={{fontWeight:"900", marginTop:"50px"}}>{countDocumentsUploadedThisMonth(corpusDocuments)}</h2>
                 </div>


                 <div style={{
  padding: "30px",
  background: "white",
  boxShadow: "0px 4px 8px -2px rgba(9, 30, 66, 0.25), 0px 0px 0px 1px rgba(9, 30, 66, 0.08)"
}} className='outlinebox'>
                 

                 <div style={{ textAlign: "left" }}>
                  <h3 style={{ display: "inline" }}>  Token consumption</h3>
                  <p style={{ display: "inline" }}> / allowed quota</p>
                </div>
           
                <h2 style={{ fontWeight: "900", marginTop: "50px" }}>{`${tokenUsage}/${tokenLimit}`}</h2>

                 </div>

                 <div style={{
  padding: "30px",
  background: "white",
  boxShadow: "0px 4px 8px -2px rgba(9, 30, 66, 0.25), 0px 0px 0px 1px rgba(9, 30, 66, 0.08)"
}} className='outlinebox'>
                 

                 <div style={{ textAlign: "left" }}>
                  <h3 style={{ display: "inline" }}>Number of pages extracted</h3>
                  <p style={{ display: "inline" }}> / allowed quota</p>
                </div>
           
                <h2 style={{ fontWeight: "900", marginTop: "50px" }}>{`${currentUsage}/${Limit}`}</h2>

                 </div>



                 <div style={{
  padding: "30px",
  background: "white",
  boxShadow: "0px 4px 8px -2px rgba(9, 30, 66, 0.25), 0px 0px 0px 1px rgba(9, 30, 66, 0.08)"
}} className='outlinebox'>
                 

                 <div style={{ textAlign: "left" }}>
                  <h3 style={{ display: "inline" }}>Questions to AI chat</h3>
                  <p style={{ display: "inline" }}> / allowed quota</p>
                </div>
           
                <h2 style={{ fontWeight: "900", marginTop: "50px" }}>{`${chatUsage}/${chatLimit}`}</h2>

                 </div>
             </div>
                   </div>
        </div>
    );
}

export default ExtractionAnalytics;