import React,{useState, useEffect, useRef} from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Drawer, message } from 'antd';
import loadingIcon from "../images/animation.svg";
import sendIcon from "../images/send.svg";
import { MdContentCopy } from "react-icons/md";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ArrowTextArea from '../common/ArrowTextArea';
import DOMPurify from 'dompurify'; 




function DemoComponent({setOpen, open, activeProject, projects, sign, title, projectID,setRefresh, refresh, formStyle}) {


  function renderHTML(htmlString) {
    const sanitizedHTML = DOMPurify.sanitize(htmlString); // Use DOMPurify or another HTML sanitizer library
    return { __html: sanitizedHTML };
  }
  



  const apiPoint = process.env.REACT_APP_API_POINT; 

  const [drawerVisible, setDrawerVisible] = useState(false);
  const location = useLocation();
  const isCloseButtonVisible = location.pathname === '/';

  const [drawerWidth, setDrawerWidth] = useState(700);

  let apiURL = `${apiPoint}api/v1/embedChat`

const [question, setQuestion] = useState("")

  // const showDrawer = () => {
  //   setOpen(true);
  // };
  const onClose = () => {
    if(!setOpen)
    return;

    setOpen(false);
    setRefresh(!refresh)

  };

  const [loading, setLoading] = useState(true)


const [conversationId, setConversationId] = useState("")
  const [conversation, setConversation] = useState([]);
  const [asked, setAsked] = useState("");
  const [streamingResponse, setStreamingResponse] = useState("");
  const [streamingActivity, setStreamingActivity] = useState(false);


// const [projectID, setProjectID] = useState("");

function getSharePageURL() {



  if(projects&& projects[activeProject])
  {
  const currentURL = window.location.href;
  let sharePageURL;
  
  if (currentURL.includes('sharepage')) {
  sharePageURL = currentURL;
  } else {
  sharePageURL = `${currentURL}sharepage/${projects[activeProject]._id}`;
  }
  
  return sharePageURL;
}
  }



const handleChange =(e)=>{
setQuestion(e.target.value);

}


const conversationContainerRef = useRef(null);



useEffect(() => {
  setConversationId('');
  setConversation([]);
}, [activeProject]); 




// useEffect(() => {

// }, [asked, conversation]);


useEffect(() => {
  const conversationContainer = conversationContainerRef.current;
  if (conversationContainer) {
    conversationContainer.scrollTop = conversationContainer.scrollHeight;
  }
}, [conversation, asked, streamingActivity, streamingResponse]);


useEffect(() => {
  const handleResize = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 1000) {
      setDrawerWidth('100vw');
    } else {
      setDrawerWidth(700);
    }
  };

  handleResize(); // Set initial width

  window.addEventListener('resize', handleResize);
  return () => window.removeEventListener('resize', handleResize);
}, []);

useEffect(() => {
  // Check if the current URL contains "sharepage"
  const shouldShowCloseButton = !location.pathname.includes('sharepage');
  setDrawerVisible(shouldShowCloseButton);
}, [location]);

const handleDrawerClose = () => {
  setDrawerVisible(false);
};




const handleSubmit = async (latestQuestion) => {

if(streamingActivity)
return;


  let currentQuestion =
    typeof latestQuestion === "string" ? latestQuestion : question;

    setQuestion("");

    if (asked === "true") return;
    
    if(currentQuestion==="") return;  

  let newElement = {};
  setAsked("true");
  newElement.question = currentQuestion;
  
  let array = [...conversation, newElement];
  setConversation(array);
  setStreamingResponse("")




  try {
    const response = await fetch(apiURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        projectId: projects?(projects[activeProject]._id):projectID,
        question: currentQuestion,
        ...(conversationId !== "" && { conversationId: conversationId }),
      }),
    });


  
    const reader = response.body.getReader();
    // console.log("reader", reader);
  
    const processResponse = async () => {
      let result = "";
      let isFirstChunk = true;
      let conversationId = null;
  
    while (true) {
      const { done, value } = await reader.read();

      let data = new TextDecoder().decode(value);



      if (done) {
        return;
      }
  


      setAsked("false");
      setStreamingActivity(true)

      if (!data.includes("conversationId:")) {
        result+=data;
      }

      setStreamingResponse(result);
      const newArray =  [...array];

      const lastIndex = newArray.length-1;
      const lastItem =  newArray[lastIndex];
      lastItem.answer = result;
      setConversation(newArray);


      if (data.includes("conversationId:")) {

        const parts = data.split('conversationId:');
        const lastElement = parts[parts.length - 1];
        const strippedStr = lastElement.trim();

        const firstElement = parts[0]

        if (firstElement) {
          result+=firstElement;

          setStreamingResponse(result);
          const newArray =  [...array];
    
          const lastIndex = newArray.length-1;
          const lastItem =  newArray[lastIndex];
          lastItem.answer = result;
          setConversation(newArray);
        }

        setConversationId(strippedStr);
        setStreamingActivity(false)
        setAsked("false");
      }



     
        
      //const dataElements = data.split(/\n/);
 
      // if (data.includes("data:")){

      //   //data.replace(/\s+$/, "");
      //   if (!data.includes("\n\n")) {
      //      data = data.trim() }

        // console.log("data inside 1st condition", data)
        //const dataElements = data.split(/\n/);
        // console.log("data elements", dataElements)
        // for (const dataElement of dataElements) {
        //   let element = dataElement.trim()
          // console.log("1 element", element)


          // if (data && data.includes("data:")){
          //   setAsked("false");
          //   setStreamingActivity(true)
          //   const parts = data.split('data:');
          // //console.log("parts", parts);
          //   const text = parts[parts.length - 1];

          //   result+=text;
          //   setStreamingResponse(result);
          //   const newArray =  [...array];
      
          //   const lastIndex = newArray.length-1;
          //   const lastItem =  newArray[lastIndex];
          //   lastItem.answer = result;
          //   setConversation(newArray);


            // console.log("executed")
          //}  
        //}
      //}
      }
    };
    processResponse();
  } catch (error) {
    console.error("Error:", error);
  }
}    





const handleKeyDown = (event) => {
  if (event.keyCode === 13 && !event.shiftKey) {

    event.preventDefault();
    handleSubmit();
  }
};

const handleCopy =()=>{
  message.info("Test link copied")
}



  return (
    <>
     
      <Drawer  
      
      title={
        <div className='flexrow wideapart' style={{ fontWeight: 'bold', fontSize: '18px'}}>
          <div style={{paddingLeft:"10px"}}>{title}</div>
          {projects && <div>
          <CopyToClipboard text={getSharePageURL()}>
            <button onClick={handleCopy} className='btnn btnn--small'><MdContentCopy style={{marginRight:"5px"}}/>Share</button>
          </CopyToClipboard>
          </div>}

        </div>
      }
      onClose={onClose} open={open} 
      width={drawerWidth}
      closable={isCloseButtonVisible}
      drawerStyle={{ background: '#f9f9f9' }}>
      <div className='chatbox'>
                <div className="chatbox_header">
                </div>


              

                <div ref={conversationContainerRef} style={{overflow: 'auto', height: '80%', position:"relative"}} >
<div className="conversation">
                {(formStyle && formStyle.firstMessage!=="") && <div  className="user-input" lang="">{formStyle.firstMessage}</div>}
                </div>                

                <div  >
                    {conversation.length>=1 && conversation.map((item,index) => (
                               <div key={index} className="conversation">


                      <div  className="operator-response" lang="">{item.question}</div>
                      {item.answer && (<div className="user-input" lang="" dangerouslySetInnerHTML={renderHTML(item.answer)}></div>)}
                     {((conversation.length === index+1) && asked==="true") && <div>{<div style={{padding:"5px", margin:"5px", position:"absolute", left:"20px"}} className="user-input">
                     <img  style={{width:"150px", height:"40px"}}  src={loadingIcon}></img>
                     </div>}</div>}  

              </div>

        ))} 
   </div>

                      </div>
  



       



            
                {/* <h1>Hello world</h1> */}
                <div style={{flexWrap: "nowrap", alignItems: "center"}} className="chatblock flexrow flexrow--rigid alignhorizontal">
        

                <ArrowTextArea value={question} handleSubmit={handleSubmit} handleChange={handleChange} asked={asked} setQuestion={setQuestion}  handleKeyDown={handleKeyDown} streamingActivity={streamingActivity}/>



                {/* <textarea onKeyDown={handleKeyDown}  style={{width:"650px"}} value={question} onChange={handleChange} placeholder="Type your message here and press enter" className="input--custom input--width "></textarea> */}
			    {/* <img onClick={handleSubmit}  className="sendButton"  style={{width:"40px", height:"20px",  paddingLeft:"20px", cursor:"pointer"}} src={sendIcon}></img> */}
            


                </div>
            </div>
      </Drawer>
    </>
  );
}

export default DemoComponent;


