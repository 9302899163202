import React, { useEffect, useState, useRef } from 'react';
import UploadMultiple from '../../common/UploadMultiple';
import Joi from "joi-browser";
import { RiDeleteBin6Fill } from "react-icons/ri";
import * as knowledgebaseService from "../../services/knowledgebaseService";
import * as companyKnowledgebase from "../../services/companyKnowledgebase"
import { message } from "antd";
import { useHistory, useLocation } from "react-router";
import NavigationBar from '../../common/NavigationBar';
import animationData from '../../animation/uploading.json'; 
import lottie from 'lottie-web';
import { useParams } from 'react-router-dom';

function ExtractionCompanyWideUpload({ reload, setReload, setActiveProject, activeProject, projects }) {
    const history = useHistory();

  
    const urlPath = history.location.pathname;
    const urlSearch = history.location.search;
    let location = useLocation();


    const { companyId } = useParams();


    const animationContainer = useRef(null);
  
    const [formState, setFormState] = useState("fileupload");


  

      const [inputField] = useState({
          url: "",
      
        });
  
        const [errorMessage] = useState({});
      
        const[loading, setLoading] = useState(false);
        const[uploadedFiles, setUploadedFiles] = useState([]);
        const [visibility, setVisibility] = useState(false)
  
  
        useEffect(() => {
   
        }, [uploadedFiles]);
        
  
        const template = [
          {
            name: "url",
            label: "Enter a URL",
            type: "text",
          },
          
      
      
        ];
  
  
          
      
        const buttonInfo = { label: " Submit ", style: "" };
      
        const schema = {
          url: Joi.string().uri().min(2).max(500).required().label("URL"),
      
        };


        useEffect(() => {
          if (formState === "uploading") {
              const anim = lottie.loadAnimation({
                  container: animationContainer.current, // the dom element
                  renderer: 'svg',
                  loop: true,
                  autoplay: true,
                  animationData: animationData
              });
              return () => anim.destroy(); // optional clean up for unmounting
          }
      }, [formState]);
     
  
  
        const handleSubmit = async () => {
   
 
          setLoading(true);
      
        
          const formData = new FormData();
          uploadedFiles.forEach((file, index) => {
            formData.append("files", file);
            // Append the filePipeline field for each file
            // formData.append(`filePipeline_${index}`, 'your_file_pipeline_value_here');
          });
  
          if(uploadedFiles.length<1)
          {
          setLoading(false);
          return message.error("Please upload atleast one file.")
  
        }
  
        setFormState("uploading")

    
        
          try {
            const response = await companyKnowledgebase.companyWideUpload( companyId , formData);
            if (response.status === 200) {
               setLoading(false);
               message.success({
                content: 'Success. Upload complete',            
                duration: 8, 
              });
               history.replace("/")
            //    setVisibility(false)
            //    setFormState("fileupload")
            //    setReload(!reload)
               setUploadedFiles([])
           
            }
          } catch (ex) {
            if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
              setLoading(false);
              message.error({
                content: ex.response.data.msg,
                duration: 8
              });
              setVisibility(false)
              setFormState("fileupload")
              setUploadedFiles([])
              return;
            }
          }
        };
  
      
  
        const handleFileChange = (info) => {
          if (info.file.type === 'text/csv') {
            message.error('CSV files are not allowed');
            return false;
          }
        
        //   if (info.file.size > 15 * 1024 * 1024) {
        //     message.error('File size must be smaller than 15MB!');
        //     setLoading(false);
        //     return false;
        //   }
        
          setUploadedFiles((prevUploadedFiles) => [
            ...prevUploadedFiles,
            info.file,
          ]);

          setVisibility(!visibility)
  
        
          // } 
          
          // else if (info.file.status === "removed") {
          //   const filteredFiles = uploadedFiles.filter(
          //     (file) => file.uid !== info.file.uid
          //   );
          //   setUploadedFiles(filteredFiles);
          // }
        };
        
       
        const handleDelete =(index)=>{
          const newArray = uploadedFiles.filter((_, i) => i !== index);
          setUploadedFiles(newArray);

          if(newArray.length<1)
          setVisibility(false)

        }

        const handleVisibility =()=>{
          setVisibility(!visibility)
        }

        if(formState==="fileupload")
    return (

      <div className="splithalf" style={{overflow:"auto", height:"100vh" }}>
            <div className="lefthalf centrething flexcolumn flexcolumn--allcenter blackcontainer">
              <div className="leftcontainer">
           <div style={{padding:"30px"}}>    

                <h2 style={{ color: "white" }}>
                    <br />Upload your <em>document</em>
                    <p style={{ color: "white" }}>Please note that whatever you upload will be accessible to all other projects under your company.</p>
                </h2>


    
          
      {/* <button onClick={handleNext} style={{ marginTop:"50px"}} className='btnn btnn-primary'>Next Step</button> */}
      
    
       </div>
              </div>
            </div> 
                 <div style={{marginTop:"10vw"}}>
                     {/* <NavigationBar companyId={companyId} setActiveProject={setActiveProject} activeProject={activeProject} projects={projects}  /> */}
                     
                             <div style={{  width:"70vw" }} className="flexcolumn flexcolumn--allcenter">
                             <div className="flexrow">
                               { !visibility && <div style={{ marginRight: "50px", width: "600px", height:"420px", background:"white", borderRadius:"25px" }} className="flexcolumn flexcolumn--allcenter bluecontainer">
                                 <p>Please upload files</p>
                                 <UploadMultiple onChange={handleFileChange} />
                                 {uploadedFiles.length>=1 && <button className='btnn btnn-disable":"btnn btnn-primary' onClick={handleVisibility}>
                                   Preview Files
                                 </button>}
                               </div>}
                       
                     
                                 {/* {uploadedFiles.length < 1 && (
                                   <div className="flexcolumn flexcolumn--allcenter">
                                     <img style={{ width: "150px", height: "50%", marginTop:"30%" }} src={empty} alt="empty" />
                                     <h3 style={{ marginTop: "30px" }}>It's empty. It seems you have not uploaded any file.</h3>
                                   </div>
                                 )} */}
                                 {visibility &&
                          <div style={{ overflow: "auto",height:"700px", width:"600px", background:"white", borderRadius:"25px"}} className="bluecontainer">
                                 
                                   <div >
                                     {uploadedFiles.map((item, index) => (
                      <div className='wideapart'>
                             <p className='bb' key={index}>{item.name.length > 30 ? item.name.slice(0, 30) + '...' : item.name}</p>
                        <RiDeleteBin6Fill onClick={()=>handleDelete(index)} style={{color:"#f34f4f", cursor:"pointer"}}/>
                      </div>
                                     ))}
                     
                                 <div className='flex flexrow' style={{textAlign:"center", position:"relative", top:"100%"}}>
                                 <button  style={{marginRight:"10px"}} className='btnn btnn btnn-secondary' onClick={handleVisibility}>
                                  Add more
                                   </button>
                                   <button  className={uploadedFiles.length<1?"btnn btnn-disable":"btnn btnn-primary"} disabled={loading} onClick={handleSubmit}>
                                     {loading ? "Uploading files..." : "Upload"}
                                   </button>
                                 </div>
                     
                     
                                   </div>
                                 
                                 </div>
                                 }
                               </div>
                       
                           </div>
                           </div>
                 </div>
    );

    if (formState === "uploading") {
      return (
          <div style={{height:"100vh", marginTop:"30px"}}>
            <div className="lottie-animation" ref={animationContainer} style={{ width: 800, height: 800, marginLeft: "30vw", height:"80vh"  }}>
                {/* Lottie animation will be rendered inside this div */}
            </div>
          </div>
      );
  }
}

export default ExtractionCompanyWideUpload;