import React,{useState, useEffect} from 'react';
import Navbar from '../../common/Navbar';
import BlockComponent from '../../components/BlockComponent';
import { useLocation } from 'react-router-dom';
import * as projectService from "../../services/projectService";
import { message } from "antd";
import { useParams } from 'react-router-dom';
import Joi from "joi-browser";
import Form from "../../common/Form";

function RawPrompt(props) {

    const location = useLocation();
    // const passedSettings = location.state?.settings;
    const { projectId } = useParams();
    const[passedSettings,setPassedSettings] = useState({})

    const filters = [];
    const [activeKey, setActiveKey] = useState(0);
    const title = "Customize your prompt";
    const description = ""
    const buttonName ="Add an agent";
    const [toggle, setToggle] = useState(passedSettings.promptSettings === 'rawPrompt' ? true : false);

    const [inputField, setInputField] = useState({
      rawPrompt: ""  
    });


    /**------------ Form Input---------------------- */

    const[loading, setLoading] = useState(false);

 
    const [reload, setReload] = useState(false)



    useEffect(() => {
      const fetchData = async () => {
          try {
              const response = await projectService.getSettings(projectId);
              setPassedSettings(response.data);
              setInputField({ rawPrompt: response.data.rawPrompt.replace(/Context:([\s\S]*)$/, '' || "" )});
          } catch (error) {
              message.error("Failed to fetch data");
          }
      };
      fetchData();
  }, [projectId, reload]);

  // Update form inputs when passedSettings changes
  // useEffect(() => {
  //   setInputField({ rawPrompt: passedSettings.rawPrompt.replace(/Context:([\s\S]*)$/, '' || "" )});
  // }, [passedSettings]);



    
 
    // console.log("inputFieldCustomPrompt", rawPrompt)
    
      const [errorMessageCustomPrompt] = useState({});
    
    
      // let auth = useAuth();
      // const history = useHistory();
    
      const template1CustomPrompt = [
    
        {
          name: "rawPrompt",
          label: "Type your custom prompt here",
          type: "textArea",
          style: { height: "1000px" },
          
        },  
      ];
    
      const buttonInfoCustomPrompt = { label: "Update prompt", style: "" };
    
      const schema1CustomPrompt = {
        rawPrompt: Joi.string().required().max(50000000).label("Prompt"),
      };
  
     

    const handleCustomPrompt = async(data)=>{
        const response = await projectService.updateProject(projectId, data )
        if(response.status===200)
        {
        message.success("Changes saved")
        setLoading(false)
        setReload(!reload)
        }
    }  





    const formState1 = (passedSettings.rawPrompt && <div>
    {/* <p>Write a message to be sent in case the answer couldn't be located in your knowledge base.</p> */}
    <Form
                key={JSON.stringify(inputField)} 
                errorMessage={errorMessageCustomPrompt}
                inputField={inputField}
                template={template1CustomPrompt}
                schema={schema1CustomPrompt}
                buttonInfo={buttonInfoCustomPrompt}
                onSubmit={handleCustomPrompt}
                loading={loading}
                setLoading={setLoading}
                />
   
   </div>)


   const formState2=null;

    const handleAdd =()=>{}

    const handleToggle = async () => {
        try {
          const promptSettings = !toggle ? { promptSettings: 'rawPrompt' } : { promptSettings: 'customPrompt' };
      
          const response = await projectService.updateProject(projectId, promptSettings);
      
          if (response.status === 200) {
            message.success("Changes saved");
            setToggle(!toggle);
          } else {
            message.error("Failed to save changes");
          }
        } catch (error) {
          // Handle any unexpected errors here
          message.error("An error occurred while saving changes");
        }
      };
      



      
      return (
        <div>
            <Navbar />
            <BlockComponent title={title} description={description} buttonName={buttonName} filters={filters} setKey={setActiveKey}  formState1={ formState1} formState2={formState2} handleModal={handleAdd} onChange={handleToggle} toggle={toggle} activeKey={activeKey} toggleVisibility={false} buttonVisitbility={false}/>
</div>

    );
}

export default RawPrompt;