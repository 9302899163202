import React, { useState, useEffect } from "react";
import { Redirect } from 'react-router-dom';
import { Helmet } from "react-helmet";
import {
  useStripe,
} from "@stripe/react-stripe-js";
import { useLocation,useHistory } from "react-router";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import * as PaymentService from "../../services/PaymentService";
import * as profileService from "../../services/profileService";
import ToggleSwitch from "../../common/ToggleSwitch"
import dateFormat from "dateformat";
import { message } from "antd";
import pricingConfig from "../Config/pricingConfig"


function ExtractionBilling() {

const [user, setUser] = useState("");
const [company, setcompany] = useState("")

const location = useLocation();
const history = useHistory();

const getSubdomain = () => {
  const hostname = window.location.hostname;
  const subdomain = hostname.split('.')[0];
  return subdomain;
};


const [subdomain, setSubdomain] = useState(getSubdomain());


const [isYearly, setIsYearly] = useState(false);


const plans = pricingConfig[subdomain] || [];


const handlePlanAction = (plan) => {



  if (plan.planName === "Enterprize") {

    window.location.href = "mailto:support@superdashhq.com?subject=Superdash custom requirement";
  } else {
   


    const ItemPassed = isYearly? plan.productIdYearly: plan.productIdMonthly
  
    handlePlanChange(ItemPassed);
  }
};







  const [PlanSelect, setPlanSelect] = useState("");
  // const [annual, setAnnual] = useState(false);
  let [formState, setFormState] = useState();
  let [billingDetails, setBillingDetails] = useState({});

  useEffect(() => {
  
    const fetchData = async () => {

      try {
        const responseData = await profileService.allUserDetails();
    

        if(responseData.status===200)
        {
 
          setcompany(responseData.data.company);

            responseData.data.company.billingId ===""
                ? setFormState("plan")
                : setFormState("portal");
              }
   
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          history.replace({ pathname: "/" });
          return;
        }
      }

    };
    fetchData();
   
  }, []);





  const stripe = useStripe();

  const handlePlanChange = (item) => {
    setPlanSelect(item);
    handleSubmit(item)
  };

  // const handleSubmit = () => {
  //   setFormState("payment");
  // };



  const handleBilling = async (e) => {
    try {
      message.success("Please wait. Loading your portal");
      const response = await PaymentService.billingPortal(company._id);
      window.location.href = response.data;
    } catch (ex) {
      if (ex.response && ex.response.status >= 400) {
        message.error(ex.response.data);
        return;
      }
    }
  };

  const handleBack =  () => {
    window.location = "/"
  };



  const handleSubmit = async (item) => {


     message.success("Please wait. We're redirecting you to Stripe");

    

    try {
      const response = await PaymentService.checkoutSession(item, "extraction");
      window.location.href = response.data.url;
      message.success("Please wait.Re-directing to stripe portal");

    } catch (ex) {
      if (ex.response && ex.response.status >= 400 && ex.response.status < 500 ) {
        message.error(ex.response.data);
        return;
      }
    }
  };

  function handleDiscordClick(event) {
    event.preventDefault();
    window.open("https://discord.com/invite/xJJfYjxcqy", "_blank");
  }
  

  const getSimpledate = (date) => {
    const newDate = dateFormat(date, "mmmm dS");
    return newDate;
  };

  const onButtonChange =()=>{
    setIsYearly(!isYearly)
  }

  const handlePageChange =()=>{
    window.location = "/"
  }

  if (formState === "plan")
  return  (
    <div style={{width:"100vw",height:"100vh"}} >
 
    <div style={{height:"100vh"}} className="flexcolumn flexcolumn--allcenter">
    <div style={{marginBottom:"50px"}} className="flexrow wideapart">
      <h3 style={{marginRight:"100px"}}>Choose Your Plan</h3>
      <div>
 <label style={{ marginRight:"10px"}} htmlFor="button">Annual plan</label>
 <ToggleSwitch
        id="button"
        checked={isYearly}
        onChange={onButtonChange}
      />
</div> 

      </div>
      {/* <p style={{marginBottom:"50px"}}>Please contact us on <a href="#" onClick={handleDiscordClick} target="_blank">Discord</a> or through the chat feature.</p> */}
<div></div>


<div className="flex flexrow">

      {plans.map((plan, index) => (
        <div key={index} style={{ position:"relative", margin:"10px"}} className="flexcolumn pricing_box">
       <h3 style={{lineHeight: "1.5", fontStyle: "normal", fontWeight: "600"}}>{plan.planName}</h3>
          {isYearly && plan.planName!=="Enterprize"?<p>{plan.yearly} billed annually</p>: <p>Price: {plan.monthly}</p>}
          <ul>
            {plan.features.map((feature, featureIndex) => (
              <li className="bb" style={{padding:"10px 5px", fontWeight:"700px", fontWeight:"600px"}} key={featureIndex}>{feature}</li>
            ))}
          </ul>
          <button className="btnn btnn-primary" onClick={() => handlePlanAction(plan)}>
            {plan.planName === "Enterprize" ? "Contact Us" : "Subscribe"}
          </button>
        </div>
      ))}
    </div>

  </div>
  </div>
  ) 


  if (formState === "portal" && company)
return (
    <div>
      <div style={{height:"100vh"}}className="flexcolumn flexcolumn--allcenter">
        <Helmet>
          <style>{"body { background: #f4f6ff }"}</style>
        </Helmet>
        <div className="flexrow alignhorizontal-center">
            <div style={{ maxHeight: "350px", marginRight:"100px" }} className="bluecontainer">
              <div>
                <h3>Your current plan details</h3>
                <span>
                  <span className="bold">Plan : </span>
                  <em style={{textTransform: 'uppercase'}}>{company.accountType}</em>
                </span>
                {/* <div>
                  <span className="bold"> </span>
                  {billingDetails.postNumber}
                </div> */}
                {/* <div>
                  <span className="bold">Seats status : </span>
                  {billingDetails.sendInvite}/{billingDetails.teamSize}
                </div> */}
                <div>
                  <span className="bold">BillingStart : </span>
                  {getSimpledate(company.billingStart)}
                </div>
                <div>
                  <span className="bold">BillingEnd : </span>
                  {getSimpledate(company.billingEnd)}
                </div>
                {/* <h4>Seats : 3</h4>
                <h4>Active posts count : 8</h4>
                <h4>Unique users: 100</h4> */}
              </div>
              <div className="flexrow flexrow--rigid">
                  <button onClick={handleBilling} className="btnn btnn-primary">
                    Manage billing
                  </button>
                  <button onClick={handleBack} style={{width:"100px", marginLeft:"20px"}} className="btnn btnn-secondary">
                             Go back
                  </button>
              </div>
            </div>
            <div >
              <h3>What should I do if I'm facing payment issue?</h3>
              <p>
                Please reach out to us at support@superdashhq.com. We'll get back
                within 24 hours and usually sooner
              </p>


              <h3>How can I suggest a new feature?</h3>
              <p>
              support@superdashhq.com. 
              </p>
              {/* <h3>What happens if you go over your allowed visitors count?</h3>
              <p>
                No surprise fees If your visitor count exceeds your plan for two
                consecutive months, we will contact you to upgrade to a higher plan
                for the following month. You can decide to continue with a higher
                plan or cancel your account at that point.
              </p> */}
            </div>
        </div>
      </div>
    </div>
  );
  

 else return  (
    <div>
      <div style={{height:"100vh"}}className="flexcolumn flexcolumn--allcenter">
        <Helmet>
          <style>{"body { background: #f4f6ff }"}</style>
        </Helmet>
        <div className="flexrow alignhorizontal-center">
            <div style={{ maxHeight: "350px", marginRight:"100px" }} className="bluecontainer">
              <div>
                <h3>Your current plan details</h3>
                <span>
                  <span className="bold">Plan : </span>
                  <Skeleton width={300} height={30} />
                </span>
                {/* <div>
                  <span className="bold"> </span>
                  {billingDetails.postNumber}
                </div> */}
                {/* <div>
                  <span className="bold">Seats status : </span>
                  {billingDetails.sendInvite}/{billingDetails.teamSize}
                </div> */}
                <div>
                  <span className="bold">BillingStart : </span>
                  <Skeleton width={20} height={10} />
                </div>
                <div>
                  <span className="bold">BillingEnd : </span>
                  <Skeleton width={20} height={10} />
                </div>
                {/* <h4>Seats : 3</h4>
                <h4>Active posts count : 8</h4>
                <h4>Unique users: 100</h4> */}
              </div>
              <div className="flexrow flexrow--rigid">
              
                  <button onClick={handleBack} style={{width:"100px"}} className="btnn btnn-secondary">
                             Go back
                  </button>
              </div>
            </div>
            <div >
              <h3>What should I do if I'm facing payment issue?</h3>
              <p>
                Please reach out to us at support@superdashhq.com. We'll get back
                within 24 hours and usually sooner
              </p>


              <h3>How can I suggest a new feature?</h3>
              <p>
              support@superdashhq.com. 
              </p>
              {/* <h3>What happens if you go over your allowed visitors count?</h3>
              <p>
                No surprise fees If your visitor count exceeds your plan for two
                consecutive months, we will contact you to upgrade to a higher plan
                for the following month. You can decide to continue with a higher
                plan or cancel your account at that point.
              </p> */}
            </div>
        </div>
      </div>
    </div>
  );







}

export default ExtractionBilling;
