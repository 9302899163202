import React, { useState, useEffect } from 'react';
import BlockComponent from '../../components/BlockComponent';
import * as humanAgentService from "../../services/humanAgentService";
import * as projectService from "../../services/projectService";
import { useParams } from 'react-router-dom';
import Joi from "joi-browser";
import Form from "../../common/Form";
import { message } from "antd";
import { Button, Modal } from 'antd';
import Navbar  from '../../common/Navbar';
import { useLocation } from 'react-router-dom';



function PromptBuilder(props) {

  const location = useLocation();
  const passedSettings = location.state?.settings;



  const { projectId } = useParams();
  const [humanagents, setHumanAgents] = useState([])
  const [modalStatus, setModalStatus] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [reload, setReload] = useState(false)
  const [toggle, setToggle] = useState(passedSettings.promptSettings === 'userPrompt');
  const [user1, setUser1] =useState(passedSettings && passedSettings.promptSettings && passedSettings.promptSettings.humanPersona
    ? passedSettings.promptSettings.humanPersona: "");
  const [user2, setUser2] =useState(passedSettings && passedSettings.promptSettings && passedSettings.promptSettings.aiPersona
    ? passedSettings.promptSettings.aiPersona: "");
  const [characteristic, setCharacteristic] = useState([]);
  const [instruction, setInstruction] = useState("");
  const [strictness, setStrictness] = useState("");
  const [additionalInstructions, setAdditionalInstructions] = useState("");
  const [visualInstructions, setVisualInstructions] = useState("");
  const [formState, setFormState] =  useState("first");
  const [langauge, setLanguage] = useState(passedSettings && passedSettings.promptSettings && passedSettings.promptSettings.language
    ? passedSettings.promptSettings.language: "")
  const [characteristicOptions, setCharacteristicOptions] = useState([
    "Hopeful",
    "Caring",
    "Optimistic",
    "Patient",
    "Kindhearted",
    "Enduring",
    "Empathetic",
    "Resilient",
    "Tolerant",
    "Uplifting",
    "Gentle",
    "Forgiving",
    "Persistent",
    "Radiant",
    "Considerate",
    "Encouraging",
    "Soothing",
    "Steadfast",
    "Inspiring",
    "Adaptable",
    "Trusting",
    "Harmonious",
    "Affectionate",
    "Serenading",
    "Reassuring",
    "Benevolent",
    "Empowering",
    "Serene",
    "Resourceful",
    "Courageous"
  ]);
  
  

  const handleModal =(item, key) =>{

    setModalStatus(true)
    
   }




const handleToggle = async () => {
  try {
    const promptSettings = !toggle ? { promptSettings: 'userPrompt' } : { promptSettings: 'customPrompt' };

    const response = await projectService.updateProject(projectId, promptSettings);

    if (response.status === 200) {
      message.success("Changes saved");
      setToggle(!toggle);
    } else {
      message.error("Failed to save changes");
    }
  } catch (error) {
    // Handle any unexpected errors here
    message.error("An error occurred while saving changes");
  }
};

const isNextButtonDisabled = !user1.trim() || !user2.trim();

const handleUser1Change = (event) => {
  setUser1(event.target.value);
};

const handleUser2Change = (event) => {
  setUser2(event.target.value);
};

const handleLanguage =(event)=>{
  setLanguage(event.target.value)
}

const handleClick = (item) => {
  setCharacteristic(prevState => {
    if (prevState.includes(item)) {
      return prevState.filter(i => i !== item);
    }
    return [...prevState, item];
  });
}

const handleLastStep =()=>{
setLanguage("")
setFormState("sixth")
}


const getButtonClass = (item) => {
  if (characteristic.includes(item)) {
    return "outlinebutton outlinebutton--blue--selection";
  }
  return "outlinebutton outlinebutton--blue--cursor";
}

/*-------------------- Modal related --------------------------------*/

const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };


const[loading, setLoading] = useState(false);
  
  
  
  
    //*----------------- Human Agent tranfer form ----------------------------------*//

    const [inputField1] = useState({
      fallbackMessage: passedSettings.fallbackMessage?passedSettings.fallbackMessage:""
    });
  

  
    const [errorMessage1] = useState({});
  
  
    // let auth = useAuth();
    // const history = useHistory();
  
    const template1 = [
  
      {
        name: "fallbackMessage",
        label: "Sorry! We couldn't locate the specific answer you're looking for. I can only answer questions from your HR policy.",
        type: "textArea",
        
      },  
    ];
  
    const buttonInfo1 = { label: "Update message", style: "" };
  
    const schema1 = {
      fallbackMessage: Joi.string().required().max(500).label("Fallback message"),
    };

    const handleMessage = async (data)=>{
      const response = await projectService.updateProject(projectId, data )
      if(response.status===200)
      {
      message.success("Changes saved")
      setLoading(false)
      }
    }

    const getClasses =(item, keyword)=>{

      if(keyword===strictness ||keyword===langauge)
      return `${item} selectionbox_selected`

      else return `${item}`

    }
    
const handleInstructionChange = (event) => {
  setInstruction(event.target.value);
};



const handleSubmit = async() => {
  try {
    const data = {
      promptSelection: {
        humanPersona: user1,
        aiPersona: user2,
        characteristic: characteristic,
        instructions: instruction,  
        strictness: strictness,
        language:langauge
      }
    };

    const response = await projectService.updateProject(projectId, data);
    
    if(response.status === 200) {
      message.success("Changes saved");
    } else {
      message.error("Failed to save changes");  // Handle non-200 responses
    }

  } catch (error) {
    console.error("An error occurred:", error);
    message.error("An error occurred while updating the project.");
  } finally {
    setLoading(false);  // Ensure loading is set to false regardless of success or error
  }
}

  
  
    //*------------ Each Tab component below ----------------------------*//



    const formState1 = formState === "first" ? (
      <div>
        <p>This conversation is between</p>
        <input  
          value={user1}
          onChange={handleUser1Change}
          placeholder="Customer" className='input--custom'></input>
    
        <p style={{padding:"10px"}}>and</p>
        <input 
          value={user2}
          onChange={handleUser2Change}
          placeholder="Customer support Agent (Your representative)" className='input--custom'></input>
        <button 
        onClick={() => setFormState("second")} 
        className={ isNextButtonDisabled?'btnn btnn-disable': 'btnn btnn-primary'}
        disabled={isNextButtonDisabled}>
          Next
      </button>
      </div>
    ) : formState === "second" ? (
      <div>
        <p>What sort of characteristics do you want your representative to have</p>
        <div className='grid grid--1x3'>
          {characteristicOptions.map((item, index) => (
            <div onClick={() => handleClick(item)} style={{margin:"10px"}} className={getButtonClass(item)}  key={index}>
              {item}
            </div>
          ))}
        </div>
        <button onClick={()=>setFormState("third")}
                disabled={characteristic.length<1} 
                className={ characteristic.length<1?'btnn btnn-disable': 'btnn btnn-primary'}
        style={{marginBottom:"30px"}}>Next</button>
      </div>
    ) : formState === "third" ? (
      <div>
        <p>How do you want your representative to answer from your data</p>

        <div onClick={()=>setStrictness("strict")} style={{marginBottom:"25px"}} className={getClasses("selectionbox","strict")}>
    <h3  className='bb'>Super Strict</h3>
    <p>If the answer is exactly mentioned in the KB, then only answer. This is ideal if your documents or KB clearly have answers to all queries and the AI doesn't need to make any smart guesses. Examples include customer support bots, Bible apps, website bots, etc., because most likely all answers will be in your uploaded data.</p>
</div>

<div onClick={()=>setStrictness("guess")} style={{marginBottom:"25px"}} className={getClasses("selectionbox","guess")}>
    <h3  className='bb'>Smart Guess</h3>
    <p>Sometimes the KB might not exactly mention the words required to answer a question. In these cases, the AI should make a lot more logical connections to provide answers. For instance, if your documents mention that specialist doctors charge $25 per hour for consultation, and a user asks about the fee for an ACL injury doctor, and if the documents don't cover anything about ACL injuries, the AI should recognize that an ACL injury requires specialist consultation and display the appropriate fee.</p>
</div>

<div onClick={()=>setStrictness("world")} style={{marginBottom:"25px"}} className={getClasses("selectionbox","world")}>
    <h3  className='bb'>Pre-trained knowledge</h3>
    <p>Answer the question if the answer cannot be located from the knowledge base by using the web and other sources. This is ideal for building teaching assistants, general-purpose bots, etc.</p>
</div>

     
        <button 
        onClick={()=>setFormState("fourth")} 
        disabled={strictness===""} 
        style={{marginBottom:"30px"}} 
        className={strictness===""?'btnn btnn-disable': 'btnn btnn-primary'}>Next</button>
      </div>
    ) : formState === "fourth" ? (
      <div>
        <p>Give instructions to strictly define the do and don't. Start your answer like this <span style={{fontWeight:"600"}}> The {user2} always...</span></p>
        <textarea onChange={handleInstructionChange} className='input--custom input--height input--width'></textarea>
        <button 
        onClick={()=>setFormState("fifth")} 
        style={{marginBottom:"30px"}} 
        className={instruction===""?'btnn btnn-disable': 'btnn btnn-primary'}
        disabled={instruction===""} 
        >Next</button>
      </div>
    ) : formState === "fifth" ? (
      <div>
      <p>In what language should your representative communicate?</p>

      <div onClick={()=>setLanguage("all")} style={{marginBottom:"25px"}} className={getClasses("selectionbox","all")}>
  <h3  className='bb'>Multiple language</h3>
  <p>Your representative will communicate in the same language that your user communicates in.</p>
</div>

<div onClick={handleLastStep} style={{marginBottom:"25px"}} className={getClasses("selectionbox","specific")}>
  <h3  className='bb'>Specific langauge</h3>
  <p>Click here to choose a language in which your representative should always communicate.</p>
</div>



   
      {langauge==="all" && <button onClick={handleSubmit} style={{marginBottom:"30px"}} className='btnn btnn-primary'>Submit</button>}
    </div>
    ) : formState === "sixth" ? (
      <div>
      <p>Type in a specific language</p>
      <input  
          value={langauge}
          onChange={handleLanguage}
          placeholder="Eg: spanish" className='input--custom'></input>
     <button 
     onClick={handleSubmit} 
     style={{marginBottom:"30px"}}
     className={langauge===""?'btnn btnn-disable': 'btnn btnn-primary'}
     disabled={langauge===""}> 
     Submit</button>
    </div>
    ) : null;
    
    

     const formState2 = <div>
       <p>Write a message to be sent in case the answer couldn't be located in your knowledge base.</p>
       <Form
          errorMessage={errorMessage1}
          inputField={inputField1}
          template={template1}
          schema={schema1}
          buttonInfo={buttonInfo1}
          onSubmit={handleMessage}
          loading={loading}
          setLoading={setLoading}
        />
      
      </div>


    const filters = ["Prompt Builder","Fallback message"];
    const [activeKey, setActiveKey] = useState(0);
    const title = "Prompt Builder";
    const description = "Use our interactive prompt builder to create a custom prompt for your app"
    const buttonName ="Add an agent"



const ChangeTab =()=>{
  
}



    return (
      <div>
                <Navbar/>
        <BlockComponent title={title} description={description} buttonName={buttonName} filters={filters} setKey={setActiveKey}  formState1={ formState1} formState2={formState2}   onChange={handleToggle} toggle={toggle} activeKey={activeKey} buttonVisitbility={false}/>
     
      </div>
    );
}

export default PromptBuilder;
