
import http from "./httpService";
const apiEndpoint = "/tpauth";

export function checkToken(projectId, code,location, accountsServer ) {

  return http.post(apiEndpoint + "/" + "thirdpartyauth" + "/"+ projectId, {
    code,
    location, 
    accountsServer
  });
}