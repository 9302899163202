import React, { useState, useEffect } from "react";
import { Redirect } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { useUserContext } from "../userContext";
import {
  useStripe,
} from "@stripe/react-stripe-js";
import { useLocation,useHistory } from "react-router";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import * as PaymentService from "../services/PaymentService";
import * as companyService from "../services/profileService";
import * as profileService from "../services/profileService";
import * as plansService from "../services/customerPlans";
import ToggleSwitch from "../common/ToggleSwitch"
import dateFormat from "dateformat";
import { message } from "antd";
import pricingConfig from "./Config/pricingConfig"
import { useParams } from 'react-router-dom';


function MembershipPlans() {

const [user, setUser] = useState("");
const [company, setcompany] = useState("")
const [plans, setPlans] = useState([])

const { companyId, projectId } = useParams();

const location = useLocation();
const history = useHistory();



const getSubdomain = () => {
  const hostname = window.location.hostname;
  const subdomain = hostname.split('.')[0];
  return subdomain;
};



const [subdomain, setSubdomain] = useState(getSubdomain());


const [isYearly, setIsYearly] = useState(false);




const handlePlanAction = (plan) => {
  if (plan.planName === "Enterprize") {

    window.location.href = "mailto:support@superdashhq.com?subject=Superdash custom requirement";
  } else {
   
    const ItemPassed = isYearly? plan.productIdYearly: plan.productIdMonthly
  

    handlePlanChange(ItemPassed);
  }
};

  const [PlanSelect, setPlanSelect] = useState("");
  // const [annual, setAnnual] = useState(false);
  const [accountType, setAccountType] = useState(false);
  let [formState, setFormState] = useState();
  let [billingDetails, setBillingDetails] = useState({});

  
  
  useEffect(() => {
  
    const fetchData = async () => {

      try {
        const responseData = await plansService.allPlans(projectId);
    

        if(responseData.status===200)
      {
        // console.log("response Data", responseData.data.plans)
        setPlans(responseData.data.plans)
      } 

   
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
        //   history.replace({ pathname: "/" });
          return;
        }
      }

    };
    fetchData();
   
  }, []);





  const stripe = useStripe();

  const handlePlanChange = (item) => {

    setPlanSelect(item);
    handleSubmit(item)
  };

  // const handleSubmit = () => {
  //   setFormState("payment");
  // };



  const handleBilling = async (e) => {
    try {
      message.success("Please wait. Loading your portal");
      const response = await PaymentService.billingPortal(company._id);
      window.location.href = response.data;
    } catch (ex) {
      if (ex.response && ex.response.status >= 400) {
        message.error(ex.response.data);
        return;
      }
    }
  };

  const handleBack =  () => {
    window.location = "/"
  };




  const handleSubmit = async (item) => {
    message.success("Please wait. We're redirecting you to Stripe");
  
    try {
      const email = "dummy@gmail.com";
      const response = await PaymentService.connectedSession(companyId, projectId, item, email);
  
      localStorage.setItem('sessionId', response.data.sessionId);
  
      window.location.href = response.data.url;
      message.success("Please wait. Redirecting to the Stripe portal");
  
    } catch (ex) {
      if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
        message.error(ex.response.data);
        return;
      }
    }
  };
  

  function handleDiscordClick(event) {
    event.preventDefault();
    window.open("https://discord.com/invite/xJJfYjxcqy", "_blank");
  }
  

  const getSimpledate = (date) => {
    const newDate = dateFormat(date, "mmmm dS");
    return newDate;
  };

//   const onButtonChange =()=>{
//     setIsYearly(!isYearly)
//   }

//   const handlePageChange =()=>{
//     window.location = "/"
//   }


  return  (
    <div style={{width:"100vw",height:"100vh"}} >
 
    <div style={{height:"100vh"}} className="flexcolumn flexcolumn--allcenter">
    <div style={{marginBottom:"50px"}} className="flexrow wideapart">
      {/* <h3 style={{marginRight:"100px"}}>Choose Your Plan</h3> */}
      {/* <div>
 <label style={{ marginRight:"10px"}} htmlFor="button">Annual plan</label>
 <ToggleSwitch
        id="button"
        checked={isYearly}
        onChange={onButtonChange}
      />
</div>  */}

      </div>
      {/* <p style={{marginBottom:"50px"}}>Please contact us on <a href="#" onClick={handleDiscordClick} target="_blank">Discord</a> or through the chat feature.</p> */}
<div></div>


<div className="flex flexrow">

      {plans.map((plan, index) => (
        <div key={index} style={{ position:"relative", margin:"10px"}} className="flexcolumn pricing_box">
       <h3 style={{lineHeight: "1.5", fontStyle: "normal", fontWeight: "600"}}>{plan.planName}</h3>
          {isYearly && plan.planName!=="Enterprize"?<p>{plan.yearly} billed annually</p>: <p>Price: {plan.monthly}</p>}
          <ul>
            {plan.features.map((feature, featureIndex) => (
              <li className="bb" style={{padding:"10px 5px", fontWeight:"700px", fontWeight:"600px"}} key={featureIndex}>{feature}</li>
            ))}
          </ul>
          <button className="btnn btnn-primary" onClick={() => handlePlanAction(plan)}>
            {plan.planName === "Enterprize" ? "Contact Us" : "Subscribe"}
          </button>
        </div>
      ))}
    </div>

  </div>
  </div>
  ) 

}

export default MembershipPlans;
