import csv from  "../../images/csv.svg";
import tables from  "../../images/tables.svg";
import React,{useState, useEffect} from 'react';
import globe from  "../../images/globe.svg";
import semantic from  "../../images/semantic.svg";
import phone from  "../../images/phone.svg";
import write from  "../../images/write.svg";
import extraction from  "../../images/extraction.svg";
import customize from  "../../images/customize.svg";
import image from  "../../images/image.svg";
import people from  "../../images/people.svg";
import modal from  "../../images/modal.svg";
import membership from  "../../images/membership.svg";
import qna from  "../../images/qna.svg";
import { useHistory } from 'react-router-dom';
import * as projectService from "../../services/projectService";
import { message } from "antd";
import { TbWebhook } from "react-icons/tb";


function HomeModules({projectId, settings, companyId}) {

    const history = useHistory();


        const handleAgent = () => {
            history.push({
                pathname: `/humanagent/${projectId}`,
                state: { settings: settings }
            });
        };


        const handleSemantic = () => {
            history.push({
                pathname: `/semantic/${projectId}`,
                state: { settings: settings }
            });
        };

        const handleVoicePrompt = () => {
            history.push({
                pathname: `/rawprompt/${projectId}`,
                state: { settings: settings }
            });
        };

        const handleSales =()=>{
         message.info("This is available only with the higher package. Please contact support.")
        }

        const handleWeb =() =>{
            history.push({
                pathname: `/websearch/${projectId}`,
                state: { settings: settings }
            });
        }

        const handleStripePlans =() =>{
            history.push({
                pathname: `/stripeplans/${projectId}`,
                state: { settings: settings }
            });
        }

        const handlePrompt =() =>{
            history.push({
                pathname: `/prompt/${projectId}`,
                state: { settings: settings }
            });
        }

        const handleQnA =()=>{
            history.push({
                pathname: `/qna/${projectId}`,
                state: { settings: settings }
            });
        }
        
        const handleRaw =() =>{
            history.push({
                pathname: `/rawprompt/${projectId}`,
                state: { settings: settings }
            });
        }

        const handleMembers =() =>{
            history.push({
                pathname: `/membermanagement/${companyId}/${projectId}`,
                state: { settings: settings }
            });
        }

        const handleWebhook = () => {
            history.push({
              pathname: "/webhook/" + projectId 

            });
          }

          const handleExtraction = () => {
            history.push({
              pathname: `/extraction/${projectId}`  
            });
          }  
          


        

  return (
    <div>
          <div className="borderbottom">

          <h3>Chat modules</h3>
<div className='grid grid--1x2'>

<div onClick={handleQnA}  style={{padding:"15px",background: "ghostwhite"}} className='outlinebox flexrow alignhorizontal-wideapart'>
                       <img src={qna} style={{width:"40px"}}></img>
                       <div>Ask questions</div>
                       {/* <Toggle/> */}
      
                   </div>   
                   </div>
             

          <div className="borderbottom">
        <h3>Whatsapp modules</h3>
        <div className='grid grid--1x2'>
        <div onClick={handleAgent} style={{padding:"15px",background: "ghostwhite"}} className='outlinebox flexrow alignhorizontal-wideapart'>
                       <img src={phone} style={{width:"40px"}} ></img>
                       <div>Human Agent transfer</div>
                       {/* <Toggle/> */}
      
        </div>
        
        
        <div onClick={handleSales}  style={{padding:"15px",background: "ghostwhite", opacity:"0.3"}} className='outlinebox flexrow alignhorizontal-wideapart'>
                       <img src={image} style={{width:"40px"}}></img>
                       <div>Images on request</div>
                       {/* <Toggle/> */}
      
                   </div>

                   





             













                   
           
      
                   </div>
      </div>



    

      <div className="borderbottom">
        <h3>Membership management</h3>
        <div className='grid grid--1x2'> 
        <div onClick={handleMembers}  style={{padding:"15px",background: "ghostwhite"}} className='outlinebox flexrow alignhorizontal-wideapart'>
                       <img src={membership} style={{width:"40px"}}></img>
                       <div>Manage members</div>
                       {/* <Toggle/> */}
      
                   </div>     
           
      
                   </div>
      </div>





<h3>General modules</h3>
<div className='grid grid--1x2'>

<div onClick={handlePrompt}  style={{padding:"15px",background: "ghostwhite"}} className='outlinebox flexrow alignhorizontal-wideapart'>
                       <img src={customize} style={{width:"40px"}}></img>
                       <div>Prompt builder</div>
                       {/* <Toggle/> */}
      
                   </div>    


<div onClick={handleWebhook}  style={{padding:"15px",background: "ghostwhite"}} className='outlinebox flexrow alignhorizontal-wideapart'>
                       <TbWebhook style={{width:"40px", height:"40px"}}/>
                       <div>Webhook</div>
                       {/* <Toggle/> */}
      
</div>


<div onClick={handleExtraction}  style={{padding:"15px",background: "ghostwhite"}} className='outlinebox flexrow alignhorizontal-wideapart'>
                       <img src={extraction} style={{width:"40px"}}></img>
                       <div>Data extraction</div>
                       {/* <Toggle/> */}
      
</div>


                          
<div  onClick={handleSales} style={{padding:"15px", background: "ghostwhite", opacity:"0.3"}} className='outlinebox flexrow alignhorizontal-wideapart'>
               <img src={csv} style={{width:"30px"}} ></img>
               <div>Calculate CSV </div>
               {/* <Toggle/> */}
            
           </div>

           <div onClick={handleStripePlans}  style={{padding:"15px", background: "ghostwhite", opacity:"0.3"}} className='outlinebox flexrow alignhorizontal-wideapart'>
               <img src={tables} style={{width:"40px"}}></img>
               <div>Stripe plans</div>
               {/* <Toggle/> */}
            
           </div>

           <div onClick={handleSales}  style={{padding:"15px", background: "ghostwhite", opacity:"0.3"}} className='outlinebox flexrow alignhorizontal-wideapart'>
               <img src={tables} style={{width:"40px"}}></img>
               <div>Dynamic tables</div>
               {/* <Toggle/> */}
            
           </div>

           <div onClick={handleWeb} style={{padding:"15px",background: "ghostwhite"}} className='outlinebox flexrow alignhorizontal-wideapart'>
               <img src={globe} style={{width:"40px"}}></img>
               <div>Web search</div>
               {/* <Toggle/> */}
            
           </div>

           <div  onClick={handleSemantic}  style={{padding:"15px",background: "ghostwhite"}} className='outlinebox flexrow alignhorizontal-wideapart'>
               <img src={semantic} style={{width:"40px"}}></img>
               <div>Semantic search</div>
               {/* <Toggle/> */}
            
           </div>

           {/* <div  style={{padding:"15px",background: "ghostwhite"}} className='outlinebox flexrow alignhorizontal-wideapart'>
               <img src={people} style={{width:"30px"}}></img>
               <div>Membership management</div>
         
            
           </div> */}

  

           <div onClick={handleVoicePrompt}  style={{padding:"15px",background: "ghostwhite"}} className='outlinebox flexrow alignhorizontal-wideapart'>
               <img src={modal} style={{width:"30px"}}></img>
               <div>Voice prompt</div>
               {/* <Toggle/> */}
            
           </div>


           
           </div>
</div>

  
    </div>
  );
}

export default HomeModules;
