import React, { useEffect, useState, useRef } from 'react';
import UploadMultiple from '../../common/UploadMultiple';
import Joi from "joi-browser";
import { RiDeleteBin6Fill } from "react-icons/ri";
import * as knowledgebaseService from "../../services/knowledgebaseService";
import * as projectService from "../../services/projectService";
import { message } from "antd";
import { useHistory, useLocation } from "react-router";
import NavigationBar from '../../common/NavigationBar';
import animationData from '../../animation/uploading.json'; 
import lottie from 'lottie-web';
import ExtractionRFP from './ExtractionRFP';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function ExtractionUpload({ project: propProject, isSuperdash, companyId, reload, setReload, setActiveProject, activeProject, projects, refresh, setRefresh }) {
    const history = useHistory();

  
    const urlPath = history.location.pathname;
    const urlSearch = history.location.search;
    let location = useLocation();
  
    let project = propProject || (location.state && location.state.project);



    const animationContainer = useRef(null);
  
    const [formState, setFormState] = useState("loading");

    useEffect(() => {
  
      if (projects && projects[activeProject] && projects[activeProject].projectSettings && projects[activeProject].projectSettings.extractionPipelineData) {
        if (projects[activeProject].projectSettings.extractionPipelineData.extractionPipelineType === 'RFP') {
          setFormState('RFP');
          setLoading(false)
        } else {
          setFormState('fileupload');
          setLoading(false)
        }

      } 
    }, [projects, activeProject, setFormState]);



      const [inputField] = useState({
          url: "",
      
        });
  
        const [errorMessage] = useState({});
      
        const[loading, setLoading] = useState(true);
        const[uploadedFiles, setUploadedFiles] = useState([]);
        const [visibility, setVisibility] = useState(false)
  
  
        useEffect(() => {
   
        }, [uploadedFiles]);
        
  
        const template = [
          {
            name: "url",
            label: "Enter a URL",
            type: "text",
          },
          
      
      
        ];
  
  
          
      
        const buttonInfo = { label: " Submit ", style: "" };
      
        const schema = {
          url: Joi.string().uri().min(2).max(500).required().label("URL"),
      
        };


        useEffect(() => {
          if (formState === "uploading") {
              const anim = lottie.loadAnimation({
                  container: animationContainer.current, // the dom element
                  renderer: 'svg',
                  loop: true,
                  autoplay: true,
                  animationData: animationData
              });
              return () => anim.destroy(); // optional clean up for unmounting
          }
      }, [formState]);
     
  
  
        const handleSubmit = async () => {
   
 
          setLoading(true);
      
        
          const formData = new FormData();
          uploadedFiles.forEach((file, index) => {
            formData.append("files", file);
            // Append the filePipeline field for each file
            // formData.append(`filePipeline_${index}`, 'your_file_pipeline_value_here');
          });
  
          if(uploadedFiles.length<1)
          {
          setLoading(false);
          return message.error("Please upload atleast one file.")
  
        }
  
        setFormState("uploading")

        const serviceMethod = isSuperdash ? knowledgebaseService.docsUpload: knowledgebaseService.portaldocsUpload;
        
          try {
            const response = await serviceMethod ( project , formData);
            if (response.status === 200) {
               setLoading(false);
               message.success({
                content: 'Success. Upload complete',            
                duration: 8, 
              });
               setVisibility(false)
               setFormState("fileupload")
               setReload(!reload)
               setUploadedFiles([])
            }
          } catch (ex) {
            if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
              setLoading(false);
              message.error({
                content: ex.response.data.msg,
                duration: 8
              });
              setVisibility(false)
              setFormState("fileupload")
              setUploadedFiles([])
              return;
            }
          }
        };
  
      
  
        const handleFileChange = (info) => {
          if (info.file.type === 'text/csv') {
            message.error('CSV files are not allowed');
            return false;
          }
        
        //   if (info.file.size > 15 * 1024 * 1024) {
        //     message.error('File size must be smaller than 15MB!');
        //     setLoading(false);
        //     return false;
        //   }
        
          setUploadedFiles((prevUploadedFiles) => [
            ...prevUploadedFiles,
            info.file,
          ]);

          setVisibility(!visibility)
  
        
          // } 
          
          // else if (info.file.status === "removed") {
          //   const filteredFiles = uploadedFiles.filter(
          //     (file) => file.uid !== info.file.uid
          //   );
          //   setUploadedFiles(filteredFiles);
          // }
        };
        
       
        const handleDelete =(index)=>{
          const newArray = uploadedFiles.filter((_, i) => i !== index);
          setUploadedFiles(newArray);

          if(newArray.length<1)
          setVisibility(false)

        }

        const handleVisibility =()=>{
          setVisibility(!visibility)
        }

        const uploadRedirection =()=>{
        setFormState("upload")
        }


        if(formState==="loading")
        return(
          <SkeletonTheme color="#b5b5b5" highlightColor="#d6d6d6">
              
          <div style={{height:"auto", overflow:"auto", marginLeft:"40vw", height:"100vh"}} className='flexcolumn flexcolumn--allcenter'>
              <div>
                <table style={{ margin: "50px" }}>
                    <thead>
                        <tr>
                            <th><Skeleton width={100} /></th>
                            <th><Skeleton width={200} /></th>
                            <th><Skeleton width={100} /></th>
                            <th><Skeleton width={100} /></th>
                            <th><Skeleton width={100} /></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><Skeleton width={100} /></td>
                            <td><Skeleton width={200} /></td>
                            <td><Skeleton width={100} /></td>
                            <td><Skeleton width={100} /></td>
                            <td><Skeleton width={100} /></td>
                        </tr>
                        <tr>
                            <td><Skeleton width={100} /></td>
                            <td><Skeleton width={200} /></td>
                            <td><Skeleton width={100} /></td>
                            <td><Skeleton width={100} /></td>
                            <td><Skeleton width={100} /></td>
                        </tr>
                        <tr>
                            <td><Skeleton width={100} /></td>
                            <td><Skeleton width={200} /></td>
                            <td><Skeleton width={100} /></td>
                            <td><Skeleton width={100} /></td>
                            <td><Skeleton width={100} /></td>
                        </tr>
                    </tbody>
                </table>
              </div>
          </div>
      </SkeletonTheme>
          )



 



        if(formState==="RFP" && companyId)
return (

  <div  style={{overflow:"auto", marginLeft: "22vw" }}>
              <NavigationBar companyId={companyId} setActiveProject={setActiveProject} activeProject={activeProject} projects={projects}   />
    <div>
      <ExtractionRFP projectId={project} companyID={companyId} setFormState={setFormState}/>
    </div>
  </div>
)

        if(formState==="fileupload")
    return (
      <div style={{overflow:"auto", marginLeft: "22vw" }}>
                 <NavigationBar companyId={companyId} setActiveProject={setActiveProject} activeProject={activeProject} projects={projects}  />

        <div style={{  width:"70vw" }} className="flexcolumn flexcolumn--allcenter">
        <div className="flexrow">
          { !visibility && <div style={{ marginRight: "50px", width: "600px", height:"420px", background:"white", borderRadius:"25px" }} className="flexcolumn flexcolumn--allcenter bluecontainer">
            <p>Please upload files</p>
            <UploadMultiple onChange={handleFileChange} />
            {uploadedFiles.length>=1 && <button className='btnn btnn-disable":"btnn btnn-primary' onClick={handleVisibility}>
              Preview Files
            </button>}
          </div>}
  

            {/* {uploadedFiles.length < 1 && (
              <div className="flexcolumn flexcolumn--allcenter">
                <img style={{ width: "150px", height: "50%", marginTop:"30%" }} src={empty} alt="empty" />
                <h3 style={{ marginTop: "30px" }}>It's empty. It seems you have not uploaded any file.</h3>
              </div>
            )} */}
            {visibility && 
                      <div style={{ overflow: "auto",height:"700px", width:"600px", background:"white", borderRadius:"25px"}} className="bluecontainer">
            
              <div >
                {uploadedFiles.map((item, index) => (
                  <div className='wideapart'>
        <p className='bb' key={index}>{item.name.length > 30 ? item.name.slice(0, 30) + '...' : item.name}</p>

                    <RiDeleteBin6Fill onClick={()=>handleDelete(index)} style={{color:"#f34f4f", cursor:"pointer"}}/>
                  </div>
                ))}

            <div className='flex flexrow' style={{textAlign:"center", position:"relative", top:"100%"}}>
            <button  style={{marginRight:"10px"}} className='btnn btnn btnn-secondary' onClick={handleVisibility}>
             Add more
              </button>
              <button  className={uploadedFiles.length<1?"btnn btnn-disable":"btnn btnn-primary"} disabled={loading} onClick={handleSubmit}>
                {loading ? "Uploading files..." : "Upload"}
              </button>
            </div>


              </div>
            
            </div>
            }
          </div>
  
      </div>
      </div>
    );

    if (formState === "uploading") {
      return (
          <div>
            <div className="lottie-animation" ref={animationContainer} style={{ width: 800, height: 800, marginLeft: "30vw", height:"80vh"  }}>
                {/* Lottie animation will be rendered inside this div */}
            </div>
          </div>
      );
  } 
 
 return ( <div  style={{overflow:"auto", marginLeft: "22vw" }}>
              <NavigationBar companyId={companyId} setActiveProject={setActiveProject} activeProject={activeProject} projects={projects}   />
    <div>
    <Skeleton width={300} height={30} />
    </div>
  </div>
)
}



export default ExtractionUpload;