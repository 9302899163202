import React,{useEffect, useState} from 'react';
import { useHistory} from "react-router";
import { useLocation } from 'react-router-dom';
import Joyride, { STATUS } from 'react-joyride';


function ExtractionRFP({setFormState, companyID, projectId}) {

    const history = useHistory();

    const url = window.location.href; 

    const location = useLocation();

    // const { projectId } = props;
    const companyId = companyID || location.state && location.state.companyId

    const [showTooltip, setShowTooltip] = useState(false);

    const [run, setRun] = useState(false);
    const [steps, setSteps] = useState([]);

    useEffect(() => {
        const onboarding = localStorage.getItem("onboarding") === "true";
        if (onboarding) {
            setRun(true);
            setSteps([
                {
                    target: '.upload-company-docs-button',
                    content: 'In this section, you should upload the standard documents that are frequently referenced when preparing new RFPs. This includes information about your team, details about the company and its products, as well as responses to previous RFPs.',
                    placement: 'top',
                },
                {
                    target: '.upload-rfp-button',
                    content: 'Following that, upload all documents specific to this RFP, such as instructions, formatting requirements, and the RFP itself. Ensure that you upload everything at once.',
                    placement: 'top',
                }
               
            ]);
        }
    }, []);

    const handleJoyrideCallback = (data) => {
        const { status } = data;
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            setRun(false);
            localStorage.removeItem("onboarding");
        }
    };
          

    const handleUpload = () => history.push(`/companywideupload/${companyId}`);

    const uploadRedirection = () => setFormState("fileupload");

    const handleView = () => history.push({
        pathname: `/companysettings/${companyId}`,
        state: { activeState: 2 }
    });


    return (
        <div className="flex flexcolumn" style={{height:"100vh",marginTop:"50px", alignItems:"center"}}>
             <Joyride
                continuous
                run={run}
                steps={steps}
                callback={handleJoyrideCallback}
                showSkipButton
                styles={{
                    options: {
                        zIndex: 10000,
                    },
                }}
            />

<div>
                {url.includes('extraction-rfp-template') ? (
                    <h3 className='bb'>Please complete the following steps to set up the workflow for your project</h3>
                ) : (
                    <h3></h3>
                )}
            </div>

            <div style={{minHeight:"100px", width:"800px", margin:"10px"}} className='selectionbox'>
                <div className='flexrow wideapart'>
                    <div>
                    <h3>Upload your RFP</h3>

              
                        <p>Upload your questions and necessary documents for this task. For documents common across multiple projects, such as company information, use the upload option below.</p>
                    </div>
                    <div>
                        {/* <button className='btnn  btnn--small btnn-transparent'>write</button> */}
                        <button onClick={uploadRedirection} className='btnn btnn--small upload-rfp-button'>Upload</button>
                    </div>
                </div>
            </div>



            <div style={{minHeight:"100px", width:"800px", margin:"10px"}} className='selectionbox'>
                <div className='flexrow wideapart'>
                    <div>
                        <h3>Company documents</h3>
                        <p> Upload documents that will be retained for all your future projects. These may include company information, team details, past RFPs etc.</p>
                    </div>
                    <div className='flex flexrow'>
                    <button onClick={handleView} className='btnn btnn--small btnn-transparent'>View Files</button>
                        <button onClick={handleUpload} className='btnn btnn--small upload-company-docs-button'>Upload Documents</button>
                    </div>
                </div>
            </div>

        

            {/* <div style={{minHeight:"100px", width:"800px", margin:"10px"}} className='selectionbox'>
                <div className='flexrow wideapart'>
                    <div>
                        <h3>Instructions to create RFP</h3>
                        <p>All files you upload here will be accesible for other projects</p>
                    </div>
                    <button className='btnn btnn--small'>upload documents</button>
                </div>
            </div>


            <div style={{minHeight:"100px", width:"800px", margin:"10px"}} className='selectionbox'>
                <div className='flexrow wideapart'>
                    <div>
                        <h3>Instructions to create RFP</h3>
                        <p>All files you upload here will be accesible for other projects</p>
                    </div>
                    <button className='btnn btnn--small'>upload documents</button>
                </div>
            </div> */}
        </div>
    );
}

export default ExtractionRFP;