import React from 'react';

function RoundCheck({ id, isChecked, onChange }) {

  return (
    <div>
      <div className="round">
        <input
          type="checkbox"
          value={isChecked}
          checked={isChecked}
          id={id}
          onChange={onChange}
        />
        <label htmlFor={id}></label>
      </div>
    </div>
  );
}

export default RoundCheck;
