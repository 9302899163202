import React, { useState, useEffect } from 'react';
import * as companyService from "../../services/companySettingService";
import { useParams } from 'react-router-dom';
import { message } from "antd";
const _ = require("lodash");



function CSPortal() {

    const {companyId} = useParams();

    const [reload, setReload] = useState(false)

  // Initialize the state with default values for all inputs
  const [inputValue, setInputValue] = useState({
    brandColor: '', // Default value for brandColor
    fontColor: '', // Default value for fontColor
    domain: '', // Default value for domain
    logo: '', // Default value for logo
  });

  const handleChange = (event) => {



    const { name, value } = event.target;
    setInputValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  useEffect(() => {

    {
     const fetchData = async () => {
      const response = await companyService.allCompanyStylesForOwner(companyId);  
      const style = _.pick(response.data.companyProfile, ["brandColor", "fontColor", "domain", "logo"]);
      setInputValue(() => ({ ...style}))
    };
     fetchData();
    }
    }, [reload]);




// const response=  await companyService.saveCompanySettings(companyId, inputValue);

const handleSubmit = async () => {
    try {
        const response=  await companyService.updateCompanySettings(companyId, inputValue);
        if(response.status === 200){
            message.success("Configuration saved");
            setReload(!reload) // Corrected the typo here
           }
    } catch (ex) {
      if (ex.response && ex.response.status >= 400 && ex.response.status < 500 ) {
        message.error(ex.response.data.msg);
      }
    }
  };




  return (
    <div>
      <div style={{alignItems: "center"}} className="wideapart bb">
        <h3 style={{fontSize:"16px"}}>Brand color</h3>
        <input 
          value={inputValue.brandColor || ''} // Ensure a controlled component
          className='colorbox'
          onChange={handleChange}
          type="color"
          id="brandColor"
          name="brandColor" />
      </div>

      <div style={{alignItems: "center"}} className="wideapart bb">
        <h3 style={{fontSize:"16px"}}>Font color</h3>
        <input
          value={inputValue.fontColor || ''} // Ensure a controlled component
          className='colorbox'
          onChange={handleChange}
          type="color"
          id="fontColor"
          name="fontColor" />
      </div>  

      <div style={{marginTop:"30px"}}>
          <div className="wideapart">
            <h3 style={{fontSize:"16px"}}>Logo Url</h3>
            <input
              className='input--custom'
              type="text"
              name="logo"
              value={inputValue.logo || ''} // Ensure a controlled component
              onChange={handleChange}
              placeholder="Logo URL"
            />
          </div>
          <div className="wideapart">
            <h3 style={{fontSize:"16px"}}>Whitelist domain</h3>
            <input
              className='input--custom'
              type="text"
              name="domain"
              value={inputValue.domain || ''} // Ensure a controlled component
              onChange={handleChange}
              placeholder="Whitelist domain. eg: www.abc.com"
            />
          </div>
      </div>

      <div className="wideapart ">
        <h3 style={{fontSize:"16px"}}>Save all changes</h3>
        <button onClick={handleSubmit} className='btnn btnn-primary'>Save</button>
      </div>
    </div>
  );
}

export default CSPortal;
