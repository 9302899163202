import React,{useState} from 'react';
import Navbar from '../../common/Navbar';
import BlockComponent from '../../components/BlockComponent';
import { useLocation } from 'react-router-dom';
import * as projectService from "../../services/projectService";
import { message } from "antd";
import { useParams } from 'react-router-dom';

function Semantic(props) {

    const location = useLocation();
    const passedSettings = location.state?.settings;
    const { projectId } = useParams();

    const filters = ["",""];
    const [activeKey, setActiveKey] = useState(0);
    const title = "Semantic Search";
    const description = "Unlock precise answers instantly by searching through your entire uploaded data."
    const buttonName ="Add an agent";
    const [toggle, setToggle] = useState(passedSettings.semanticSearchOnly?passedSettings.semanticSearchOnly:false)



    let formState1=null;
    let formState2=null;

    const handleAdd =()=>{}

    const handleToggle = async () => {
        try {
          const response = await projectService.updateProject(projectId, { semanticSearchOnly: !toggle });
      
          if (response.status === 200) {
            message.success("Changes saved");
            setToggle(!toggle);
          } else {
            message.error("Failed to save changes");
          }
        } catch (error) {

          message.error("An error occurred while saving changes");
        }
      };
      

    return (
        <div>
        <Navbar/>
<BlockComponent title={title} description={description} buttonName={buttonName} filters={filters} setKey={setActiveKey}  formState1={ formState1} formState2={formState2} handleModal={handleAdd} onChange={handleToggle} toggle={toggle} activeKey={activeKey} buttonVisitbility={false}/>
</div>
    );
}

export default Semantic;