import React from 'react';

function SpecialToggle({ nameLeft, nameRight,checked,setChecked }) {
    return (
        <div>
            <input
                id={`left`}
                className="toggle toggle-left"
                type="radio"
                value="left"
                onClick={() => { setChecked(false) }}
                checked={checked === false}
                onChange={() => { }}
            />
            <label htmlFor={`left`} className="btnn-toggle">
                {nameLeft}
            </label>
            <input
                id={`right`}
                className="toggle toggle-right"
                type="radio"
                value="right"
                onClick={() => { setChecked(true) }}
                checked={checked === true}
                onChange={() => { }}
            />
            <label htmlFor={`right`} className="btnn-toggle">
                {nameRight}
            </label>
        </div>
    );
}

export default SpecialToggle;