import React, {useState} from 'react';
import { useUserContext } from "../userContext";
import { useCompanyContext } from "../companyContext";
import * as companyService from "../services/companyService";
import { useHistory} from "react-router";
import ToggleSwitch from '../common/ToggleSwitch';
import Joi from "joi-browser";
import Form from "../common/Form";
import { message } from "antd";
import play from '../images/play.svg';
// import docs from '../images/docs.svg';
import doc from '../images/doc.svg';
import faq from '../images/faq.svg';
import upload from '../images/upload.svg';
import spreadsheet from '../images/spreadsheet.svg';
import url from '../images/url.svg';
import gohighlevel from '../images/gohighlevel.jpg';
import pdf from '../images/pdf.svg';
import { set } from 'lodash';
import Whatsapp from '../components/FormComponent/Whatsapp';
import { FcAnswers} from "react-icons/fc";
import { FcLink} from "react-icons/fc";





function TrainingOptions(props) {

    const [inputField] = useState({
        label:"",
      });

      const [selection, setSelection] = useState("");
  

      const [errorMessage] = useState({});
    

    
      const template = [
        {
          name: "label",
          label: "Give a name",
          type: "text",  
        },
     
      
    ];
    
    const buttonInfo = { label: "Create", style: "" };
    
    const schema = {
        label: Joi.string().max(50).required().label("Folder name"),
        
    };

    const { projectId } = props.match.params;

    
      const handleSubmit = async(obj)=>{

      obj.projectId = projectId;
      obj.label ="First folder"
      
        try {
    
             message.success("Please wait.Creating your file")
             history.replace({ pathname: "/trainingdata/?ref=" + selection,
             state: {
              passedData:obj,
            },});
          } catch (ex) {
            if (ex.response && ex.response.status === 400) {
            //   toast.error(ex.response.data);
              return;
            }
          }

      }




    const apiPoint = process.env.REACT_APP_API_POINT; 
    const[loading, setLoading] = useState(false);
    const history = useHistory();
    const [accountsId, setAccountsId] = useState("")

    const [formState, setFormState]= useState("first")   


    // const user = useUserContext();
    // const company = useCompanyContext();



    const handleSelection =(item)=>{
        setSelection(item);
       
        let obj= {}

        obj.projectId = projectId;
        obj.label ="First folder"
      
        try {
    
             history.replace({ pathname: "/trainingdata/?ref=" + item,
             state: {
              passedData:obj,
            },});
          } catch (ex) {
            if (ex.response && ex.response.status === 400) {
            //   toast.error(ex.response.data);
              return;
            }
          }
      
    }
 

   

      return (
              <div style={{height:"100vh"}} className="splithalf">
              <div className="lefthalf centrething flexcolumn flexcolumn--allcenter">
                <div className="leftcontainer">
             <div>    
      <h2 style={{color:"white"}}> <br/>How do you want to <em>import your knowledgeBase </em></h2>
  
       
        {/* <button onClick={handleNext} style={{ marginTop:"50px"}} className='btnn btnn-primary'>Next Step</button> */}
        
      
         </div>
                </div>
              </div>
              {formState==="first" && <div  className="righthalf centrething flexcolumn flexcolumn--allcenter">
                  <div className='flexcolumn flexcolumn--allcenter'>
      
    {/* <div onClick={()=>handleSelection("qna")}  style={{justifyContent: "space-around"}} className='flexrow bluebox alignhorizontal-center bluebox--special'>
<FcAnswers style={{width:"50px", height:"50px"}}/>
    <p style={{fontWeight:"600"}}>QnA Format</p>
    </div> */}
    <div onClick={()=>handleSelection("fileupload")}  style={{justifyContent: "space-around"}} className='flexrow bluebox alignhorizontal-center bluebox--special'>
    <img style={{width:"40px"}} src={upload}></img>
    <p style={{fontWeight:"600"}}>Upload files</p>
    </div> 

   <div onClick={()=>handleSelection("gsheetlink")}  style={{justifyContent: "space-around"}} className='flexrow bluebox alignhorizontal-center bluebox--special'>
   <img style={{width:"40px"}} src={spreadsheet}></img>
   <p style={{fontWeight:"600"}}>GSheet</p>
    </div>

   <div onClick={()=>handleSelection("url")}  style={{justifyContent: "space-around"}} className='flexrow bluebox alignhorizontal-center bluebox--special'>
   <img style={{width:"40px"}} src={url}></img>
   <p style={{fontWeight:"600"}}>Add URLs</p>
    </div> 


    <div onClick={()=>handleSelection("qna")}  style={{justifyContent: "space-around"}} className='flexrow bluebox alignhorizontal-center bluebox--special'>
    <img style={{width:"40px"}} src={faq}></img>
    <p style={{fontWeight:"600"}}>Write or Paste Text</p>
    </div>



    {/* <div onClick={()=>handleSelection("lform")}  style={{justifyContent: "space-around"}} className='flexrow bluebox alignhorizontal-center bluebox--special'>
    <img style={{width:"40px"}} src={doc}></img>
    <p style={{fontWeight:"600"}}>Copy text</p>
    </div>  */}



    {/* <div onClick={()=>handleSelection("gdocs")} style={{justifyContent: "space-around"}} className='flexrow bluebox alignhorizontal-center bluebox--special '>
    <img style={{width:"40px"}} src={pdf}></img>
    <p style={{fontWeight:"600"}}>PDF Format</p>

    </div> */}


    {/* <div onClick={()=>handleMessage()} style={{justifyContent: "space-around"}} className='flexrow bluebox alignhorizontal-center bluebox--special'>
<FcLink style={{width:"50px", height:"50px"}}/>
    <p style={{fontWeight:"600"}}>Website</p>

    </div> */}









                  </div>
              </div>}


              {/* {formState==="2nd" && 
              <div className='righthalf centrething flexcolumn flexcolumn--allcenter'>
            <div className="flexcolumn bluecontainer">
           <h3>Create a folder name</h3>
           <Form
              errorMessage={errorMessage}
              inputField={inputField}
              template={template}
              schema={schema}
              buttonInfo={buttonInfo}
              onSubmit={handleSubmit}
              loading={loading}
              setLoading={setLoading}
            />
              </div>
              </div>
              } */}
            </div>
          );
  
     
  

  }
  
  export default TrainingOptions;