import React,{useState} from 'react';
import { useHistory } from "react-router";
import { Redirect } from "react-router-dom";
import { message } from "antd";
import Joi from "joi-browser";
import * as customerService from "../../services/customerService";

import { Link } from "react-router-dom";
import Form from '../../common/Form';

function AddCustomerPassword({projectId, setIsMembershipModalOpen}) {

    const [inputField, setInputField] = useState({
        email:"",
        password: "",
        active: false,
      });


      const[loading, setLoading] = useState(false);

      const [errorMessage] = useState({});
    
      // let auth = useAuth();
      const history = useHistory();
    
      const template = [
        {
          name: "email",
          label: "Customer Email",
          type: "text",  
        },
        {
          name: "password",
          label: "Set a password for customer",
          type: "password",
    
        },
        {
          name: "active",
          label: "Active",
          type: "toggle",
    
        },
      
      ];
    
      const buttonInfo = { label: "Connect account", style: "" };
    
      const schema = {
        email: Joi.string().required().label("Email"),
        password: Joi.string().required().label("Password"),
        active: Joi.boolean()
      };

      const handleSubmit = async (req) => {
        try {
          const response = await customerService.addCustomer(projectId, req);
          if (response.status === 201) {
            message.success("Customer access created")
            setIsMembershipModalOpen(false);
            setLoading(false)
            setInputField({
              email:"",
              password:"",
              active:false
            })
          }
        } catch (ex) {
          if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
            setInputField({
              email:"",
              password:"",
              active:false
            })
            message.error(ex.response.data.msg);
            setIsMembershipModalOpen(false);
            setLoading(false)
  
          } else {
            message.error("Something went wrong. Please try again later.");
            setIsMembershipModalOpen(false);
            setLoading(false)
            setInputField({
              email:"",
              password:"",
              active:false
            })
          }
          setLoading(false);
        }
      };
      

    return (
        <div>

       <div style={{textAlign:"center"}}>
              </div>
              <Form
                errorMessage={errorMessage}
                inputField={inputField}
                template={template}
                schema={schema}
                buttonInfo={buttonInfo}
                onSubmit={handleSubmit}
                loading={loading}
                setLoading={setLoading}
              />
  

        
        </div>
    );
}

export default AddCustomerPassword;