import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import Joi from "joi-browser";
import Form from "../common/Form";
import auth from "../services/authService";
import { message } from "antd";

// import "../scss/onboarding.scss";

function Login() {
    const [inputField] = useState({
        email: "",
        password: "",
      });
    
      const [errorMessage] = useState({});
      const[loading, setLoading] = useState(false);
    
      // let history = useHistory();
      // let location = useLocation();
      // let auth = useAuth();
    
      const template = [
        {
          name: "email",
          label: "Enter your email here",
          type: "email",
          style: "light",
        },
        {
          name: "password",
          label: "Enter your password here",
          type: "password",
          style: "light",
        },
      ];
    
      const buttonInfo = { label: "Login ", style: "" };
    
      const schema = {
        email: Joi.string().email().required().label("email"),
        password: Joi.string().required().label("password"),
      };
    

    
      const handleSubmit = async ({ email, password }) => {
        try {
         await auth.login(email, password);
         window.location.href = window.location.origin
        } catch (ex) {
          if (ex.response && ex.response.status >= 400 && ex.response.status < 500 ) {
            setLoading(false)
            message.error(ex.response.data.msg);
          }
        }
      };



    return auth.getCurrentUser() ? (
        <Redirect to="/" />
      ) : (
        <div style={{ height: "100vh" }} className="flexcolumn flexcolumn--allcenter">
          <div className="flexcolumn bluecontainer">
            <div style={{textAlign:"center"}}>
              <h2>Welcome Back</h2>
            </div>
            <Form
              errorMessage={errorMessage}
              inputField={inputField}
              template={template}
              schema={schema}
              buttonInfo={buttonInfo}
              onSubmit={handleSubmit}
              loading={loading}
              setLoading={setLoading}
            />
           
          </div>
          <div style={{textAlign:"center"}} >
               <p className="instruction instruction--med">
               {/* New user? click here to <Link to="/signup">signup</Link> */}
               <br/>Forgot password? <Link to="/reset">reset here </Link> 
                {/* <Link to="/forgot">reset password</Link> */}
              </p>
           
            </div>
        </div>
    );



}

export default Login;
