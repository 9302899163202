import React,{useState, useEffect} from 'react';
import Navbar from '../../common/Navbar';
import BlockComponent from '../../components/BlockComponent';
import { useLocation } from 'react-router-dom';
import * as projectService from "../../services/projectService";
import * as extractionService from "../../services/extractionStatus";
import { message, Modal } from "antd";
import { useParams } from 'react-router-dom';
import Joi from "joi-browser";
import Form from "../../common/Form";
import { BsTrash} from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import { TbCapture, TbRuler2} from "react-icons/tb";
import { BiDownload } from "react-icons/bi";
import ModalComponent from '../../common/ModalComponent';

function Extraction(props) {

    const location = useLocation();

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [actionType, setActionType] = useState(null);
    const [footerStyle, setFooterStyle] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [modalStatus, setModalStatus] = useState(false)
    const [item, setItem]  = useState({});
    const [modalTitle, setModalTitle] = useState("")
    const [data, setData] = useState({})

    
    const {projectId} = useParams();

    const filters = ["Extracted data",""];
    const [activeKey, setActiveKey] = useState(0);
    const title = "Extraction";
    const description = "Find and manage all of the extracted information from various sources here."
    const buttonName ="Add a member";
    const [toggle, setToggle] = useState(false)
    const [reload, setReload] = useState(false)
    const [extractedData, setExtractedData] = useState([])
    const [itemID, setItemID] = useState("")
    const [key, setKey] = useState("1")
    



    useEffect(() => {
      const fetchData = async () => {
        const { data } = await extractionService.GetAllExtractionStatus(projectId);
        const reversedData = data.reverse();
        setExtractedData(reversedData)
      };
      fetchData();
    }, [reload]);





    const handleEdit = (item) => {
      setKey(item._id)
      setInputField({
        email: item.email,
        phoneNumber: item.phoneNumber,
        externalId: item.externalId,
        allowedQuestions: item.allowedQuestions,
        active:item.active
      });
  
      setActionType('edit');
      setIsModalOpen(true);
    };

    const getButtonColor =(item)=>{
     
    

      return item==="finished"? "outlinebutton  outlinebutton--green" : "outlinebutton  outlinebutton--red";

  }   

  const handleModal =(item, key) =>{

    setModalStatus(true)
   
    if(key==="capture")
   {
   setModalTitle("Captured details")
   setData(item.extractedData)
   setFooterStyle(false)
   return
   } 
   }

//    const handleOk = async () => {
//     setIsLoading(true)
//     try {
//       const response = await webhookstatusService.retyWebhooks(item._id, projectId);
    
//       if (response.status === 200) {
//         setModalStatus(false);
//         setReload(prevReload => !prevReload);
//         message.success("Success");
//         setIsLoading(false)
//       }
//     } catch (error) {
//       console.error("An error occurred:", error);
//       setIsLoading(false)
//     }
    
//   };

const handleDownload = (pdfLink) => {
    // Create a link element
    const link = document.createElement('a');
    link.href = pdfLink;
    link.target = '_blank';
    link.download = 'downloaded.pdf'; // Set the default file name for the download

    // Append the link to the body
    document.body.appendChild(link);

    // Trigger the click event
    link.click();

    // Remove the link from the body
    document.body.removeChild(link);
  };

  
    

  let formState1=
  <table className='tableStyle'>
  <thead>
    <tr>
    <th>ConversationId</th>
    <th>Extracted data</th>
    <th>Status</th>
    <th>Download</th>
 
    </tr>
  </thead>
   <tbody>
  {extractedData.length> 1 && extractedData.map((item, index) => (
      <tr key={index}>
      <td >{item.conversationId}</td> 
      <td  style={{ cursor: 'pointer' }} onClick={() => handleModal(item, "capture")}><TbCapture style={{height:"20px"}}/></td> 

      {/* <td >{item.extractedData}</td>  */}
      <td><button className={getButtonColor(item.conversationStatus)}>{item.conversationStatus}</button></td> 
      {item.finalOutput &&item.finalOutput.startsWith('https://pdfuploadbucket-2.s3.amazonaws.com') && (
  <BiDownload style={{ cursor: "pointer", margin: "20px" }} onClick={() => handleDownload(item.finalOutput)} />
)}
  
      <td>
   

  
      
      
      </td> 
      {/* <td  style={{ cursor: 'pointer' }} onClick={() => handleModal(item, "capture")}><TbCapture style={{height:"20px"}}/></td>  */}
      <td>
    {/* {item.status === "failed" ? (
      <button
        className={getButtonColor(item.status)}
        style={{ cursor: 'pointer' }}
        onClick={() => handleModal(item, "retry")}
      >
  <div>
   Retry
  </div>
      </button>
    ) : (
      <button className={getButtonColor(item.status)}>{item.status}</button>
    )} */}
  </td>
      {/* <td>{getTime(item.timestamp)}</td> */}
      {/* <td style={{cursor:"pointer"}}><BsTrash onClick={()=>showRevokeModal(item._id)} style={{ color: 'red' }}/></td>  */}
  </tr>
  
  
  ))} 
  </tbody>
  </table>




    let formState2=null;


/*-------- Modal related code below --------------------------_*/

  const [isModalOpen, setIsModalOpen] = useState(false);
 
    const handleAdd =()=>{
      setKey("2")
      setInputField({
        email:"",
        phoneNumber:"",
        externalId:"",
        allowedQuestions:"",
        active:false
      })
      setIsModalOpen(true);
      setActionType('add');
    }

    const showRevokeModal =(itemId)=>{
      setDeleteModalOpen(true);
      setItemID(itemId)
    }
  

//     const RevokeOk= async () => {

//       try {
//         const response = await customerService.deleteCustomer(companyId, projectId, itemID);
        
//         if (response.status === 200) {
//             setDeleteModalOpen(false);
//             setLoading(false);
//             message.success("Deleted succesfully");
//             setReload(!reload)
//         }
  
//     } catch (ex) {
//         if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
//             setLoading(false);
//             message.error(ex.response.data.msg);
//         }
//     }
//   };




    const [inputField, setInputField] = useState({
      email:"",
      phoneNumber:"",
      externalId:"",
      allowedQuestions:"",
    });


  
    const[loading, setLoading] = useState(false);
  
  
    const [errorMessage] = useState({});


  
  
    // let auth = useAuth();
    // const history = useHistory();
  
    const template = [
  
      {
        name: "email",
        label: "Member email(optional)",
        type: "text",
        
      },
      {
        name: "phoneNumber",
        label: "Member phone number (optional)",
        type: "text",
  
      },

      {
        name: "externalId",
        label: "UniqueID (optional)",
        type: "text",
  
      },
      {
        name: "allowedQuestions",
        label: "Quota",
        type: "number",
  
      }, 
      
      {
        name: "active",
        label: "Active",
        type: "toggle",
  
      }, 
    ];


  
    const buttonInfo = { label: actionType === 'add' ? "Add a member" : "Edit a member", style: "" };
  
    const schema = {
      email: Joi.string().allow(null, "").max(50).label("Member email"),
      phoneNumber: Joi.string().allow(null, "").max(50).label("Member phone number"),
      externalId: Joi.string().allow(null, "").max(30).label("Member ID"),
      allowedQuestions: Joi.number().required().label("Question Limit"),
      active: Joi.boolean().label("Active state"),
    };



    const handleMembershipAdd = async (data) => {
      const { email, phoneNumber, externalId, allowedQuestions, active } = data;
    
      if (!email && !phoneNumber && !externalId) {
          message.error("Please add a value for at least one of these fields: email, phone number, or UniqueId.");
          setLoading(false);
          return;
      }
  


      const allowedQuestionNumber = parseInt(allowedQuestions, 10);

      data = {
        ...data,
        // companyId,
        projectId,
        active,
        allowedQuestions: allowedQuestionNumber 
      }

    //   try {
    //       const response = await customerService.addCustomer(projectId, data);
          
    //       if (response.status === 201) {
    //           setIsModalOpen(false);
    //           setLoading(false);
    //           message.success("Member added successfully");
    //           setReload(!reload)
    //       }
  
    //   } catch (ex) {
    //       if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
    //           setLoading(false);
    //           message.error(ex.response.data.msg);
    //       }
    //   }


    

  /*------------------- Delete Model -------------------------------*/
  
 


  const handleOk = () => {
    setIsModalOpen(false);
  };



    setDeleteModalOpen(false);
  };

  const RevokeCancel = () => {
    setDeleteModalOpen(false);
  };


  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  



//*--------------- Toggle code below -------------------------------*//

    const handleToggle = async () => {
        try {
          const response = await projectService.updateProject(projectId, { membershipManagement: !toggle });
      
          if (response.status === 200) {
            message.success("Changes saved");
            setToggle(!toggle);
          } else {
            message.error("Failed to save changes");
          }
        } catch (error) {

          message.error("An error occurred while saving changes");
        }
      };

      const handleMembershipEdit = async(data)=>{

       
    //     try {
    //       const response = await customerService.updateCustomer(projectId, key, data);
          
    //       if (response.status === 200) {
    //           setIsModalOpen(false);
    //           setLoading(false);
    //           message.success("Member Edited succesfully");
    //           setReload(!reload)
    //       }
  
    //   } catch (ex) {
    //       if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
    //           setLoading(false);
    //           message.error(ex.response.data.msg);
    //       }
    //   }

      }



      
    
    return (
        <div>
        <Navbar/>
<BlockComponent title={title} description={description} buttonName={buttonName} filters={filters} setKey={setActiveKey}  formState1={ formState1} formState2={formState2} handleModal={handleAdd} onChange={handleToggle} toggle={toggle} activeKey={activeKey} buttonVisitbility={false} toggleVisibility={false}/>  
<ModalComponent title={title} modalStatus={modalStatus} data={data} setModalStatus={setModalStatus}  setIsLoading={setIsLoading} isLoading={isLoading} footerStyle={footerStyle} />       
</div>
    );

}

export default Extraction;