import React, { useState } from "react";
import { useHistory } from "react-router";
import { Redirect } from "react-router-dom";
import { message } from "antd";
import Joi from "joi-browser";
import Form from "../../common/Form";
import * as userService from "../../services/userService";
import auth from "../../services/authService";
import { Link } from "react-router-dom";

function ExtractionSignup(props) {


    const [inputField] = useState({
        email: "",
        password: "",
    
      });
    
      const[loading, setLoading] = useState(false);
    
      const [errorMessage] = useState({});
    
      // let auth = useAuth();
      const history = useHistory();
    
      const template = [
        {
          name: "email",
          label: "Enter your email here",
          type: "email",
    
        },
        {
          name: "password",
          label: "Set up a password",
          type: "password",
    
        },
      
      ];
    
      const buttonInfo = { label: "Sign Up ", style: "" };
    
      const schema = {
        email: Joi.string().email().required().label("email"),
        password: Joi.string().min(8).required().label("password"),
    
      };
    
      const handleSubmit = async (user) => 
      {
        try {
          const response = await userService.register(user);
          if(response.status===200)
          {
          auth.loginWithJwt(response.data.token);
          // auth.loginWithJwt(response.data["x-auth-token"]);
          history.replace({ pathname: "/welcomehome" });
          }
        } catch (ex) {
          if (ex.response && ex.response.status === 400) {
            setLoading(false)
            message.error(ex.response.data.msg);
            return;
          }
        }
      
      };
    


    return (
        auth.getCurrentUser() ? (
            <Redirect to="/" />
          ) : (

        <div style={{ height: "100vh" }} className='grid grid--1x2'>
        <div style={{ height: "100vh" }} className='flex flexcolumn flexcolumn--allcenter'>
            {/* <img style={{ width: style.imageWidth, height: "auto" }} src={style.logo} alt="Logo" /> */}
            <h2>Create an account</h2>
            <div className='bb'></div>
            <Form
              errorMessage={errorMessage}
              inputField={inputField}
              template={template}
              schema={schema}
              buttonInfo={buttonInfo}
              onSubmit={handleSubmit}
              loading={loading}
              setLoading={setLoading}
            />
        </div>
        <div style={{ background:"black", height: "100vh" }}>
            <div style={{ height: "100vh" }} className='flex flexcolumn flexcolumn--allcenter'>
                {/* <img style={{width:"auto", height:"100px"}} src={style.logo}></img> */}
    
            </div>
        </div>
    </div>
          )

    );
}

export default ExtractionSignup;