import http from "./httpService";
const apiEndpoint = "/knowledgeBase";

export function createProject(obj) {

  return http.post(apiEndpoint, obj);
}

export function getSpecificKB(projectId) {
  return http.get(apiEndpoint + "/project/" + projectId );
}



export function retrain(projectId, text) {
  return http.put(apiEndpoint + "/retrain", {
    projectId,
    text, 
  });
}


export function updateKB(change) {
  return http.patch(apiEndpoint, change)
}

export function addGSheet(projectID, data) {

  
  const params = new URLSearchParams({
    url: data.url,
    templateKey: data.template
  })


  return http.post(`${apiEndpoint}/addGSheet/${projectID}?${params}`);
}

export function getCorpus(projectId) {
  return http.get(apiEndpoint + "/corpusstatus/" + projectId);
}

export function getPortalCorpus(projectId) {
  return http.get(apiEndpoint + "/portalcorpusstatus/" + projectId);
}


export function fetchURLs(obj) {
  return http.post(apiEndpoint + "/fetchurls", obj);
}

export function trainURLs(projectID, obj) {
  return http.post(apiEndpoint + "/urlist/" + projectID, obj);
}

export function deleteKB(projectId, etag) {
  return http.delete(apiEndpoint +  "/" + projectId + "/" + etag);
}

export function portalDeleteKB(projectId, etag) {
  return http.delete(apiEndpoint +  "/portaldeleteKB/" + projectId + "/" + etag);
}

export function deleteParagraph(projectId, key) {
  return http.delete(apiEndpoint + "/deleteParagraph" +  "/" + projectId + "/" + key);
}


export function docsUpload(projectId, formData) {
  return http.post(apiEndpoint + "/upload/" + projectId, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}


export function portaldocsUpload(projectId, formData) {
  return http.post(apiEndpoint + "/portalupload/" + projectId, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function deletefileforPortal(projectId, etag) {
  return http.delete(apiEndpoint + "/portaldeleteFile/" + projectId + "/" + etag);
}

export function deletefile(projectId, etag) {
  return http.delete(apiEndpoint + "/deleteFile/" + projectId + "/" + etag);
}


export function retryExtraction(projectId, etag) {
  return http.post(apiEndpoint + "/retryextraction/" + projectId +"/" + etag);
}


export function getDocumentDownloadLink(projectId, etag) {
  return http.post(apiEndpoint + "/getDocumentDownloadLink/" + projectId +"/" + etag);
}






