import React,{ useState, useEffect } from 'react';
import Navbar from '../../common/Navbar';
import * as webhookstatusService from "../../services/webhookstatusService";
import * as webhookService from "../../services/webhookService";
import { TbCapture, TbRuler2} from "react-icons/tb";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ModalComponent from '../../common/ModalComponent';
import { message } from "antd";
import { Button, Modal } from 'antd';
import { useHistory } from "react-router";
import Joi from "joi-browser";
import Form from "../../common/Form";









function Webhook() {

  const [webhook, SetWebhooks] = useState([])
  const [title, setTitle] = useState("")
  const [data, setData] = useState({})
  const [modalStatus, setModalStatus] = useState(false)
  const [item, setItem]  = useState({});
  const [reload, setReload] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [footerStyle, setFooterStyle] = useState(false)

  const location = useLocation();
  // const companyData = location.state.companyData;




  const { projectId } = useParams();



  useEffect(() => {
    const fetchData = async () => {
      const { data } = await webhookstatusService.getAllWebhooks(projectId);
      const reversedData = data.response.reverse();
      SetWebhooks(reversedData)
    };
    fetchData();
  }, [reload]);



  const getTime =(data)=>{

    if(data===0)
    return "Never"
  
   const epochTime = data;
   const date = new Date(epochTime);
   const dateString = date.toLocaleString(); // or use toUTCString() or toDateString() as needed
   return(dateString);
      }
      

   const getButtonColor =(item)=>{
       return item==="finished" ? "outlinebutton  outlinebutton--green" : "outlinebutton  outlinebutton--red";

   }   



   const handleModal =(item, key) =>{

    setModalStatus(true)
   
    if(key==="capture")
   {
   setTitle("Captured details")
   setData(item.extracted_data)
   setFooterStyle(false)
   return
   }

   setTitle ("Retry webhook")
   setData("Please confirm, do you want to retry the webhook now?")
   setItem(item)
   setFooterStyle(true)
   
   }

   const handleOk = async () => {
    setIsLoading(true)
    try {
      const response = await webhookstatusService.retyWebhooks(item._id, projectId);
    
      if (response.status === 200) {
        setIsLoading(false)
        setModalStatus(false);
        setReload(prevReload => !prevReload);
        message.success("Success");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setIsLoading(false)
    }
    
  };


const handleAdd =()=>{
  // if(companyData.accountType!=="enterprize")
  // return message.info("please contact support")

  // setIsModalOpen(true);
  showModal()


}

/*-------------------- Modal related --------------------------------*/

const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleYes = async (data) => {

    try {
      const response = await webhookService.subscribetoWebhook(projectId, data.eventName, data.endpointUrl);
    
      if (response.status === 201 || response.status === 200) {
        message.success("Success");
        setIsModalOpen(false);
        setLoading(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setIsModalOpen(false);
      message.error("request failed");
      setLoading(false);
    }
  };


  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const [inputField] = useState({
    eventName: "",
    endpointUrl:""

  });

  const[loading, setLoading] = useState(false);


  const [errorMessage] = useState({});


  // let auth = useAuth();
  const history = useHistory();

  const template = [

    {
      name: "eventName",
      label: "Event Type",
      type: "select",
      options: [
        { id: 1, value: "captureEvent", label: "Capture details from conversation" },
      ]
    },

    {
      name: "endpointUrl",
      label: "Endpoint URL",
      type: "text",

    },

  
  ];

  const buttonInfo = { label: "Submit", style: "" };

  const schema = {
    eventName: Joi.string().required().label("event name"),
    endpointUrl: Joi.string().required().label("endpointUrl"),
  };





    return (
        <div style={{width:"100vw"}}>
        <Navbar/>
        <div  className='flexcolumn flexcolumn--allcenter'>
            <div style={{justifyContent: "space-around"}} className='flexrow homebox'>
                <div>
                    <h3>Webhook</h3>
                    <p>Your webhook dashboard monitors everything that's happening via webhooks in your app here.</p>
                </div>
                <div>
                    <button onClick={handleAdd} className='btnn btnn--small'>+ Add</button>
                </div>
            </div>

            <div style={{marginTop:"0px"}} className='homebox'>

            { <table className='tableStyle'>

<thead>
  <tr>
  <th >Webhook ID</th>
  <th> Data</th>  
  <th >Status</th>
  <th >Timestamp</th>
  <th></th>      
  </tr>
</thead>
 <tbody>
{webhook.length> 1 && webhook.map((item, index) => (
    <tr key={index}>
    <td >{item.webhookId}</td> 
    <td  style={{ cursor: 'pointer' }} onClick={() => handleModal(item, "capture")}><TbCapture style={{height:"20px"}}/></td> 
    <td>
  {item.status === "failed" ? (
    <button
      className={getButtonColor(item.status)}
      style={{ cursor: 'pointer' }}
      onClick={() => handleModal(item, "retry")}
    >
<div>
 Retry
</div>
    </button>
  ) : (
    <button className={getButtonColor(item.status)}>{item.status}</button>
  )}
</td>
    <td>{getTime(item.timestamp)}</td>
    {/* <td style={{cursor:"pointer"}}><BsTrash onClick={()=>showRevokeModal(item._id)} style={{ color: 'red' }}/></td>  */}
</tr>


))} 
</tbody>


</table>}

<ModalComponent title={title} modalStatus={modalStatus} data={data} setModalStatus={setModalStatus} handleOk={handleOk} setIsLoading={setIsLoading} isLoading={isLoading} footerStyle={footerStyle} />

<>
  <Modal title="Add a new subscription" open={isModalOpen} footer={null} onCancel={() => setIsModalOpen(false)}>
  <div style={{ display: "table-caption" }}>
      <Form
        errorMessage={errorMessage}
        inputField={inputField}
        template={template}
        schema={schema}
        buttonInfo={buttonInfo}
        onSubmit={handleYes}
        loading={loading}
        setLoading={setLoading}
      />
    </div>
  </Modal>
</>


            </div>
        </div>
        </div>
    );
}

export default Webhook;