import React from 'react';
import Toggle from '../../src/common/Toggle'
import { Tabs } from 'antd';
import TabContainer from '../common/TabContainer';
const { TabPane } = Tabs;


function BlockComponent({title, description, buttonName, filters, setKey, formState1,formState2, formState3,handleModal, onChange, toggle, activeKey, buttonVisitbility, bottomVisibility, toggleVisibility }) {



    return (
        <div className='flexcolumn flexcolumn--allcenter' style={{ width: "100vw"}}>
            <div style={{marginBottom:"0px"}} className='homebox '>
                <div style={{ alignItems: "center" }} className='flex flexrow--rigid wideapart'>
                    <div>
                        <h3>{title}</h3>
                        <p>{description}</p>
                        {toggleVisibility!==false && <div><Toggle onChange={onChange} toggle={toggle}/></div>}
                    </div>
                    <div>
                        {buttonVisitbility!==false && <button onClick={handleModal} className='btnn btnn-primary'>{buttonName}</button>}
                    </div>
                </div>

            </div>
            <TabContainer filters={filters} setKey={setKey} />

            { bottomVisibility!==false && <div style={{height:"auto", marginTop:"0px"}} className='homebox '>
             {activeKey===0 && formState1}
             {activeKey===1 && formState2}
             {activeKey===2 && formState3}
            </div>}


        </div>
    );
}

export default BlockComponent;
