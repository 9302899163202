import React,{useState} from 'react';
import { Button, Modal } from 'antd';
import * as customerService from "../../services/customerService";
import { message } from "antd";


function DeleteModalforMembership({deleteModalOpenForMembers, setDeleteModalOpenForMembers, companyId, projectId, memberid, setReload, reload}) {


      const RevokeOk= async () => {

        try {
          const response = await customerService.deleteCustomer(companyId, projectId, memberid);
          
          if (response.status === 200) {
            setDeleteModalOpenForMembers(false);
            //   setLoading(false);
              message.success("Deleted succesfully");
              setReload(!reload)
          }
    
      } catch (ex) {
          if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
            //   setLoading(false);
              message.error(ex.response.data.msg);
          }
      }
    };
  
  
      const RevokeCancel = () => {
        setDeleteModalOpenForMembers(false);
      };



    return (
        <div>
            {<Modal 
 title="Delete this member"
 visible={deleteModalOpenForMembers}
 onOk={RevokeOk}
 onCancel={RevokeCancel}
 okButtonProps={{
   style: { backgroundColor: "red", color: "white", outline:"none" },
 }}
 okText="Confirm"  
     >
       <p>Please confirm you want to delete all information about this member.
        </p>
</Modal>}  
            
        </div>
    );
}

export default DeleteModalforMembership;