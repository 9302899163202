import React from 'react';
import Send from "../images/arrow.svg";

function ArrowTextArea({ handleSubmit, value, asked, setQuestion, handleChange, handleKeyDown, streamingActivity}) {



  return (
    <div    style={{ position: "relative" }}>
       <textarea
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        className="submit--custom"
        placeholder= {asked === "true" || streamingActivity === true?"Please wait... We're checking if the AI wants to add more data.":"Type your message here..."}    
        style={{ paddingRight: "40px", boxSizing: "border-box" }}
        value={value}
      />
      <div
        style={{
          position: "absolute",
          top: "50%",
          right: "10px",
          cursor: "pointer",
          transform: "translateY(-50%)",
        }}
      >
        <img
          src={Send}
          alt="Send"
          style={{
            width: "24px",
            height: "24px",
            opacity: asked === "true" || streamingActivity === true ? "0.2" : "1",
          }}
          onClick={asked === "true" || streamingActivity === true ? null : handleSubmit}
        />
      </div>
    </div>
  );
}

export default ArrowTextArea;
