import React, { useState, useEffect, useRef } from 'react';
import empty from "../../images/emptyMessage.png"
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import whatsapp from "../../images/whatsapp.svg"
import { SiFastapi } from 'react-icons/si';
import { BsChatRightFill } from "react-icons/bs";
import { TbTextResize } from "react-icons/tb";
import { AiTwotoneThunderbolt} from "react-icons/ai";
import { FcRedo } from "react-icons/fc";
import { Button, Modal } from 'antd';
import * as knowledgeService from "../../services/knowledgebaseService";
import * as conversationAttachmentService from "../../services/conversationAttachmentService";
import { message } from 'antd';
import DOMPurify from 'dompurify'; 
import { TbCapture } from "react-icons/tb";
import * as webhookstatusService from "../../services/webhookstatusService";
import { RiAttachment2 } from "react-icons/ri";
import ReusableDrawerElement from '../../common/ReusableDrawerElement';
import { MdOutlineCloudDownload } from "react-icons/md";













function HomeConversations({ conversation, projectId, handleRefresh}) {


  function renderHTML(htmlString) {
    const sanitizedHTML = DOMPurify.sanitize(htmlString); // Use DOMPurify or another HTML sanitizer library
    return { __html: sanitizedHTML };
  }
  
//This component is for DrawerElement
  const [openDrawer, setOpenDrawer] = useState(false);
  const [attachments, setAttachments]  = useState([]);


  const [chats, setChats] = useState([]);
  const [formState, setFormState] = useState("first");
  const [selection, setSelection] = useState(0)
  const [activeItem, setActiveItem] = useState({})
  const [selectedItem, setSelectedItem] = useState({})

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseOver = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseOut = () => {
    setHoveredIndex(null);
  };

 
  
  const conversationContainerRef = useRef(null);

  useEffect(() => {



     if(conversation && conversation.conversations) 
     {
     setFormState("second")

 

     if((conversation.conversations.length>=1))

      {
     
        setFormState("second")
       setChats(conversation.conversations[0].messages)
       setSelectedItem(conversation.conversations[0])
    }
}
  }, [conversation]);


const getClassNames =(index)=>{
 return (selection===index)? "personContainer personContainer--selected" :"personContainer";

}



  const handleSelection = (item, index) => {

    setChats(item.messages);
    setSelection(index)
    setSelectedItem(item)
    setAttachments([])
  };

  const handleWhatsappNumber =(item)=>{
    const phoneNumber = item.replace(/\D/g, "");
    return phoneNumber;
  }


  

  /*------------ Modal related code ------------------------------------------*/

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  // const [modalText, setModalText] = useState('Content of the modal');
  const showModal = () => {
    setOpen(true);
  };


  const handleOk = async () => {
    setConfirmLoading(true);

    const text = `${activeItem.q} ${activeItem.a}`;

    try {
      const response = await knowledgeService.retrain( projectId, text);
      if(response.status===200)
      {
        setOpen(false)
        message.success("New training instructions added.")
        return;
      }
    } catch (ex) {
      if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
        message.error(ex.response.data.msg);
        return;
      }
    }
    finally {
      setConfirmLoading(false);
    }
  };


  const handleCancel = () => {

    setOpen(false);
  };


  const Handle = (item) => {
    setOpen(true)
    setActiveItem(item)
  }

  const handleRetry = async()=>{

    try {
      const response = await webhookstatusService.manualRetry(selectedItem.project, selectedItem._id)
      if(response.status===200)
      {
        message.success("Extraction complete. Your extraction status will be updated soon.")
        handleRefresh();
      }
    } catch (ex) {
      if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
        message.error(ex.response.data.msg);
        return;
      }
    }

  }


  const handleTextAreaChange = (event) => {
    setActiveItem({ ...activeItem, a: event.target.value });
  };

  const handleDrawer =()=>{
    setOpenDrawer(!openDrawer)
  }

  const [loadingSkeletonDrawser, setLoadingSkeletonDrawser] = useState(true);

  const handleDocumentCall = async ()=>{
    setLoadingSkeletonDrawser(true)


    handleDrawer()

    try {
      const response = await conversationAttachmentService.GetAllAttachments(selectedItem.project, selectedItem._id);
      if(response.status===200)
      {
        setAttachments(response.data);
        setLoadingSkeletonDrawser(false)
        // message.success("Extraction complete. Your extraction status will be updated soon.")
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400 ) {
        setLoadingSkeletonDrawser(false)
        return;
      }
      if(ex.response && ex.response.status >= 401 && ex.response.status < 500)
      message.error(ex.response.data.msg);
      return;
    }





 

  }


const handleAttachmentDownload = async(etag, itemName)=>{


  try {
    const response = await conversationAttachmentService.downloadAttachments(selectedItem.project, selectedItem._id,etag);
    if (response.status === 200) {
      message.success("Your download will start soon");    
      const link = document.createElement('a');
      link.href = response.data.downloadURL;
      link.target = '_blank'; // Open in a new tab
      link.setAttribute('download', itemName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  } catch (ex) {
    if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
      message.error(ex.response.data.msg);
      return;
    }
  } 
}

console.log("chats", chats)



  const getExtractionColor =(item)=>{

    
    if(item.extractionStatus==="default" || item.extractionStatus==="mandatoryFieldsMissing")
  return "capture"
  
  if(item.extractionStatus==="failed")
  return "capture capture--red"

  if(item.extractionStatus==="MandatoryDataPresent")
  return "capture capture--grey"
  
  else return "capture capture--green"
  
    }


if(formState==="first")
return (

    <div className="flexrow" style={{ padding:"20px"}}>
    <div>
        <p><Skeleton width={100} height={20} /></p>
        <p><Skeleton width={100} height={20} /></p>
        <p><Skeleton width={100} height={20} /></p>
        <p><Skeleton width={100} height={20} /></p>
        <p><Skeleton width={100} height={20} /></p>
        <p><Skeleton width={100} height={20} /></p>
        <p><Skeleton width={100} height={20} /></p>
        <p><Skeleton width={100} height={20} /></p>
        <p><Skeleton width={100} height={20} /></p>
        <p><Skeleton width={100} height={20} /></p>
        <p><Skeleton width={100} height={20} /></p>
        <p><Skeleton width={100} height={20} /></p>
        <p><Skeleton width={100} height={20} /></p>
        <p><Skeleton width={100} height={20} /></p>
    
        </div>

        <div style={{marginLeft:"30px"}}>
        <p><Skeleton width={700} height={600} /></p>
        </div>
    </div>

    );

  
  if ((conversation.conversations && conversation.conversations.length>=1)&&(formState==="second"))
  return (
    <div style={{justifyContent: "spaceAround"}} className='flexrow'>
<div style={{ overflowY:"auto", overflowX:"hidden", height:"80vh", borderRight:"1px solid var(--color-line)"}} className='flexcolumn'>

      {conversation && conversation.conversations &&
  conversation.conversations.map((item, index) => {

    return (
    

        <div onClick={() => handleSelection(item, index)} className={getClassNames(index)}>
          <div style={{alignItems: "center"}}className='flexrow'>
          {item.sourceOfConversation === "WHATSAPP" ? <img style={{width:"25px", height:"25px", marginRight:"10px"}} src={whatsapp} /> : item.sourceOfConversation === "API" ? <SiFastapi style={{width:"25px", height:"25px", marginRight:"10px"}}  /> : <BsChatRightFill style={{width:"25px", height:"25px", marginRight:"10px"}}  /> }

          <div>{item.sourceOfConversation === "WHATSAPP" ? handleWhatsappNumber(item.userIdentifier) : `conversation ${conversation.conversations.length - index}`}</div>
          <TbCapture className={getExtractionColor(item)} style={{ marginLeft:"28px", height:"14px"}}/>
          </div>
        </div>
     
    );
  })
}



      </div>
      <div style={{ width:"80%"}}>
        <div style={{ overflow: 'auto', height:"80vh", borderRadius:"10px"}}>
          {/* {selectedItem.extractionStatus==="failed" &&  */}
          <div style={{position: "relative", left: "-200px"}} className='bb'>
          <button onClick={handleRetry} style={{position:"relative", left:"75%", marginBottom:"15px", marginRight:"10px"}} className='button-4'> <TbCapture/> Retry extraction</button>
          <button onClick={handleDocumentCall} style={{position:"relative", left:"75%", marginBottom:"15px"}} className='button-4'> <RiAttachment2 /> View attachments</button>
            </div>
            {/* }  */}
          <div ref={conversationContainerRef}>
            {chats.length >= 1 && chats.map((item, index) => (
              <div key={index} className="conversation">
                {item.q.trim() !== "" && <div className="operator-response">{item.q}</div>}
                <div style={{alignItems: "center", justifyContent: "space-between"}} className='flexrow flexrow--rigid'>
                 {item.a.trim() !== "" &&<div>
                   <div className="user-input" lang="" dangerouslySetInnerHTML={renderHTML(item.a)}></div>
                    <div>
                    <FcRedo
                    style={{
                      width: "50px",
                      height: "20px",
                      opacity: hoveredIndex === index ? "1" : "0.6",
                      cursor: "pointer",
                    }}
                    onMouseOver={() => handleMouseOver(index)}
                    onMouseOut={handleMouseOut}
                    onClick={()=>Handle(item)}
                                   />
                       </div>
                 </div>}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <>
      <ReusableDrawerElement
      setLoadingSkeletonDrawser={setLoadingSkeletonDrawser}
      loadingSkeletonDrawser={loadingSkeletonDrawser}
    title="Attachments"
    openDrawern={openDrawer}
    onClose={() => setOpenDrawer(false)} // Assuming setOpenDrawer is used to control the drawer's open state
    size="default" 
>
{
  attachments && attachments.data && attachments.data.length > 0
    ? <div>{attachments.data.map((item, index) => (<div className='flex flexrow wideapart' style={{background: "#f7f7f7",borderRadius:"15px", padding:"10px 15px", marginBottom:"5px"}} key={index}>{item.filename}<div><MdOutlineCloudDownload onClick={()=>(handleAttachmentDownload(item.eTag,item.filename))}/></div></div>))}</div>
    : <p>No attachments for this conversation</p>
}

</ReusableDrawerElement>


      <Modal
        title="Help the AI write a better answer next time"
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        Please provide an improved response below so that in the future, your suggested answer will carry greater weight for the same question.
        <div className=''>
          <br/>
          <p style={{fontWeight:"600"}}>Question : {activeItem.q}</p>

        </div>
    
        <textarea 
        value={activeItem.a}
        onChange={handleTextAreaChange}
        style={{height:"200px"}} 
        className='input--custom'></textarea >

      </Modal>
    </>
    </div>
  );

  return (

<div style={{height:"60vh"}} className='flexcolumn flexcolumn--allcenter'>
     <img style={{marginBottom:"50px"}} src={empty}></img> 
    <h3 style={{textAlign:"center"}}>No messages yet. <br/> Check back later to see any new conversations.</h3>

</div>

  )
}

export default HomeConversations;
