import React from 'react';



const MenuBar = ({ style, MENU_ITEMS, activeMenu, setActiveMenu, isSuperdash}) => {

    const handleItemClick = (label) => {
        setActiveMenu(label);
    };

    const content = isSuperdash ? (
        <h1 style={{ fontSize: style.imageWidth, fontWeight:"bolder", margin:"5px 30px" }}>Super_</h1> // Adjust style as needed
      ) : (
        <img style={{ width: style.imageWidth }} src={style.logo} className="brandlogo" alt="brand logo" />
      );

       return (
        <div style={style} className="navbar-container">
            <div className="navbar-top">
            {content}
            </div>
            <div className="navbar-links">
                {MENU_ITEMS.map(item => (
                    <div 
                        key={item.label} 
                        className={`link-item ${activeMenu === item.label ? 'active' : ''}`}
                        onClick={() => handleItemClick(item.label)}
                    >
                        {React.createElement(item.icon)}
                        <span>{item.label}</span>
                    </div>
                ))}
            </div>
            <span style={{ fontSize: "11px", fontWeight: "500", position: "absolute", bottom: "30px", left: "50px" }}>AI infrastructure by Superdash</span>
        </div>
    );
}

export default MenuBar;
