import http from "./httpService";
const apiEndpoint = "/attachmentUpload";

export function GetAllAttachments(projectId, conversationId) {
    return http.get(apiEndpoint 
        + "/corpusstatus" + "/" + projectId + "/" +
         conversationId)
}

export function downloadAttachments(projectId, conversationId, etag) {
    return http.post(apiEndpoint + "/getDownloadLink" + "/" + projectId + "/" + conversationId + "/" + etag);
  }


  
