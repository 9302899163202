import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import Joi from "joi-browser";
import Form from "../common/Form";
import * as companyService from "../services/companyService";
import * as metaService from "../services/metaDataService";
import * as profileService from "../services/profileService";
import * as reviewService from "../services/reviewService"
// import Navbar from "../common/Navbar";
import { message } from "antd";
import * as userService from "../services/userService";
// import Configuration from "./Configuration";

function Welcome(props) {
  const [inputField] = useState({
    
    firstName:"",
    lastName:"",
    companyName: "",
    country:""

  });

  const [companyId, setCompanyId] = useState("")

  const[loading, setLoading] = useState(false);

  const[countries, setCountries] = useState([]);



  const [errorMessage] = useState({});

  // let auth = useAuth();
  let history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await metaService.stripeCountries();
      setCountries(data.countries)
    };
    fetchData();
  }, []);

  const template = [
    {
      name: "firstName",
      label: "Enter your first name",
      type: "text",
    },
    {
      name: "lastName",
      label: "Enter your last name",
      type: "text",
    },
    {
      name: "companyName",
      label: "Enter your company Name",
      type: "text",
    },
    {
      name: "country",
      label: "Select your country",
      type: "select",
      options: countries
    },
  ];


  const buttonInfo = { label: "Create account", style: "" };
  // const buttonInfo1 = { label: " Create A", style: "" };


  const schema = {

    firstName: Joi.string().min(2).max(50).required().label("First  Name"),
    lastName: Joi.string().min(2).max(50).required().label("Last Name"),
    companyName: Joi.string().min(3).max(100).required().label("Company Name"),
    country: Joi.string().max(50).required().label("Country"),

  };

  const handleSubmit = async (profile) => {
    try {

      const { data } = await companyService.createCompany(profile);
      message.success("Please wait. Setting up your account.");
  
      const response = await profileService.connectionSetup(data._id, profile);
  
      if (response.status === 200) {
        history.replace({
          // pathname: "/billing/" + "?flow=signup",
          pathname: "/" ,
          state: {
            company: data,
          },
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        message.error(error.response.data.msg);
      } else {
        message.error("Something went wrong. Please try again later.");
      }
      setLoading(false);
    }
  };
  

      // const  [firstName, secondName] = profile.fullName.split(' ', 2);
  

   
      // profile.firstName = firstName;
      // profile.lastName = secondName;


   

   
    
    


    //  console.log(response);

      // const fd = new FormData();
      // fd.append("companyId", data.id);
      // await userService.updateUser(fd);

   
     

    //   history.replace({ pathname: "/onboarding/"+ data._id});
 

 

  // const handleNext = () => {
  //   history.replace({ pathname: "/setimage/" + companyId });
  // };

  function handleDiscord() {
    window.location.href = "https://discord.gg/xJJfYjxcqy";
  }



  return (
    <div style={{ height: "100vh" }} className="flexcolumn flexcolumn--allcenter">
<div style={{margin:"20px 40px"}}className="mobile">  
<h3 style={{fontSize:"18px"}}>We apologize for any inconvenience caused. </h3>
<br/>

<p>Our chat widgets and full screen chatbot options are optimized for mobile devices to ensure a smooth user experience. </p> <p> However, we recommend using a desktop computer for training the chatbot, as you will have access to our entire dashboard and instructions for training the chatbot effectively.
Thank you for your understanding and for being a part of our community. Don't forget to join our Discord server for updates and support </p>
<button onClick={handleDiscord} className="btnn btnn-primary">Discord</button>
<br/>
</div> 
 <div className="flexcolumn bluecontainer desktop">
 <p style={{ padding:" 2rem 4rem", fontSize:"18px"}}>Let's create an account. What's your company name</p>
 <div>
   <Form
                errorMessage={errorMessage}
                inputField={inputField}
                template={template}
                schema={schema}
                buttonInfo={buttonInfo}
                onSubmit={handleSubmit}
                loading={loading}
                setLoading={setLoading}
              />
 </div>
        
        </div>
      </div>
    )



}

export default Welcome;
