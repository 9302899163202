import React, { useState, useEffect } from "react";
import { Drawer, Space, Skeleton } from 'antd';

function ReusableDrawerElement({ title, size, onClose, openDrawern, children, loadingSkeletonDrawser,setLoadingSkeletonDrawser }) {
    

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoadingSkeletonDrawser(false);
        }, 5000);

        // Clean up the timer when the component is unmounted
        return () => clearTimeout(timer);
    }, []);

    return (
        <div>
            <Space>

            </Space>
            <Drawer
                title={title}
                placement="right"
                size={size}
                onClose={onClose}
                open={openDrawern}
                width={450}>
                {loadingSkeletonDrawser? <Skeleton active /> : children} 
            </Drawer>
        </div>
    );
}

export default ReusableDrawerElement;
