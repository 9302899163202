import http from "./httpService";
const apiEndpoint = "/companyKnowledge";


  

  export function companyWideUpload(companyId, formData) {
    return http.post(apiEndpoint + "/upload" + "/" + "company" + "/" + companyId, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }  

  export function updateKB( change) {
    return http.patch(apiEndpoint , change)
  }
  
  export function getAllCompanyData(companyId) {
    return http.get(apiEndpoint + "/companykb/" + companyId);
  }  

  export function getcompanyWideKnowledge(companyId) {
    return http.get(apiEndpoint + "/corpusstatus/" + companyId);
  }  

  export function deletefilefromCompanyKB(companyId, etag) {
    return http.delete(apiEndpoint + "/" +companyId + "/" + etag);
  }

  export function updateCompanyKB(companyId,change) {
    return http.put(apiEndpoint + "/companyParaUpload" + "/" + companyId, change)
  }




  
