import React, { useState } from "react";
import { useHistory } from "react-router";
import { Redirect } from "react-router-dom";
import { message } from "antd";
import Joi from "joi-browser";
import Form from "../common/Form";
import authService from "../services/authService";
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';

function ResetPassword(props) {

    const [inputField] = useState({
        otp:"",
        newPassword: ""
        });

        const[loading, setLoading] = useState(false);

        const history = useHistory();

        const [errorMessage] = useState({});

        const { email } = useParams();


      
        const template = [
          
          {
            name: "otp",
            label: "Enter the OTP you received in your email",
            type: "text",
            
          },
          {
              name: "newPassword",
              label: "Type your new password",
              type: "password",
        
            },
        
        ];
      
        const buttonInfo = { label: "Reset password", style: "" };
      
        const schema = {
          otp: Joi.string().alphanum().required().label("otp"),
          newPassword: Joi.string().min(8).required().label("password"),
      
        };
      
       
       
        const handleReset = async(data)=>{
         
            data.email = email;
         
          {
              try {
                const response = await authService.resetPassword(data);
                if(response.status===200)
                {
                    message.success("Password changed successfully. Login now")
                    history.replace({ pathname: "/login"});
      
                }
              } catch (ex) {
             if (ex.response && ex.response.status >= 400 && ex.response.status < 500 ) {
                  setLoading(false)
                  message.error(ex.response.data.msg);
                  return;
                }
              }
      
        }
        }



    return (
        <div>
                      <div style={{ height: "100vh" }} className="flexcolumn flexcolumn--allcenter">
        <div className="flexcolumn bluecontainer">
       <div style={{textAlign:"center"}}>
        
              </div>
              <Form
                errorMessage={errorMessage}
                inputField={inputField}
                template={template}
                schema={schema}
                buttonInfo={buttonInfo}
                onSubmit={handleReset}
                loading={loading}
                setLoading={setLoading}
              />    
        </div>
        <p className="instruction instruction--med">
               Login page<Link to="/login"> here </Link>   
              </p>
        </div>
      
        </div>
    );
}

export default ResetPassword;