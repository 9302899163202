import React,{useState} from 'react';
import { useHistory } from "react-router";
import { Redirect } from "react-router-dom";
import { message } from "antd";
import Joi from "joi-browser";

import { Link } from "react-router-dom";
import Form from '../../common/Form';

function Whatsapp(props) {

    const [inputField] = useState({
        account:"",
        secret: "",
        token: "",
      });


      const[loading, setLoading] = useState(false);

      const [errorMessage] = useState({});
    
      // let auth = useAuth();
      const history = useHistory();
    
      const template = [
        {
          name: "account",
          label: "Whatsapp account ID",
          type: "text",  
        },
        {
          name: "secret",
          label: "Whatsapp secret",
          type: "password",
    
        },
        {
          name: "token",
          label: "Page access token",
          type: "password",
    
        },
      
      ];
    
      const buttonInfo = { label: "Connect account", style: "" };
    
      const schema = {
        account: Joi.string().required().label("Whatsapp account"),
        secret: Joi.string().required().label("Whatsapp secret"),
        token: Joi.string().required().label("Whatsapp token"),
      };

      const handleSubmit =()=>{

      }

    return (
        <div>
               <div style={{ height: "100vh" }} className="flexcolumn flexcolumn--allcenter">
        <div className="flexcolumn bluecontainer">
       <div style={{textAlign:"center"}}>
              </div>
              <Form
                errorMessage={errorMessage}
                inputField={inputField}
                template={template}
                schema={schema}
                buttonInfo={buttonInfo}
                onSubmit={handleSubmit}
                loading={loading}
                setLoading={setLoading}
              />
  

         
        </div>
      </div>

        </div>
    );
}

export default Whatsapp;