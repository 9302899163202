import React,{useState} from 'react';
// import Stripe from "../images/stripe.svg";
import loadingIcon from "../images/loading.svg";
import { message } from "antd";
import * as paymentService from "../../src/services/PaymentService";
import { useHistory } from "react-router";
import { useParams } from 'react-router-dom';

function StripeConnect(props) {

const [loading, setLoading] = useState(false);    


const { companyId, accountId} = useParams();



let history = useHistory();

const handleLastSession = async ()=>{

  if (accountId) {
    history.push({
      pathname: "/stripeconnectreauth/" + companyId + "/" + accountId,
      // state: {
      //   listing: listingId,
      // },
    });
  } else {
    history.push({
      pathname: "/stripeconnectreauth/" + companyId,
      // Additional code or parameters can go here if needed
    });
  }
  
}




// const handleConnect =async ()=>{
//     setLoading(true)

//     try {
//         const response= await paymentService.startOnboarding(listingId);
    
//         if(response.status===200)
//         {
//             window.location.href = response.data.url
//         }
        
//       } catch (ex) {
//         if (ex.response && ex.response.status === 400 && ex.response.status < 500 ) {
//           message.error(ex.response.data);
//           setLoading(false)
//           return;
//         }
//       }
  
    // }


    return (
        <div className='flexcolumn flexcolumn--allcenter' style={{ height: "100vh" }}>
      
<div style={{background:"white", padding:"50px"}}className='containerbox containerbox--fixed'>
    {/* <img style={{width:"30px", marginBottom:"20px"}} src={Stripe}></img> */}
<h2>Integrate Stripe Connect and Add a Paywall to Your AI Chatbot</h2>
<hr style={{marginBottom:"20px"}}/>
<p>Receive payments in your currency and into your local bank account</p>
<button onClick={handleLastSession} className='btnn btnn-primary'>

{loading?<span>
  Please wait
  <img style={{marginLeft:"10px", width:"48px"}}src={loadingIcon}></img>
  
</span>:"Connect account"}

</button>
{/* {accountId!="noaccount" && <button style={{marginLeft:"15px"}} className='btnn btnn-primary--black'>Continue last session</button>} */}

</div>
       
        </div>
    );
}

export default StripeConnect;