import http from "./httpService";
const apiEndpoint = "/companysettings";

export function allCompanyStyles() {
    return http.get(apiEndpoint)
  }

  export function allCompanyStylesForOwner(companyId) {
    return http.get(apiEndpoint + "/" + companyId)
  }



  export function saveCompanySettings(companyId, req) {
    return http.post(apiEndpoint + "/" + companyId, req);
  }


  export function updateCompanySettings(companyId, req) {
    return http.put(apiEndpoint + "/" + companyId, req);
  }
  
  