import { message } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from "react-router";
import InputWithDropdown from '../../common/InputWithDropdown';
import SubmitInput from '../../common/SubmitInput';
import ExtractForm from '../../components/ExtractForm';
import FileUpload from '../FileUpload';
import GSheetLink from './GSheetLink';
import LongText from './LongText';
import Site from '../../pages/FineTuning/Site';
import Navbar from '../../common/Navbar';


function TrainingData ({passedData}) {

  const [documentLink, setDocumentLink] = useState("");

  const history = useHistory();
  const location = useLocation();
  
  
  const urlPath = history.location.pathname;
  const urlSearch = history.location.search;




    const docLink =()=>{
        
if(documentLink==="")
      return "https://drive.google.com/file/d/1hJXS7HP3XnyQx5S1GvfZBZ3RDrVwFy4b8HF8qu7mMsk/preview"

else {

  const regex = /\/d\/([^/]+)\/edit/;
  const match = documentLink.match(regex);
  
  if (match !== null) {
    const result = match[1];
    return  `https://drive.google.com/file/d/${result}/preview`
  } else {
    message.error("Please clear this field and then copy the link as per the instructions shared in the document.");
  } 
      }


    } 


    const handleInsert =()=>{

    }

    const iframeRef = useRef(null);


    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "c") {
        const iframe = document.getElementsByTagName("iframe")[0];
        const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
        const selectedText = iframeDoc.getSelection().toString();
        navigator.clipboard.writeText(selectedText);
      }
    };


    
    
    // useEffect(() => {
    //   const handleCopy = (event) => {
    //     if ((event.ctrlKey || event.metaKey) && event.code === 'KeyC') {
    //       iframeRef.current.contentWindow.document.execCommand('copy');
    //     }
    //   };
  
    //   iframeRef.current.contentWindow.document.addEventListener('keydown', handleCopy);
  
    //   return () => {
    //     iframeRef.current.contentWindow.document.removeEventListener('keydown', handleCopy);
    //   };
    // }, []);
    

    return (

      <div>
        <Navbar/>
            <div style={{width:"100vw",justifyContent: "space-around"}} className="flexrow">
                {(urlPath.includes("qna")||(urlSearch.includes("qna"))) && <div style={{overflow:"auto"}}>
                   <ExtractForm passedData={location.state.passedData}/>
               </div>}
               {(urlPath.includes("lform")||(urlSearch.includes("lform"))) && <div style={{overflow:"auto"}}>
                   <LongText passedData={location.state.passedData}/>
               </div>}
               {(urlPath.includes("gsheetlink")||(urlSearch.includes("gsheetlink"))) && <div style={{overflow:"auto"}}>
                   <GSheetLink passedData={location.state.passedData}/>
               </div>}
               {(urlPath.includes("fileupload")||(urlSearch.includes("fileupload"))) && <div style={{overflow:"auto"}}>
                   <FileUpload passedData={location.state.passedData}/>
               </div>}
               {(urlPath.includes("url")||(urlSearch.includes("url"))) && <div style={{overflow:"auto"}}>
                   <Site passedData={location.state.passedData}/>
               </div>}
        
        
        
        {/*
               {(urlPath.includes("gdocs")||(urlSearch.includes("gdocs"))) && <div>
                  <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <SubmitInput onChange={(e) => setDocumentLink(e.target.value)} onClick={handleInsert} />
                  </div>
                  <iframe title="document loader" style={{width:"40vw", height:"100vh"}} src={docLink()} frameBorder="0" ref={iframeRef} />
              </div>} */}
              {(urlPath.includes("qna")||(urlSearch.includes("qna"))) && <div style={{ marginTop: "30px", marginBottom: "10px" }} className='instructionbox'>
                <p>In this block, you can input both a question and its corresponding answer without specifying which is which. Please limit each block to only one question and its corresponding answer.</p>
                <br/>
                </div>
              }
        
        
            </div>
      </div>
    
   
    );
}

export default TrainingData ;