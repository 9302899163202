import React, { useEffect, useState } from 'react';
import * as companySettingService from "../../services/companySettingService";
import Form from "../../common/Form";
import Joi from "joi-browser";
import { message } from "antd";
import auth from "../../services/portalAuthService";
import { Link, Redirect } from "react-router-dom";

function CPLogin(props) {

const [style, setStyle] = useState({})


const [inputField] = useState({
    email: "",
    password: "",
  });

  const [errorMessage] = useState({});
  const[loading, setLoading] = useState(false);

  // let history = useHistory();
  // let location = useLocation();
  // let auth = useAuth();

  const template = [
    {
      name: "email",
      label: "Enter your email here",
      type: "email",
      style: "light",
    },
    {
      name: "password",
      label: "Enter your password here",
      type: "password",
      style: "light",
    },
  ];

  const buttonInfo = { label: "Login ", style: "" };

  const schema = {
    email: Joi.string().email().required().label("email"),
    password: Joi.string().required().label("password"),
  };



  const handleSubmit = async ({ email, password }) => {
    try {
     await auth.login(email, password);
      window.location = "/";
    } catch (ex) {
        if (ex.response && ex.response.status >= 400 && ex.response.status < 500 ) {
        setLoading(false)
        message.error(ex.response.data.msg);
      }
    }
  };




    useEffect(() => {
        const fetchData = async () => {
       
          const value = await companySettingService.allCompanyStyles();
          setStyle(value.data.design)
        };
        fetchData();
    }, []);



    return (
        auth.getCurrentUser() ? (
            <Redirect to="/" />
          ) : (

        <div style={{ height: "100vh" }} className='grid grid--1x2'>
        <div style={{ height: "100vh" }} className='flex flexcolumn flexcolumn--allcenter'>
            {/* <img style={{ width: style.imageWidth, height: "auto" }} src={style.logo} alt="Logo" /> */}
            <h2>Welcome back</h2>
            <div className='bb'></div>
            <Form
              errorMessage={errorMessage}
              inputField={inputField}
              template={template}
              schema={schema}
              buttonInfo={buttonInfo}
              onSubmit={handleSubmit}
              loading={loading}
              setLoading={setLoading}
            />
        </div>
        <div style={{ background: style.brandColor, height: "100vh" }}>
            <div style={{ height: "100vh" }} className='flex flexcolumn flexcolumn--allcenter'>
                <img style={{width:"auto", height:"100px"}} src={style.logo}></img>
    
            </div>
        </div>
    </div>
          )

    );
}

export default CPLogin;