import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";
import { useHistory, useLocation } from "react-router";
// import refresh from "../animation/paymentRefresh.json";
import { BiArrowBack } from "react-icons/bi";
import { useParams } from 'react-router-dom';
import * as paymentService from "../../src/services/PaymentService";
import { message } from "antd";


function StripeConnectionReAuth (props) {


  const container = useRef(null);

  const { companyId, accountId } = useParams();



  let history = useHistory();

//   useEffect(() => {
//     lottie.loadAnimation({
//       container: container.current,
//       renderer: "svg",
//       loop: true,
//       autoplay: true,
//       animationData: refresh,
//     });
//   }, []);

  

  useEffect(() => {

    const fetchData = async () => {
    try {
      const response= await paymentService.startOnboarding(companyId, accountId); 
      if(response.status===200)
      {
          window.location.href = response.data.url
      }
      
    } catch (ex) {
      if (ex.response && ex.response.status === 400 && ex.response.status < 500 ) {
        message.error(ex.response.data);
        return;
      }
    }
  }

  fetchData();

  }, []);


  const handleClick =()=>{
    history.goBack();
  }


  return (
    <div style={{ height: "100vh" }} className="flexcolumn flexcolumn--allcenter">
      <div
        style={{ height: "300px" }}
        className="container"
        ref={container}
      ></div>
      <h2 style={{ textAlign: "center", margin: "30px" }}>
      Please wait... We're redirecting you to Stripe Connect.
      </h2>
      <button onClick={handleClick} className="btnn btnn-primary">
        <BiArrowBack style={{marginRight:"5px"}}/>
        Go back
        </button>
      {/* <Link to={() => history.goBack()}>
        {" "}
        <BiArrowBack /> Go back
      </Link> */}
    </div>
  );
}

export default StripeConnectionReAuth;
