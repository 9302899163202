import React from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload, Button } from 'antd';

const { Dragger } = Upload;

// Check if the URL contains 'extraction'
const isExtractionURL = window.location.hostname.includes('extraction');

// Define allowed file types
let allowedFileTypes = [
    'application/pdf', 
    'text/plain', 
    'application/msword', 
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];

// If the URL contains 'extraction', add image file types
if (isExtractionURL) {
    allowedFileTypes = [
        ...allowedFileTypes,
        'image/jpeg', 
        'image/png', 
        'image/jpg'
    ];
}

const props = {
    name: 'file',
    multiple: true,
    accept: allowedFileTypes.join(', '),
    // Set beforeUpload to false to prevent automatic upload
    beforeUpload: () => false,
    onDrop(e) {
        // Handle file drop
    },
};

const UploadMultiple = ({ onChange }) => (
    <Dragger {...props} showUploadList={false} onChange={onChange}>
        <p className="ant-upload-drag-icon">
            <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">
            Support for a single or bulk upload. Allowed file types: TXT, DOC, DOCX, PDF, JPEG, PNG, JPG. The maximum allowed file size is 100 MB.
        </p>
    </Dragger>
);

export default UploadMultiple;

