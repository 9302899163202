import React,{useState, useEffect} from 'react';
import * as extractionService from "../../services/extractionStatus";
import * as knowledgebaseService from "../../services/knowledgebaseService";

import { useLocation } from "react-router";

import { message, Modal } from "antd";


import { BiDownload } from "react-icons/bi";
import ModalComponent from '../../common/ModalComponent';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import NavigationBar from '../../common/NavigationBar';
import { FiClock } from "react-icons/fi";
import FromToDateSelection from '../../common/FromToDateSelection';
import CsvIcon from '../../images/csv.svg';
import { MdOutlineMarkChatUnread } from "react-icons/md"
import ChatComponent from '../../components/ChatComponent';



function CPjobs({ project: propProject, isSuperdash, companyId,  setActiveProject, activeProject, projects }) {


    const [extractedData, setExtractedData] = useState([])
    const [footerStyle, setFooterStyle] = useState(false)
    const [modalStatus, setModalStatus] = useState(false)
    const [data, setData] = useState({})
    const [modalTitle, setModalTitle] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [activeExtractionChat, setActiveExtractionChat] = useState("")
    const [title, setTitle] = useState("Talk to your documents")  
    const [extractionId, setExtractionId] = useState("")


    let projectType, assistantEnabled;

    if (projects[activeProject] && projects[activeProject].projectSettings) {
        if (projects[activeProject].projectSettings.extractionPipelineData) {
            projectType = projects[activeProject].projectSettings.extractionPipelineData.extractionPipelineType;
            assistantEnabled = projects[activeProject].projectSettings.extractionPipelineData.assistantEnabled;
        }
    }
    


    const [dates, setDates] = useState({ startDate: null, endDate: null });

    let location = useLocation();
  
    let project = propProject || (location.state && location.state.project);



    useEffect(() => {
      // Define the fetchData function inside the useEffect
      const fetchData = async () => {
        setLoading(true);
    
        let data;
    
        try {
          // Use a ternary operator to choose the service method based on isSuperdash
          const serviceMethod = isSuperdash
            ? extractionService.GetAllExtractionStatus
            : extractionService.GetAllExtractionStatusForPortal;
    
          // Call the chosen method
          const response = await serviceMethod(project);
          data = response.data;
        } catch (error) {
          console.error('Error fetching extraction status', error);
          setLoading(false);
          return; 
        }
        const reversedData = data.reverse();
        setExtractedData(reversedData);
        setLoading(false);
      };
    
      fetchData();
    }, [project, isSuperdash]);






    const handleDownload = (fileName, finalOutput, extractionPipelineStatus) => {

      if(extractionPipelineStatus!=="finished")
      return message.error("Please wait. We're still processing your document")

      // Create a link element
      const link = document.createElement('a');
      link.href = finalOutput;
      link.target = '_blank';
      link.download = fileName; // Set the default file name for the download
  
      // Append the link to the body
      document.body.appendChild(link);
  
      // Trigger the click event
      link.click();
  
      // Remove the link from the body
      document.body.removeChild(link);
    };

      const handleModal =(item, key) =>{

        setModalStatus(true)
       
        if(key==="capture")
       {
       setModalTitle("Captured details")
       setData(item.extractedData)
       setFooterStyle(false)
       return
       } 
       }

       const getButtonColor =(item)=>{
     
  

      return item==="finished"? "outlinebutton  outlinebutton--green" : item=== "failed"? "outlinebutton outlinebutton--red" :"outlinebutton  outlinebutton--grey";

  }   

  const handleChatWithExtraction =(item)=>{

    if(item.extractionPipelineStatus!=="finished")
    return message.error("Please wait. We're still processing your document")


    setExtractionId(item._id)
    setOpen(!open)
        
  }
  

//TODO: Replace retryExtraction for Admins
  const handleRetry = async (etag)=>{
    
    try {
        const response = await knowledgebaseService.retryExtraction(project, etag);
        if (response.status === 200) {
         message.success("Retry succesful")
        }
      } catch (ex) {
        if (ex.response && ex.response.status >= 400 && ex.response.status<500 ) {
          message.error(ex.response.data.msg);
          return;
        }
      }
}

const getClassNames =(item, key)=>{


if((item!=="finished") && key==="download")
return "opacityless"

if((item!=="finished") && key==="chat" && projectType==="RFP" )
return "opacityless"


return ""
}

const handleCSVDownload = async () => {
  if (!dates.startDate || !dates.endDate) {
    return message.info("Please select dates to proceed");
  }

  try {
    const response = await extractionService.CsvDownload(project, dates.startDate, dates.endDate);
    if (response.status === 200 && response.data.pdfLink) {
      message.success("Your download will start soon");
      const link = document.createElement('a');
      link.href = response.data.pdfLink;
      link.setAttribute('download', `${dates.startDate}_${dates.endDate}.pdf`); // The name of the file you want to save as
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } else {
      message.error("PDF link is not available");
    }
  } catch (ex) {
    if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
      message.error(ex.response.data.msg);
      return;
    }
    // Handle other kinds of errors
  }
};




 

    return (

      <div style={{overflow:"auto", height:"auto", marginLeft: "22vw" }}>
           <NavigationBar companyId={companyId} setActiveProject={setActiveProject} activeProject={activeProject} projects={projects}  />
        <div style={{ width: "80vw" }}>
        {loading ? (
           <SkeletonTheme color="#b5b5b5" highlightColor="#d6d6d6">
                <div style={{height:"auto", overflow:"auto"}} className='flexcolumn flexcolumn--allcenter'>
                    <table style={{ margin: "50px" }}>
                        <thead>
                            <tr>
                                <th><Skeleton width={100} /></th>
                                <th><Skeleton width={200} /></th>
                                <th><Skeleton width={100} /></th>
                                <th><Skeleton width={100} /></th>
                                <th><Skeleton width={100} /></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><Skeleton width={100} /></td>
                                <td><Skeleton width={200} /></td>
                                <td><Skeleton width={100} /></td>
                                <td><Skeleton width={100} /></td>
                                <td><Skeleton width={100} /></td>
                            </tr>
                            <tr>
                                <td><Skeleton width={100} /></td>
                                <td><Skeleton width={200} /></td>
                                <td><Skeleton width={100} /></td>
                                <td><Skeleton width={100} /></td>
                                <td><Skeleton width={100} /></td>
                            </tr>
                            <tr>
                                <td><Skeleton width={100} /></td>
                                <td><Skeleton width={200} /></td>
                                <td><Skeleton width={100} /></td>
                                <td><Skeleton width={100} /></td>
                                <td><Skeleton width={100} /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </SkeletonTheme>
        ) : (

              <div style={{margin: "20px 100px"}}>
                <div className="flex wideapart">
                  <FromToDateSelection setDates={setDates} />
                  <div>
                  {extractedData[0] && extractedData[0].sheetURL && extractedData[0].sheetURL!==""  && (
  <a href={extractedData[0].sheetURL?extractedData[0].sheetURL:""} target="_blank" rel="noopener noreferrer">
    <button className='btnn btnn--small btnn-transparent'>Gsheet link</button>
  </a>
)}
                    <button 
                    onClick={handleCSVDownload}
                    className='btnn btnn--small' 
                     style={{ 
                     opacity: (dates.startDate && dates.endDate) ? 1 : 0.3 
                      }}
                     >
               <img style={{height:"20px", marginRight:"10px"}} src={CsvIcon} alt="csv" />
              Download
              </button>

                  </div>
                </div>
                <table style={{ background: "white"}} className='newTableStyle'>
                <thead>
                  <tr>
                  {/* <th>ID</th> */}
                  <th>File Name</th>
                  {/* <th>Extracted data</th> */}
                  <th>ETA</th>
                  <th>Status</th>
                  <th>Download</th>
                  {assistantEnabled===true && <th>Chat</th>}
                  </tr>
                </thead>
                <tbody>
                {extractedData.length >= 1 && extractedData.map((item, index) => (
                  <tr key={index}>
                    {/* <td>{item._id}</td>  */}
                    <td style={{fontWeight:"500"}}>{item.fileName}</td>
                    {/* <td style={{ cursor: 'pointer' }} onClick={() => handleModal(item, "capture")}>
                      <TbCapture style={{height:"20px"}}/>
                    </td> */}
                
                    <td>
                      <FiClock style={{marginRight:"8px"}}/>
                      <span style={{fontWeight:"500"}}>{item.ETA}</span>
                    </td>
                    <td>
                      <button className={getButtonColor(item.extractionPipelineStatus)}>
                        {item.extractionPipelineStatus}
                      </button>
                    </td>
                    {/* Wrap the SVG in a table cell */}
                    {
                      <td className={getClassNames(item.extractionPipelineStatus, "download")}>
                        <BiDownload style={{ cursor: "pointer", margin: "30px" }} onClick={() => handleDownload(item.fileName, item.finalOutput, item.extractionPipelineStatus)} />
                      </td>
                    }

                  {assistantEnabled===true && <td  className={getClassNames(item.extractionPipelineStatus,"chat")} onClick={()=>handleChatWithExtraction(item)} style={{cursor:"pointer"}}>
                   <MdOutlineMarkChatUnread />
                   </td>}



                    {/* Wrap the SVG in a table cell */}
                
                    {/* <td>
                      <AiFillPlayCircle style={{cursor:"pointer"}} onClick={() => handleRetry(item.etag)} />
                    </td> */}
                  </tr>
                ))}
                            </tbody>
                </table>
              </div>
            )
        }
          </div>
          <div>
             
          <ModalComponent title={title} modalStatus={modalStatus} data={data} setModalStatus={setModalStatus}  setIsLoading={setIsLoading} isLoading={isLoading} footerStyle={footerStyle} />
          </div>
     <ChatComponent open={open} setOpen={setOpen} title={title} activeExtractionChat={activeExtractionChat} projectId={project} extractionId={extractionId}/>
      </div>
    );
}

export default CPjobs;