import { Button, Modal } from 'antd';
import { useEffect, useState } from 'react';
import CopyInput from './CopyInput';
import ReactJsonPretty from 'react-json-pretty';



const ModalComponent = ({title, modalStatus, data, setModalStatus, handleOk, setIsLoading, isLoading, footerStyle}) => {

  const [isModalOpen, setIsModalOpen] = useState(modalStatus);


  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setModalStatus(false);
  };

    // Define the footer content conditionally based on footerStyle
    const modalFooter = footerStyle ? (
      [
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="ok" type="primary" onClick={handleOk} loading={isLoading}>
          OK
        </Button>,
      ]
    ) : null;


  return (
    <>
    <Modal
      title={title}
      open={modalStatus}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={modalFooter} // Use the conditional footer here
    >
      <ReactJsonPretty json={data} />
    </Modal>
  </>
  );
};
export default ModalComponent;