import { Route, Switch, Redirect } from "react-router-dom";
import Login from './pages/Login';
import Logout from './pages/Logout';
import React from "react";
import ProtectedRoute from "./common/ProtectedRoute";
import ExtractionHome from "./pages/ExtractionApp/ExtractionHome";
import config from "./config.json";
import CS from "./pages/CompanySettings/CS";
import SignUp from "./pages/Signup";
import ExtractionSignup from "./pages/ExtractionApp/ExtractionSignup";
import ExtractionWelcome from "./pages/ExtractionApp/ExtractionWelcome";
import ExtractionOnboarding from "./pages/ExtractionApp/ExtractionOnboarding";
import ExtractionBilling from "./pages/ExtractionApp/ExtractionBilling";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ExtractionTemplateSelection from "./pages/ExtractionApp/ExtractionTemplateSelection";
import TagManager from 'react-gtm-module';
import ExtractionRFP from "./pages/ExtractionApp/ExtractionRFP";
import ExtractionCompanyWideUpload from "./pages/ExtractionApp/ExtractionCompanyWideUpload";
import ExtractionPaymentSuccess from "./pages/ExtractionApp/ExtractionPaymentSuccess";
import ExtractionCopyText from "./pages/ExtractionApp/ExtractionCopyText";
import ExtractionSearchCompanyKB from "./pages/ExtractionApp/ExtractionSearchCompanyKB";

const StripePublicKey = config.StripePublicKey;

const stripePromise = loadStripe(StripePublicKey);


 const tagManagerArgs = {
   gtmId: 'GTM-WQSVGK5'
 };
TagManager.initialize(tagManagerArgs);


function ExtractionApp(props) {
    return (
        <div style={{ backgroundColor: '#f0f1f5' }}>
                 <Elements stripe={stripePromise}>
        <Switch>
            <Route path="/signup" component={ExtractionSignup} /> 
            <Route path="/login" component={Login} /> 
            <Route path="/logout" component={Logout} />  
            <ProtectedRoute path="/welcomehome" component={ExtractionWelcome} />
            <ProtectedRoute path="/extraction-onboarding/:companyId" component={ExtractionOnboarding} />
            <ProtectedRoute path="/extraction-template/:companyId/:projectId" component={ExtractionTemplateSelection} />
            <ProtectedRoute path="/extraction-billing" component={ExtractionBilling} />
            <ProtectedRoute path="/success/:sessionId?" component={ExtractionPaymentSuccess} />
            <ProtectedRoute path="/cancelled" component={ExtractionPaymentSuccess} />
            
            <ProtectedRoute path="/internal/company-kb-text/:companyId" component={ExtractionCopyText} />
            <ProtectedRoute path="/internal/company-kb-search/:companyId" component={ExtractionSearchCompanyKB} />
    
            <ProtectedRoute path="/companywideupload/:companyId" component={ExtractionCompanyWideUpload}/> 
            <ProtectedRoute path="/extraction-rfp-template/:companyId" component={ExtractionRFP}/> 


            <ProtectedRoute path="/companysettings/:companyId" component={CS} />
            <ProtectedRoute path="/" component={ExtractionHome} />
            <Redirect to="/login" />
        </Switch>
        </Elements>
    </div>
    );
}

export default ExtractionApp;