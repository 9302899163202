import React,{useEffect, useState} from 'react';
import * as companyKnowledgebaseService from "../../services/companyKnowledgebase";
import { useParams } from 'react-router-dom';
import { useLocation } from "react-router";
import { MdEdit } from "react-icons/md";
import { FcOk, FcHighPriority, FcAnswers } from "react-icons/fc";
import { useHistory } from "react-router";
import { Button, message, Modal, Tooltip, Empty } from 'antd';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import upload from '../../images/upload.svg';
import spreadsheet from '../../images/spreadsheet.svg';
import empty from '../../images/empty.svg';
import url from '../../images/url.svg';
import { BiRefresh } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import NavigationBar from '../../common/NavigationBar';
import { FcInspection } from "react-icons/fc";

function CSGlobalInfo(props) {

    const history = useHistory();

    const {companyId} = useParams();
    const [corpus, setCorpus] = useState([])
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [etag, setEtag] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false)


    const [reload, setReload] = useState(false)

    const getType = (item) => {
        if (item === "url_list")
            return <Tooltip title="url"><img style={{ width: "25px" }} src={url} alt="url"></img></Tooltip>;
        if (item === "google_sheet")
            return <Tooltip title="google sheets"><img style={{ width: "25px" }} src={spreadsheet} alt="google sheets"></img></Tooltip>;
        return <Tooltip title="file upload"><img style={{ width: "25px" }} src={upload} alt="file upload"></img></Tooltip>;
    };

    useEffect(() => {

        {
         const fetchData = async () => {
          const {data} = await companyKnowledgebaseService.getcompanyWideKnowledge(companyId);
          setCorpus(data.data)
          setLoading(true)
        //   setCompanyData(data)
        };
         fetchData();
        }
        }, [reload]);

 
  
          
          const RevokeCancel = () => {
            setDeleteModalOpen(false);
          };
  
          const showDeleteModal =(etag)=>{
            setDeleteModalOpen(true);
            setEtag(etag);
          }
          
           

        const showRevokeModal =(ETAG)=>{
            setDeleteModalOpen(true);
            setEtag(ETAG);
          }
    
      
    
      
    
          const RevokeOk = async () => {
            try {
              const response = await companyKnowledgebaseService.deletefilefromCompanyKB(companyId, etag);
              if (response.status === 200) {
                 setReload(!reload)
                setDeleteModalOpen(false);
              }
            } catch (ex) {
              if (ex.response && ex.response.status >= 400 && ex.response.status<500 ) {
          
                message.error(ex.response.data.msg);
                setDeleteModalOpen(false);
                return;
              }
            }
          };
          


        const handleUpload =()=>{
            return history.push(`/companywideupload/${companyId}`);

            // /companywideupload/:companyId


        }

        if(!loading)
        return (
            <div>
                 <div className=' flex flexrow wideapart horizontal--center bb'>
            <div >
               <h3>Company wide info</h3>
               <p>This is a great place to upload information that will be accessible to all projects within your company.</p>
                        </div>
        </div>
            <SkeletonTheme color="#b5b5b5" highlightColor="#d6d6d6">
                 <div style={{height:"100vh"}}>
                     <table>
                         <thead>
                             <tr>
                                 <th><Skeleton width={100} /></th>
                                 <th><Skeleton width={200} /></th>
                                 <th><Skeleton width={100} /></th>
                                 <th><Skeleton width={100} /></th>
                                 <th><Skeleton width={100} /></th>
                             </tr>
                         </thead>
                         <tbody>
                             <tr>
                                 <td><Skeleton width={100} /></td>
                                 <td><Skeleton width={200} /></td>
                                 <td><Skeleton width={100} /></td>
                                 <td><Skeleton width={100} /></td>
                                 <td><Skeleton width={100} /></td>
                             </tr>
                             <tr>
                                 <td><Skeleton width={100} /></td>
                                 <td><Skeleton width={200} /></td>
                                 <td><Skeleton width={100} /></td>
                                 <td><Skeleton width={100} /></td>
                                 <td><Skeleton width={100} /></td>
                             </tr>
                             <tr>
                                 <td><Skeleton width={100} /></td>
                                 <td><Skeleton width={200} /></td>
                                 <td><Skeleton width={100} /></td>
                                 <td><Skeleton width={100} /></td>
                                 <td><Skeleton width={100} /></td>
                             </tr>
                         </tbody>
                     </table>
                 </div>
             </SkeletonTheme>
        </div>
    )

    if(loading && corpus && corpus.length === 0)
    return (
        <div style={{height:"auto"}} >
        <div className=' flex flexrow wideapart horizontal--center bb'>
            <div >
               <h3>Company wide info</h3>
               <p>This is a great place to upload information that will be accessible to all projects within your company.</p>
                        </div>
                        <div onClick={handleUpload}><button className='btnn btnn--small'>Add documents</button></div>
        </div>
        <div style={{textAlign:"center"}}>
    <img style={{width:"50px", marginTop:"10%"}} src={empty}></img>
    <h3>This space is empty. <br/>Please use upload section to add more files</h3>
                                  </div>
                                  </div>
                                  )

                                  else return (  <div style={{height:"auto"}} >
                                  <div className=' flex flexrow wideapart horizontal--center bb'>
                                      <div >
                                         <h3>Company wide info</h3>
                                         <p>This is a great place to upload information that will be accessible to all projects within your company.</p>
                                                  </div>
                                                  <div onClick={handleUpload}><button className='btnn btnn--small'>Add documents</button></div>
                                  </div>



            <div>
   
                        <table style={{ margin: "50px 20px", background: "white", width:"800px" }} className='newTableStyle'>
                                <thead>
                                    <tr>
                                        {/* <th>TYPE</th> */}
                                        <th>FILE NAME</th>
                                        <th>STATUS</th>
                                        <th>DELETE</th>
                                        {/* <th>RUN</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {corpus.map((item, index) => (
                                        <tr key={index}>
                                            {/* <td>{getType(item.type)}</td> */}
                                            <td>{item.type !== "s3" ? item.location : item.filename}</td>
                                            <td style={{ width: "10px" }}>
                                                {item.state === "finished" ?
                                                    <Tooltip title="finished">
                                                        <FcOk size={20} style={{ width: "25%" }} />
                                                    </Tooltip> :
                                                    item.state === "failed" ?
                                                        <Tooltip title="failed">
                                                            <FcHighPriority size={20} style={{ width: "25%" }} />
                                                        </Tooltip> :
                                                        <Tooltip title="submitted">
                                                            <FcInspection size={30} style={{ width: "35%" }} />
                                                        </Tooltip>
                                                }
                                            </td>
                                            <td>
                                                <RiDeleteBin6Line onClick={()=>showRevokeModal(item.eTag)} style={{ cursor: "pointer", color: "red", opacity: "0.8" }} />
                                            </td>
                
                                        </tr>
                                    ))}
                                </tbody>
                                {<Modal
                   title="Delete File"
                   visible={deleteModalOpen}
                   onOk={RevokeOk}
                   onCancel={RevokeCancel}
                   okButtonProps={{
                     style: { backgroundColor: "red", color: "white", outline:"none" },
                   }}
                   okText="Delete permantly"
                       >
                <p>
                
                    Removing this file will halt any ongoing pipeline that is currently using it. If, however, the processing of this document has already concluded, the existing workflow will remain unaffected.
                    <br/></p>
                
                
                </Modal>}
                            </table>
              
            </div>

</div> 



    );
}

export default CSGlobalInfo;