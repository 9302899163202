import http from "./httpService";
const apiEndpoint = "/clientportal";



export function getPortalDetails(projectId) {
  return http.get(apiEndpoint + "/" + projectId )
}

export function enableClientPortal (companyId,projectId, req) {
    return http.post(apiEndpoint + "/"+ companyId + "/" + projectId, req);
}

export function editClientPortal (companyId, projectId, req) {
    return http.put(apiEndpoint + "/"+ companyId + "/" + projectId, req);
}


