import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import ClientPortal from "./ClientPortal";
import ExtractionApp from "./ExtractionApp";

const getSubdomain = () => {
  const parts = window.location.hostname.split('.');
  if (parts.length >= 2) {
      return parts[0];
  }
  return null;
};

const subdomain = getSubdomain();
const isCorrectDomain = window.location.hostname.includes(process.env.REACT_APP_DOMAIN);



ReactDOM.render(
  <BrowserRouter>
    {!isCorrectDomain ? <ClientPortal /> : 
      <>
         {/* <App/>    */}
        {/* <ExtractionApp/>   */}
    
       
        { subdomain === 'app' && <App />}
        { subdomain === 'extraction' && <ExtractionApp/>}  


        
      </>
    }
  </BrowserRouter>,
  document.getElementById("root")
);

reportWebVitals();
