import React,{useState, useEffect} from 'react';
import Navbar from '../../common/Navbar';
import BlockComponent from '../../components/BlockComponent';
import { useLocation } from 'react-router-dom';
import * as projectService from "../../services/projectService";
import * as customerService from "../../services/customerService";
import { message, Modal } from "antd";
import { useParams } from 'react-router-dom';
import Joi from "joi-browser";
import Form from "../../common/Form";
import { BsTrash} from "react-icons/bs";
import { FaEdit } from "react-icons/fa";

function MembershipManagement(props) {

    const location = useLocation();
    const passedSettings = location.state?.settings;
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [actionType, setActionType] = useState(null);

  
    const { companyId,projectId} = useParams();

    const filters = ["Members",""];
    const [activeKey, setActiveKey] = useState(0);
    const title = "Membership management";
    const description = "Toggle on to activate membership management & utilize this page to manage your members"
    const buttonName ="Add a member";
    const [toggle, setToggle] = useState(passedSettings.membershipManagement?passedSettings.membershipManagement:false)
    const [reload, setReload] = useState(false)
    const [members, setMembers] = useState([])
    const [itemID, setItemID] = useState("")
    const [key, setKey] = useState("1")
    



    useEffect(() => {
      const fetchData = async () => {
        const { data } = await customerService.allCustomers(projectId);
        setMembers(data)
      };
      fetchData();
    }, [reload]);




    const handleEdit = (item) => {
      setKey(item._id)
      setInputField({
        email: item.email,
        phoneNumber: item.phoneNumber,
        externalId: item.externalId,
        allowedQuestions: item.allowedQuestions,
        active:item.active
      });
  
      setActionType('edit');
      setIsModalOpen(true);
    };

    const getButtonColor =(item)=>{
      return item===true ? "outlinebutton  outlinebutton--green" : "outlinebutton  outlinebutton--red";

  }   

  
    

    let formState1=
    <table className='tableStyle'>
    <thead>
      <tr>
      <th>Email</th>
      <th>Phone Number</th>  
      <th>Unique ID</th>
      <th>Quota</th>
      <th>Usage</th>
      <th>Active</th>
      <th>Actions</th>      
      </tr>
    </thead>
     <tbody>
    {members.length> 1 && members.map((item, index) => (
        <tr key={index}>
        <td >{item.email}</td> 
        <td >{item.phoneNumber}</td> 
        <td >{item.externalId}</td> 
        <td >{item.allowedQuestions}</td> 
        <td >{item.usage.current.questions}</td> 
        <td><button className={getButtonColor(item.active)}>{item.active===true?"Active":"Paused"}</button></td> 

        <td>
          <div className='flex flexrow--rigid'>
          <BsTrash style={{ marginRight:"10px",cursor: "pointer", color: 'red'}} onClick={()=>showRevokeModal(item._id)} />
            <FaEdit style={{cursor:"pointer"}}  onClick={()=>handleEdit(item)}/>
          </div>

    
        
        
        </td> 
        {/* <td  style={{ cursor: 'pointer' }} onClick={() => handleModal(item, "capture")}><TbCapture style={{height:"20px"}}/></td>  */}
        <td>
      {/* {item.status === "failed" ? (
        <button
          className={getButtonColor(item.status)}
          style={{ cursor: 'pointer' }}
          onClick={() => handleModal(item, "retry")}
        >
    <div>
     Retry
    </div>
        </button>
      ) : (
        <button className={getButtonColor(item.status)}>{item.status}</button>
      )} */}
    </td>
        {/* <td>{getTime(item.timestamp)}</td> */}
        {/* <td style={{cursor:"pointer"}}><BsTrash onClick={()=>showRevokeModal(item._id)} style={{ color: 'red' }}/></td>  */}
    </tr>
    
    
    ))} 
    </tbody>
    </table>




    let formState2=null;


/*-------- Modal related code below --------------------------_*/

  const [isModalOpen, setIsModalOpen] = useState(false);
 
    const handleAdd =()=>{
      setKey("2")
      setInputField({
        email:"",
        phoneNumber:"",
        externalId:"",
        allowedQuestions:"",
        active:false
      })
      setIsModalOpen(true);
      setActionType('add');
    }

    const showRevokeModal =(itemId)=>{
      setDeleteModalOpen(true);
      setItemID(itemId)
    }
  

    const RevokeOk= async () => {

      try {
        const response = await customerService.deleteCustomer(companyId, projectId, itemID);
        
        if (response.status === 200) {
            setDeleteModalOpen(false);
            setLoading(false);
            message.success("Deleted succesfully");
            setReload(!reload)
        }
  
    } catch (ex) {
        if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
            setLoading(false);
            message.error(ex.response.data.msg);
        }
    }
  };




    const [inputField, setInputField] = useState({
      email:"",
      phoneNumber:"",
      externalId:"",
      allowedQuestions:"",
    });


  
    const[loading, setLoading] = useState(false);
  
  
    const [errorMessage] = useState({});


  
  
    // let auth = useAuth();
    // const history = useHistory();
  
    const template = [
  
      {
        name: "email",
        label: "Member email(optional)",
        type: "text",
        
      },
      {
        name: "phoneNumber",
        label: "Member phone number (optional)",
        type: "text",
  
      },

      {
        name: "externalId",
        label: "UniqueID (optional)",
        type: "text",
  
      },
      {
        name: "allowedQuestions",
        label: "Quota",
        type: "number",
  
      }, 
      
      {
        name: "active",
        label: "Active",
        type: "toggle",
  
      }, 
    ];


  
    const buttonInfo = { label: actionType === 'add' ? "Add a member" : "Edit a member", style: "" };
  
    const schema = {
      email: Joi.string().allow(null, "").max(50).label("Member email"),
      phoneNumber: Joi.string().allow(null, "").max(50).label("Member phone number"),
      externalId: Joi.string().allow(null, "").max(30).label("Member ID"),
      allowedQuestions: Joi.number().required().label("Question Limit"),
      active: Joi.boolean().label("Active state"),
    };



    const handleMembershipAdd = async (data) => {
      const { email, phoneNumber, externalId, allowedQuestions, active } = data;
    
      if (!email && !phoneNumber && !externalId) {
          message.error("Please add a value for at least one of these fields: email, phone number, or UniqueId.");
          setLoading(false);
          return;
      }
  


      const allowedQuestionNumber = parseInt(allowedQuestions, 10);

      data = {
        ...data,
        companyId,
        projectId,
        active,
        allowedQuestions: allowedQuestionNumber 
      }

      try {
          const response = await customerService.addCustomer(projectId, data);
          
          if (response.status === 201) {
              setIsModalOpen(false);
              setLoading(false);
              message.success("Member added successfully");
              setReload(!reload)
          }
  
      } catch (ex) {
          if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
              setLoading(false);
              message.error(ex.response.data.msg);
          }
      }


    

  /*------------------- Delete Model -------------------------------*/
  
 


  const handleOk = () => {
    setIsModalOpen(false);
  };



    setDeleteModalOpen(false);
  };

  const RevokeCancel = () => {
    setDeleteModalOpen(false);
  };


  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  



//*--------------- Toggle code below -------------------------------*//

    const handleToggle = async () => {
        try {
          const response = await projectService.updateProject(projectId, { membershipManagement: !toggle });
      
          if (response.status === 200) {
            message.success("Changes saved");
            setToggle(!toggle);
          } else {
            message.error("Failed to save changes");
          }
        } catch (error) {

          message.error("An error occurred while saving changes");
        }
      };

      const handleMembershipEdit = async(data)=>{

       
        try {
          const response = await customerService.updateCustomer(projectId, key, data);
          
          if (response.status === 200) {
              setIsModalOpen(false);
              setLoading(false);
              message.success("Member Edited succesfully");
              setReload(!reload)
          }
  
      } catch (ex) {
          if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
              setLoading(false);
              message.error(ex.response.data.msg);
          }
      }

      }



      
    
    return (
        <div>
        <Navbar/>
<BlockComponent title={title} description={description} buttonName={buttonName} filters={filters} setKey={setActiveKey}  formState1={ formState1} formState2={formState2} handleModal={handleAdd} onChange={handleToggle} toggle={toggle} activeKey={activeKey} buttonVisitbility={true}/>
<Modal title={actionType === 'add' ? "Add a member":"Edit this member" } open={isModalOpen} footer={null} onCancel={() => setIsModalOpen(false)}>
          <div style={{ display: "table-caption" }}>
        <Form
          key={inputField.externalId}
          errorMessage={errorMessage}
          inputField={inputField}
          template={template}
          schema={schema}
          buttonInfo={buttonInfo}
          onSubmit={actionType === 'add' ? handleMembershipAdd : handleMembershipEdit}
          loading={loading}
          setLoading={setLoading}
          onCancel={handleModalClose}
        />
            </div>
  </Modal>
          {<Modal 
 title="Delete this member"
 visible={deleteModalOpen}
 onOk={RevokeOk}
 onCancel={RevokeCancel}
 okButtonProps={{
   style: { backgroundColor: "red", color: "white", outline:"none" },
 }}
 okText="Confirm"  
     >
       <p>Once you delete this user, all data associated with them will be deleted
        </p>
</Modal>}          
</div>
    );

}

export default MembershipManagement;