import React, { useState, useEffect } from 'react';
import * as knowledgebaseService from "../../services/knowledgebaseService";
import { useLocation } from "react-router";
import { MdEdit } from "react-icons/md";
import { FcOk, FcHighPriority, FcAnswers } from "react-icons/fc";
import { useHistory } from "react-router";
import { Button, message, Modal, Tooltip, Empty } from 'antd';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import upload from '../../images/upload.svg';
import spreadsheet from '../../images/spreadsheet.svg';
import empty from '../../images/empty.svg';
import url from '../../images/url.svg';
import { BiRefresh } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import NavigationBar from '../../common/NavigationBar';
import { FcInspection } from "react-icons/fc";


function ExtractionFiles({ project: propProject, isSuperdash, companyId, corpus: propCorpus, loading, reload, setReload,  setActiveProject, activeProject, projects }) {
    let location = useLocation();

    // const [loading, setLoading] = useState(true);


    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [etag, setEtag] = useState("");

    const getType = (item) => {
        if (item === "url_list")
            return <Tooltip title="url"><img style={{ width: "25px" }} src={url} alt="url"></img></Tooltip>;
        if (item === "google_sheet")
            return <Tooltip title="google sheets"><img style={{ width: "25px" }} src={spreadsheet} alt="google sheets"></img></Tooltip>;
        return <Tooltip title="file upload"><img style={{ width: "25px" }} src={upload} alt="file upload"></img></Tooltip>;
    };

    let project = propProject || (location.state && location.state.project)

    let corpus = propCorpus || location.state && location.state.corpus;


    const showRevokeModal =(ETAG)=>{
        setDeleteModalOpen(true);
        setEtag(ETAG);
      }

  
      const RevokeCancel = () => {
        setDeleteModalOpen(false);
      };
      
  

      const RevokeOk = async () => {
        setDeleteModalOpen(false);

        const serviceMethod = isSuperdash ? knowledgebaseService.deletefile: knowledgebaseService.deletefileforPortal;

        try {
            const response = await serviceMethod (project, etag);
                
            if (response.status === 200) {
                message.success("The file is successfully deleted");
                setReload(!reload)

            } else {
                message.error("Something went wrong");
            }
        } catch (error) {
            message.error("Something went wrong");
        }
    };
    


    return (
        <div style={{overflow:"auto", height:"auto", marginLeft: "22vw" }}>
             
            <div style={{ width: "80vw" }}>
            <NavigationBar companyId={companyId} setActiveProject={setActiveProject} activeProject={activeProject} projects={projects}  />
                {! corpus? (
                   <SkeletonTheme color="#b5b5b5" highlightColor="#d6d6d6">
                        <div style={{height:"100vh"}} className='flexcolumn flexcolumn--allcenter'>
                            <table style={{ margin: "50px" }}>
                                <thead>
                                    <tr>
                                        <th><Skeleton width={100} /></th>
                                        <th><Skeleton width={200} /></th>
                                        <th><Skeleton width={100} /></th>
                                        <th><Skeleton width={100} /></th>
                                        <th><Skeleton width={100} /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><Skeleton width={100} /></td>
                                        <td><Skeleton width={200} /></td>
                                        <td><Skeleton width={100} /></td>
                                        <td><Skeleton width={100} /></td>
                                        <td><Skeleton width={100} /></td>
                                    </tr>
                                    <tr>
                                        <td><Skeleton width={100} /></td>
                                        <td><Skeleton width={200} /></td>
                                        <td><Skeleton width={100} /></td>
                                        <td><Skeleton width={100} /></td>
                                        <td><Skeleton width={100} /></td>
                                    </tr>
                                    <tr>
                                        <td><Skeleton width={100} /></td>
                                        <td><Skeleton width={200} /></td>
                                        <td><Skeleton width={100} /></td>
                                        <td><Skeleton width={100} /></td>
                                        <td><Skeleton width={100} /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </SkeletonTheme>
                ) : corpus && corpus.length === 0 ? <div style={{textAlign:"center"}}>
                     <img style={{width:"250px", marginTop:"10%"}} src={empty}></img>
                     <h3>This space is empty. <br/>Please use upload section to add more files</h3>
                                                   </div>
               :
                (
                    corpus && corpus.length >= 1 && (
                    <table style={{ margin: "20px 50px", background: "white" }} className='newTableStyle'>
                            <thead>
                                <tr>
                                    <th>TYPE</th>
                                    <th>FILE NAME</th>
                                    <th>STATUS</th>
                                    <th>DELETE</th>
                                    {/* <th>RUN</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {corpus.map((item, index) => (
                                    <tr key={index}>
                                        <td>{getType(item.type)}</td>
                                        <td>{item.type !== "s3" ? item.location : item.filename}</td>
                                        <td style={{ width: "10px" }}>
                                            {item.state === "finished" ?
                                                <Tooltip title="finished">
                                                    <FcOk size={20} style={{ width: "25%" }} />
                                                </Tooltip> :
                                                item.state === "failed" ?
                                                    <Tooltip title="failed">
                                                        <FcHighPriority size={20} style={{ width: "25%" }} />
                                                    </Tooltip> :
                                                    <Tooltip title="submitted">
                                                        <FcInspection size={30} style={{ width: "35%" }} />
                                                    </Tooltip>
                                            }
                                        </td>
                                        <td>
                                            <RiDeleteBin6Line onClick={()=>showRevokeModal(item.eTag)} style={{ cursor: "pointer", color: "red", opacity: "0.8" }} />
                                        </td>
            
                                    </tr>
                                ))}
                            </tbody>
                            {<Modal 
               title="Delete File"
               visible={deleteModalOpen}
               onOk={RevokeOk}
               onCancel={RevokeCancel}
               okButtonProps={{
                 style: { backgroundColor: "red", color: "white", outline:"none" },
               }}
               okText="Delete permantly"  
                   >
            <p>
            
                Removing this file will halt any ongoing pipeline that is currently using it. If, however, the processing of this document has already concluded, the existing workflow will remain unaffected.
                <br/></p>
            
            
            </Modal>}
                        </table>
                    )
                )}
            </div>
        </div>
    );
}

export default ExtractionFiles;
