import React from 'react';
import { Space, Select, Input, Button } from 'antd';

const { Option } = Select;

function InputWithDropdown({handleOptionChange,onInputChange, onSubmit, options }) {



if(options)
  return (
<div>
    <Space.Compact style={{ width: '100%' }}>
      <Select defaultValue={options[0]} options={options.map(option => ({ label: option, value: option }))} />
      <Input onChange={onInputChange} placeholder="Type here..." style={{ width: '700px' }} />
      <Button onClick={onSubmit} type="primary" >Submit</Button>
    </Space.Compact>
    </div>
  );

  return null;
}



export default InputWithDropdown;
