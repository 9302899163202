import React,{useState, useEffect} from 'react';
import TabContainer from '../../common/TabContainer';
import CSPortal from './CSPortal';
import CSCompany from './CSCompany';
import Navbar from '../../common/Navbar';
import { useLocation } from 'react-router-dom';
import CSGlobalInfo from './CSGlobalInfo';

function CS(props) {

    const [activeKey, setActiveKey] = useState(0);
    const filters = ["Company info","Portal", "Company Documents"];


    const location = useLocation();

    useEffect(() => {
        if (location.state && location.state.activeState) {
            setActiveKey(location.state.activeState);
        }
    }, [location]);

    return (
        <div>
                  <Navbar/>
            <div className="flexcolumn flexcolumn--allcenter" style={{minHeight:"100vh", width:"100vw", marginBottom:"40px"}}>
            
                        <div style={{marginTop:"40px"}}>
                            <TabContainer filters={filters} setKey={setActiveKey} activeKey={activeKey} />
                        </div>
                  <div style={{background:"white", padding:"50px", width:"1000px", minHeight:"500px", borderRadius:"25px", height:"auto", marginTop:"20px", marginBottom:"50px"}}>
                {activeKey===0 && <CSCompany/>}
                {activeKey===1 && <CSPortal/>}
                {activeKey===2 && <CSGlobalInfo/>}
            </div>
            </div>
        </div>
    );
}

export default CS;