import { DownOutlined } from '@ant-design/icons';
import { Button, Divider, Dropdown, Space } from 'antd';
import React, { useState } from 'react';
import { useHistory } from "react-router";

function ProjectsBox({ projects, activeProject, setActiveProject }) {

    const [visible, setVisible] = useState(false)

    let history = useHistory();
   
    const dropdownStyle = {
        minWidth: '200px',
        backgroundColor: '#fff',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
        padding: '8px',
      };
      
      const menuStyle = {
        maxHeight: '200px',
        overflowY: 'auto',
      };
      
      const menuItemStyle = {
        padding: '8px 16px',
        cursor: 'pointer',
      };
      
      const menuItemHoverStyle = {
        backgroundColor: '#f5f5f5',
      };

const handleClick =(index)=>{
    setActiveProject(index)
    setVisible(false);
    window.location = "/"
}

//   const items = projects.map((option, index) => ({
//     key: index,
//     label: (
//       <div  onClick={()=>handleClick(index)}>
//         {option.name}
//       </div>
//     ),
//   }));



const handleProject =()=>{


  const hostname = window.location.hostname;


        if (hostname.includes('extraction')) {
            history.replace({ pathname: `/extraction-onboarding/${projects[0].company._id}` });
        } else {
            history.replace({ pathname: `/onboarding/${projects[0].company._id}` });
        }
}


 if(projects.length >= 1) 
  return (
    <Dropdown
    onVisibleChange={(visible) => setVisible(visible)}
    visible={visible}

    overlay={
        <div style={dropdownStyle}>
              <div style={menuStyle}>
        {projects && projects.map((option, index) => (
          <div 
        //   style={{padding:"5px"}} 
          key={index}
          style={menuItemStyle}
          onClick={()=>handleClick(index)}
          onMouseOver={(e) => {
            e.currentTarget.style.backgroundColor = '#f5f5f5';
            e.currentTarget.style.cursor = 'pointer';
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.backgroundColor = '';
          }}
          
          
        >{option.name}</div>
        ))}
       <Divider style={{ margin: "0px" }} />
          <Space style={{ padding: "15px 5px" }}>
            <Button onClick={handleProject} type="primary">Create new Workspace</Button>
          </Space>
          </div>
      </div>
    }
    trigger={['click']}
  >
    <div
      style={{marginRight:"50px"}} 
        className="projectbox"
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        {projects && projects[activeProject] && projects[activeProject].name} <DownOutlined /> 
      </div>
  </Dropdown>
  );

  return null;

    // if(projects.length > 1) {
  //     return (
  //         <div>
  //             <div style={{marginRight: "30px"}} className='projectbox'>
  //                 {projects[activeProject].name}
  //                 <Dropdown overlay={menu}>
  //                     <a
  //                         className="ant-dropdown-link"
  //                         onClick={(e) => e.preventDefault()}
  //                     >
  //                         <DownOutlined />
  //                     </a>
  //                 </Dropdown>
  //             </div>
  //         </div>
  //     );
  // }

 
}

export default ProjectsBox;
