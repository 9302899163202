import React from "react";

const Search = ({ value, onChange, onSearch, handleBackspaceKeyPress, style }) => {
  return (
    <input
      style = {style?style:null}
      type="text"
      name="query"
      className="searchbox"
      placeholder="Search for the exact phrases you have in your documents"
      value={value}
      onChange={(e) => onSearch(e.currentTarget.value)}
      onKeyDown={handleBackspaceKeyPress}
    />
  );
};

export default Search;
