import React from 'react';
import Search from '../common/Search';

function SearchPage(props) {
    return (
        <div  className="flexrow" style={{ height:"100vh", width:"100vw", justifyContent: "space-around"}}>
            <div style={{width:"1400px", marginTop:"30px"}}>
                <Search/>
                <div className='grid grid--7030'>
                <div className='whitebox'>
                <h3>Quick answer</h3>
                <p>

                To connect Dropbox to your account, follow these steps:

Go to the Dropbox website (https://www.dropbox.com/) and click on the "Sign In" button in the top right corner of the screen.

Enter your email address and password to log in to your account.

Click on the profile icon in the top right corner of the screen and select "Settings" from the dropdown menu.

Click on the "Connections" tab in the left-hand menu.

Under the "Connected apps and devices" section, click on the "Link a new account" button.

Follow the prompts to connect your Dropbox account to the service you want to use it with.

If you have any trouble connecting your Dropbox account, please don't hesitate to reach out to our support team for assistance. We're always happy to help!




                </p>
                </div>
                <div>
                    <div style={{margin:"20px"}}>
                       <h3>Quick reference</h3>
                        </div>
                    <div className='smallwhitebox'><div className='leftborder'>One way to connect Dropbox to your account is to use a tool called Zapier. Zapier is a platform that allows you to connect different web services and automate tasks between them.</div></div>
                    <div className='smallwhitebox'><div className='leftborder'>Connect Dropbox to your account is through the use of an application programming interface (API). An API is a set of rules and protocols that allows different software systems to communicate with each other.</div></div>
                    <div className='smallwhitebox'><div className='leftborder'>If you want to connect your Dropbox account to another service, one option is to use the Dropbox API. The Dropbox API allows you to access and manipulate data stored in your Dropbox account programmatically, using a set of rules and protocols that enable different software systems to communicate with each other.</div></div>
                </div>
                </div>
            </div>
        </div>
    );
}

export default SearchPage;