import React from "react";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import config from "./config.json";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import "react-toastify/dist/ReactToastify.css";
import "antd/dist/antd.css";
import "./scss/App.scss";
// import NotFound from "./pages/NotFound";
// import Home from "./pages/Home";
import ProtectedRoute from "./common/ProtectedRoute";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";


import TagManager from 'react-gtm-module';
import Home from "./pages/Home";
import Welcome from "./pages/Welcome";
import SetImage from "./pages/SetImage";
import Logout from "./pages/Logout";
import Onboarding from "./pages/Onboarding";
import Connection from "./pages/Succesful";
import Succesful from "./pages/Succesful";
import Billing from "./pages/Billing";
import Success from "./pages/ThirdPartySuccess";
import Cancelled from "./pages/Cancelled";
import GooglePlay from "./pages/GooglePlay";
import GoogleBusiness from "./pages/GoogleBusiness";
import BusinessSuccesful from "./pages/BusinessSuccesful";
import Integration from "./pages/Integration";
import FileUpload from "./pages/FileUpload";
import SearchPage from "./pages/SearchPage";
import ConfirmMail from "./pages/ConfirmMail";
import TempPage from "./pages/TempPage";
import PDFExtract from "./pages/FineTuning/PDFExtract";
import ExtractForm from "./components/ExtractForm";

import KBSearch from "./pages/KBSearch";
import TrainingOptions from "./pages/TrainingOptions";
import TrainingData from "./pages/FineTuning/TrainingData";
import LongText from "./pages/FineTuning/LongText";
import SendOTP from "./pages/SentOTP";
import ResetPassword from "./pages/ResetPassword";
import SharePage from "./pages/Home/SharePage";
import Webhook from "./pages/Block/Webhook";
import HumanAgent from "./pages/Block/HumanAgent";
import Semantic from "./pages/Block/Semantic";
import WebSearch from "./pages/Block/WebSearch";

import RawPrompt from "./pages/Block/RawPrompt";
import PromptBuilder from "./pages/Block/PromptBuilder";
import MembershipManagement from "./pages/Block/MembershipManagement";
import Extraction from "./pages/Block/Extraction";
import CPLogin from "./pages/ClientPortal/CPLogin";
import StripeConnect from "./pages/StripeConnect";
import StripeConnectionReAuth from "./pages/StripeConnectReAuth";
import StripeConnectAddPage from "./pages/FormStripeConnectCustomer";
import MembershipPlans from "./pages/MembershipPlans";
import StripePlans from "./pages/Block/StripePlans";
import QNA from "./pages/Block/QNA";
import ThirdPartySuccess from "./pages/ThirdPartySuccess";


const StripePublicKey = config.StripePublicKey;

// import { login } from "./services/authService";




const stripePromise = loadStripe(StripePublicKey);


// const tagManagerArgs = {
//   gtmId: 'GTM-WQSVGK5'
// };
// TagManager.initialize(tagManagerArgs);



function App() {
  // const options = {
  //   // passing the client secret obtained from the server
  //   clientSecret: "{{CLIENT_SECRET}}",
  // };
  return (
    <div style={{
      backgroundImage: 'linear-gradient(90deg, rgb(239, 242, 247) 0%, 7.60286%, rgb(237, 240, 249) 15.2057%, 20.7513%, rgb(235, 239, 248) 26.297%, 27.6386%, rgb(235, 239, 248) 28.9803%, 38.2826%, rgb(231, 237, 249) 47.585%, 48.1216%, rgb(230, 236, 250) 48.6583%, 53.1306%, rgb(228, 236, 249) 57.6029%, 61.5385%, rgb(227, 234, 250) 65.4741%, 68.7835%, rgb(222, 234, 250) 72.093%, 75.7603%, rgb(219, 230, 248) 79.4275%, 82.8265%, rgb(216, 229, 248) 86.2254%, 87.8354%, rgb(213, 228, 249) 89.4454%, 91.8605%, rgb(210, 226, 249) 94.2755%, 95.4383%, rgb(209, 225, 248) 96.6011%, 98.3005%, rgb(208, 224, 247) 100%)'
    }}>
      <Elements stripe={stripePromise}>
    
          <ToastContainer />
          <Router>
            <Switch>
              {/* <Route path="/company/:companyId" component={Home} /> */}

       

              {/* <Route path="/setimage" component={SetImage}/> */}
              {/* <Route path="/emailsent" component={ConfirmMail} /> */}
              <Route path="/customerplans/:companyId/:projectId" component={MembershipPlans} />
              <Route path="/login" component={Login} />
              <Route path="/trysignup" component={Signup} />
              <Route path="/reset" component={SendOTP} />
              <Route path="/resetpassword/:email?" component={ResetPassword} />
              <Route path="/logout" component={Logout} />
              <Route path="/sharepage/:projectId" component={SharePage} />
              <Route path="/getmemembershipaccess/" component={StripeConnectAddPage} />
               <ProtectedRoute path="/welcome" component={Welcome} />
              <ProtectedRoute path="/search" component={SearchPage} />
              <ProtectedRoute path="/trainingdata" component={TrainingData} />
              {/* <ProtectedRoute path="/longtext" component={LongText} /> */}
              <ProtectedRoute path="/billing" component={Billing} /> 
              <ProtectedRoute path="/webhook/:projectId" component={Webhook} /> 
              <ProtectedRoute path="/success" component={Success} /> 
              <ProtectedRoute path="/cancelled" component={Cancelled} /> 
              <ProtectedRoute path="/humanagent/:projectId" component={HumanAgent}/>
              <ProtectedRoute path="/websearch/:projectId" component={WebSearch}/>
              {/* <ProtectedRoute path="/rawprompt/:projectId" component={RawPrompt}/> */}
              {/* <ProtectedRoute path="/stripeplans/:projectId" component={StripePlans}/> */}
              <ProtectedRoute path="/semantic/:projectId" component={Semantic}/>
              <ProtectedRoute path="/stripeconnect/:companyId" component={StripeConnect}/>
              <ProtectedRoute path="/stripeconnectreauth/:companyId?/:accountId?" component={StripeConnectionReAuth}/>
              <ProtectedRoute path="/membermanagement/:companyId/:projectId" component={MembershipManagement}/>
              <ProtectedRoute path="/thirdparty/success" component={ThirdPartySuccess}/>              
              <ProtectedRoute path="/prompt/:projectId" component={PromptBuilder}/>
              <ProtectedRoute path="/qna/:projectId" component={QNA}/>
              <ProtectedRoute path="/rawprompt/:projectId" component={RawPrompt}/>
              <ProtectedRoute path="/onboarding/:companyId" component={Onboarding}/>
              <ProtectedRoute path="/training/:projectId" component={TrainingOptions}/>
              <ProtectedRoute path="/kbsearch/:projectId" component={KBSearch}/>
              <ProtectedRoute path="/integration/:companyId" component={Integration}/>
              <ProtectedRoute path="/extraction/:projectId" component={Extraction}/>



              {/* <Route path="/cplogin" component={CPLogin} /> */}

              {/* <ProtectedRoute path="/fileupload/:companyId" component={FileUpload}/> */}
              {/* <ProtectedRoute path="/gih" component={PDFExtract}/>  */}
              <ProtectedRoute path="/" component={Home}/>
               {/* <Route to="/not-found" component={NotFound} /> */}
              <Redirect to="/not-found" /> 
            </Switch>
          </Router>
     
      </Elements> 
    </div>
  );
}



//specific ones to generic ones

export default App;
