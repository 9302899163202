import React,{useState} from 'react';
// import logo from '../images/logo.svg';
import { useHistory} from "react-router";
import { MdOutlineArrowDropDownCircle } from "react-icons/md";
import { Menu, Dropdown } from "antd";
import { Link } from 'react-router-dom';
import { BsFileBarGraph,BsPersonBadge ,BsGear } from 'react-icons/bs';
import { FaRegPaperPlane, FaBolt } from 'react-icons/fa';
import { GoLinkExternal } from "react-icons/go";
import { BsScrewdriver } from "react-icons/bs";
import { BiChat} from "react-icons/bi";
import { TiFlowChildren } from "react-icons/ti";






function MenuBar({listing, menuState, setMenuState, menuOptions}) {

  

    const history = useHistory();
    const url = history.location.pathname;

    // const handleEditPage = async () => {
   
    //     history.push({
    //       pathname: "/editlisting/" ,
    //       state: {
    //         streamerName: listing.streamerName,
    //         displayName: listing.displayName,
    //         bio: listing.bio,
    //         customMessage: listing.customMessage,
    //         currency: listing.currency,
    //         minAmount:listing.minAmount,
    //         listingId :listing._id,
    //         streamerImage:listing.streamerImage
    //       },
    //     });
      
      
      
    //   }


    const handleFanPage =()=>{
        history.push({
        //   pathname: "/" + listing.streamerName + "/returnTo=admin",
        });
      }


      const getselectedClasses =(item)=>{
     
        return menuState===item?" menuitem flexrow selected":" menuitem flexrow";
        
            }


            const  handleMenuState =(item)=>{
                setMenuState(item)
              }

            
              const getMenuClasses =(item)=>{
        

                return menuState===item?"menuitem__active":"menuitem__inactive";

              }
            



    const menu = (
    
        <Menu>
    
             {/* <Menu.Item key="5">
            Change Profile pic
            <Link to={`/set-profile-pic`}></Link>
           </Menu.Item> 
    
           <Menu.Item key="6" >
              Whatzzznew Setup
              <Link
                to={{
                  pathname: "/configuration",
                }}
              ></Link>
            </Menu.Item>
              
      
            <Menu.Item key="7" >
              Billing
              <Link
                to={{
                  pathname: "/billing"
                }}
              ></Link>
            </Menu.Item>*/}
    
    
            {/* <Menu.Item key="8" >
              Edit Profile
              <Link
       to={{
        pathname: "/editlisting/" ,
        state: {
          streamerName: listing.streamerName,
          displayName: listing.displayName,
          bio: listing.bio,
          customMessage: listing.customMessage,
          currency: listing.currency,
          minAmount:listing.minAmount,
          listingId :listing._id,
          streamerImage:listing.streamerImage
        },
            }}
              ></Link>
            </Menu.Item>  */}
    
         
{/*     
          <Menu.Item key="9">
            Logout<a href="/logout"></a>
          </Menu.Item> */}
        </Menu>
      );
    


    return (
        <div className='menubar'>
<div className='flexrow--rigid wideapart'>
    
    <div  style={{cursor:"pointer"}}>
        
        {/* <img alt="company_logo" style={{height:"70px"}} src={logo}></img> */}
   
    </div>
    
    <div  style={{cursor:"pointer", "paddingTop": "10px"}}>
             {/* {<Dropdown overlay={menu}         onClick={(e) => e.preventDefault()} placement="bottomRight" arrow={{ pointAtCenter: true }}>
             <MdOutlineArrowDropDownCircle style={{color:"white", marginRight:"20px", width:"25px"}}/>
            </Dropdown>} */}
    </div>


</div>

<div>




    <div
    onClick={() =>  handleMenuState(menuOptions[0])}
    className={getselectedClasses(menuOptions[0])}
    >
                              <div>
                                  <FaRegPaperPlane style={{marginRight: "35px"}}className=' svgIcon'/>
                              </div>
                              <div  className={getMenuClasses(menuOptions[0])}
                              >{menuOptions[0]}</div>
                            </div>
    
    
    
                            <div
                            onClick={() => handleMenuState(menuOptions[1])}
                            className={getselectedClasses(menuOptions[1])}
                            >
                              <div >
                                  <BsFileBarGraph style={{marginRight: "35px"}} className='svgIcon'/>
                              </div>
                              <div className={getMenuClasses(menuOptions[1])}
                              
                              >{menuOptions[1]}</div>
                            </div>


                            <div
                            onClick={() => handleMenuState(menuOptions[2])}
                            className={getselectedClasses(menuOptions[2])}
                            >
                              <div >
                                  <TiFlowChildren style={{marginRight: "35px"}} className='svgIcon'/>
                              </div>
                              <div className={getMenuClasses(menuOptions[2])}
                              
                              >{menuOptions[2]}</div>
                            </div>



                            <div
                            onClick={() => handleMenuState(menuOptions[3])}
                            className={getselectedClasses(menuOptions[3])}
                            >
                              <div >
                                  <BiChat style={{marginRight: "35px"}} className='svgIcon'/>
                              </div>
                              <div className={getMenuClasses(menuOptions[3])}
                              
                              >{menuOptions[3]}</div>
                            </div>


                       









                             <div
                            onClick={() => handleMenuState(menuOptions[4])}
                            className={getselectedClasses(menuOptions[4])}
                            >
                              <div >
                                  <FaBolt  style={{marginRight: "35px"}} className='svgIcon'/>
                              </div>
                              <div className={getMenuClasses(menuOptions[4])}
                              
                              >{menuOptions[4]}</div>
                            </div> 

                            <div
                            onClick={() => handleMenuState(menuOptions[5])}
                            className={getselectedClasses(menuOptions[5])}
                            >
                              <div >
                                  <BsScrewdriver style={{marginRight: "35px"}} className='svgIcon'/>
                              </div>
                              <div className={getMenuClasses(menuOptions[5])}
                              
                              >{menuOptions[5]}</div>
                            </div> 





                            {/* <div
                            onClick={() => handleFanPage()}
                            className="bb menuitem flexrow"
                            >
                              <div >
                                  <BsPersonBadge style={{marginRight: "35px"}} className='svgIcon'/>
                              </div>
                              <div className='flexrow--rigid horizontal--center'>
                                  <div className="menuitem__inactive"
                                  
                                  >My Public profile</div>
                                  <GoLinkExternal style={{marginLeft:"10px", opacity:"0.5"}}/>
                              </div>
                            </div> */}
</div>

                        {/* <div className='bb menuitem'>
                      
                  <CopyToClipboard text={profileLink}>
                  <div className="wideapart" onClick ={handleCopy}>
                    <FaLink style={{marginRight:"10px"}} />
                    Copy shareable profile link
                  </div>
                  </CopyToClipboard>
                
                        </div> */}

                    
                    
                    
          



        </div>
    );
}

export default MenuBar;