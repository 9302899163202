import React,{useState} from "react";
import { Link } from "react-router-dom";
import { useUserContext } from "../userContext";
import { Menu, Dropdown, message } from "antd";
import { DownOutlined } from "@ant-design/icons";
import ProjectsBox from "../components/ProjectsBox";
import DemoComponent from "../components/DemoComponent.js";
import { useHistory } from 'react-router-dom';



// import logo from '../images/logo.png';


function Navbar({projects, activeProject, setActiveProject, company, KB ,corpus, sign, setRefresh,refresh}) {
  
  // const user = useUserContext();

  const [open, setOpen] = useState(false);

const title = "Talk to Your Custom Data"

const location = window.location.pathname;


let companyId = company && company._id;
  
  const apiPoint = process.env.REACT_APP_API_POINT;

  const menu = (
    
    <Menu>

        {/* <Menu.Item key="5">
        Change Profile pic
        <Link to={`/set-profile-pic`}></Link>
       </Menu.Item> */}

  
          
  
         {/* <Menu.Item key="7" >
          Billing
          <Link
            to={{
              pathname: "/billing",
              state: { company:company
              }
            }}

            
          ></Link>
        </Menu.Item>   */}

          <Menu.Item key="8" >
          Connect Stripe
          <Link
            to={{
              pathname: `/stripeconnect/${companyId}`,
            }}

            
          ></Link>
        </Menu.Item>  


        {/* <Menu.Item key="9" >
          Company settings 
          <Link
            to={{
              pathname: "/settings"
            }}
          ></Link>
        </Menu.Item> */}

     



      <Menu.Item key="10">
        Logout<a href="/logout"></a>
      </Menu.Item>
    </Menu>
  );



const handleTalk =()=>{

  // if(sign!="green")
  // message.info("Please note that training is not complete for some of the documents.")

  setOpen(true)
}

const history = useHistory();

function handleClick() {
  history.push('/');
}





  // Determine the CSS style based on the sign parameter
  function getColoredCircle(sign) {
    switch (sign) {
      case 'red':
        return "CircleClass CircleClass--red";
      case 'green':
        return "CircleClass CircleClass--green";
      case 'orange':
        return "CircleClass CircleClass--orange"
      default:
        return ''; // Invalid sign, return an empty string
    }
  }

const getContent = (sign) => {
  const circleClass = getColoredCircle(sign);
  return (
    <div className={circleClass}></div>
  );
};


  return (
    <React.Fragment>
      <div className="navbar wideapart">



          <h3 style={{cursor:"pointer", color:"white", fontWeight:"800px"}} onClick={handleClick}>Superdash</h3>


        {/* <button className="btnn-text" onClick={handleClick}><img alt="company_logo" className="logo" ></img></button> */}
        <div className="flexrow">

        {location === '/' && (
        <button onClick={handleTalk} className="button--blue">
          <div style={{ alignItems: "center" }} className="flexrow flexrow--rigid">
            {getContent(sign)}
            <span style={{ marginLeft: "10px" }}>Talk to AI</span>
          </div>
        </button>
      )}

  {projects && <ProjectsBox projects={projects} activeProject={activeProject} setActiveProject={setActiveProject}/>}
          <div>
            <Dropdown overlay={menu}>
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                {/* {user ? <img alt="your account" className="profilepic" src={user.user.avatar}/> : ""} */}
                <DownOutlined />
              </a>
            </Dropdown>
          </div>
        </div>
      </div>
      {projects &&<DemoComponent activeProject={activeProject} projects={projects} setOpen={setOpen} open={open} sign={sign} title={title} setRefresh={setRefresh} refresh={refresh}/>}




    </React.Fragment>
  );
}

export default Navbar;
